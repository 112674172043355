/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Component, Input } from '@angular/core';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { MultiWidgetDividerLocation } from 'src/app/api-wrapper/models/widget-models/image-text-widget-model';
import { IWidgetSupport } from '../../models/widget-support-info.model';

@Component({
    selector: 'app-abstract-real-time-multi-base',
    template: '',
    styles: [],
})
export abstract class BaseMultiRealTimeWidgetComponent<
    FormData,
    ConfigData extends IWidgetSupport
> extends AbstractFormBaseComponent<any, ConfigData> {
    @Input() location: 'detail' | 'real-time' = 'real-time';
    @Input() dividerLocation: MultiWidgetDividerLocation;
    @Input() flipped: boolean;
    mouseOnButton = false;
    originalX = 0;
    testEnv = false;

    get dividerLocationValue(): MultiWidgetDividerLocation {
        return this.location === 'detail'
            ? this.dividerLocation
            : (this.form.get('dividerLocation')?.value as MultiWidgetDividerLocation) ?? 'CENTER';
    }

    get flippedValue(): boolean {
        return this.location === 'detail' ? this.flipped : this.form.get('flipped')?.value ?? false;
    }

    constructor() {
        super();
    }

    abstract createForm(): FormGroupTyped<FormData>;

    switchSides(): void {
        this.form.patchValue({ flipped: !this.flippedValue });
    }

    setStarterImageSize(): string {
        let size: string;
        switch (this.config?.starterWidgetSize) {
            case WidgetSizeEnum.FULLSIZE:
                size = 'XL';
                break;
            default:
                size = 'L';
                break;
        }
        return size;
    }

    changeLayout(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        if (!this.mouseOnButton) {
            this.originalX = 0;
            return;
        }

        if ((event.target as HTMLElement).classList.contains('nitroicon-switch_sides')) {
            return;
        }
        if (this.originalX === 0) {
            this.originalX = event.x;
            return;
        }
        const diff = this.originalX - event.x;

        if (Math.abs(diff) < 5) {
            return;
        }
        if ((diff > 0 && this.dividerLocationValue === 'LEFT') || (diff < 0 && this.dividerLocationValue === 'RIGHT')) {
            return;
        }
        let dividerLocation: MultiWidgetDividerLocation = 'CENTER';
        if (this.dividerLocationValue === 'CENTER') {
            dividerLocation = diff > 0 ? 'LEFT' : 'RIGHT';
        }
        this.form.patchValue({ dividerLocation });
    }
}
