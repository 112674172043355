import { ExamWidgetData } from 'src/app/api/models/exam-widget-data';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { SimpleExamConfigResponse } from '@apiModels';

export interface RealTimeExamWidgetForm {
    examId: number;
    examData: SimpleExamConfigResponse;
}

export interface DetailExamWidgetForm {
    realTimeExamWidgetFormData: RealTimeExamWidgetForm;
    examId: number;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'QUESTION_ANSWERED' | 'QUESTION_CORRECTLY_ANSWERED' | 'SHOW';
}

export interface ExamWidgetDataSpec extends ExamWidgetData {
    fulfillment: 'NONE' | 'QUESTION_ANSWERED' | 'QUESTION_CORRECTLY_ANSWERED' | 'SHOW';
}

export class ExamWidgetClass extends BaseWidgetClass<ExamWidgetDataSpec> {
    constructor(data: TypedResponse<ExamWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeExamWidgetForm {
        return {
            examData: this.data.examData ?? {},
            examId: this.data?.examId ?? null,
        };
    }

    getDetailFormData(): DetailExamWidgetForm {
        return {
            realTimeExamWidgetFormData: this.getRealTimeFormData(),
            examId: this.data.examId ?? null,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'QUESTION_ANSWERED' : 'NONE'),
        };
    }

    updateWidgetData(modifiedData: RealTimeExamWidgetForm | DetailExamWidgetForm): void {
        this.data = { ...this.data, ...modifiedData };
    }
}
