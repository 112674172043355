import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { RealTimeAnchorWidgetForm } from 'src/app/api-wrapper/models/widget-models/anchor-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

@Component({
    selector: 'app-anchor-real-time-widget',
    templateUrl: './anchor-real-time-widget.component.html',
    styleUrls: ['./anchor-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AnchorRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class AnchorRealTimeWidgetComponent
    extends AbstractFormBaseComponent<RealTimeAnchorWidgetForm, IWidgetSupport>
    implements OnInit
{
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    testEnv = false;
    visible: boolean;
    state: 'TEMPLATE' | 'EDITOR' = 'TEMPLATE';

    get name(): string {
        return this.form && this.form.get('name').value;
    }

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.visible = (this.form.get('visible')?.value as boolean) ?? false;
    }

    createForm(): FormGroupTyped<RealTimeAnchorWidgetForm> {
        return new FormGroupTyped<RealTimeAnchorWidgetForm>({
            name: FormHelper.controlFactoryWithCalculatedValue(null),
            structural: FormHelper.controlFactoryWithCalculatedValue(null),
            visible: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    setVisibility(visible: boolean): void {
        this.visible = visible;
        this.form.patchValue({ visible });
    }

    afterBlur(): void {
        this.state = 'TEMPLATE';
    }

    stateToEditor(): void {
        this.state = 'EDITOR';
        this.setFocusOnInput();
    }

    configDataChanged(): void {
        setTimeout(() => {
            this.state = 'EDITOR';
            this.setFocusOnInput();
        }, 0);
    }

    setFocusOnInput(): void {
        setTimeout(() => {
            (document.querySelector(`#${this.config?.focusElemId}`) as HTMLInputElement).focus();
        }, 0);
    }
}
