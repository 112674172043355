<header class="header">
    <h3>{{ 'QUILL.LINK-EDIT-TITLE' | translate }}</h3>
    <button
        class="editor-btn height-small radius-25 border-1 primary icon-only tooltip-right tooltip-delay"
        [attr.data-tooltip]="'QUILL.LINK-EDIT-CANCEL' | translate"
        (click)="cancel()"
    >
        <span class="nitroicon-close editor-btn-icon"></span>
    </button>
</header>

<form [formGroup]="form" *ngIf="form" (submit)="addLink()">
    <input
        class="link-input"
        [class.invalid]="form.get('link').hasError('required') && form.get('link').touched"
        [formControlName]="'link'"
        [placeholder]="'QUILL.LINK-EDIT-PLACEHOLDER' | translate"
    />

    <div class="detail-form__group">
        <app-radio-group-control
            class="detail-form__group app-radio-group-control"
            [title]="'QUILL.LINK-EDIT-LOCATION-TITLE' | translate"
            [options]="targetOptions"
            formControlName="target"
        ></app-radio-group-control>
    </div>

    <footer class="footer">
        <button *ngIf="hasStartValue" type="button" (click)="deleteLink()" class="editor-btn ghost danger action-btn">
            {{ 'QUILL.LINK-EDIT-DELETE' | translate }}
        </button>
        <button type="button" (click)="addLink()" class="editor-btn ghost primary action-btn">
            {{ 'QUILL.LINK-EDIT-DONE' | translate }}
        </button>
    </footer>
</form>
