import {
    UntypedFormGroup,
    AbstractControl,
    ValidatorFn,
    AbstractControlOptions,
    AsyncValidatorFn,
} from '@angular/forms';

import { Observable } from 'rxjs';

export class FormGroupTyped<T> extends UntypedFormGroup {
    readonly value: T;
    readonly valueChanges: Observable<T>;

    constructor(
        controls: { [key in keyof T]: AbstractControl } | {},
        validatorOrOpts?: ValidatorFn | Array<ValidatorFn> | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | Array<AsyncValidatorFn> | null
    ) {
        super(controls, validatorOrOpts, asyncValidator);
    }

    patchValue(
        value: Partial<T> | T,
        options?: {
            onlySelf?: boolean;
            emitEvent?: boolean;
        }
    ): void {
        super.patchValue(value, options);
    }

    setValue(
        value: Partial<T> | T,
        options?: {
            onlySelf?: boolean;
            emitEvent?: boolean;
            emitModelToViewChange?: boolean;
            emitViewToModelChange?: boolean;
        }
    ): void {
        super.setValue(value, options);
    }

    addControl(name: Extract<keyof T, string> | string, control: AbstractControl): void {
        super.addControl(name, control);
    }

    setControl(name: Extract<keyof T, string> | string, control: AbstractControl): void {
        super.setControl(name, control);
    }

    removeControl(name: Extract<keyof T, string> | string): void {
        super.removeControl(name);
    }

    get(path: Array<Extract<keyof T, string>> | Extract<keyof T, string> | string): AbstractControl | never | null {
        return super.get(path);
    }

    getRawValue(): T {
        return super.getRawValue() as T;
    }
}
