import { IFileUploadElement, IFileUploadValue } from '@shared/models/file-upload.model';

export abstract class BaseSubFormClass<T, F> {
    data: T;
    fileUploadElement: IFileUploadElement | null = null;
    filesToSave: IFileUploadElement[] = [];

    abstract getFormData(): F;
    abstract updateData(modifiedData: F): void;

    constructor(data: T) {
        this.data = data;
    }

    getFilesToSave(): IFileUploadElement[] {
        const returnValues = this.filesToSave.map((file) => file);
        this.filesToSave = [];
        return returnValues;
    }

    removeResourceIds(resourceIds: string[]): void {}

    extractUUID(element: IFileUploadValue): string | null {
        return element.fileItem ? element.fileItem.uuid : element.elementId;
    }

    shouldSaveFile(resource: IFileUploadElement, valueToCompareTo: string | null): boolean {
        return resource !== null && resource.uuid !== valueToCompareTo;
    }

    getDataForSave(): T {
        return { ...this.data };
    }
}
