import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';
import { filter, pluck } from 'rxjs/operators';
import { IChoiceAnswerModel } from 'src/app/api-wrapper/models/widget-models/questions/choice-question/choice-anser.model';
import { RealTimeChoiceQuestionWidgetForm } from 'src/app/api-wrapper/models/widget-models/questions/choice-question/choice-question-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { BaseQuestionRealTimeWidgetComponent } from '../../base-classes/abstract-base-real-time-question';
import { iChoiceAnswerFactory, realTimeBaseQuestionWidgetFormFactory } from '../../helpers/choice-answers.helpers';

@Component({
    selector: 'app-single-choice-question-real-time-widget',
    templateUrl: './single-choice-question-real-time-widget.component.html',
    styleUrls: ['./single-choice-question-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleChoiceQuestionRealTimeWidgetComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleChoiceQuestionRealTimeWidgetComponent extends BaseQuestionRealTimeWidgetComponent<
    RealTimeChoiceQuestionWidgetForm,
    IWidgetSupport
> {
    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    get answers(): UntypedFormArray {
        return this.form.get('answers') as UntypedFormArray;
    }

    updateForm(value: RealTimeChoiceQuestionWidgetForm): void {
        this.shouldSave = false;
        super.updateForm(value);
        this.form.setControl('answers', FormHelper.initBaseFormarray(value.answers, this.createAnswer));
        this.cdr.detectChanges();
        this.shouldSave = true;
    }

    createAnswer = (data?: Partial<IChoiceAnswerModel>): FormGroupTyped<IChoiceAnswerModel> => {
        if (!data.id) {
            data.id = UUIDGeneratorUtility.generate();
        }
        const answerForm = iChoiceAnswerFactory(data);

        answerForm.valueChanges
            .pipe(
                pluck('correct'),
                filter((value) => value)
            )
            .subscribe(() => {
                this.getAnswersControls()
                    .filter((control) => control !== answerForm)
                    .forEach((control) =>
                        (control as UntypedFormGroup).patchValue({ correct: false }, { emitEvent: false })
                    );
            });

        return answerForm;

        // eslint-disable-next-line @typescript-eslint/semi,@typescript-eslint/member-delimiter-style
    };

    createForm(): FormGroupTyped<RealTimeChoiceQuestionWidgetForm> {
        return realTimeBaseQuestionWidgetFormFactory();
    }

    addNewAnswer(): void {
        this.answers.push(this.createAnswer({}));
    }

    removeAnswer(answerId: number): void {
        this.answers.removeAt(answerId);
    }

    getAnswersControls = (): AbstractControl[] => {
        return this.answers.controls;
        // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    };
}
