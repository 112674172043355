import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';

export function myRxStompConfigFactory(keycloak: KeycloakService): InjectableRxStompConfig {
    return {
        brokerURL: 'ws://localhost/apps-api/editor/api/v1/editorWS',
        beforeConnect: (client) => {
            const wsPreTag = environment.mode === 'dev' ? 'ws' : 'wss';
            return keycloak.getToken().then((token) => {
                client.configure({
                    brokerURL: `${wsPreTag}://${window.location.hostname}/apps-api/editor/api/v1/editorWS?access_token=${token}`,
                });
            });
        },
        // Headers
        // Typical keys: login, passcode, host
        connectHeaders: {},

        // How often to heartbeat?
        // Interval in milliseconds, set to 0 to disable
        heartbeatIncoming: 5000, // Typical value 0 - disabled
        heartbeatOutgoing: 5000, // Typical value 20000 - every 20 seconds

        // Wait in milliseconds before attempting auto reconnect
        // Set to 0 to disable
        // Typical value 500 (500 milli seconds)
        reconnectDelay: 500,

        // Will log diagnostics on console
        // It can be quite verbose, not recommended in production
        // Skip this key to stop logging to console
        debug: (msg: string): void => {
            if (environment.mode === 'dev' || environment.mode === 'test') {
                //  console.log(new Date(), msg);
            }
        },
    };
}
