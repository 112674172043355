import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '@shared/components/loader/loader.service';

@Component({
    selector: 'app-img-viewer',
    templateUrl: './img-viewer.component.html',
    styleUrls: ['./img-viewer.component.scss'],
})
export class ImgViewerComponent implements OnInit {
    imageAlt: string;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { resourceId: string; courseId: number; description?: string; title?: string },
        private loaderService: LoaderService
    ) {}

    ngOnInit(): void {
        this.loaderService.incrementXhrNumber();
    }

    onLoad(): void {
        this.loaderService.decrementXhrNumber();
        this.imageAlt = this.data.description;
    }
}
