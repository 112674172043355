import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { BaseFileUploadComponent } from '@shared/forms/file-upload/base-file-upload/base-file-upload.component';

@Component({
    selector: 'app-audio-upload',
    templateUrl: './audio-upload.component.html',
    styleUrls: ['./audio-upload.component.scss', '../base-file-upload/base-file-upload.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AudioUploadComponent),
            multi: true,
        },
    ],
})
export class AudioUploadComponent extends BaseFileUploadComponent {
    @Input() acceptTypes = 'audio/mp3';
    @Input() regExp = new RegExp(/\.(mp3)$/i);
    @Input() maxFileSize = 1_000_000_000;
    bypassSw = true;
    @ViewChild('droparea') audioElement: ElementRef<HTMLAudioElement>;

    constructor(protected toastsSrv: ToastMessageService, private cdr: ChangeDetectorRef) {
        super(toastsSrv, cdr);
    }

    startToPlay(): void {
        this.audioElement.nativeElement.play();
    }
}
