import { IFileUploadValue } from '@shared/models/file-upload.model';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';
import { OrderAnswer } from 'src/app/api/models/order-answer';

import { BaseSubFormClass } from '../../base-widget-models/base-sub-form-class';

export interface ISortingAnswerModel {
    id?: string;
    order?: number;
    mediaResourceElement?: IFileUploadValue;
    text?: string;
}

export class SortingAnswerModel extends BaseSubFormClass<OrderAnswer, ISortingAnswerModel> {
    constructor(data: OrderAnswer) {
        super(data);

        if (!this.data.id) {
            this.data.id = UUIDGeneratorUtility.generate();
        }
    }

    getFormData(): ISortingAnswerModel {
        return {
            order: this.data?.order ?? null,
            id: this.data?.id ?? null,
            mediaResourceElement: {
                fileItem: this.fileUploadElement,
                elementId: this.data.mediaResourceId ?? null,
            },
            text: this.data?.text ?? null,
        };
    }

    updateData(modifiedData: ISortingAnswerModel): void {
        const { mediaResourceElement, ...rest } = modifiedData;
        const mediaResourceId = this.extractUUID(mediaResourceElement);
        this.fileUploadElement = mediaResourceElement.fileItem;
        this.data = { ...this.data, ...rest, mediaResourceId };
    }
}
