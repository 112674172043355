<div class="learning-card__flip-container" [ngClass]="flip">
    <div class="learning-card__front">
        <div class="learning-card__quill">
            <ng-content select=".front"></ng-content>
        </div>
        <div class="learning-card__footer">
            <button (click)="toggleFlip()" class="template-btn card-btn right-icon">
                {{ 'LEARNING-CARD-WIDGET.TURN-AROUND' | translate }}
            </button>
        </div>
    </div>

    <div class="learning-card__back">
        <div class="learning-card__quill">
            <ng-content select=".back"></ng-content>
        </div>
        <div class="learning-card__footer">
            <button (click)="toggleFlip()" class="template-btn card-btn right-icon">
                {{ 'LEARNING-CARD-WIDGET.TURN-BACK' | translate }}
            </button>
        </div>
    </div>
</div>
