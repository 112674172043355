<section class="page-size-selector">
    <app-select-input
        [startValue]="pageSize"
        (selectedOption)="sizeChanged($event)"
        [options]="pageSizeSelectOptions"
    ></app-select-input>
</section>

<section class="page-selector">
    <button
        class="editor-btn height-small icon-only direction-button direction-down tooltip-small"
        type="button"
        [disabled]="pageIndex === 0"
        [attr.aria-label]="'PAGINATOR.FIRST_PAGE' | translate"
        [attr.data-tooltip]="'PAGINATOR.FIRST_PAGE' | translate"
        (click)="moveToTheBeginning()"
    >
        <span class="nitroicon-double-arrow-left editor-btn-icon"></span>
    </button>

    <button
        [disabled]="pageIndex === 0"
        class="editor-btn height-small icon-only direction-button direction-down tooltip-small"
        type="button"
        [attr.aria-label]="'PAGINATOR.PREVIOUS_PAGE' | translate"
        [attr.data-tooltip]="'PAGINATOR.PREVIOUS_PAGE' | translate"
        (click)="moveOne(-1)"
    >
        <span class="nitroicon-arrow_right editor-btn-icon"></span>
    </button>

    <button
        *ngFor="let pageElem of availablePages"
        class="editor-btn height-small icon-only direction-button tooltip-small"
        [ngClass]="{ selected: pageElem === pageIndex }"
        type="button"
        (click)="moveToPage(pageElem)"
    >
        {{ pageElem + 1 }}
    </button>

    <button
        [disabled]="totalPages === 0 || pageIndex === totalPages - 1"
        class="editor-btn height-small icon-only direction-button tooltip-small"
        type="button"
        [attr.aria-label]="'PAGINATOR.NEXT_PAGE' | translate"
        [attr.data-tooltip]="'PAGINATOR.NEXT_PAGE' | translate"
        (click)="moveOne(1)"
    >
        <span class="nitroicon-arrow_right editor-btn-icon"></span>
    </button>

    <button
        [disabled]="totalPages === 0 || pageIndex === totalPages - 1"
        class="editor-btn height-small icon-only direction-button tooltip-small"
        type="button"
        [attr.aria-label]="'PAGINATOR.LAST_PAGE' | translate"
        [attr.data-tooltip]="'PAGINATOR.LAST_PAGE' | translate"
        (click)="moveToTheEnd()"
    >
        <span class="nitroicon-double-arrow-left editor-btn-icon"></span>
    </button>
</section>
