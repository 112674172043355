<input type="file" #fileInput [accept]="acceptTypes" (change)="fileChangeListener()" />

<ng-container *ngIf="fileSrc; else emptyBtn">
    <audio
        #audio
        [src]="fileSrc | protectedResourceUrl | async"
        class="audio"
        (click)="fileInput.click()"
        (drop)="landFile($event); audio.className = 'dropper'"
        (dragenter)="audio.className = 'dropper dragover'"
        (dragover)="$event.preventDefault()"
        (dragleave)="audio.className = 'dropper'"
        (error)="handleError()"
        (timeupdate)="setLastKnownTime($event)"
        [currentTime]="currentTime"
        (play)="setIsplaying(true)"
        (pause)="setIsplaying(false)"
        (ended)="setIsplaying(false); audioEnded()"
        (loadedmetadata)="setAudioDuraton()"
    ></audio>
    <div id="audio-player-container">
        <button (click)="togglePlayPause()" class="audio-player-container__btn">
            <span *ngIf="playerStatus === 'PAUSED'; else playing" class="nitroicon-play"></span>

            <ng-template #playing>
                <span class="nitroicon-Pause"></span>
            </ng-template>
        </button>
        <div class="duration">
            <input
                #durationSlider
                type="range"
                class="duration__slider"
                id="duration__slider"
                [max]="audioMax"
                [value]="0"
                (input)="sliderInput()"
                (change)="sliderChanged()"
            />
            <button class="duration__btn" [ngStyle]="{ left: sliderValue }">
                <span id="current-time" class="time">{{ audioCurrentTime ?? '00:00' }}</span>
                <span> / </span>
                <span id="duration" class="time">{{ audioDurationTime ?? '00:00' }}</span>
            </button>
        </div>
    </div>
    <button class="delete-btn" (click)="deleteFile()" (keyup.enter)="deleteFile()">
        <span class="nitroicon-close" aria-hidden="true"></span>
    </button>
</ng-container>

<ng-template #emptyBtn>
    <div class="dropper" #droparea>
        <button
            data-e2e="audio-upload"
            class="empty-btn"
            (click)="fileInput.click()"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
            tabindex="0"
        >
            <span #contentWrapper>
                <ng-content></ng-content>
            </span>

            <div *ngIf="contentWrapper.childNodes.length === 0" class="empty-btn__cover"></div>
        </button>
    </div>
</ng-template>
