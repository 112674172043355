<form
    data-e2e="image-text"
    [formGroup]="form"
    class="image-text-widget"
    [ngClass]="[dividerLocationValue, setStarterImageSize()]"
>
    <section
        class="column left-column"
        [class.right-borders]="!flippedValue"
        [class.left-borders]="flippedValue"
        [style.order]="flippedValue ? 1 : 0"
        [ngClass]="imageFitValue"
    >
        <app-image-upload
            #imageUpload
            class="app-image-upload"
            [imageSize]="setStarterImageSize()"
            [formControlName]="'element'"
            [courseId]="config?.courseId"
        >
            <div class="image-text-widget__upload">
                <img src="assets/images/simple-image-widget.svg" class="image-text-widget__img" alt="" />
            </div>
        </app-image-upload>
        <div
            class="real-time-actions"
            [class.right]="!flippedValue"
            [class.left]="flippedValue"
            *ngIf="location === 'real-time'"
            (mousedown)="mouseOnButton = true"
            (mouseup)="mouseOnButton = false"
            (mouseleave)="mouseOnButton = false"
            (mousemove)="changeLayout($event)"
        >
            <button
                class="switch-button editor-btn height-small icon-only primary"
                type="button"
                (mousemove)="$event.stopPropagation()"
                (click)="switchSides()"
            >
                <span class="nitroicon-switch_sides editor-btn-icon"></span>
            </button>
        </div>
    </section>

    <section
        class="column right-column"
        [class.right-borders]="flippedValue"
        [class.left-borders]="!flippedValue"
        [ngClass]="otherImageFitValue"
    >
        <app-image-upload
            #otherImageUpload
            class="app-image-upload"
            [imageSize]="setStarterImageSize()"
            [formControlName]="'otherElement'"
            [courseId]="config?.courseId"
        >
            <div class="image-text-widget__upload">
                <img src="assets/images/simple-image-widget.svg" class="image-text-widget__img" alt="" />
            </div>
        </app-image-upload>
    </section>
</form>
