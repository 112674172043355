import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { startWith } from 'rxjs/operators';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { MultiWidgetDividerLocation } from 'src/app/api-wrapper/models/widget-models/image-text-widget-model';
import { DetailAudioTextWidgetForm } from 'src/app/api-wrapper/models/widget-models/multi-widgets/audio-text-widget-model';
import {
    multiWidgetdividerOptions,
    multiWidgetflippedAvailableOptions,
} from 'src/app/api-wrapper/models/widget-models/multi-widgets/multi-detail-options';
import { CourseEditorHelpers } from 'src/app/course-editor/helpers/fulfillment.helper';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import { AudioTextRealTimeWidgetComponent } from '../audio-text-real-time-widget/audio-text-real-time-widget.component';

@Component({
    selector: 'app-audio-text-detail-widget',
    templateUrl: './audio-text-detail-widget.component.html',
    styleUrls: ['./audio-text-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AudioTextDetailWidgetComponent),
            multi: true,
        },
    ],
})
export class AudioTextDetailWidgetComponent
    extends AbstractFormBaseComponent<DetailAudioTextWidgetForm, IWidgetSupport>
    implements OnInit
{
    widgetSizeEnum = WidgetSizeEnum;
    @ViewChild('realTimeAudioText', { static: false }) realTimeAudioText: AudioTextRealTimeWidgetComponent;
    size: WidgetSizeEnum;
    sizeClass: string;
    testEnv = false;

    dividerOptions: IChekboxRadioFormModel[] = multiWidgetdividerOptions;

    flippedAvailableOptions: IChekboxRadioFormModel[] = multiWidgetflippedAvailableOptions;

    fulfillmentAvailableOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-available-option-true', label: 'FORM-CONTROLS.YES', value: true },
        { id: 'fulfillment-available-option-false', label: 'FORM-CONTROLS.NO', value: false },
    ];

    fulfillmentOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-option-show', label: 'FORM-CONTROLS.SHOW', value: 'SHOW', group: 'LAX' },
        {
            id: 'fulfillment-option-audio-started',
            label: 'FORM-CONTROLS.AUDIO_STARTED',
            value: 'AUDIO_STARTED',
            group: 'MEDIUM',
        },
        {
            id: 'fulfillment-option-audio-finished',
            label: 'FORM-CONTROLS.AUDIO_FINISHED',
            value: 'AUDIO_FINISHED',
            group: 'STRICT',
        },
        { id: 'fulfillment-option-none', label: '', value: 'NONE', hidden: true, group: 'NONE' },
    ];

    constructor() {
        super();
    }

    get dividerLocationValue(): MultiWidgetDividerLocation {
        return (this.form.get('dividerLocation')?.value ?? 'CENTER') as MultiWidgetDividerLocation;
    }

    get flippedValue(): boolean {
        return (this.form.get('flipped')?.value ?? false) as boolean;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initDisabledFields();
        this.subscriptions.add(
            this.form.controls.size.valueChanges
                .pipe(startWith(this.form.get('size').value as WidgetSizeEnum))
                .subscribe((size: WidgetSizeEnum) => {
                    if (this.config?.starterWidgetSize) {
                        this.config.starterWidgetSize = size;
                        if (this.realTimeAudioText) {
                            this.realTimeAudioText.configDataChanged(size);
                        }
                    }
                    this.size = size;
                    this.sizeClass = size === 'ORIGINAL' ? 'margin oriinal' : size?.toLowerCase();
                })
        );
    }

    createForm(): FormGroupTyped<DetailAudioTextWidgetForm> {
        return new FormGroupTyped<DetailAudioTextWidgetForm>({
            realTimeAudioTextWidgetFormData: FormHelper.controlFactoryWithCalculatedValue(null),
            audioDescription: FormHelper.controlFactoryWithCalculatedValue(null),
            dividerLocation: FormHelper.controlFactoryWithCalculatedValue(null),
            flipped: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
            size: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    initDisabledFields(): void {
        const value = this.form.get('fulfillmentAvailable').value as boolean;
        if (!value) {
            this.form.get('fulfillment').disable({ emitEvent: false });
        }
        this.subscriptions.add(
            this.form.get('fulfillmentAvailable').valueChanges.subscribe((fulfillmentAvailable: boolean) => {
                CourseEditorHelpers.handleFulfillment(
                    fulfillmentAvailable,
                    this.form,
                    this.config.fulfillmentSettings.fulfillmentLevel,
                    this.fulfillmentOptions
                );
            })
        );
    }

    setWidgetSize(size: WidgetSizeEnum): void {
        this.form.patchValue({ size });
    }
}
