<form [formGroup]="form" class="detail-form">
    <div class="detail-form__site-left">
        <app-note-real-time-widget
            *ngIf="!testEnv"
            class="detail-form__real-time-widget"
            [formControlName]="'realTimeNoteWidgetFormData'"
            [config]="config"
            [editorLocation]="'detail'"
        ></app-note-real-time-widget>
    </div>
</form>
