<form [formGroup]="form" *ngIf="shouldSave" [@parent] [attr.data-e2e]="e2eTag">
    <app-custom-textarea
        class="carousel__title navigation-block-header"
        [formControlName]="'title'"
        [placeholder]="titlePlaceholder | translate"
    ></app-custom-textarea>
    <!-- Fő slider ami a tananyagban is megjelenik -->
    <section class="carousel__section">
        <ul class="carousel__wrapper" [ngClass]="ratio" *ngIf="images.value?.length; else startPage">
            <ng-container *ngFor="let slide of images.value; let i = index; trackBy: trackByFn">
                <li *ngIf="i === currentSlide" [@slideAnimation]="animationType">
                    <img
                        appImgPreloader
                        alt=""
                        [courseId]="config.courseId"
                        [resourceId]="getImageResourceId(slide)"
                        [resource]="getResource(slide)"
                        [notProcessedUrl]="notProcessedUrl"
                        [imageSize]="'M'"
                    />
                </li>
            </ng-container>
        </ul>

        <!-- Slider irányító gombok -->
        <button
            *ngIf="!pptProcessIsOnSelector"
            (click)="onPreviousClick()"
            class="template-btn card-btn carousel__left-button"
        >
            <span class="nitroicon-arrow_right"></span>
        </button>
        <button
            *ngIf="!pptProcessIsOnSelector"
            (click)="onNextClick()"
            class="template-btn card-btn carousel__right-button"
        >
            <span class="nitroicon-arrow_right"></span>
        </button>

        <!-- A kezdőoldal amennyiben nincsen még egy dia sem -->
        <ng-template #startPage>
            <div class="carousel__wrapper with-border">
                <app-slide-upload
                    (fileItemAdded)="addImageCard($event)"
                    (pptProccessStarted)="pptProccessStarted($event)"
                    [addSlideType]="'START_PAGE'"
                    [courseId]="config.courseId"
                    [pageId]="config.pageId"
                    [widgetId]="config.widgetId"
                    [embeded]="embeded"
                ></app-slide-upload>
            </div>
        </ng-template>

        <!-- Sliderhez a pöttyök -->
        <ul class="indicators" *ngIf="images?.value?.length < 16; else sliderCounter">
            <li *ngFor="let slide of images.value; let i = index; trackBy: trackByFn">
                <button
                    [attr.aria-label]="'CAROUSEL-WIDGET.PREV-BUTTON' | translate"
                    [attr.tabindex]="i === currentSlide ? -1 : 0"
                    [class.active]="i === currentSlide"
                    (click)="setCurrentSlide(i)"
                    class="template-btn card-btn indicators__btn"
                ></button>
            </li>
        </ul>
        <ng-template #sliderCounter>
            <button class="template-btn card-btn indicator-counter-btn">
                <span>{{ currentSlide + 1 }}</span>
                <span>/</span>
                <span>{{ images?.value?.length }}</span>
            </button>
        </ng-template>

        <!-- Zoomable gomb -->
        <button *ngIf="zoomable && !pptProcessIsOnSelector" class="template-btn card-btn carousel__fullsize-button">
            <span class="nitroicon-carousel-zoom"></span>
        </button>
    </section>

    <!-- Slider a képaláírásokhoz és a real time editorban be lehet zárni a diakezelő slidert -->
    <section class="opener-and-caption">
        <article class="opener-and-caption__caption-wrapper" formArrayName="images">
            <ul class="caption-list-elem reset-list">
                <ng-container *ngFor="let slideElement of images.controls; let i = index">
                    <li *ngIf="i === currentSlide" [formGroupName]="i">
                        <app-quill
                            *ngIf="!testEnv"
                            [formControlName]="'caption'"
                            class="image-widget__caption"
                            [toolbarVisible]="false"
                            [placeholder]="'CAROUSEL-WIDGET.SLIDE-TITLE-PLACEHOLDER' | translate"
                        ></app-quill>
                    </li>
                </ng-container>
            </ul>
        </article>
        <article class="opener-and-caption__carousel-slider-handler">
            <button
                (click)="openCarouselSlider = !openCarouselSlider"
                class="editor-btn ghost height-small collapse-icon"
                [class.opened]="openCarouselSlider"
                *ngIf="editorLocation === 'real-time'"
                type="button"
            >
                <span class="nitroicon-arrow_down editor-btn-icon"></span>
                {{ (openCarouselSlider ? 'CAROUSEL-WIDGET.HIDE-MINI-SLIDES' : 'CAROUSEL-WIDGET.SHOW-MINI-SLIDES') |
                translate }}
            </button>
        </article>
    </section>

    <!-- Slider a diák törlésére hozzáadására és sorrendezésére -->
    <section
        class="carousel-slider-container"
        *ngIf="openCarouselSlider"
        [@cardCollapse]="{ value: true, params: { animTiming: '500ms ease' } }"
    >
        <app-carousel-slider
            [form]="form"
            [config]="config"
            [notProcessedUrl]="notProcessedUrl"
            (selectedIndexChange)="setCurrentSlide($event)"
            [selectedIndex]="currentSlide"
            [xPosition]="xPosition"
            (addNewImage)="addImageCard($event)"
            (deleteImage)="deleteCard($event)"
            (moveImage)="moveImage($event)"
        >
        </app-carousel-slider>
    </section>
    <!-- A Detail editorban lehet az akadálymentesítéshez szöveget megadni -->
    <section class="description-section" *ngIf="editorLocation === 'detail'">
        <article class="description-section__caption-wrapper" formArrayName="images">
            <ul class="caption-list-elem reset-list">
                <ng-container *ngFor="let slideElement of images.controls; let i = index; let len = count">
                    <li *ngIf="i === currentSlide" [formGroupName]="i">
                        <app-collapse-card
                            [title]="
                                'CAROUSEL-WIDGET.ACCESSIBILTY-CARD'
                                    | translate: { current: currentSlide + 1, length: len }
                            "
                            [opened]="true"
                        >
                            <div class="detail-form__group">
                                <label class="detail-form__group__label" for="description"
                                    >{{ 'IMAGE-WIDGET.ACCESSIBILTY-TEXT' | translate }}
                                </label>
                                <textarea name="description" id="description" formControlName="description"></textarea>
                            </div>
                        </app-collapse-card>
                    </li>
                </ng-container>
            </ul>
        </article>
    </section>
</form>
<app-loader-for-components [id]="config.widgetId"></app-loader-for-components>
