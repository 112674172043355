import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ImageUploadComponent } from '@shared/forms/file-upload/image-upload/image-upload.component';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { MultiWidgetImageFit } from 'src/app/api-wrapper/models/widget-models/image-text-widget-model';
import { RealTimeImageImageWidgetForm } from 'src/app/api-wrapper/models/widget-models/multi-widgets/image-image-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { BaseMultiRealTimeWidgetComponent } from '../../helper-base-classes/abstract-base-real-time-multi';

@Component({
    selector: 'app-image-image-real-time-widget',
    templateUrl: './image-image-real-time-widget.component.html',
    styleUrls: ['./image-image-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageImageRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class ImageImageRealTimeWidgetComponent extends BaseMultiRealTimeWidgetComponent<
    RealTimeImageImageWidgetForm,
    IWidgetSupport
> {
    @ViewChild('imageUpload') imageUpload: ImageUploadComponent;
    @ViewChild('otherImageUpload') otherImageUpload: ImageUploadComponent;
    @Input() imageFit: MultiWidgetImageFit;
    @Input() otherImageFit: MultiWidgetImageFit;

    constructor() {
        super();
    }

    get imageFitValue(): MultiWidgetImageFit {
        return this.location === 'detail' ? this.imageFit : this.form.get('imageFit')?.value ?? 'CONTAIN';
    }

    get otherImageFitValue(): MultiWidgetImageFit {
        return this.location === 'detail' ? this.otherImageFit : this.form.get('otherImageFit')?.value ?? 'CONTAIN';
    }

    createForm(): FormGroupTyped<RealTimeImageImageWidgetForm> {
        return new FormGroupTyped<RealTimeImageImageWidgetForm>({
            dividerLocation: FormHelper.controlFactoryWithCalculatedValue(null),
            flipped: FormHelper.controlFactoryWithCalculatedValue(null),
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            imageFit: FormHelper.controlFactoryWithCalculatedValue(null),
            otherElement: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            otherImageFit: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    configDataChanged(widgetSize?: WidgetSizeEnum): void {
        if (this.config.starterWidgetSize !== widgetSize) {
            this.config.starterWidgetSize = widgetSize;
            switch (this.config?.starterWidgetSize) {
                case WidgetSizeEnum.FULLSIZE:
                    this.imageUpload.setSrc('XL');
                    this.otherImageUpload.setSrc('XL');
                    break;
                default:
                    this.imageUpload.setSrc('L');
                    this.otherImageUpload.setSrc('L');
                    break;
            }
        }
    }
}
