import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
    ComponentLoaderType,
    LoaderForComponentsModel,
} from '@shared/components/loader-for-components/loader-for-components.model';

@Injectable({ providedIn: 'root' })
export class LoaderForComponentsService {
    private loader = new BehaviorSubject<LoaderForComponentsModel>({ id: 'global', status: false, type: 'DEFAULT' });

    loaderStatus$ = this.loader.asObservable();

    public showLoader(id: string | number = 'global', type: ComponentLoaderType = 'DEFAULT'): void {
        const textId = String(id);
        this.loader.next({ id: textId, type, status: true });
    }

    public hideLoader(id: string | number = 'global', type: ComponentLoaderType = 'DEFAULT'): void {
        const textId = String(id);
        this.loader.next({ id: textId, type, status: false });
    }
}
