import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { FormGroupTyped } from '@shared/models/formgroup-typed';

export class FormHelper {
    static controlFactory<T, K extends keyof T>(
        object: T,
        property: K,
        disabled = false,
        validators: Validators = []
    ): UntypedFormControl {
        const value =
            object !== null && object !== undefined && (object[property] || (object[property] as unknown) !== undefined)
                ? object[property]
                : null;
        return new UntypedFormControl({ value, disabled }, validators);
    }

    static controlFactoryWithCalculatedValue(
        value: unknown,
        validators: Validators = [],
        disabled = false
    ): UntypedFormControl {
        return new UntypedFormControl({ value, disabled }, validators);
    }

    static initBaseFormarray<T>(
        model: T[],
        formElemFactory: (formElem: T, params?: unknown) => FormGroupTyped<T>,
        params?: unknown
    ): UntypedFormArray {
        const formArray = new UntypedFormArray([]);
        if (model?.length) {
            model.forEach((formElem: T) => formArray.push(formElemFactory(formElem, params)));
        }

        return formArray;
    }

    static initBaseFormarrayWihControlls(model: string[]): UntypedFormArray {
        const formArray = new UntypedFormArray([]);
        if (model?.length) {
            model.forEach((elem: string) => formArray.push(new UntypedFormControl(elem)));
        }

        return formArray;
    }

    static convertRGBToHex(red: number, green: number, blue: number): string {
        return (
            (red.toString(16).length === 1 ? '0' + red.toString(16) : red.toString(16)) +
            (green.toString(16).length === 1 ? '0' + green.toString(16) : green.toString(16)) +
            (blue.toString(16).length === 1 ? '0' + blue.toString(16) : blue.toString(16))
        );
    }
}
