<header class="full-page-modal__header" [ngClass]="alternativeHeader">
    <h1 *ngIf="title" class="full-page-modal__title">{{ title }}</h1>
    <div id="full-page-modal-exported-header-container"></div>
    <div class="full-page-modal__header-buttons">
        <button
            *ngIf="!dontNeedSaveButtons"
            (click)="onSave()"
            class="editor-btn primary border-0 icon-only radius-25 tooltip-left tooltip-delay"
            [attr.data-tooltip]="'DETAIL_EDITOR.SAVE' | translate"
        >
            <span class="nitroicon-save editor-btn-icon"></span>
        </button>
        <button
            *ngIf="!dontNeedSaveButtons"
            (click)="onSaveAndClose()"
            class="editor-btn primary border-0 icon-only radius-25 tooltip-left tooltip-delay"
            [attr.data-tooltip]="'DETAIL_EDITOR.SAVE_AND_CLOSE' | translate"
        >
            <span class="nitroicon-save_and_back editor-btn-icon"></span>
        </button>
        <button
            *ngIf="!dontNeedCloseButton"
            data-e2e="full-page-modal-close-button"
            (click)="onClose()"
            class="editor-btn primary border-0 icon-only radius-25 tooltip-left tooltip-delay"
            [attr.data-tooltip]="'DETAIL_EDITOR.CLOSE' | translate"
        >
            <span class="nitroicon-error editor-btn-icon"></span>
        </button>
    </div>
</header>

<div class="full-page-modal__inner-component-wrapper">
    <ng-template inner-component></ng-template>
</div>
