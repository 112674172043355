/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { MoveNavCardRequest } from '@apiModels';
import { IFileUploadValue } from '@shared/models/file-upload.model';
import { NavigationBlockWidgetData } from 'src/app/api/models/navigation-block-widget-data';
import { NavigationCardData } from 'src/app/api/models/navigation-card-data';

import { BaseSubFormClass } from './base-widget-models/base-sub-form-class';
import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from './base-widget-models/widget-size.enum';

export interface RealTimeNavigationBlockWidgetForm {
    title: string;
    cards: INavigationCardFormData[];
    columns: number;
    id: number;
}

export interface DetailNavigationBlockWidgetForm {
    realTimeNavigationBlockForm: RealTimeNavigationBlockWidgetForm;
    columns: number;
    size: WidgetSizeEnum;
}

export type UnionNavBlockDataType = Partial<RealTimeNavigationBlockWidgetForm & DetailNavigationBlockWidgetForm>;

export class NavigationBlockWidgetClass extends BaseWidgetClass<NavigationBlockWidgetData> {
    localCards: NavigationCardClass[];
    constructor(data: TypedResponse<NavigationBlockWidgetData>) {
        super(data);
        this.localCards = this.data.cards.map((c) => new NavigationCardClass({ ...c, navigationBlockID: this.id }));
    }

    getRealTimeFormData(): RealTimeNavigationBlockWidgetForm {
        return {
            title: this.data?.title ?? '',
            cards: this.localCards.map((c) => c.getFormData()) ?? [],
            columns: this.data?.columns ?? 2,
            id: this.id,
        };
    }

    getDetailFormData(): DetailNavigationBlockWidgetForm {
        return {
            realTimeNavigationBlockForm: this.getRealTimeFormData(),
            columns: this.data?.columns ?? 2,
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
        };
    }

    patchDataFromServer(data?: TypedResponse<NavigationBlockWidgetData>): boolean {
        let hasNewCardWithPatchedPageId = false;
        if (data?.data?.cards.length !== this.localCards.length) {
            return false;
        }
        this.localCards.forEach((elem, i: number) => {
            if (!elem?.data?.pageId) {
                hasNewCardWithPatchedPageId = true;
                elem.patchPageId(data?.data?.cards[i].pageId);
            }
        });
        return hasNewCardWithPatchedPageId;
    }

    shouldBeDeletedAfterUpdate(): boolean {
        return this.data.cards.length < 1;
    }

    updateWidgetData(modifiedData: RealTimeNavigationBlockWidgetForm | DetailNavigationBlockWidgetForm): void {
        let newData: UnionNavBlockDataType = modifiedData;
        const detailForm = modifiedData.hasOwnProperty('realTimeNavigationBlockForm');
        if (detailForm) {
            const {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                columns,
                ...rest
            } = (modifiedData as DetailNavigationBlockWidgetForm).realTimeNavigationBlockForm;
            newData = {
                ...modifiedData,
                ...rest,
            };
        }

        const { cards, columns, size, title } = newData;

        const tempSize = size ?? this.data.size;

        const tempCards: NavigationCardClass[] = cards.map((elem: INavigationCardFormData, i: number) => {
            const existing = this.localCards.find((item) => item.data.pageId === elem.pageId);

            if (existing) {
                if (this.shouldSaveFile(elem.coverImageElement.fileItem, existing.data.request?.coverImageResourceId)) {
                    this.filesToSave.push(elem.coverImageElement.fileItem);
                }
                existing.updateData(elem);
                return existing;
            }

            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { coverImageElement, ...rest } = elem;
            const { pageId, ...restOfTheData } = rest;
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const coverImageResourceId = this.extractUUID(coverImageElement);
            if (!pageId && elem.coverImageElement.fileItem) {
                this.filesToSave.push(elem.coverImageElement.fileItem);
            }
            if (elem.navigationBlockID !== this.id) {
                NavigationBlockHelper.setMoveNavCardRequest({
                    newIndex: i,
                    newWidgetId: this.id,
                    oldWidgetId: elem.navigationBlockID,
                    pageId: elem.pageId,
                });
            }
            return new NavigationCardClass({
                request: { ...restOfTheData, coverImageResourceId },
                navigationBlockID: this.id,
                ...(pageId ? { pageId } : {}),
            });
        });
        this.localCards = [...tempCards];
        const mappedCards = this.localCards.map((c) => c.getDataForSave());
        this.data = { ...this.data, title, cards: mappedCards as any, columns, size: tempSize };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            const card = this.localCards.find((cardElem) => cardElem.data.request.coverImageResourceId === id);
            if (!card) {
                return;
            }
            const updateElem = {
                ...card.getFormData(),
                coverImageElement: {
                    fileItem: null,
                    elementId: null,
                },
            };
            card.updateData(updateElem);
        });
        const cards = this.localCards.map((c) => c.getDataForSave());
        this.data = { ...this.data, cards };
    }
}

export interface INavigationCardFormData {
    pageId: number;
    coverImageElement?: IFileUploadValue;
    isLesson: boolean;
    name: string;
    navigationBlockID?: number;
}

export interface NavigationCardDataClass extends NavigationCardData {
    navigationBlockID?: number;
}
export class NavigationCardClass extends BaseSubFormClass<NavigationCardDataClass, INavigationCardFormData> {
    constructor(data: NavigationCardDataClass) {
        super(data);
    }
    getFormData(): INavigationCardFormData {
        return {
            navigationBlockID: this.data.navigationBlockID,
            pageId: this.data?.pageId ?? null,
            isLesson: this.data?.request?.isLesson ?? false,
            coverImageElement: {
                fileItem: this.fileUploadElement,
                elementId: this.data?.request?.coverImageResourceId ?? null,
            },
            name: this.data?.request?.name ?? '',
        };
    }

    patchPageId(pageId: number): void {
        this.data.pageId = pageId;
    }

    updateData(modifiedData: INavigationCardFormData): void {
        const { coverImageElement, ...rest } = modifiedData;
        this.fileUploadElement = coverImageElement.fileItem;
        const coverImageResourceId = this.extractUUID(coverImageElement);
        const request = { ...this.data?.request, ...rest, coverImageResourceId };
        this.data = { ...this.data, request };
    }
}

export class NavigationBlockHelper {
    protected static moveNavCardRequest: MoveNavCardRequest = null;

    static setMoveNavCardRequest(moveNavCardRequest: MoveNavCardRequest): void {
        this.moveNavCardRequest = moveNavCardRequest;
    }

    static hasMoveNavCardRequest(): boolean {
        return this.moveNavCardRequest !== null;
    }

    static getMoveNavCardRequest(): MoveNavCardRequest {
        const moveNavCardRequest: MoveNavCardRequest = { ...this.moveNavCardRequest };
        this.moveNavCardRequest = null;
        return moveNavCardRequest;
    }
}
