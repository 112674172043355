import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-radio-control',
    templateUrl: './radio-control.component.html',
    styleUrls: ['./radio-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: RadioControlComponent,
            multi: true,
        },
    ],
})
export class RadioControlComponent {
    @Input() labelText = '';
    @Input() titleText = '';
    @Input() ariaLabel = '';
    @Input() id: any;

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _value = false;

    get value(): boolean {
        return this._value;
    }

    set value(v: boolean) {
        if (v !== this._value) {
            this._value = v;
        }
    }

    writeValue(value: any): void {
        this._value = value;
    }

    onLabel(event: KeyboardEvent): void {
        if (event.code === 'Space' || event.code === 'Enter') {
            this.value = !this._value;
        }
    }
}
