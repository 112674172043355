<form [formGroup]="form" class="detail-form">
    <div class="detail-form__site-left">
        <app-learning-card-real-time-widget
            *ngIf="!testEnv"
            class="detail-form__real-time-widget"
            [formControlName]="'realTimeLearningCardWidgetFormData'"
            [config]="config"
        ></app-learning-card-real-time-widget>
    </div>

    <div class="detail-form__site-right">
        <app-collapse-card [title]="'LEARNING-CARD-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
            <app-radio-group-control
                class="detail-form__group"
                [title]="'LEARNING-CARD-WIDGET.FULFILLMENT-AVAILABLE-TITLE' | translate"
                [options]="fulfillmentAvailableOptions"
                formControlName="fulfillmentAvailable"
            ></app-radio-group-control>

            <app-radio-group-control
                class="detail-form__group"
                [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                [options]="fulfillmentOptions"
                formControlName="fulfillment"
            ></app-radio-group-control>
        </app-collapse-card>
    </div>
</form>
