/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SimpleExamConfigResponse } from '../models/simple-exam-config-response';

@Injectable({
  providedIn: 'root',
})
export class ExamControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listExams
   */
  static readonly ListExamsPath = '/exams';

  /**
   * List exams.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listExams()` instead.
   *
   * This method doesn't expect any request body.
   */
  listExams$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<SimpleExamConfigResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ExamControllerService.ListExamsPath, 'get');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimpleExamConfigResponse>>;
      })
    );
  }

  /**
   * List exams.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listExams$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listExams(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<SimpleExamConfigResponse>> {

    return this.listExams$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimpleExamConfigResponse>>) => r.body as Array<SimpleExamConfigResponse>)
    );
  }

}
