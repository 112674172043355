import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { DetailSortingQuestionWidgetForm } from 'src/app/api-wrapper/models/widget-models/questions/sorting-question/sorting-question-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { createDetailQuestionForm } from '../../question-widget-wrappers/question-detail-widget-wrapper/detail-form-init';

@Component({
    selector: 'app-sorting-question-detail-widget',
    templateUrl: './sorting-question-detail-widget.component.html',
    styleUrls: ['./sorting-question-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SortingQuestionDetailWidgetComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation],
})
export class SortingQuestionDetailWidgetComponent extends AbstractFormBaseComponent<
    DetailSortingQuestionWidgetForm,
    IWidgetSupport
> {
    testEnv = false;
    constructor() {
        super();
    }

    get hasMedia(): boolean {
        return this.form.get('hasMedia').value as boolean;
    }

    get hasPdf(): boolean {
        return this.form.get('hasPdf').value as boolean;
    }

    get attempts(): number {
        return this.form.get('attempts').value as number;
    }

    get hasAnswerMedia(): boolean {
        return this.form.get('hasAnswerMedia').value as boolean;
    }

    get hasAnswerExplanation(): boolean {
        return this.form.get('hasAnswerExplanation').value as boolean;
    }

    createForm(): FormGroupTyped<DetailSortingQuestionWidgetForm> {
        return new FormGroupTyped<DetailSortingQuestionWidgetForm>({
            realTimeQuestionWidgetFormData: FormHelper.controlFactoryWithCalculatedValue(null),
            ...createDetailQuestionForm(),
        });
    }
}
