import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';

@Component({
    selector: 'app-radio-group-control',
    templateUrl: './radio-group-control.component.html',
    styleUrls: ['./radio-group-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroupControlComponent),
            multi: true,
        },
    ],
})
export class RadioGroupControlComponent<T> implements ControlValueAccessor {
    static uniqueId = 0;
    @Input() groupName = `radioGroup${++RadioGroupControlComponent.uniqueId}`;
    @Input() title = '';
    @Input() layout: 'vertical' | 'horizontal' = 'vertical';
    @Input() options: IChekboxRadioFormModel[] = [];
    controlIsDisabled = false;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _value: T;
    protected onChange: (_: T) => void;
    protected onTouched: () => void;

    get value(): T {
        return this._value;
    }

    set value(v: T) {
        if (v !== this._value) {
            this._value = v;
            if (this.onChange) {
                this.onChange(v);
            }
        }
    }

    writeValue(obj: T): void {
        this._value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.controlIsDisabled = isDisabled;
    }

    onValueChange(event: Event, value: T): void {
        event.stopPropagation();
        this.value = value;
    }
}
