import { PageRecommendationWidgetData } from '@apiModels';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';

export interface RealTimeRecommendationWidgetForm {
    text: string;
    description: string;
    layout: 'TEXT_AND_BUTTON' | 'TEXT_AND_CARD' | 'BUTTON';
    pageId: number;
    widgetId: number;
    name: string;
    coverImageResourceId: string;
}

export class RecommendationWidgetClass extends BaseWidgetClass<PageRecommendationWidgetData> {
    constructor(data: TypedResponse<PageRecommendationWidgetData>) {
        super(data);
        this.extraSelector = '.ql-editor';
    }

    getRealTimeFormData(): RealTimeRecommendationWidgetForm {
        return {
            text: this?.data.text ?? '',
            description: this.data?.description ?? '',
            layout: this?.data.layout ?? 'TEXT_AND_CARD',
            pageId: this?.data?.page?.id ?? null,
            widgetId: this?.data?.widgetId ?? null,
            name: this?.data?.page?.name ?? null,
            coverImageResourceId: this?.data?.page?.data?.coverImageResourceId ?? null,
        };
    }

    updateWidgetData(data: RealTimeRecommendationWidgetForm): void {
        const { text, description, pageId, widgetId } = data;
        this.data = { ...this.data, text, description, pageId, widgetId };
    }

    getDetailFormData(): any {
        return null;
    }
}
