import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mediaTime',
})
export class MediaTimePipe implements PipeTransform {
    public transform(time: number): string {
        if (!time) {
            return '0:00';
        }
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${returnedSeconds}`;
    }
}
