import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomHandlerService } from '@sharedServices/dom-handler.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit {
    actualYear: number;
    @ViewChild('editorFooter') footerElement;

    constructor(private domHandlerService: DomHandlerService) {}

    ngOnInit(): void {
        this.actualYear = new Date().getFullYear();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setToolbarWidth();
        });
    }

    @HostListener('window:resize')
    setToolbarWidth(): void {
        const footerHeight = window.getComputedStyle(this.footerElement.nativeElement).height;
        this.domHandlerService.setCSSVariable('--footer-height', footerHeight);
    }
}
