import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { RealTimeYoutubeWidgetForm } from 'src/app/api-wrapper/models/widget-models/embeded-widgets/youtube-widget.model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

let apiLoaded = false;

@Component({
    selector: 'app-youtube-real-time-widget',
    templateUrl: './youtube-real-time-widget.component.html',
    styleUrls: ['./youtube-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => YoutubeRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class YoutubeRealTimeWidgetComponent
    extends AbstractFormBaseComponent<RealTimeYoutubeWidgetForm, IWidgetSupport>
    implements OnInit
{
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    title: string;
    src: string;
    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!apiLoaded) {
            // This code loads the IFrame Player API code asynchronously, according to the instructions at
            // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);
            apiLoaded = true;
        }

        super.ngOnInit();
    }

    updateForm(data: RealTimeYoutubeWidgetForm): void {
        super.updateForm(data);
        this.src = data.videoId ? `https://www.youtube.com/embed/${data.videoId}` : null;
        this.title = data.title ?? '';
    }

    createForm(): FormGroupTyped<RealTimeYoutubeWidgetForm> {
        return new FormGroupTyped<RealTimeYoutubeWidgetForm>({
            videoId: FormHelper.controlFactoryWithCalculatedValue(null),
            description: FormHelper.controlFactoryWithCalculatedValue(null),
            size: FormHelper.controlFactoryWithCalculatedValue(null),
            title: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
