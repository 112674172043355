import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { MenuActionInterface } from '@shared/components/material-wrappers/material-menu/material-menu.component';
import { CustomClozeAnswerBlot } from '@shared/components/quill/quill-formats/custom-cloze-answer.blot';
import { CustomClozeTextBlot } from '@shared/components/quill/quill-formats/custom-cloze-text.blot';
import { CustomClozeBlot } from '@shared/components/quill/quill-formats/custom-cloze.blot';
import { CustomKeyboard } from '@shared/components/quill/quill-formats/custom-key-module';
import { CustomLinkBlot } from '@shared/components/quill/quill-formats/custom-link.bloat';
import { CustomTooltipBlot } from '@shared/components/quill/quill-formats/custom-tooltip.blot';
import { FileUploadHandlerService } from '@sharedServices/file-upload-handler.service';
import { FullPageModalGuardService } from '@sharedServices/full-page-guard.service';
import { HeaderIconService } from '@sharedServices/header-icon-service';
import { LanguageWrapperService } from '@sharedServices/language-wrapper.service';
import { SocketService } from '@sharedServices/socket.service';
import { URLHelperUtility } from '@sharedUtilities/url-helpers.utility';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import Quill from 'quill';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface ExtendedKeycloak extends KeycloakProfile {
    attributes?: { locale: string[] };
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    userMmenuItems = [];
    break: boolean;
    subscription = new Subscription();
    public isLoggedIn = false;
    public userProfile: ExtendedKeycloak | null = null;
    shouldShowCourseListIcon = false;
    shouldShowBackArrowIcon = false;
    hasExamAdminRole = false;

    constructor(
        private wrapperService: LanguageWrapperService,
        public breakpointObserver: BreakpointObserver,
        private fileUploadSrv: FileUploadHandlerService,
        private socketService: SocketService,
        private keycloak: KeycloakService,
        private fullPageModalGuardService: FullPageModalGuardService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        Quill.register(CustomLinkBlot);
        Quill.register(CustomTooltipBlot);
        Quill.register(CustomClozeBlot);
        Quill.register(CustomClozeTextBlot);
        Quill.register(CustomClozeAnswerBlot);
        Quill.register('modules/keyboard', CustomKeyboard, true);
        this.breakpoint();
    }

    ngOnInit(): void {
        void this.keycloackInit();
        this.routeListener();
        this.subscription.add(
            HeaderIconService.showBackArrow.subscribe((showBackArrow) => {
                setTimeout(() => {
                    this.shouldShowBackArrowIcon = showBackArrow;
                });
            })
        );
    }

    async keycloackInit(): Promise<void> {
        this.isLoggedIn = await this.keycloak.isLoggedIn();

        if (this.isLoggedIn) {
            this.userProfile = await this.keycloak.loadUserProfile();

            const languages = this.userProfile?.attributes?.locale ?? [];
            this.wrapperService.init(languages);
            this.adminRolesMenu();

            const userId = this.keycloak.getKeycloakInstance().subject;
            this.userMmenuItems.unshift({
                type: 'USER',
                icon: 'nitroicon-profile',
                item: this.userProfile,
            });
            this.userProfile.id = userId;
            void this.setToken();
            this.subscription.add(
                this.keycloak.keycloakEvents$.subscribe((event) => {
                    if (event.type === 3 || event.type === 6) {
                        void this.setToken();
                    }
                })
            );

            return;
        } else {
            await this.keycloak.login();
        }
    }

    adminRolesMenu(): void {
        this.hasExamAdminRole = this.keycloak.isUserInRole('EXAM_ADMIN');
    }

    async setToken(): Promise<void> {
        const token = await this.keycloak.getToken();
        URLHelperUtility.setToken(token);
    }

    onHeaderAction(event: MenuActionInterface<any, any>): void {
        switch (event.action) {
            case 'LOGOUT':
                this.onLogout();
                break;
            default:
                break;
        }
    }

    onLogout(): void {
        if (this.fullPageModalGuardService.canDeactivate()) {
            void this.keycloak.logout();
        }
    }

    routeListener(): void {
        this.subscription.add(
            this.router.events
                .pipe(
                    filter((e) => e instanceof NavigationEnd),
                    map(() => this.route.snapshot),
                    map((route: ActivatedRouteSnapshot) => {
                        while (route.firstChild) {
                            route = route.firstChild;
                        }
                        return route;
                    })
                )
                .subscribe((route: ActivatedRouteSnapshot) => {
                    this.shouldShowCourseListIcon = !!route.url.length;
                })
        );
    }

    breakpoint(): void {
        this.subscription.add(
            this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((state: BreakpointState) => {
                this.break = !state.matches;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
