<form
    [formGroup]="form"
    class="learning-card-widget"
    [ngClass]="{ 'link-editor-is-open': openedLinkEditor }"
    data-e2e="learning-card-widget"
>
    <app-flip-card [flip]="flip" class="learning-card">
        <app-quill
            *ngIf="!testEnv"
            class="front learning-card-widget__text"
            [formControlName]="'statement'"
            [toolbarVisible]="true"
            (linkEditOpen)="openedLinkEditor = $event"
            [placeholder]="'LEARNING-CARD-WIDGET.STATEMENT' | translate"
        ></app-quill>
        <app-quill
            *ngIf="!testEnv"
            class="back learning-card-widget__text"
            [formControlName]="'explanation'"
            [toolbarVisible]="true"
            (linkEditOpen)="openedLinkEditor = $event"
            [placeholder]="'LEARNING-CARD-WIDGET.EXPLANATION' | translate"
        ></app-quill>
    </app-flip-card>
</form>
