import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';

export type MultiWidgetDividerLocation = 'LEFT' | 'CENTER' | 'RIGHT';

export type MultiWidgetImageFit = 'CONTAIN' | 'PADDED' | 'COVER';

export const multiWidgetdividerOptions: IChekboxRadioFormModel[] = [
    {
        id: 'divider-option-left',
        iconLabel: 'nitroicon-left_side_emphasis',
        label: 'IMAGE-TEXT-WIDGET.DIVIDER-LOCATION-LEFT',
        value: 'LEFT',
    },
    {
        id: 'divider-option-center',
        iconLabel: 'nitroicon-equal_sides',
        label: 'IMAGE-TEXT-WIDGET.DIVIDER-LOCATION-CENTER',
        value: 'CENTER',
    },
    {
        id: 'divider-option-right',
        iconLabel: 'nitroicon-right_side_emphasis',
        label: 'IMAGE-TEXT-WIDGET.DIVIDER-LOCATION-RIGHT',
        value: 'RIGHT',
    },
];

export const multiWidgetflippedAvailableOptions: IChekboxRadioFormModel[] = [
    {
        id: 'flipped-option-true',
        iconLabel: 'nitroicon-text_and_image',
        label: 'IMAGE-TEXT-WIDGET.POSITION-TEXT-IMAGE',
        value: false,
    },
    {
        id: 'flipped-option-false',
        iconLabel: 'nitroicon-image_and_text',
        label: 'IMAGE-TEXT-WIDGET.POSITION-IMAGE-TEXT',
        value: true,
    },
];

export const multiWidgetvideoFitOptions: IChekboxRadioFormModel[] = [
    {
        id: 'imageFit-option-contain',
        iconLabel: 'nitroicon-imagefit_contain',
        label: 'IMAGE-TEXT-WIDGET.DIVIDER-LOCATION-LEFT',
        value: 'CONTAIN',
    },
    {
        id: 'imageFit-option-padded',
        iconLabel: 'nitroicon-imagefit_padded',
        label: 'IMAGE-TEXT-WIDGET.DIVIDER-LOCATION-CENTER',
        value: 'PADDED',
    },
];

export const multiWidgetimageFitOptions: IChekboxRadioFormModel[] = [
    ...multiWidgetvideoFitOptions,
    {
        id: 'imageFit-option-cover',
        iconLabel: 'nitroicon-imagefit_cover',
        label: 'IMAGE-TEXT-WIDGET.DIVIDER-LOCATION-RIGHT',
        value: 'COVER',
    },
];
