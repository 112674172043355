<span class="nitroicon-{{ ownClass }}"></span>
<div class="message">
    <p class="translated" *ngIf="!link">{{ translatedMessage | translate }}</p>
    <p *ngIf="!link">{{ message }}</p>
    <a class="resource-error-link" *ngIf="link" [routerLink]="link">{{ translatedMessage | translate }}</a>
    <a class="resource-error-link" *ngIf="link" [routerLink]="link" [fragment]="fragment">{{ message }}</a>
</div>
<button aria-label="Bezárás" type="button" class="close-btn" *ngIf="closeable" (click)="closeToastMessage()">
    <span class="nitroicon-close"></span>
</button>
