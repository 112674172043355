<section class="levels-nav-container">
    <form [formGroup]="levelForm">
        <div *ngFor="let level of levels; let idx = index" class="level-item">
            <label [for]="'level' + idx">{{ "COURSE_SUCCES_SETTINGS." + level | translate }}</label>
            <input
                [id]="'level' + idx"
                type="radio"
                name="chosen_level"
                [value]="level"
                formControlName="chosen_level"
                (change)="onLevelChange(level)"
            >
        </div>
    </form>
</section>
<section class="details-container">
    <h3>{{ "COURSE_SUCCES_SETTINGS.TITLE" | translate }}</h3>
    <div class="details-container__level-placeholder">
        <span>
            {{ "COURSE_SUCCES_SETTINGS." + fulfillmentSettings.fulfillmentLevel + "_PLACEHOLDER" | translate }}
        </span>
    </div>
    <app-collapse-card
        class="details-container__settings-detail"
        [opened]="true"
        [title]="'COURSE_SUCCES_SETTINGS.SETTINGS_DETAIL' | translate"
    >
    <form [formGroup]="fulfillmentForm">
        <div *ngFor="let group of widgetGroups">
            <div class="flex-container group-header-container">
                <span [class]="'nitroicon-' + group.iconName"></span>
                <div class="group-name">{{ ('WIDGET.' + group.groupName | translate).toUpperCase() }}</div>
            </div>
            <div *ngFor="let elem of group.elems; let idx = index" class="group-elem-container">
                <ng-container *ngIf="group.elems[0] !== ''">
                    <div>{{ 'WIDGET.' + elem | translate }}</div>
                </ng-container>
                <ng-container *ngIf="group.options.length > 0; else multiSelect">
                    <app-select-input 
                        [formControlName]="group.elems[idx] !== '' ? group.elems[idx] : (group.groupName)"
                        [options]="group.elems[idx] !== 'EXAM_WIDGET' ? group.options : examWidgetOptions"
                        [disabled]="fulfillmentSettings.fulfillmentLevel !== 'CUSTOM'"
                        (selectedOption)="fulfillmentFormInputChange(group.elems[idx] !== '' ? group.elems[idx] : (group.groupName), $event)"
                    >
                    </app-select-input>
                </ng-container>
                <ng-template #multiSelect [ngSwitch]="elem">
                    <app-select-input
                        *ngSwitchCase="'IMAGE_TEXT_WIDGET'"
                        [formControlName]="elem"
                        [disabled]="fulfillmentSettings.fulfillmentLevel !== 'CUSTOM'"
                        [options]="[{ value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' }, { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' }]"
                        (selectedOption)="fulfillmentFormInputChange(elem, $event)"
                    >
                    </app-select-input>
                    <app-select-input
                        *ngSwitchCase="'IMAGE_IMAGE_WIDGET'"
                        [formControlName]="elem"
                        [disabled]="fulfillmentSettings.fulfillmentLevel !== 'CUSTOM'"
                        [options]="[{ value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' }, { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' }]"
                        (selectedOption)="fulfillmentFormInputChange(elem, $event)"
                    >
                    </app-select-input>
                    <app-select-input
                        *ngSwitchCase="'VIDEO_TEXT_WIDGET'"
                        [formControlName]="elem"
                        [disabled]="fulfillmentSettings.fulfillmentLevel !== 'CUSTOM'"
                        [options]="[{ value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' }, { value: 'VIDEO_STARTED', displayName: 'FORM-CONTROLS.VIDEO_STARTED' }, { value: 'VIDEO_FINISHED', displayName: 'FORM-CONTROLS.VIDEO_FINISHED' }, { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' }]"
                        (selectedOption)="fulfillmentFormInputChange(elem, $event)"
                    >
                    </app-select-input>
                    <app-select-input
                        *ngSwitchCase="'AUDIO_TEXT_WIDGET'"
                        [formControlName]="elem"
                        [disabled]="fulfillmentSettings.fulfillmentLevel !== 'CUSTOM'"
                        [options]="[{ value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' }, { value: 'AUDIO_STARTED', displayName: 'FORM-CONTROLS.AUDIO_STARTED' }, { value: 'AUDIO_FINISHED', displayName: 'FORM-CONTROLS.AUDIO_FINISHED' }, { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' }]"
                        (selectedOption)="fulfillmentFormInputChange(elem, $event)"
                    >
                    </app-select-input>
                    <app-select-input
                        *ngSwitchCase="'DOCUMENT_TEXT_WIDGET'"
                        [formControlName]="elem"
                        [disabled]="fulfillmentSettings.fulfillmentLevel !== 'CUSTOM'"
                        [options]="[{ value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' }, { value: 'CLICK', displayName: 'DOCUMENT-WIDGET.CLICK' }, { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' }]"
                        (selectedOption)="fulfillmentFormInputChange(elem, $event)"
                    >
                    </app-select-input>
                </ng-template>
            </div>
        </div>
    </form>
    </app-collapse-card>
</section>
