<header class="header">
    <h3 class="translated">{{ headerMessage | translate }}</h3>
    <button type="button" class="close-btn" *ngIf="closeable" (click)="closeToastMessage()">
        <span class="nitroicon-close"></span>
    </button>
</header>
<section class="main">
    <div
        class="progress"
        *ngIf="type !== 'ERROR'"
        role="progressbar"
        [attr.aria-valuenow]="progress"
        aria-valuemin="0"
        aria-valuemax="100"
    >
        {{ progress }}% {{ fileName }}
    </div>
    <a class="resource-error-link" *ngIf="type === 'ERROR' && link" [routerLink]="link" [fragment]="fragment">
        {{ fileName }}
    </a>
</section>
