export const Levels: Array<'NONE' | 'LAX' | 'MEDIUM' | 'STRICT' | 'CUSTOM'> = [
    'STRICT',
    'MEDIUM',
    'LAX',
    'NONE',
    'CUSTOM',
];
export const WidgetGroups = [
    {
        groupName: 'QUESTION_WIDGET',
        iconName: 'quiz',
        elems: [''],
        options: [
            { value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' },
            { value: 'QUESTION_ANSWERED', displayName: 'QUESTION-WIDGET.COMMON.MEASUREMENT.ANSWERED-QUESTION' },
            {
                value: 'QUESTION_CORRECTLY_ANSWERED',
                displayName: 'QUESTION-WIDGET.COMMON.MEASUREMENT.CORECTLY-ANSWERED-QUESTION',
            },
            { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' },
        ],
    },
    {
        groupName: 'SIMPLE_IMAGE_WIDGET',
        iconName: 'image',
        elems: [''],
        options: [
            { value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' },
            { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' },
        ],
    },
    {
        groupName: 'SIMPLE_VIDEO_WIDGET',
        iconName: 'video',
        elems: [''],
        options: [
            { value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' },
            { value: 'VIDEO_STARTED', displayName: 'FORM-CONTROLS.VIDEO_STARTED' },
            { value: 'VIDEO_FINISHED', displayName: 'FORM-CONTROLS.VIDEO_FINISHED' },
            { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' },
        ],
    },
    {
        groupName: 'AUDIO_WIDGET',
        iconName: 'voice',
        elems: [''],
        options: [
            { value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' },
            { value: 'AUDIO_STARTED', displayName: 'FORM-CONTROLS.AUDIO_STARTED' },
            { value: 'AUDIO_FINISHED', displayName: 'FORM-CONTROLS.AUDIO_FINISHED' },
            { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' },
        ],
    },
    {
        groupName: 'CARDS',
        iconName: 'cards',
        elems: ['LEARNING_CARD_WIDGET'],
        options: [
            { value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' },
            { value: 'CLICK', displayName: 'FORM-CONTROLS.CLICK' },
            { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' },
        ],
    },
    {
        groupName: 'DOCUMENT_WIDGET',
        iconName: 'document',
        elems: [''],
        options: [
            { value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' },
            { value: 'CLICK', displayName: 'DOCUMENT-WIDGET.CLICK' },
            { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' },
        ],
    },
    {
        groupName: 'MULTI',
        iconName: 'multi_widgets_selector',
        elems: [
            'IMAGE_TEXT_WIDGET',
            'IMAGE_IMAGE_WIDGET',
            'VIDEO_TEXT_WIDGET',
            'AUDIO_TEXT_WIDGET',
            'DOCUMENT_TEXT_WIDGET',
        ],
        options: [],
    },
    {
        groupName: 'GALLERY_WIDGET',
        iconName: 'carousel',
        elems: [''],
        options: [
            { value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' },
            { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' },
        ],
    },
    {
        groupName: 'EMBEDED',
        iconName: 'code',
        elems: ['IFRAME_WIDGET', 'EMBEDDED_DOCUMENT_WIDGET', 'CAROUSEL_WIDGET', 'YOUTUBE_WIDGET', 'EXAM_WIDGET'],
        options: [
            { value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' },
            { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' },
        ],
    },
];

export const ExamWidgetOptions = [
    { value: 'NONE', displayName: 'FORM-CONTROLS.NONE' },
    { value: 'SHOW', displayName: 'FORM-CONTROLS.SHOW' },
    { value: 'QUESTION_ANSWERED', displayName: 'EXAM_WIDGET.EXAM_SUCCESSFUL' },
    {
        value: 'QUESTION_CORRECTLY_ANSWERED',
        displayName: 'EXAM_WIDGET.EXAM_ATTEMPTED',
    },
];
