<form [formGroup]="form">
    <header class="detail-form__header" [ngClass]="sizeClass">
        <div class="detail-form__header__action-buttons">
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.MARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.MARGIN)"
            >
                <span class="nitroicon-block_narrow editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.NOMARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.NOMARGIN)"
            >
                <span class="nitroicon-block_mid editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.FULLSIZE }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.FULLSIZE)"
            >
                <span class="nitroicon-block_fullsize editor-btn-icon"></span>
            </button>
        </div>
    </header>

    <ng-template #realTimeElement>
        <app-document-text-real-time-widget
            #realTimeDocumenText
            *ngIf="!testEnv"
            class="detail-form__real-time-widget"
            [ngClass]="sizeClass"
            [formControlName]="'realTimeDocumentTextWidgetFormData'"
            [config]="config"
            [location]="'detail'"
            [dividerLocation]="dividerLocationValue"
            [flipped]="flippedValue"
        ></app-document-text-real-time-widget>
    </ng-template>
    <ng-container *ngIf="size === widgetSizeEnum.FULLSIZE">
        <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
    </ng-container>

    <section class="detail-form resizable" [ngClass]="sizeClass">
        <div class="detail-form__site-left">
            <ng-container *ngIf="size !== widgetSizeEnum.FULLSIZE">
                <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
            </ng-container>

            <app-collapse-card [title]="'IMAGE-WIDGET.ACCESSIBILTY-CARD' | translate" [opened]="true">
                <fieldset class="detail-form__group">
                    <label class="detail-form__group__label" for="document-widget-documentDescription">
                        {{ 'DOCUMENT-WIDGET.DOCUMENT-ACCESSIBILTY-LABEL' | translate }}
                    </label>
                    <textarea
                        name="document-widget-documentDescription"
                        id="document-widget-documentDescription"
                        [placeholder]="'DOCUMENT-WIDGET.DOCUMENT-ACCESSIBILTY-PLACEHOLDER' | translate"
                        formControlName="documentDescription"
                    ></textarea>
                </fieldset>
            </app-collapse-card>
        </div>

        <div class="detail-form__site-right">
            <app-collapse-card [title]="'IMAGE-TEXT-WIDGET.LAYOUT-TITLE' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.DIVIDER-LOCATION-TITLE' | translate"
                    [options]="dividerOptions"
                    [layout]="'horizontal'"
                    formControlName="dividerLocation"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.POSITION-TITLE' | translate"
                    [options]="flippedAvailableOptions"
                    [layout]="'horizontal'"
                    formControlName="flipped"
                ></app-radio-group-control>
            </app-collapse-card>
            <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.FULFILLMENT-AVAILABLE-TITLE' | translate"
                    [options]="fulfillmentAvailableOptions"
                    formControlName="fulfillmentAvailable"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                    [options]="fulfillmentOptions"
                    formControlName="fulfillment"
                ></app-radio-group-control>
            </app-collapse-card>
        </div>
    </section>
</form>
