/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { UserControllerService } from './services/user-controller.service';
import { ThemeControllerService } from './services/theme-controller.service';
import { ProfileControllerService } from './services/profile-controller.service';
import { DirectoryControllerService } from './services/directory-controller.service';
import { CourseControllerService } from './services/course-controller.service';
import { WidgetControllerService } from './services/widget-controller.service';
import { CourseVersionControllerService } from './services/course-version-controller.service';
import { PageControllerService } from './services/page-controller.service';
import { BaseCourseControllerService } from './services/base-course-controller.service';
import { EditorTenantControllerService } from './services/editor-tenant-controller.service';
import { TenantControllerService } from './services/tenant-controller.service';
import { PublicControllerService } from './services/public-controller.service';
import { ExamControllerService } from './services/exam-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UserControllerService,
    ThemeControllerService,
    ProfileControllerService,
    DirectoryControllerService,
    CourseControllerService,
    WidgetControllerService,
    CourseVersionControllerService,
    PageControllerService,
    BaseCourseControllerService,
    EditorTenantControllerService,
    TenantControllerService,
    PublicControllerService,
    ExamControllerService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
