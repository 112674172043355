import { Component, HostBinding } from '@angular/core';
import { collapseAnimation } from '@shared/animations/collapse.animations';

@Component({
    selector: 'app-collapse',
    templateUrl: './collapse.component.html',
    styleUrls: ['./collapse.component.scss'],
    animations: [collapseAnimation],
})
export class CollapseComponent {
    @HostBinding('@cardCollapse') cardCollapseAnimation = true;
}
