import { QuestionWidgetData } from '@apiModels';
import { IFileUploadElement } from '@shared/models/file-upload.model';
import { RealTimeBaseQuestionWidgetForm } from './question-base-model';

export function InitRealTimeQuestionFormData(
    data: QuestionWidgetData,
    fileUploadElement: IFileUploadElement,
    mediaUploadElement: IFileUploadElement
): RealTimeBaseQuestionWidgetForm {
    const { question, attempts } = data;
    return {
        answers: [],
        text: question?.text ?? null,
        introduction: question?.introduction ?? null,
        hasAnswerMedia: question?.hasAnswerMedia ?? false,
        hasMedia: question?.hasMedia ?? false,
        hasPdf: question?.hasPdf ?? false,
        attempts: attempts ?? 0,
        pdfResourceElement: {
            fileItem: fileUploadElement,
            elementId: question?.pdfResourceId ?? null,
        },
        mediaResourceElement: {
            fileItem: mediaUploadElement,
            elementId: question?.mediaResourceId ?? null,
        },
    };
}
