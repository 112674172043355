import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProtectedResourceDownloadService } from '@sharedServices/protected-resource-download.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'protectedResourceUrl',
})
export class ProtectedResourceUrlPipe implements PipeTransform {
    constructor(private protectedDownloadSrv: ProtectedResourceDownloadService, protected sanitizer: DomSanitizer) {}

    transform(src: string, simpleUrlDownload = true): Observable<SafeResourceUrl> {
        if (!src) {
            return of(null);
        }
        if (src.startsWith('blob')) {
            return of(this.sanitizer.bypassSecurityTrustResourceUrl(src));
        }

        return this.protectedDownloadSrv
            .downloadResource(src, simpleUrlDownload)
            .pipe(map((file) => this.sanitizer.bypassSecurityTrustResourceUrl(file)));
    }
}
