<form [formGroup]="form" class="detail-form">
    <div class="detail-form__site-left">
        <app-audio-real-time-widget
            class="detail-form__real-time-widget"
            [formControlName]="'realTimeAudioWidgetFormData'"
            [config]="config"
        ></app-audio-real-time-widget>

        <app-collapse-card
            *ngIf="!testEnv"
            [title]="'VIDEO-WIDGET.TRANSCRIPT' | translate"
            [opened]="true"
            class="detail-form__group"
        >
            <textarea
                [formControlName]="'description'"
                [placeholder]="'AUDIO-WIDGET.TRANSCRIPT-PLACEHOLDER' | translate"
            ></textarea>
        </app-collapse-card>

        <!--        <fieldset class="detail-form__group">-->
        <!--            <label class="detail-form__group__label" for="audio-widget-description">-->
        <!--                {{ 'AUDIO-WIDGET.TRANSCRIPT-TITLE' | translate }}-->
        <!--            </label>-->
        <!--            <textarea-->
        <!--                id="audio-widget-description"-->
        <!--                class="audio-widget__caption"-->
        <!--                [placeholder]="'AUDIO-WIDGET.TRANSCRIPT-PLACEHOLDER' | translate"-->
        <!--                formControlName="description"-->
        <!--            ></textarea>-->
        <!--        </fieldset>-->
    </div>

    <div class="detail-form__site-right">
        <app-collapse-card [title]="'AUDIO-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
            <app-radio-group-control
                class="detail-form__group"
                [title]="'AUDIO-WIDGET.FULFILLMENT-AVAILABLE-TITLE' | translate"
                [options]="fulfillmentAvailableOptions"
                formControlName="fulfillmentAvailable"
            ></app-radio-group-control>

            <app-radio-group-control
                class="detail-form__group"
                [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                [options]="fulfillmentOptions"
                formControlName="fulfillment"
            ></app-radio-group-control>
        </app-collapse-card>
    </div>
</form>
