import { ChangeDetectorRef, Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFileUploadValue } from '@shared/models/file-upload.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { RealTimeDocumentTextWidgetForm } from 'src/app/api-wrapper/models/widget-models/multi-widgets/document-text-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { BaseMultiRealTimeWidgetComponent } from '../../helper-base-classes/abstract-base-real-time-multi';

@Component({
    selector: 'app-document-text-real-time-widget',
    templateUrl: './document-text-real-time-widget.component.html',
    styleUrls: ['./document-text-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DocumentTextRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class DocumentTextRealTimeWidgetComponent extends BaseMultiRealTimeWidgetComponent<
    RealTimeDocumentTextWidgetForm,
    IWidgetSupport
> {
    @ViewChild('hiddenText') textEl: ElementRef;
    minWidth = 180;
    width = this.minWidth;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    get hasElement(): boolean {
        if (!this.form) {
            return false;
        }
        const { fileItem, elementId } = this.form.get('element').value as IFileUploadValue;
        return fileItem !== null || elementId !== null;
    }

    resize(time = 0): void {
        setTimeout(() => {
            if (this.textEl) {
                this.width = Math.max(this.minWidth, this.textEl?.nativeElement?.offsetWidth);
                this.changeDetectorRef.detectChanges();
            }
        }, time);
    }

    createForm(): FormGroupTyped<RealTimeDocumentTextWidgetForm> {
        return new FormGroupTyped<RealTimeDocumentTextWidgetForm>({
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            documentTitle: FormHelper.controlFactoryWithCalculatedValue(null),
            text: FormHelper.controlFactoryWithCalculatedValue(null),
            dividerLocation: FormHelper.controlFactoryWithCalculatedValue(null),
            flipped: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    configDataChanged(widgetSize?: WidgetSizeEnum): void {
        if (this.config.starterWidgetSize !== widgetSize) {
            this.config.starterWidgetSize = widgetSize;
        }
    }
}
