import { AbstractWidgetData } from '@apiModels';
import { IFileUploadElement, IFileUploadValue } from '@shared/models/file-upload.model';
import { IFileUploadHandler } from '@sharedServices/file-upload-handler.service';

import { TypedResponse } from './typed-widget-response';
import { WidgetModifyParams } from './widget-modify-params';

export abstract class BaseWidgetClass<T extends AbstractWidgetData> implements TypedResponse<T> {
    data: T;
    id: number;
    order: number;
    pageId: number;
    filesToSave: IFileUploadElement[] = [];
    detailModalId: string;
    focusElemId: string;
    extraSelector = '';
    fileUploadElement: IFileUploadElement | null = null;

    constructor(data: TypedResponse<T>) {
        Object.assign(this, data);
        this.detailModalId = `detail-modal-${data.id}-selector`;
        this.focusElemId = `focus-${data.id}-selector`;
    }

    abstract getRealTimeFormData(): Partial<T> | any;

    abstract getDetailFormData(): Partial<T> | any;

    updateWidgetData(modifiedData: Partial<T> | any): void {
        this.data = { ...this.data, ...modifiedData };
    }

    patchDataFromServer(data: TypedResponse<T>): boolean {
        return false;
    }

    shouldBeDeletedAfterUpdate(): boolean {
        return false;
    }

    removeResourceIds(resourceIds: string[]): void {}

    getFilesToSave(courseId: number): IFileUploadHandler[] {
        const returnValues = this.filesToSave.map((file) => ({
            file,
            courseId,
            pageId: this.pageId,
            widgetId: this.id,
        }));
        this.filesToSave = [];
        return returnValues;
    }

    getWidgetModifyRequest(): WidgetModifyParams<T> {
        return {
            widgetId: this.id,
            body: { data: { ...this.data } },
        };
    }

    extractUUID(element: IFileUploadValue): string | null {
        return element.fileItem ? element.fileItem.uuid : element.elementId;
    }

    shouldSaveFile(resource: IFileUploadElement, valueToCompareTo: string | null): boolean {
        return resource !== null && resource.uuid !== valueToCompareTo;
    }
}
