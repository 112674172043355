import { Component, HostBinding, Input } from '@angular/core';
import { loaderAnimations } from './animations';
import { LoaderType } from './models/loader.model';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    animations: [loaderAnimations],
})
export class LoaderComponent {
    @HostBinding('@visibleAnimation') visibleAnimation;
    @Input() type!: LoaderType;
}
