<input type="file" #fileInput [accept]="acceptTypes" (change)="fileChangeListener()" />
<ng-container *ngIf="fileSrc; else emptyBtn">
    <a
        [href]="fileSrc | safeUrl"
        target="_blank"
        class="editor-btn primary card-btn left-icon download-button height-small"
        [download]="fileName"
    >
        <span class="nitroicon-download editor-btn-icon"></span>
        {{ 'FILE.DOWNLOAD' | translate }}
    </a>

    <button class="delete-btn" (click)="deleteFile()" (keyup.enter)="deleteFile()">
        <span class="nitroicon-close" aria-hidden="true"></span>
    </button>
</ng-container>

<ng-template #emptyBtn>
    <div class="dropper" #droparea>
        <button
            class="empty-btn"
            (click)="fileInput.click()"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
            tabindex="0"
        >
            <span #contentWrapper>
                <ng-content></ng-content>
            </span>

            <div *ngIf="contentWrapper.childNodes.length === 0" class="empty-btn__cover">
                <button class="editor-btn accent height-small btn-w100 empty-button left-icon" type="button">
                    <span class="nitroicon-upload editor-btn-icon"></span>
                    {{ 'FILE.UPLOAD' | translate }}
                </button>
            </div>
        </button>
    </div>
</ng-template>
