/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BaseCourseRequest } from '../models/base-course-request';
import { BaseCourseResponse } from '../models/base-course-response';
import { PageBaseCourseResponse } from '../models/page-base-course-response';

@Injectable({
  providedIn: 'root',
})
export class BaseCourseControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation modifyBaseCourse
   */
  static readonly ModifyBaseCoursePath = '/courses/base/{courseId}';

  /**
   * Modify a course with basic data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyBaseCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyBaseCourse$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: BaseCourseRequest
  }): Observable<StrictHttpResponse<BaseCourseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.ModifyBaseCoursePath, 'put');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseCourseResponse>;
      })
    );
  }

  /**
   * Modify a course with basic data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifyBaseCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyBaseCourse(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: BaseCourseRequest
  }): Observable<BaseCourseResponse> {

    return this.modifyBaseCourse$Response(params).pipe(
      map((r: StrictHttpResponse<BaseCourseResponse>) => r.body as BaseCourseResponse)
    );
  }

  /**
   * Path part for operation deleteCourse
   */
  static readonly DeleteCoursePath = '/courses/base/{courseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCourse$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.DeleteCoursePath, 'delete');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCourse(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation unDeleteCourse
   */
  static readonly UnDeleteCoursePath = '/courses/base/{courseId}/undelete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unDeleteCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  unDeleteCourse$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.UnDeleteCoursePath, 'put');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unDeleteCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unDeleteCourse(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.unDeleteCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getBaseCourses
   */
  static readonly GetBaseCoursesPath = '/courses/base';

  /**
   * Search all courses and list them with basic data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBaseCourses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBaseCourses$Response(params?: {
    textFragment?: string;
    directoryId?: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<PageBaseCourseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.GetBaseCoursesPath, 'get');
    if (params) {
      rb.query('textFragment', params.textFragment, {});
      rb.query('directoryId', params.directoryId, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageBaseCourseResponse>;
      })
    );
  }

  /**
   * Search all courses and list them with basic data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBaseCourses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBaseCourses(params?: {
    textFragment?: string;
    directoryId?: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<PageBaseCourseResponse> {

    return this.getBaseCourses$Response(params).pipe(
      map((r: StrictHttpResponse<PageBaseCourseResponse>) => r.body as PageBaseCourseResponse)
    );
  }

  /**
   * Path part for operation createBaseCourse
   */
  static readonly CreateBaseCoursePath = '/courses/base';

  /**
   * Create a course with basic data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBaseCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBaseCourse$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: BaseCourseRequest
  }): Observable<StrictHttpResponse<BaseCourseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.CreateBaseCoursePath, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseCourseResponse>;
      })
    );
  }

  /**
   * Create a course with basic data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createBaseCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBaseCourse(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: BaseCourseRequest
  }): Observable<BaseCourseResponse> {

    return this.createBaseCourse$Response(params).pipe(
      map((r: StrictHttpResponse<BaseCourseResponse>) => r.body as BaseCourseResponse)
    );
  }

  /**
   * Path part for operation rePostProcessResources
   */
  static readonly RePostProcessResourcesPath = '/courses/base/{courseId}/rePostProcessResources';

  /**
   * Re-runs postprocessing on all course resources, repairing previous errors.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rePostProcessResources()` instead.
   *
   * This method doesn't expect any request body.
   */
  rePostProcessResources$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.RePostProcessResourcesPath, 'post');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Re-runs postprocessing on all course resources, repairing previous errors.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rePostProcessResources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rePostProcessResources(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.rePostProcessResources$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation cleanCourse
   */
  static readonly CleanCoursePath = '/courses/base/{courseId}/clean';

  /**
   * Clean unused resources of course.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cleanCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  cleanCourse$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.CleanCoursePath, 'post');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Clean unused resources of course.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cleanCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cleanCourse(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.cleanCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation unDeleteCourses
   */
  static readonly UnDeleteCoursesPath = '/courses/base/undeleteMultiple';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unDeleteCourses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unDeleteCourses$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: Array<number>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.UnDeleteCoursesPath, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unDeleteCourses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unDeleteCourses(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: Array<number>
  }): Observable<void> {

    return this.unDeleteCourses$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation permanentDeleteCourses
   */
  static readonly PermanentDeleteCoursesPath = '/courses/base/permanentDeleteMultiple';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permanentDeleteCourses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permanentDeleteCourses$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: Array<number>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.PermanentDeleteCoursesPath, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `permanentDeleteCourses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permanentDeleteCourses(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: Array<number>
  }): Observable<void> {

    return this.permanentDeleteCourses$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importCourseFromScorm
   */
  static readonly ImportCourseFromScormPath = '/courses/base/import';

  /**
   * Import a course from exported scorm zip (strictly CC generated zip, not general scorm).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCourseFromScorm()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCourseFromScorm$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<StrictHttpResponse<BaseCourseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.ImportCourseFromScormPath, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseCourseResponse>;
      })
    );
  }

  /**
   * Import a course from exported scorm zip (strictly CC generated zip, not general scorm).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importCourseFromScorm$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCourseFromScorm(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<BaseCourseResponse> {

    return this.importCourseFromScorm$Response(params).pipe(
      map((r: StrictHttpResponse<BaseCourseResponse>) => r.body as BaseCourseResponse)
    );
  }

  /**
   * Path part for operation copyExistingCourse
   */
  static readonly CopyExistingCoursePath = '/courses/base/copy/{courseId}';

  /**
   * Copy an existing course.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyExistingCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyExistingCourse$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<BaseCourseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.CopyExistingCoursePath, 'post');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseCourseResponse>;
      })
    );
  }

  /**
   * Copy an existing course.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyExistingCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyExistingCourse(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<BaseCourseResponse> {

    return this.copyExistingCourse$Response(params).pipe(
      map((r: StrictHttpResponse<BaseCourseResponse>) => r.body as BaseCourseResponse)
    );
  }

  /**
   * Path part for operation getDeletedCourses
   */
  static readonly GetDeletedCoursesPath = '/courses/base/deleted';

  /**
   * Search all courses and list them with basic data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeletedCourses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeletedCourses$Response(params?: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<PageBaseCourseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.GetDeletedCoursesPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageBaseCourseResponse>;
      })
    );
  }

  /**
   * Search all courses and list them with basic data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeletedCourses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeletedCourses(params?: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<PageBaseCourseResponse> {

    return this.getDeletedCourses$Response(params).pipe(
      map((r: StrictHttpResponse<PageBaseCourseResponse>) => r.body as PageBaseCourseResponse)
    );
  }

  /**
   * Path part for operation permanentDelete
   */
  static readonly PermanentDeletePath = '/courses/base/{courseId}/permanentDelete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permanentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  permanentDelete$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BaseCourseControllerService.PermanentDeletePath, 'delete');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `permanentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permanentDelete(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.permanentDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
