import { v4 as uuidv4 } from 'uuid';
import { MD5, x64 } from 'crypto-js';

import { Observable } from 'rxjs';
import { Md5 } from 'ts-md5';

export class UUIDGeneratorUtility {
    static generate(): string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        return uuidv4() as string;
    }

    static generateMd5(data: File): Observable<string> {
        return new Observable((observer) => {
            const reader = new FileReader();
            reader.onload = (d) => {
                const md5 = new Md5();
                md5.start();
                if (typeof d.target.result === 'string') {
                    md5.appendStr(d.target.result);
                } else {
                    md5.appendByteArray(new Uint8Array(d.target.result));
                }

                observer.next(md5.end() as string);
            };
            reader.readAsArrayBuffer(data);
        });
    }
}
