import { UntypedFormGroup } from '@angular/forms';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';

export class CourseEditorHelpers {
    static handleFulfillment(
        fulfillmentAvailable: boolean,
        form: UntypedFormGroup,
        fulfillmentLevel: string,
        fulfillmentOptions: Array<IChekboxRadioFormModel>
    ): void {
        if (fulfillmentAvailable) {
            const option = fulfillmentOptions.find((elem) => elem.group === fulfillmentLevel);
            const optionB = option ?? fulfillmentOptions.find((elem) => elem.group === 'MEDIUM');
            const optionC = optionB ?? fulfillmentOptions.find((elem) => elem.group === 'LAX');
            form.get('fulfillment').setValue(optionC.value);
            form.get('fulfillment').enable();
        } else {
            form.get('fulfillment').disable();
            form.patchValue({ fulfillment: 'NONE' });
        }
    }
}
