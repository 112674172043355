import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { startWith } from 'rxjs/operators';
import { CourseEditorHelpers } from 'src/app/course-editor/helpers/fulfillment.helper';

import { DetailLearningCardWidgetForm } from '../../../../api-wrapper/models/widget-models/learning-card-widget-model';
import { IWidgetSupport } from '../../../models/widget-support-info.model';

@Component({
    selector: 'app-learning-card-detail-widget',
    templateUrl: './learning-card-detail-widget.component.html',
    styleUrls: ['./learning-card-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LearningCardDetailWidgetComponent),
            multi: true,
        },
    ],
})
export class LearningCardDetailWidgetComponent
    extends AbstractFormBaseComponent<DetailLearningCardWidgetForm, IWidgetSupport>
    implements OnInit
{
    fulfillmentAvailableOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-available-option-true', label: 'FORM-CONTROLS.YES', value: true },
        { id: 'fulfillment-available-option-false', label: 'FORM-CONTROLS.NO', value: false },
    ];

    fulfillmentOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-option-show', label: 'FORM-CONTROLS.SHOW', value: 'SHOW', group: 'LAX' },
        { id: 'fulfillment-option-click', label: 'FORM-CONTROLS.CLICK', value: 'CLICK', group: 'MEDIUM' },
        { id: 'fulfillment-option-none', label: '', value: 'NONE', hidden: true, group: 'NONE' },
    ];
    testEnv = false;
    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initDisabledFields();
    }

    createForm(): FormGroupTyped<DetailLearningCardWidgetForm> {
        return new FormGroupTyped<DetailLearningCardWidgetForm>({
            realTimeLearningCardWidgetFormData: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    initDisabledFields(): void {
        const value = this.form.get('fulfillmentAvailable').value as boolean;
        if (!value) {
            this.form.get('fulfillment').disable({ emitEvent: false });
        }
        this.subscriptions.add(
            this.form.get('fulfillmentAvailable').valueChanges.subscribe((fulfillmentAvailable: boolean) => {
                CourseEditorHelpers.handleFulfillment(
                    fulfillmentAvailable,
                    this.form,
                    this.config.fulfillmentSettings.fulfillmentLevel,
                    this.fulfillmentOptions
                );
            })
        );
    }
}
