import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { startWith } from 'rxjs/operators';
import { DetailCarouselWidgetForm } from 'src/app/api-wrapper/models/widget-models/carousel-widget-model';
import { CourseEditorHelpers } from 'src/app/course-editor/helpers/fulfillment.helper';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { CarouselRealTimeWidgetComponent } from '../carousel-real-time-widget/carousel-real-time-widget.component';

@Component({
    selector: 'app-carousel-detail-widget',
    templateUrl: './carousel-detail-widget.component.html',
    styleUrls: ['./carousel-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CarouselDetailWidgetComponent),
            multi: true,
        },
    ],
})
export class CarouselDetailWidgetComponent
    extends AbstractFormBaseComponent<DetailCarouselWidgetForm, IWidgetSupport>
    implements OnInit
{
    testEnv = false;
    @ViewChild('realTimeCarousel', { static: false }) realTimeCarousel: CarouselRealTimeWidgetComponent;

    slideSizeOptions: IChekboxRadioFormModel[] = [
        { id: 'SIXTEEN-PER-NINE', label: 'CAROUSEL-WIDGET.SIXTEEN-PER-NINE', value: 16 },
        { id: 'FOUR-PER-THREE', label: 'CAROUSEL-WIDGET.FOUR-PER-THREE', value: 4 },
        { id: 'ONE-PER-ONE', label: 'CAROUSEL-WIDGET.ONE-PER-ONE', value: 1 },
        { id: 'THREE-PER-FOUR', label: 'CAROUSEL-WIDGET.THREE-PER-FOUR', value: 3 },
    ];

    zoomOptionsForImages: IChekboxRadioFormModel[] = [
        { id: 'option-zoomable-true', label: 'FORM-CONTROLS.YES', value: true },
        { id: 'option-zoomable-false', label: 'FORM-CONTROLS.NO', value: false },
    ];

    fulfillmentAvailableOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-available-option-true', label: 'FORM-CONTROLS.YES', value: true },
        { id: 'fulfillment-available-option-false', label: 'FORM-CONTROLS.NO', value: false },
    ];

    fulfillmentOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-option-show', label: 'FORM-CONTROLS.SHOW', value: 'SHOW', group: 'LAX' },
        { id: 'fulfillment-option-none', label: '', value: 'NONE', hidden: true, group: 'NONE' },
    ];

    constructor() {
        super();
    }

    get xPosition(): number {
        return this.form.get('xPosition')?.value as number;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initRatioListener();
        this.initZoomableListener();
        this.initDisabledFields();
    }

    createForm(): FormGroupTyped<DetailCarouselWidgetForm> {
        return new FormGroupTyped<DetailCarouselWidgetForm>({
            realTimeCarouselWidgetForm: FormHelper.controlFactoryWithCalculatedValue(null),
            xPosition: FormHelper.controlFactoryWithCalculatedValue(null),
            zoomable: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    initRatioListener(): void {
        this.subscriptions.add(
            this.form.get('xPosition').valueChanges.subscribe((xPosition) => {
                this.realTimeCarousel.onRatioChange(xPosition);
            })
        );
    }

    initZoomableListener(): void {
        this.subscriptions.add(
            this.form.get('zoomable').valueChanges.subscribe((zoomable) => {
                this.realTimeCarousel.onZoomableChange(zoomable);
            })
        );
    }

    initDisabledFields(): void {
        const value = this.form.get('fulfillmentAvailable').value as boolean;
        if (!value) {
            this.form.get('fulfillment').disable({ emitEvent: false });
        }
        this.subscriptions.add(
            this.form.get('fulfillmentAvailable').valueChanges.subscribe((fulfillmentAvailable: boolean) => {
                CourseEditorHelpers.handleFulfillment(
                    fulfillmentAvailable,
                    this.form,
                    this.config.fulfillmentSettings.fulfillmentLevel,
                    this.fulfillmentOptions
                );
            })
        );
    }
}
