import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { URLHelperUtility } from '@sharedUtilities/url-helpers.utility';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { ISubtitleFormData } from 'src/app/api-wrapper/models/widget-models/video-widget-model';

export interface IDocument extends Document {
    webkitFullscreenElement: any;
    webkitExitFullscreen: () => void;
}

@Component({
    selector: 'app-custom-video',
    templateUrl: './custom-video.component.html',
    styleUrls: ['./custom-video.component.scss'],
})
export class CustomVideoComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() elementId!: string;
    @Input() fileSrc: string;
    @Input() posterValue: string;
    @Input() textWidget = false;
    @Input() courseId: number;
    @Input() orderedScales: string[] = [];
    @Input() selectedResolution = '1080p';
    @Input() loop = false;
    @Input() fullSize = false;
    @ViewChild('video') videoElement: ElementRef<HTMLVideoElement>;
    @ViewChild('durationSlider') durationSlider: ElementRef<HTMLInputElement>;
    @ViewChild('volumeSlider') volumeSlider: ElementRef<HTMLInputElement>;
    _subtitle: ISubtitleFormData;
    get subtitle(): ISubtitleFormData {
        return this._subtitle;
    }
    @Input() set subtitle(value: ISubtitleFormData) {
        this._subtitle = value;

        this.subtitleUrl = this.subtitle?.element?.fileItem
            ? URL.createObjectURL(this.subtitle?.element?.fileItem.resource)
            : this.subtitle?.element?.elementId
            ? URLHelperUtility.resourceURLForCourse(this.courseId, this.subtitle?.element?.elementId)
            : null;
    }
    _controlsEnabled: boolean;
    get controlsEnabled(): boolean {
        return this._controlsEnabled;
    }
    @Input() set controlsEnabled(value: boolean) {
        this._controlsEnabled = value;
        if (value) {
            setTimeout(() => {
                this.durationSlider?.nativeElement?.addEventListener('mousemove', this.updateSeekTooltip);
            }, 100);
        } else {
            this.durationSlider?.nativeElement?.removeEventListener('mousemove', this.updateSeekTooltip);
        }
    }
    _muted: boolean;
    get muted(): boolean {
        return this._muted;
    }
    @Input() set muted(value: boolean) {
        this._muted = value;
        this.currentVolume = value ? 0 : 1;
    }
    _autoplay: boolean;
    get autoplay(): boolean {
        return this._autoplay;
    }
    @Input() set autoplay(value: boolean) {
        this._autoplay = value;
        if (!this.isPlaying && value) {
            this.videoElement?.nativeElement?.play();
        }
        if (this.isPlaying && !value) {
            this.videoElement?.nativeElement?.pause();
        }
        this.isPlaying = value;
    }
    resourceBaseUrl: string;
    posterUrl: string;
    subtitleUrl: string;
    lastKnownTime = 0;
    currentTime = 0;
    jumpTo?: number = null;
    widgetSizeEnum = WidgetSizeEnum;
    posterSize = 'L';
    isPlaying = false;
    videoMax = 0;
    seekTime!: number;
    sliderValue = '0';
    currentVolume = 1;
    volumeBeforeMute = 1;
    isFullScreen = false;
    currentVideoSpeed = 1;
    videoSpeedList = [0.5, 1, 1.25, 1.5];
    subtitleIsOn = false;
    supportFullScreen = false;

    constructor(
        protected route: ActivatedRoute,
        protected videoContainer: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.supportFullScreen = !!(
            document.fullscreenEnabled ||
            document['mozFullScreenEnabled'] ||
            document['msFullscreenEnabled'] ||
            document['webkitSupportsFullscreen'] ||
            document['webkitFullscreenEnabled'] ||
            document.createElement('video')['webkitRequestFullScreen']
        );
    }

    setVideoSpeed(speed: number): void {
        this.currentVideoSpeed = speed;
        this.videoElement.nativeElement.playbackRate = speed;
    }

    setVideoResourceUrl(): string {
        if (this.orderedScales.length) {
            this.selectedResolution = this.orderedScales.includes(this.selectedResolution)
                ? this.selectedResolution
                : this.orderedScales[0];
            return URLHelperUtility.videoResourceURLForCourse(this.courseId, this.elementId, this.selectedResolution);
        }
        return URLHelperUtility.videoResourceURLForCourse(this.courseId, this.elementId);
    }

    setScale(scale: string): void {
        this.jumpTo = this.lastKnownTime;
        this.selectedResolution = scale;
        this.fileSrc = this.setVideoResourceUrl();
    }

    reloadVideo(): void {
        if (this.lastKnownTime > 0) {
            this.videoElement.nativeElement.load();
        }
    }

    togglePlay(): void {
        if (!this.controlsEnabled) {
            return;
        }

        if (this.videoElement.nativeElement.paused || this.videoElement.nativeElement.ended) {
            this.videoElement.nativeElement?.play();
            this.isPlaying = true;
        } else {
            this.videoElement.nativeElement?.pause();
            this.isPlaying = false;
        }
    }

    toggleSubtitle(changeSubtitleMode = true): void {
        const tracks = this.videoElement?.nativeElement?.textTracks;
        for (let i = 0; i < tracks.length; i++) {
            tracks[i].mode = tracks[i].mode === 'hidden' ? 'showing' : 'hidden';
        }
        if (changeSubtitleMode) {
            this.subtitleIsOn = !this.subtitleIsOn;
        }
    }

    ngAfterViewInit(): void {
        this.videoElement?.nativeElement?.addEventListener('loadedmetadata', this.initVideoListener);
        this.videoContainer?.nativeElement?.addEventListener('fullscreenchange', this.fullscreenListener);
        this.videoContainer?.nativeElement?.addEventListener('webkitfullscreenchange', this.fullscreenListener);
        this.durationSlider?.nativeElement?.addEventListener('mousemove', this.updateSeekTooltip);
        this.toggleSubtitle(false);
    }

    fullscreenListener = () => {
        this.isFullScreen = document.fullscreenElement !== null;
    };

    initVideoListener = () => {
        this.videoMax = Math.round(this.videoElement.nativeElement.duration);
    };

    sliderChanged(): void {
        const currentValue = Number(this.durationSlider.nativeElement.value);
        this.updateVideo(currentValue);
        this.currentTime = currentValue;
    }

    replay(): void {
        if (this.lastKnownTime <= 0) {
            return;
        }
        const newTime = Math.max(this.lastKnownTime - 10, 0);
        this.updateVideo(newTime);
        this.currentTime = newTime;
    }

    forward(): void {
        if (this.lastKnownTime >= this.videoMax) {
            return;
        }
        const newTime = Math.min(this.lastKnownTime + 10, this.videoMax);
        this.updateVideo(newTime);
        this.currentTime = newTime;
    }

    updateSeekTooltip = (event: MouseEvent): void => {
        this.seekTime = Math.round((event.offsetX / event.target['clientWidth']) * this.videoMax);
        const rect = this.videoElement.nativeElement.getBoundingClientRect();
        this.sliderValue = `${event.pageX - rect.left}px`;
        this.cdr.markForCheck();
    };

    toggleFullscreen(): void {
        if ((document as IDocument)?.webkitFullscreenElement) {
            (document as IDocument)?.webkitExitFullscreen();
        } else if (document.fullscreenElement) {
            void document.exitFullscreen();
        } else if (this.videoContainer?.nativeElement?.requestFullscreen) {
            void this.videoContainer.nativeElement.requestFullscreen();
        } else if (this.videoElement?.nativeElement['webkitSupportsFullscreen']) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            this.videoElement?.nativeElement['webkitEnterFullscreen']();
        }
    }

    toggleMute(): void {
        if (this.videoElement.nativeElement.muted) {
            this.videoElement.nativeElement.muted = false;
            this.currentVolume = this.volumeBeforeMute;
            this.videoElement.nativeElement.volume = this.currentVolume;
            return;
        }
        this.videoElement.nativeElement.muted = true;
        this.volumeBeforeMute = this.currentVolume;
        this.currentVolume = 0;
        this.videoElement.nativeElement.volume = this.currentVolume;
    }

    updateVolume(): void {
        if (this.videoElement.nativeElement.muted) {
            this.videoElement.nativeElement.muted = false;
        }
        this.currentVolume = Number(this.volumeSlider.nativeElement.value);
        this.volumeSlider.nativeElement.style.backgroundSize = `${(this.currentVolume / 1) * 100}%`;
        this.videoElement.nativeElement.volume = this.currentVolume;
        if (this.currentVolume === 0) {
            this.videoElement.nativeElement.muted = true;
        }
    }

    handleError(): void {
        this.fileSrc = URLHelperUtility.videoResourceURLForCourse(this.courseId, this.elementId);
        this.jumpTo = this.lastKnownTime;
    }

    setLastKnownTime(data: Event): void {
        if (this.jumpTo !== null) {
            this.currentTime = this.jumpTo;
            this.jumpTo = null;
            if (this.isPlaying) {
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                setTimeout(() => this.videoElement?.nativeElement?.play());
            }
        }
        const time: number = (data.target as HTMLVideoElement).currentTime;
        if (time > 0) {
            this.updateVideo(time);
        }
    }

    updateVideo(time: number): void {
        if (!this.controlsEnabled) {
            return;
        }
        this.lastKnownTime = time;
        this.durationSlider.nativeElement.style.backgroundSize = `${(time / this.videoMax) * 100}%`;
    }

    finished(): void {
        if (!this.loop) {
            this.isPlaying = false;
        }
    }

    ngOnDestroy(): void {
        this.videoElement?.nativeElement?.removeEventListener('loadedmetadata', this.initVideoListener);
        this.durationSlider?.nativeElement?.removeEventListener('mousemove', this.updateSeekTooltip);
        this.videoContainer?.nativeElement?.removeEventListener('fullscreenchange', this.fullscreenListener);
        this.videoContainer?.nativeElement?.removeEventListener('webkitfullscreenchange', this.fullscreenListener);
    }
}
