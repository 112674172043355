import { HttpEvent, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { IFileUploadElement } from '@shared/models/file-upload.model';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { UpdateWidgetService } from 'src/app/course-editor/services/update-widget.service';
import { ConfirmChoiceService } from './confirm-choice.service';

import { CourseUploadControllerService } from './course-upload-controller.service';

import { WindowEventService } from './window-event.service';

export interface IFileUploadHandler {
    file: IFileUploadElement;
    courseId?: number;
    pageId?: number;
    widgetId?: number;
    relevantWidgetId?: number;
}

export interface IUploadMessage {
    id: string;
    courseId: number;
    widgetId: number;
    translatedMessage?: string;
    message?: string;
    type: 'SUCCESS' | 'INFO' | 'ERROR' | 'WARNING';
    fileName: string;
    progress?: number;
    link?: string;
    fragment?: string;
}

@Injectable({ providedIn: 'root' })
export class FileUploadHandlerService implements OnDestroy {
    private fileMesage = new Subject<IFileUploadHandler[]>();
    private filemessage$ = this.fileMesage.asObservable();
    private subscription: Subscription;
    windowSub: Subscription;
    public fileCache = new Map<string, File>();
    private uploadSubs = new Map<string, Subscription>();

    // Üzenetküldés
    currentMessages: IUploadMessage[] = [];
    subCurrentMessages = new BehaviorSubject<IUploadMessage[]>([]);
    subCurrentMessages$ = this.subCurrentMessages.asObservable();

    constructor(
        private courseSrv: CourseUploadControllerService,
        private confirmChoiceService: ConfirmChoiceService,
        private windowEventService: WindowEventService,
        private updateWidgetService: UpdateWidgetService,
        private toastsSrv: ToastMessageService
    ) {
        this.subscription = this.filemessage$
            .pipe(
                filter((fileData: IFileUploadHandler[]) => fileData && fileData?.length > 0),
                tap((fileData: IFileUploadHandler[]) => {
                    fileData.forEach((file) => {
                        this.uploadSubs.set(file.file.uuid, this.converToObservable(file).subscribe());
                    });
                })
            )
            .subscribe();
        this.windowSub = this.windowEventService.beforeUnloadEvent().subscribe((event: Event) => {
            if (this.currentMessages.length) {
                event.preventDefault();
                (event as any).returnValue = '';
                return undefined;
            }
        });
    }

    public singleUpload(fileData: IFileUploadHandler): Observable<HttpEvent<HttpProgressEvent>> {
        return this.converToObservable(fileData);
    }

    sendFilesToUpload(fileData: IFileUploadHandler[]): void {
        this.fileMesage.next(fileData);
    }

    private converToObservable(fileData: IFileUploadHandler): Observable<HttpEvent<HttpProgressEvent>> {
        const { file, courseId, pageId, relevantWidgetId, widgetId } = fileData;
        let fileUploadMessage: IUploadMessage;
        this.fileCache.set(file.uuid, file.resource);
        return this.courseSrv
            .uploadResourceResponse({
                courseId,
                ...(relevantWidgetId ? { relevantWidgetId } : {}),
                resourceId: file.uuid,
                body: { file: file.resource },
            })
            .pipe(
                catchError(() => {
                    this.fileCache.delete(file.uuid);
                    this.removeFileUploadMessage(file.uuid);
                    this.updateWidgetService.removeResourceIds(fileUploadMessage.courseId, fileUploadMessage.widgetId, [
                        file.uuid,
                    ]);
                    this.toastsSrv.addFileUploadMessage({
                        closeable: true,
                        timeout: 0,
                        type: 'ERROR',
                        link: this.linkGenerator(courseId, pageId),
                        fileName: file.name,
                        fragment: String(widgetId),
                        progress: null,
                    });
                    return of(null);
                }),
                tap((httpEvent: HttpEvent<HttpProgressEvent>) => {
                    if (!httpEvent) {
                        return;
                    }
                    switch (httpEvent.type) {
                        case HttpEventType.Sent:
                            fileUploadMessage = {
                                id: file.uuid,
                                type: 'INFO',
                                progress: 0,
                                fileName: file.name,
                                courseId,
                                widgetId,
                            };
                            this.addFileUploadMessage(fileUploadMessage);
                            break;
                        case HttpEventType.UploadProgress:
                            fileUploadMessage.progress = Math.round((100 * httpEvent.loaded) / httpEvent.total);
                            break;
                        case HttpEventType.Response:
                            fileUploadMessage.progress = 100;
                            fileUploadMessage.type = 'SUCCESS';
                            this.removeFileUploadMessage(file.uuid);
                    }
                })
            );
    }

    getExistingFile(uuid: string): File | null {
        return this.fileCache.get(uuid);
    }

    linkGenerator(courseId: number, pageId: number): string | null {
        if (courseId && pageId) {
            return `/course/${courseId}/${pageId}`;
        }
        return null;
    }

    public addFileUploadMessage(message: IUploadMessage): void {
        this.currentMessages.push(message);
        this.subCurrentMessages.next(this.currentMessages);
    }

    cancelFileUpload(id: string, fileUploadMessage: IUploadMessage): void {
        this.confirmChoiceService
            .confirm({
                titlePath: 'FILE-UPLOAD.CANCEL-TITLE',
                messagePath: 'FILE-UPLOAD.CANCEL-TEXT',
                primaryButtonTextPath: 'COMMON.YES',
                primaryButtonIcon: null,
                secondaryButtonTextPath: 'COMMON.NO',
            })
            .subscribe(() => {
                this.uploadSubs.get(id)?.unsubscribe();
                this.uploadSubs.delete(id);
                this.fileCache.delete(id);
                this.removeFileUploadMessage(id);
                this.updateWidgetService.removeResourceIds(fileUploadMessage.courseId, fileUploadMessage.widgetId, [
                    id,
                ]);
            });
    }

    cancelAllTheFileUploads(): void {
        if (!this.currentMessages.length) {
            return;
        }
        this.confirmChoiceService
            .confirm({
                titlePath: 'FILE-UPLOAD.CANCEL-TITLE',
                messagePath: 'FILE-UPLOAD.MASS-CANCEL-TEXT',
                messageExtraInfo: String(this.currentMessages.length),
                primaryButtonTextPath: 'COMMON.YES',
                primaryButtonIcon: null,
                secondaryButtonTextPath: 'COMMON.NO',
            })
            .subscribe(() => {
                Array.from(this.uploadSubs.keys())?.forEach((element: string) => {
                    this.fileCache.delete(element);
                });
                this.uploadSubs.forEach((uploadElem) => {
                    uploadElem?.unsubscribe();
                });
                this.uploadSubs.clear();
                const widgetsToRemoveResourcesFrom: { [key: number]: string[] } = {};
                const widgetToCourseIdMapping: { [key: number]: number } = {};
                this.currentMessages.forEach((message) => {
                    const { courseId, widgetId } = message;
                    const current = (widgetsToRemoveResourcesFrom[widgetId] ?? []) as string[];
                    widgetsToRemoveResourcesFrom[widgetId] = [...current, message.id];
                    widgetToCourseIdMapping[widgetId] = courseId;
                });
                Object.keys(widgetsToRemoveResourcesFrom).forEach((key: string) => {
                    const numKey = Number(key);
                    this.updateWidgetService.removeResourceIds(widgetToCourseIdMapping[numKey], numKey, [
                        ...widgetsToRemoveResourcesFrom[numKey],
                    ]);
                });
                this.currentMessages = [];
                this.subCurrentMessages.next(this.currentMessages);
            });
    }

    public removeFileUploadMessage(id: string): void {
        this.currentMessages = this.currentMessages.filter((message) => message.id !== id);
        this.subCurrentMessages.next(this.currentMessages);
    }

    public updateFileUploadMessage({
        uploadElem,
        progress,
        type,
        link,
        fragment,
    }: Partial<IUploadMessage> | any): void {
        if (type) {
            uploadElem.type = type;
        }

        if (progress) {
            uploadElem.progress = progress;
        }
        if (link) {
            uploadElem.link = link;
        }

        if (fragment) {
            uploadElem.fragment = fragment;
        }
    }

    ngOnDestroy(): void {
        this.fileCache.clear();
        this.subscription.unsubscribe();
        this.windowSub.unsubscribe();
    }
}
