import { IframeWidgetData } from '@apiModels';

import { BaseWidgetClass } from '../base-widget-models/base-widget-class';
import { TypedResponse } from '../base-widget-models/typed-widget-response';
import {
    BaseDetailEmbededWidgetForm,
    BaseRealTimeEmbededWidgetForm,
    EmbededDetailFormFactory,
    EmbededRealTimeFormFactory,
} from './base-embeded.model';

export interface RealTimeIframeWidgetForm extends BaseRealTimeEmbededWidgetForm {
    src: string;
    height?: string;
}

export interface DetailIframeWidgetForm extends BaseDetailEmbededWidgetForm {
    src: string;
    height?: string;
}

export interface IframeWidgetDataSpec extends IframeWidgetData {
    fulfillment: 'NONE' | 'SHOW';
}

export class IframeWidgetClass extends BaseWidgetClass<IframeWidgetDataSpec> {
    constructor(data: TypedResponse<IframeWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeIframeWidgetForm {
        return {
            src: this.data?.src ?? null,
            height: this.data?.height ?? '394',
            ...EmbededRealTimeFormFactory<BaseRealTimeEmbededWidgetForm>(this.data),
        };
    }

    getDetailFormData(): DetailIframeWidgetForm {
        return {
            src: this.data?.src ?? null,
            height: this.data?.height ?? '394',
            ...EmbededDetailFormFactory<BaseDetailEmbededWidgetForm>(this.data),
        };
    }
}
