import { YoutubeWidgetData } from '@apiModels';

import { BaseWidgetClass } from '../base-widget-models/base-widget-class';
import { TypedResponse } from '../base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from '../base-widget-models/widget-size.enum';
import {
    BaseDetailEmbededWidgetForm,
    BaseRealTimeEmbededWidgetForm,
    EmbededDetailFormFactory,
    EmbededRealTimeFormFactory,
} from './base-embeded.model';

export interface RealTimeYoutubeWidgetForm extends BaseRealTimeEmbededWidgetForm {
    videoId: string;
    size: WidgetSizeEnum;
}

export interface DetailYoutubeWidgetForm extends BaseDetailEmbededWidgetForm {
    videoId: string;
    size: WidgetSizeEnum;
}

export interface YoutubeWidgetDataSpec extends YoutubeWidgetData {
    fulfillment: 'NONE' | 'SHOW';
}

export class YoutubeWidgetClass extends BaseWidgetClass<YoutubeWidgetDataSpec> {
    constructor(data: TypedResponse<YoutubeWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeYoutubeWidgetForm {
        return {
            videoId: this.data?.videoId ?? null,
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
            ...EmbededRealTimeFormFactory<BaseRealTimeEmbededWidgetForm>(this.data),
        };
    }

    getDetailFormData(): DetailYoutubeWidgetForm {
        return {
            videoId: this.data?.videoId ?? null,
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
            ...EmbededDetailFormFactory<BaseDetailEmbededWidgetForm>(this.data),
        };
    }
}
