import { Pipe, PipeTransform } from '@angular/core';
import {
    IDownloadProgress,
    ProtectedResourceDownloadService,
} from '@sharedServices/protected-resource-download.service';
import { Observable, of } from 'rxjs';

@Pipe({
    name: 'protectedPdfResourceUrl',
})
export class ProtectedPdfResourceUrlPipe implements PipeTransform {
    constructor(private protectedResSrv: ProtectedResourceDownloadService) {}

    transform(src: string, templateCb?: (status: IDownloadProgress) => void): Observable<any> {
        if (!src) {
            return of(null);
        }
        if (src.startsWith('blob')) {
            return of(src);
        }

        return this.protectedResSrv.downloadResource(src);
    }
}
