import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { startWith } from 'rxjs/operators';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import {
    MultiWidgetDividerLocation,
    MultiWidgetImageFit,
} from 'src/app/api-wrapper/models/widget-models/image-text-widget-model';
import {
    multiWidgetdividerOptions,
    multiWidgetflippedAvailableOptions,
    multiWidgetvideoFitOptions,
} from 'src/app/api-wrapper/models/widget-models/multi-widgets/multi-detail-options';
import { DetailVideoTextWidgetForm } from 'src/app/api-wrapper/models/widget-models/video-text-widget.model';
import { VideoDetailBaseWidgetComponent } from '../../VideoWidget/video-base-classes/video-detail-base';

@Component({
    selector: 'app-video-text-detail-widget',
    templateUrl: './video-text-detail-widget.component.html',
    styleUrls: [
        './video-text-detail-widget.component.scss',
        '../video-text-real-time-widget/video-text-real-time-widget.component.scss',
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VideoTextDetailWidgetComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation],
})
export class VideoTextDetailWidgetComponent
    extends VideoDetailBaseWidgetComponent<DetailVideoTextWidgetForm>
    implements OnInit
{
    widgetSizeEnum = WidgetSizeEnum;
    size: WidgetSizeEnum;
    sizeClass: string;
    testEnv = false;

    dividerOptions: IChekboxRadioFormModel[] = multiWidgetdividerOptions;
    flippedAvailableOptions: IChekboxRadioFormModel[] = multiWidgetflippedAvailableOptions;
    imageFitOptions: IChekboxRadioFormModel[] = multiWidgetvideoFitOptions;

    constructor() {
        super();
    }

    get dividerLocationValue(): MultiWidgetDividerLocation {
        return (this.form.get('dividerLocation')?.value ?? 'CENTER') as MultiWidgetDividerLocation;
    }

    get flippedValue(): boolean {
        return (this.form.get('flipped')?.value ?? false) as boolean;
    }

    get videoFitValue(): MultiWidgetImageFit {
        return (this.form.get('videoFit')?.value ?? 'CONTAIN') as MultiWidgetImageFit;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscriptions.add(
            this.form.controls.size.valueChanges
                .pipe(startWith(this.form.get('size').value as WidgetSizeEnum))
                .subscribe((size: WidgetSizeEnum) => {
                    if (this.config?.starterWidgetSize) {
                        this.config.starterWidgetSize = size;
                    }
                    this.size = size;
                    this.sizeClass = size === 'ORIGINAL' ? 'margin oriinal' : size?.toLowerCase();
                })
        );
    }

    setStarterImageSize(): string {
        let size: string;
        switch (this.config?.starterWidgetSize) {
            case WidgetSizeEnum.FULLSIZE:
                size = 'XL';
                break;
            default:
                size = 'L';
                break;
        }
        return size;
    }

    createForm(): FormGroupTyped<DetailVideoTextWidgetForm> {
        return new FormGroupTyped<DetailVideoTextWidgetForm>({
            ...this.addVideoDetailFormControls(),
            text: FormHelper.controlFactoryWithCalculatedValue(null),
            dividerLocation: FormHelper.controlFactoryWithCalculatedValue(null),
            flipped: FormHelper.controlFactoryWithCalculatedValue(null),
            videoFit: FormHelper.controlFactoryWithCalculatedValue(null),
            loop: FormHelper.controlFactoryWithCalculatedValue(null),
            muted: FormHelper.controlFactoryWithCalculatedValue(null),
            autoplay: FormHelper.controlFactoryWithCalculatedValue(null),
            controlsEnabled: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
