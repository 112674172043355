<form [formGroup]="form" class="detail-form">
    <div class="detail-form__site-left">
        <div class="iframe-empty-state" *ngIf="!src">
            <img src="assets/images/iframe-widget-empty-state.svg" alt="" />
            <div class="embed-image__text">
                <span>
                    {{ 'EMBEDED-WIDGET.EMPTY-STATE-TEXT-FIRST' | translate }}
                    <br />
                    {{ 'EMBEDED-WIDGET.EMPTY-STATE-TEXT-SECOND' | translate }}</span
                >
            </div>
            <button class="editor-btn primary">{{ 'EMBEDED-WIDGET.EMPTY-STATE-BUTTON' | translate }}</button>
        </div>

        <div class="iframe-container" *ngIf="src">
            <iframe
                sandbox="allow-forms allow-scripts allow-same-origin allow-pointer-lock allow-popups"
                frameborder="0"
                allowfullscreen
                [src]="src | safeResourceUrl"
                [height]="height"
                class="iframe-container__iframe"
            ></iframe>
        </div>
        <app-collapse-card [title]="'IMAGE-WIDGET.ACCESSIBILTY-CARD' | translate" [opened]="true">
            <div class="detail-form__group">
                <label class="detail-form__group__label" for="description"
                    >{{ 'EMBEDED-WIDGET.ACCESSIBILTY' | translate }}
                </label>
                <textarea name="description" id="description" formControlName="description"></textarea>
            </div>
        </app-collapse-card>
    </div>

    <div class="detail-form__site-right">
        <app-collapse-card [title]="'EMBEDED-WIDGET.SETTINGS' | translate" [opened]="true">
            <fieldset class="detail-form__group">
                <label class="detail-form__group__label" for="src"> {{ 'EMBEDED-WIDGET.LINK' | translate }} </label>
                <input
                    name="src"
                    class="detail-form__input"
                    id="src"
                    [placeholder]="'EMBEDED-WIDGET.LINK-PLACEHOLDER' | translate"
                    formControlName="src"
                />
            </fieldset>
            <fieldset class="detail-form__group">
                <label class="detail-form__group__label" for="title"> {{ 'EMBEDED-WIDGET.TITLE' | translate }} </label>
                <input
                    name="title"
                    class="detail-form__input"
                    id="title"
                    [placeholder]="'EMBEDED-WIDGET.TITLE-PLACEHOLDER' | translate"
                    formControlName="title"
                />
            </fieldset>
            <fieldset class="detail-form__group">
                <legend class="detail-form__group__label">{{ 'EMBEDED-WIDGET.HEIGHT' | translate }}</legend>
                <div class="detail-form__number-block">
                    <app-number-input formControlName="height" [min]="1" [max]="2000"></app-number-input>
                </div>
            </fieldset>
        </app-collapse-card>
        <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
            <app-radio-group-control
                class="detail-form__group"
                [title]="'EMBEDED-WIDGET.FULLFILMENT' | translate"
                [options]="fulfillmentAvailableOptions"
                formControlName="fulfillmentAvailable"
            ></app-radio-group-control>

            <app-radio-group-control
                class="detail-form__group"
                [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                [options]="fulfillmentOptions"
                formControlName="fulfillment"
            ></app-radio-group-control>
        </app-collapse-card>
    </div>
</form>
