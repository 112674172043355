import { Component, ElementRef, forwardRef, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { textAnimation } from '@shared/animations/collapse.animations';
import { QuillComponent } from '@shared/components/quill/quill.component';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { DomHandlerService } from '@sharedServices/dom-handler.service';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { RealTimeTextWidgetForm } from 'src/app/api-wrapper/models/widget-models/text-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

@Component({
    selector: 'app-text-real-time-widget',
    templateUrl: './text-real-time-widget.component.html',
    styleUrls: ['./text-real-time-widget.component.scss'],
    animations: [textAnimation],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class TextRealTimeWidgetComponent
    extends AbstractFormBaseComponent<RealTimeTextWidgetForm, IWidgetSupport>
    implements OnInit
{
    @ViewChild('quillComponent') quillComponent: QuillComponent;
    @ViewChild('collapsibleElement') collapsibleElement: ElementRef;
    testEnv = false;
    opened = false;
    collapsible: boolean;
    closedClass = true;
    animTiming = '400ms ease';

    constructor(private domSrv: DomHandlerService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.collapsible = (this.form.get('collapsible')?.value as boolean) ?? false;
    }

    setInteractive(collapsible: boolean): void {
        this.collapsible = collapsible;
        this.form.patchValue({ collapsible });
    }

    setOpened(state: boolean): void {
        this.opened = state;
        this.handleOpenedStateChange();
    }

    toogleOpened(): void {
        this.opened = !this.opened;
        this.handleOpenedStateChange();
    }

    handleOpenedStateChange(): void {
        this.animTiming = '400ms ease';
        if (!this.opened) {
            this.closedClass = true;
        }

        if (this.opened) {
            setTimeout(() => {
                this.domSrv.setFocus(this.config?.focusElemId, '.ql-editor');
                this.closedClass = false;
            }, 300);
            return;
        }
        const rect = this.collapsibleElement.nativeElement.getBoundingClientRect() as { top: number };

        if (rect.top < -156) {
            this.animTiming = '100ms';
            window.scrollBy({ left: 0, top: rect.top, behavior: 'auto' });
        }

        this.quillComponent.manualBlur();
    }

    createForm(): FormGroupTyped<RealTimeTextWidgetForm> {
        return new FormGroupTyped<RealTimeTextWidgetForm>({
            text: FormHelper.controlFactoryWithCalculatedValue(null),
            collapsible: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
