import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FileUploadHandlerService } from './file-upload-handler.service';

export interface IDownloadProgress {
    status: 'PROGRESS' | 'ERROR' | 'DONE';
    progress: number;
    errorStatus?: number;
}

@Injectable({ providedIn: 'root' })
export class ProtectedResourceDownloadService {
    constructor(private http: HttpClient, private fileUploadHandlerSrv: FileUploadHandlerService) {}

    downloadResource(downloadUrl: string, simpleUrlDownload = true): Observable<string> {
        const file = this.checkIfFileExistsInCache(downloadUrl);
        if (file) {
            return new Observable((obs) => {
                obs.next(URL.createObjectURL(file));
                obs.complete();
            });
        }

        if (simpleUrlDownload) {
            return of(downloadUrl);
        }
        return this.http.get(downloadUrl, { responseType: 'blob' }).pipe(map((blob) => URL.createObjectURL(blob)));
    }

    checkIfFileExistsInCache(downloadUrl: string): File | null {
        const [url] = downloadUrl.split('?access_token');
        const [resourceId] = url.split('/').reverse();
        return this.fileUploadHandlerSrv.getExistingFile(resourceId);
    }
}
