<form [formGroup]="form">
    <app-custom-textarea
        *ngIf="editorLocation === 'detail', else noEdit"
        class="navblock-title navigation-block-header"
        [formControlName]="'title'"
        [placeholder]="'NAVIGATION-BLOCK-WIDGET.TITLE' | translate "
    ></app-custom-textarea>

    <ng-template #noEdit>
        <header class="navigation-block-header" *ngIf="title">
            <h2>{{ title }}</h2>
        </header>
    </ng-template>
    <ul
        *ngIf="!testEnv"
        class="navigation-cards-container"
        formArrayName="cards"
        [style.--grid-columns]="coulumnsNumber"
        [sortablejs]="cards"
        [sortablejsOptions]="options"
        [class.horizontal-more-than-two-card]="coulumnsNumber > 2"
        [class.horizontal-block]="horizontal"
        [class.fullsize-columns]="coulumnsNumber > 3"
    >
        <li
            class="navigation-card-wrapper"
            *ngFor="let navigationCard of cards.controls; let i = index; let len = count"
            [formGroupName]="i"
        >
            <button
                class="editor-btn accent ghost height-small icon-only absoloute-action-button card-drag-handler handle"
                [ngClass]="{ nobutton: len < 1 }"
            >
                <span class="nitroicon-move editor-btn-icon"></span>
            </button>

            <app-navigation-card
                *ngIf="!testEnv"
                class="app-navigation-card"
                [form]="navigationCard"
                [config]="config"
                [disableActions]="numberOfCardsChanged"
                [len]="len"
            ></app-navigation-card>

            <div class="hide-card">{{ 'NAVIGATION-BLOCK-WIDGET.NEW-CARD-DROP-PLACEHOLDER' | translate }}</div>

            <button
                class="editor-btn primary ghost height-small icon-only absoloute-action-button card-delete-button"
                [ngClass]="{ nobutton: len < 2 }"
                (click)="deleteCard(i)"
            >
                <span class="nitroicon-close editor-btn-icon"></span>
            </button>
        </li>
    </ul>
</form>

<ng-container *ngIf="editorLocation === 'real-time'">
    <app-exported-template-actions [selector]="'#extra-action-container_' + config?.focusElemId" *ngIf="!testEnv">
        <button
            [disabled]="emittedForSave && numberOfCardsChanged"
            class="editor-btn section-btn ghost height-small icon-only"
            (click)="addNavigationCard()"
        >
            <span class="nitroicon-add editor-btn-icon"></span>
        </button>
    </app-exported-template-actions>
</ng-container>
