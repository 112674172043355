import { animate, state, style, transition, trigger } from '@angular/animations';

const animTiming = '500ms';

export const collapseAnimation = [
    trigger('parent', [transition(':enter', [])]),
    trigger('cardCollapse', [
        transition(':enter', [
            style({
                height: 0,
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
                marginBottom: 0,
                opacity: 0,
                overflow: 'hidden',
            }),
            animate(
                animTiming,
                style({
                    height: '*',
                    paddingTop: '*',
                    paddingBottom: '*',
                    marginTop: '*',
                    marginBottom: '*',
                    opacity: 1,
                })
            ),
        ]),
        transition(':leave', [
            style({
                height: '*',
                paddingTop: '*',
                paddingBottom: '*',
                marginTop: '*',
                marginBottom: '*',
                opacity: 1,
                overflow: 'hidden',
            }),
            animate(
                animTiming,
                style({
                    height: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    marginBottom: 0,
                    opacity: 0,
                })
            ),
        ]),
    ]),
];

export const collapseCentralAnimations = [
    trigger('centralCollapseAnimation', [
        transition(':enter', [
            style({ height: 0, width: 0, padding: 0, margin: 0, opacity: 0, overflow: 'hidden' }),
            animate(`${animTiming} ease`, style({ height: '*', width: '*', padding: '*', margin: '*', opacity: 1 })),
        ]),
        transition(':leave', [
            style({ height: '*', width: '*', padding: '*', margin: '*', opacity: 1, overflow: 'hidden' }),
            animate(`${animTiming} ease`, style({ height: 0, width: 0, padding: 0, margin: 0, opacity: 0 })),
        ]),
    ]),
];
export const textAnimation = [
    trigger('openClose', [
        state('closed', style({ height: '120px', overflow: 'hidden' })),
        state(
            'open',
            style({
                height: '*',
                overflow: 'initial',
            })
        ),
        transition('open => closed', [animate(`{{animTiming}}`)]),
        transition('closed => open', [animate('0.4s')]),
    ]),
];
