<form [formGroup]="form" data-e2e="cloze-question" class="cloze-question">
    <app-question-real-time-widget-wrapper
        *ngIf="!testEnv"
        [title]="'QUESTION-WIDGET.COMMON.CLOZE-QUESTION'"
        [config]="config"
        [form]="form"
        [editorLocation]="editorLocation"
        [hasMediaInfo]="hasMedia"
        [hasPdfInfo]="hasPdf"
        [attempts]="attempts"
    >
        <quill-editor
            class="quill-editor"
            [placeholder]="'QUESTION-WIDGET.COMMON.CLOZE-QUESTION-PLACEHOLDER' | translate"
            [formats]="['gap']"
            [theme]="'bubble'"
            (onContentChanged)="handleTextChange($event)"
            (onEditorCreated)="handleOnInit($event)"
        >
            <div quill-editor-toolbar>
                <span class="ql-formats" data-prevent-autosave tabindex="0">
                    <button class="text-button" type="button" (click)="addGap()">
                        {{ 'QUESTION-WIDGET.COMMON.CLOZE-QUESTION-MARK-AS-CLOZE' | translate }}
                    </button>
                    <button type="button" (click)="removeGapFormat()">
                        <span class="nitroicon-format_clear"></span>
                    </button>
                </span>
            </div>
        </quill-editor>
    </app-question-real-time-widget-wrapper>

    <app-collapse
        class="collapse-component"
        *ngIf="editorLocation === 'detail' ||  showToolbar"
        [@cardCollapse]="{ value: true, params: { animTiming: '500ms ease' } }"
    >
        <ul class="answers" class="reset-list answer-gaps" formArrayName="gaps">
            <ng-container *ngFor="let answer of gaps.controls; let i = index; let len = count">
                <li *ngIf="answer.get('type').value === 'GAP_ANSWERS'" [formGroupName]="i">
                    <app-cloze-question-answer
                        [answerForm]="answer"
                        (addWrongAnswer)="addChoiceAnswer(i)"
                        (removeWrongAnswer)="deleteChoiceAnswer(i, $event)"
                    >
                    </app-cloze-question-answer>
                </li>
            </ng-container>
        </ul>
    </app-collapse>
</form>
