import { SimpleVideoWidgetData, Subtitle } from '@apiModels';
import { IFileUploadElement, IFileUploadValue } from '@shared/models/file-upload.model';
import { BaseSubFormClass } from './base-widget-models/base-sub-form-class';
import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from './base-widget-models/widget-size.enum';

export interface RealTimeSimpleVideoWidgetForm {
    element: IFileUploadValue;
    previewImageElement: IFileUploadValue;
    hasTranscript: boolean;
    transcript: string;
    subtitleClass: ISubtitleFormData;
    loop: boolean;
    muted: boolean;
    autoplay: boolean;
    controlsEnabled: boolean;
}

export interface DetailSimpleVideoWidgetForm extends RealTimeSimpleVideoWidgetForm {
    playAsGif: boolean;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW' | 'VIDEO_STARTED' | 'VIDEO_FINISHED';
    size: WidgetSizeEnum;
}

export interface SimpleVideoWidgetDataSpec extends SimpleVideoWidgetData {
    fulfillment: 'NONE' | 'SHOW' | 'VIDEO_STARTED' | 'VIDEO_FINISHED';
    subtitleClass: SubtitleClass;
}

export class VideoWidgetClass extends BaseWidgetClass<SimpleVideoWidgetDataSpec> {
    filePosterUploadElement: IFileUploadElement | null = null;

    constructor(data: TypedResponse<SimpleVideoWidgetDataSpec>) {
        super(data);
        this.data.subtitleClass = new SubtitleClass(this.data?.subtitle);
    }

    getRealTimeFormData(): RealTimeSimpleVideoWidgetForm {
        return {
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data.videoResourceId ?? null,
            },
            previewImageElement: {
                fileItem: this.filePosterUploadElement,
                elementId: this.data.previewImageResourceId ?? null,
            },
            subtitleClass: this.data.subtitleClass.getFormData(),
            transcript: this.data?.transcript ?? null,
            hasTranscript: this.data?.hasTranscript ?? false,
            loop: this.data?.loop ?? false,
            muted: this.data?.muted ?? false,
            autoplay: this.data?.autoplay ?? false,
            controlsEnabled: this.data?.controlsEnabled ?? true,
        };
    }

    getDetailFormData(): DetailSimpleVideoWidgetForm {
        return {
            ...this.getRealTimeFormData(),
            playAsGif: this.data?.playAsGif ?? false,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
        };
    }

    updateWidgetData(modifiedData: RealTimeSimpleVideoWidgetForm | DetailSimpleVideoWidgetForm): void {
        const { element, previewImageElement, subtitleClass, ...rest } = modifiedData;

        if (this.shouldSaveFile(element.fileItem, this.data.videoResourceId)) {
            this.filesToSave.push(element.fileItem);
        }

        if (subtitleClass) {
            if (this.shouldSaveFile(subtitleClass.element.fileItem, this.data?.subtitle?.subtitleResourceId)) {
                this.filesToSave.push(subtitleClass.element.fileItem);
            }
            this.data.subtitleClass.updateData(subtitleClass);
        }

        if (previewImageElement) {
            if (this.shouldSaveFile(previewImageElement?.fileItem, this.data?.previewImageResourceId)) {
                this.filesToSave.push(previewImageElement.fileItem);
            }
        }

        this.fileUploadElement = element.fileItem;
        this.filePosterUploadElement = previewImageElement?.fileItem;

        const subtitle = this.data.subtitleClass.getDataForSave();

        const videoResourceId = this.extractUUID(element);
        const previewImageResourceId = this.extractUUID(previewImageElement);

        if (subtitle.subtitleResourceId === null) {
            subtitle.originalFileName = null;
            subtitle.srcLang = null;
        }
        this.data = { ...this.data, ...rest, subtitle, videoResourceId, previewImageResourceId };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.videoResourceId === id) {
                this.data = { ...this.data, videoResourceId: null };
                this.fileUploadElement = null;
            } else if (this.data.previewImageResourceId === id) {
                this.data = { ...this.data, previewImageResourceId: null };
                this.filePosterUploadElement = null;
            } else if (this.data.subtitle?.subtitleResourceId === id) {
                const subtitle = { subtitleResourceId: null, originalFileName: null, srcLang: null };
                this.data = { ...this.data, subtitle };
                this.data.subtitleClass = new SubtitleClass(this.data?.subtitle);
            }
        });
    }
}

export interface ISubtitleFormData {
    element: IFileUploadValue;
    srcLang: string;
    originalFileName: string;
}
export class SubtitleClass extends BaseSubFormClass<Subtitle, ISubtitleFormData> {
    getFormData(): ISubtitleFormData {
        return {
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data?.subtitleResourceId ?? null,
            },
            srcLang: this.data?.srcLang ?? null,
            originalFileName: this.data?.originalFileName ?? null,
        };
    }

    updateData(modifiedData: ISubtitleFormData): void {
        const { element, ...rest } = modifiedData;
        const subtitleResourceId = this.extractUUID(element);
        this.fileUploadElement = element.fileItem;
        let originalFileName = this.fileUploadElement?.name ?? rest.originalFileName;
        if (!subtitleResourceId) {
            originalFileName = null;
        }
        this.data = { ...this.data, ...rest, subtitleResourceId, originalFileName };
    }
}
