import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Group } from '@apiModels';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { Options } from 'sortablejs';
import { RealTimeGroupQuestionWidgetForm } from 'src/app/api-wrapper/models/widget-models/questions/group-question/group-question-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import { BaseQuestionRealTimeWidgetComponent } from '../../base-classes/abstract-base-real-time-question';
import { realTimeBaseQuestionWidgetFormFactory } from '../../helpers/choice-answers.helpers';

@Component({
    selector: 'app-group-question-real-time-widget',
    templateUrl: './group-question-real-time-widget.component.html',
    styleUrls: ['./group-question-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GroupQuestionRealTimeWidgetComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation],
})
export class GroupQuestionRealTimeWidgetComponent
    extends BaseQuestionRealTimeWidgetComponent<RealTimeGroupQuestionWidgetForm, IWidgetSupport>
    implements OnInit
{
    options: Options;
    groupsOpened: { [key: number]: boolean } = {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
    };
    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        const group = `${this.config.widgetId}-${this.editorLocation}-group-question`;
        this.options = {
            group,
            forceFallback: false,
        };
        super.ngOnInit();
    }

    get groups(): UntypedFormArray {
        return this.form.get('groups') as UntypedFormArray;
    }

    getGroup(index: number): UntypedFormArray {
        return this.groups.at(index).get('elements') as UntypedFormArray;
    }

    get nonGrouped(): UntypedFormArray {
        return this.form.get('nonGrouped') as UntypedFormArray;
    }

    updateForm(value: RealTimeGroupQuestionWidgetForm): void {
        this.shouldSave = false;
        super.updateForm(value);
        this.form.setControl('groups', FormHelper.initBaseFormarray(value.groups, this.createAnswer));
        this.form.setControl('nonGrouped', FormHelper.initBaseFormarrayWihControlls(value.nonGrouped));
        this.cdr.detectChanges();
        this.shouldSave = true;
    }

    createAnswer = (data?: Partial<Group>): FormGroupTyped<Group> => {
        return new FormGroupTyped<Group>({
            name: FormHelper.controlFactoryWithCalculatedValue(data?.name ?? ''),
            elements: FormHelper.initBaseFormarrayWihControlls(data?.elements ?? []),
        });
    };

    createForm(): FormGroupTyped<RealTimeGroupQuestionWidgetForm> {
        return realTimeBaseQuestionWidgetFormFactory({
            groups: FormHelper.initBaseFormarray([], this.createAnswer),
            nonGrouped: FormHelper.initBaseFormarray([], this.createAnswer),
        });
    }

    addNewGroup(): void {
        this.groups.push(this.createAnswer({}));
        const length = this.groups.length;
        this.groupsOpened[length - 1] = true;
    }

    removeGroup(index: number): void {
        this.groups.removeAt(index);
        for (let i = index; i < 4; i++) {
            this.groupsOpened[i] = this.groupsOpened[i + 1];
        }
    }

    addElement(index: number): void {
        const group = this.groups.controls[index] as FormGroupTyped<Group>;
        (group.controls.elements as UntypedFormArray).push(new UntypedFormControl(''));
    }

    deleteElement(groupIndex: number, elementIndex: number): void {
        const group = this.groups.controls[groupIndex] as FormGroupTyped<Group>;
        (group.controls.elements as UntypedFormArray).removeAt(elementIndex);
    }

    addNewFalseElem(): void {
        this.nonGrouped.push(new UntypedFormControl(''));
    }

    removeFalseElem(index: number): void {
        this.nonGrouped.removeAt(index);
    }
}
