import { TextWidgetData } from 'src/app/api/models/text-widget-data';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';

export interface RealTimeTextWidgetForm {
    text: string | null;
    collapsible: boolean;
}

export class TextWidgetClass extends BaseWidgetClass<TextWidgetData> {
    constructor(data: TypedResponse<TextWidgetData>) {
        super(data);
        this.extraSelector = '.ql-editor';
    }

    getRealTimeFormData(): RealTimeTextWidgetForm {
        return {
            text: this.data.text,
            collapsible: this.data?.collapsible ?? false,
        };
    }

    getDetailFormData(): any {
        return null;
    }
}
