<div class="dialog-header">
    <h2 mat-dialog-title *ngIf="titlePath">{{ titlePath | translate }}</h2>
    <button
        class="editor-btn height-small radius-25 border-0 primary icon-only"
        mat-dialog-close
        *ngIf="allowCloseInHeader"
        [mat-dialog-close]="resultNone"
        [attr.aria-label]="'MATERIAL.DIALOG.CLOSE' | translate"
    >
        <span class="nitroicon-close editor-btn-icon"></span>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <p *ngIf="messagePath">{{ messagePath | translate: { value: messageExtraInfo } }}</p>
    <ng-container *ngIf="contentTemplateRef">
        <ng-template
            [ngTemplateOutlet]="contentTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: data }"
        ></ng-template>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions *ngIf="showDialogActions" class="dialog-actions" [ngClass]="alignActions">
    <button
        *ngIf="secondaryButtonTextPath"
        class="dialog-actions__button editor-btn border-2 secondary"
        [ngClass]="buttonIconAlign === 'start' ? 'left-icon' : 'right-icon'"
        [mat-dialog-close]="resultSecondary"
    >
        <span
            *ngIf="secondaryButtonIcon && buttonIconAlign === 'start'"
            class="dialog-actions__button__icon editor-btn-icon"
            [ngClass]="secondaryButtonIcon"
        ></span>
        <span class="dialog-actions__button__text">{{ secondaryButtonTextPath | translate }}</span>
        <span
            *ngIf="secondaryButtonIcon && buttonIconAlign === 'end'"
            class="dialog-actions__button__icon editor-btn-icon"
            [ngClass]="secondaryButtonIcon"
        ></span>
    </button>

    <button
        *ngIf="primaryButtonTextPath"
        class="dialog-actions__button editor-btn border-2 primary"
        [ngClass]="[
            buttonIconAlign === 'start' ? 'left-icon' : 'right-icon',
            primaryButtonIcon === 'nitroicon-delete' ? 'danger' : 'primary'
        ]"
        [mat-dialog-close]="resultPrimary"
        cdkFocusInitial
    >
        <span
            *ngIf="primaryButtonIcon && buttonIconAlign === 'start'"
            class="dialog-actions__button__icon editor-btn-icon"
            [ngClass]="primaryButtonIcon"
        ></span>
        <span class="dialog-actions__button__text">{{ primaryButtonTextPath | translate }}</span>
        <span
            *ngIf="primaryButtonIcon && buttonIconAlign === 'end'"
            class="dialog-actions__button__icon editor-btn-icon"
            [ngClass]="primaryButtonIcon"
        ></span>
    </button>
</mat-dialog-actions>
