import { IFileUploadValue } from '@shared/models/file-upload.model';
import { SimpleImageWidgetData } from 'src/app/api/models/simple-image-widget-data';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from './base-widget-models/widget-size.enum';

export interface RealTimeImageWidgetForm {
    element: IFileUploadValue;
    caption: string | null;
}

export interface DetailSimpleImageWidgetForm {
    realTimeSimpleImageWidgetFormData: RealTimeImageWidgetForm;
    description?: string;
    accessibilityRole: 'STANDARD' | 'H2' | 'H3';
    zoomable: boolean;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW';
    size: WidgetSizeEnum;
}

export interface SimpleImageWidgetDataSpec extends SimpleImageWidgetData {
    fulfillment: 'NONE' | 'SHOW';
}

export class ImageWidgetClass extends BaseWidgetClass<SimpleImageWidgetDataSpec> {
    constructor(data: TypedResponse<SimpleImageWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeImageWidgetForm {
        return {
            caption: this.data?.caption ?? null,
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data.imageResourceId ?? null,
            },
        };
    }

    getDetailFormData(): DetailSimpleImageWidgetForm {
        return {
            realTimeSimpleImageWidgetFormData: this.getRealTimeFormData(),
            description: this.data?.description ?? '',
            accessibilityRole: this.data?.accessibilityRole ?? 'STANDARD',
            zoomable: this.data?.zoomable ?? false,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
        };
    }

    updateWidgetData(modifiedData: RealTimeImageWidgetForm | DetailSimpleImageWidgetForm): void {
        if (modifiedData.hasOwnProperty('realTimeSimpleImageWidgetFormData')) {
            modifiedData = {
                ...modifiedData,
                ...(modifiedData as DetailSimpleImageWidgetForm).realTimeSimpleImageWidgetFormData,
            };
        }
        // @ts-ignore
        const { element, ...rest } = modifiedData;

        if (this.shouldSaveFile(element.fileItem, this.data.imageResourceId)) {
            this.filesToSave.push(element.fileItem);
        }
        this.fileUploadElement = element.fileItem;
        const imageResourceId = this.extractUUID(element);
        this.data = { ...this.data, ...rest, imageResourceId };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.imageResourceId === id) {
                this.data = { ...this.data, imageResourceId: null };
                this.fileUploadElement = null;
            }
        });
    }
}
