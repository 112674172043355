<input type="file" #fileInput [accept]="acceptTypes" (change)="fileChangeListener()" />

<ng-container *ngIf="fileSrc; else emptyBtn">
    <img
        #droparea
        [src]="fileSrc | protectedResourceUrl | async"
        class="image"
        [ngClass]="{ original: imageSize === '' }"
        (click)="fileInput.click()"
        (drop)="landFile($event); droparea.classList.add('dropper'); droparea.classList.remove('dragover')"
        (dragenter)="droparea.classList.add('dropper')"
        (dragover)="$event.preventDefault(); droparea.classList.add('dragover')"
        (dragleave)="droparea.classList.remove('dragover')"
        (error)="onError($event)"
        (load)="imgLoaded()"
        tabindex="0"
        alt=""
    />
    <button *ngIf="showDeleteButtonOnHover" class="delete-btn" (click)="deleteFile()" (keyup.enter)="deleteFile()">
        <span class="nitroicon-close" aria-hidden="true"></span>
    </button>
</ng-container>

<div class="cropper" *ngIf="imageMode === 'crop'">
    <image-cropper
        [imageBase64]="fileSrc | noBypassProtectedResourceUrl | async"
        [maintainAspectRatio]="maintainAspectRatio"
        [aspectRatio]="aspectRatio"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
    >
    </image-cropper>
</div>

<ng-template #emptyBtn>
    <div class="dropper" *ngIf="imageMode === 'normal'" #droparea>
        <button
            data-e2e="image-upload"
            class="empty-btn"
            (click)="fileInput.click()"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
            tabindex="0"
        >
            <span class="content-wrapper" #contentWrapper>
                <ng-content></ng-content>
            </span>

            <div *ngIf="contentWrapper && contentWrapper.childNodes.length === 0" class="empty-btn__cover">
                <img src="assets/images/simple-image-widget.svg" class="image-widget__img" alt="" />
                <span class="empty-btn__upload-text">{{ 'IMAGE-WIDGET.IMAGE-DRAG-OR' | translate }}</span>
                <button type="button" class="empty-btn__upload-button editor-btn primary border-2">
                    {{ 'IMAGE-WIDGET.IMAGE-UPLOAD' | translate }}
                </button>
            </div>
        </button>
    </div>
</ng-template>
