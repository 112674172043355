<input
    [attr.disabled]="disabled ? true : null"
    *ngIf="simpleInput; else textarea"
    class="standalone-input"
    [formControl]="formControl"
    [placeholder]="placeholder | translate"
    (keyup.enter)="onEnter()"
    (blur)="onEnter()"
/>

<ng-template #textarea>
    <textarea
        [attr.disabled]="disabled ? true : null"
        class="standalone-input"
        [rows]="numberOfRows"
        [formControl]="formControl"
        [placeholder]="placeholder | translate"
        (keyup.enter)="onEnter()"
        (blur)="onEnter()"
    ></textarea>
</ng-template>
