<form [formGroup]="form" class="document-widget" data-e2e="recommendation-widget-button">
    <div class="document-download-wrapper" tabindex="0" [ngClass]="align" [class.edit]="state === 'EDIT'">
        <div class="align-selector" [ngClass]="editorLocation">
            <button
                (click)="updateAlign('LEFT')"
                class="align-selector__btn editor-btn section-btn height-medium"
                [ngClass]="{ selected: align === 'LEFT' }"
            >
                <span class="nitroicon-text_widget_format_align_left"></span>
            </button>
            <button
                (click)="updateAlign('CENTER')"
                class="align-selector__btn editor-btn section-btn height-medium"
                [ngClass]="{ selected: align === 'CENTER' }"
            >
                <span class="nitroicon-text_widget_format_align_center"></span>
            </button>
            <button
                (click)="updateAlign('RIGHT')"
                class="align-selector__btn editor-btn section-btn height-medium"
                [ngClass]="{ selected: align === 'RIGHT' }"
            >
                <span class="nitroicon-text_widget_format_align_right"></span>
            </button>
        </div>
        <button *ngIf="state === 'EDIT'" class="template-btn section-btn left-icon document-download-wrapper__button">
            <input
                [ngStyle]="{ 'width.px': width }"
                (input)="resize()"
                class="generic-input-reset"
                type="text"
                [id]="config?.focusElemId"
                [placeholder]="'RECOMMENDATION-BUTTON-WIDGET.UNNAMED' | translate"
                [formControlName]="'text'"
            />
            <span #hiddenText class="document-download-wrapper__hidden-span">
                {{ this.form.controls['text'].value }}
            </span>
        </button>
        <a
            *ngIf="state === 'LINK'"
            [routerLink]="pageId ? ['/', 'course', config?.courseId, pageId] : null"
            [fragment]="widgetId"
            class="template-btn section-btn left-icon document-download-wrapper__button"
            [id]="config?.focusElemId"
        >
            {{ this.form.controls['text'].value }}
        </a>
    </div>
</form>

<ng-container *ngIf="editorLocation === 'real-time'">
    <app-exported-template-actions [selector]="'#extra-action-container_' + config?.focusElemId" *ngIf="!testEnv">
        <button class="editor-btn section-btn ghost height-small icon-only" (click)="toogleState()">
            <span [ngClass]="state === 'EDIT' ? 'nitroicon-button' : 'nitroicon-edit'" class="editor-btn-icon"></span>
        </button>
        <button class="editor-btn section-btn ghost height-small icon-only" (click)="openPageLinkHandler()">
            <span class="nitroicon-link editor-btn-icon"></span>
        </button>
    </app-exported-template-actions>
</ng-container>
