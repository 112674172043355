import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DomHandlerService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    setFocus(selector: string, extraSelector: string): void {
        const elemToFocus: HTMLElement | null = this.document.querySelector(`#${selector} ${extraSelector}`);

        if (elemToFocus) {
            elemToFocus.focus();
        }
    }

    blurItem(selector: string, extraSelector: string): void {
        const elemToBlur: HTMLElement | null = this.document.querySelector(`#${selector} ${extraSelector}`);

        if (elemToBlur) {
            elemToBlur.blur();
        }
    }

    scrollTo(fragment: string): void {
        let run = 0;
        const intr = setInterval(() => {
            if (run > 20) {
                clearInterval(intr);
            }
            run++;
            const element: HTMLElement | null = this.document.querySelector('#' + fragment);
            if (element) {
                const offsetTop = element?.offsetTop;
                window.scrollTo({ top: offsetTop, behavior: 'smooth' });
                // element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
                clearInterval(intr);
            }
        }, 150);
    }

    hasFullPageModalOpen(): boolean {
        const fullPageModalList = this.document.getElementsByTagName('app-full-page-modal');
        return fullPageModalList.length > 0;
    }

    setCSSVariable(cssSelector: string, value = ''): void {
        this.document.documentElement.style.setProperty(cssSelector, value);
    }

    documentHeight(): number {
        return document.body.clientHeight;
    }

    offset(el: HTMLElement): { top: number; left: number } {
        const rect = el.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }
}
