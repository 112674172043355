/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ClozeGapAnswers, ClozeGapText, DropDownClozeQuestion } from '@apiModels';
import { Question } from 'src/app/api/models/question';

import { TypedResponse } from '../../base-widget-models/typed-widget-response';
import { InitRealTimeQuestionFormData } from '../init-real-time-form';
import {
    BaseQuestionWidgetDataSpec,
    DetailBaseQuestionWidgetForm,
    QuestionBaseWidgetClass,
    RealTimeBaseQuestionWidgetForm,
} from '../question-base-model';
import { ClozeGapAnswerModel } from './cloze-answer.model';
import { ClozeGapTextModel } from './cloze-text.model';
import { ClozeGapFactory } from './gap-answer-factory';

export enum ClozeGapType {
    GAP_ANSWERS = 'GAP_ANSWERS',
    GAP_TEXT = 'GAP_TEXT',
}

export type ClozeGapAnswerUnion = (ClozeGapAnswerModel | ClozeGapTextModel)[];

export interface RealTimeClozeQuestionWidgetForm extends RealTimeBaseQuestionWidgetForm {
    gaps: (ClozeGapText | ClozeGapAnswers)[];
}

export interface DetailClozeQuestionWidgetForm extends DetailBaseQuestionWidgetForm {
    realTimeQuestionWidgetFormData: RealTimeClozeQuestionWidgetForm;
}

export type MixedChoiceQuestionForm = RealTimeClozeQuestionWidgetForm & DetailClozeQuestionWidgetForm;

export interface ClozeQuestionWidgetDataSpec extends BaseQuestionWidgetDataSpec {
    question: DropDownClozeQuestion;
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION_CORRECTLY_ANSWERED' | 'QUESTION_ANSWERED';
    gaps: ClozeGapAnswerUnion;
}

export class ClozeQuestionWidgetClass extends QuestionBaseWidgetClass<
    ClozeQuestionWidgetDataSpec,
    RealTimeClozeQuestionWidgetForm,
    DetailClozeQuestionWidgetForm
> {
    constructor(data: TypedResponse<ClozeQuestionWidgetDataSpec>) {
        super(data, null);
        this.data.gaps = this.data?.question?.gaps.map((a) => ClozeGapFactory.factory(a));
    }

    getRealTimeFormData(): RealTimeClozeQuestionWidgetForm {
        return {
            gaps: this.data?.question?.gaps ?? [],
            ...InitRealTimeQuestionFormData(this.data, this.fileUploadElement, this.mediaUploadElement),
        };
    }

    updateAnswers(questionData: Question, answers: never[]): unknown[] {
        return [];
    }
}
