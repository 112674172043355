import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { BaseFileUploadComponent } from '@shared/forms/file-upload/base-file-upload/base-file-upload.component';
import { IFileUploadValue } from '@shared/models/file-upload.model';
import { URLHelperUtility } from '@sharedUtilities/url-helpers.utility';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';

export type ImageAspectRatio = 'NONE' | '4/3' | '16/9';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss', '../base-file-upload/base-file-upload.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploadComponent),
            multi: true,
        },
    ],
})
export class ImageUploadComponent extends BaseFileUploadComponent {
    @Input() acceptTypes = 'image/png, image/jpeg, image/jpg, image/gif, image/tiff, image/svg+xml';
    @Input() regExp = new RegExp(/\.(gif|jpg|jpeg|tiff|png|svg|webp|jfif)$/i);
    @Input() maxFileSize = 1_000_000_000;
    @Input() imageSize = 'L';
    @Input() aspectRatio = 4 / 3;
    @Input() maintainAspectRatio = true;
    @Output() imageLoadedEvent = new EventEmitter<void>();
    @ContentChild(TemplateRef, { static: true }) contentTemplateRef: TemplateRef<any>;
    imageMode: 'normal' | 'crop' = 'normal';
    croppedImage: File;

    constructor(protected toastsSrv: ToastMessageService, private cdr: ChangeDetectorRef) {
        super(toastsSrv, cdr);
    }

    public setRatio(ratio: ImageAspectRatio | string): void {
        switch (ratio) {
            case 'NONE':
                this.maintainAspectRatio = false;
                break;
            case '4/3':
                this.aspectRatio = 4 / 3;
                this.maintainAspectRatio = true;

                break;
            case '16/9':
                this.aspectRatio = 16 / 9;
                this.maintainAspectRatio = true;

                break;
            default:
                break;
        }
    }

    public setImageMode(mode: 'normal' | 'crop'): void {
        if (mode === 'crop' && !this.fileSrc) {
            return;
        }
        this.imageMode = mode;
    }

    public imageCropped(event: ImageCroppedEvent): void {
        const blob = base64ToFile(event.base64);
        const extension = blob.type.split('/')[1];
        this.croppedImage = this.blobToFile(blob, this.value?.fileItem?.name ?? `untitled.${extension}`);
    }

    private blobToFile(blob: Blob, fileName: string): File {
        return new File([blob], fileName, { lastModified: new Date().getTime(), type: blob.type });
    }

    public acceptCroppedImage(): void {
        this.processFile(this.croppedImage);
        this.cancelCroppedImage();
    }

    public cancelCroppedImage(): void {
        this.croppedImage = null;
        this.setImageMode('normal');
    }

    imageLoaded(): void {}

    cropperReady(): void {}

    loadImageFailed(): void {}

    getResourceUrl(value: IFileUploadValue): string {
        return URLHelperUtility.imageResourceURLForCourse(
            this.courseId,
            value.elementId,
            this.imageSize ?? 'L',
            this.bypassSw
        );
    }

    setSrc(imageSize: string): void {
        if (!this.value.fileItem && this.value?.elementId) {
            this.fileSrc = URLHelperUtility.imageResourceURLForCourse(
                this.courseId,
                this.value.elementId,
                imageSize,
                this.bypassSw
            );
        }
    }

    onError(event: Event): void {
        event.preventDefault();
        this.fileSrc = URLHelperUtility.resourceURLForCourse(this.courseId, this.value.elementId, this.bypassSw);
    }

    imgLoaded(): void {
        this.imageLoadedEvent.emit();
    }
}
