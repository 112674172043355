import { RecommendationButtonWidgetData } from '@apiModels';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';

export interface RealTimeRecommendationButtonWidgetForm {
    pageId: number;
    text: string;
    widgetId: number;
    align: 'LEFT' | 'CENTER' | 'RIGHT';
}

export class RecommendationButtonWidgetClass extends BaseWidgetClass<RecommendationButtonWidgetData> {
    constructor(data: TypedResponse<RecommendationButtonWidgetData>) {
        super(data);
        this.extraSelector = '.generic-input-reset';
    }

    getRealTimeFormData(): RealTimeRecommendationButtonWidgetForm {
        return {
            text: this?.data.text ?? '',
            align: this?.data.align ?? 'LEFT',
            pageId: this?.data?.pageId ?? null,
            widgetId: this?.data?.widgetId ?? null,
        };
    }

    getDetailFormData(): any {
        return null;
    }
}
