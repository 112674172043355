import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { LearningCardWidgetData } from 'src/app/api/models/learning-card-widget-data';

export interface RealTimeLearningCardWidgetForm {
    statement: string;
    explanation: string;
}

export interface DetailLearningCardWidgetForm {
    realTimeLearningCardWidgetFormData: RealTimeLearningCardWidgetForm;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
}

export interface LearningCardWidgetDataSpec extends LearningCardWidgetData {
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
}

export class LearningCardWidgetClass extends BaseWidgetClass<LearningCardWidgetDataSpec> {
    constructor(data: TypedResponse<LearningCardWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeLearningCardWidgetForm {
        return {
            statement: this.data?.statement ?? null,
            explanation: this.data?.explanation ?? null,
        };
    }

    getDetailFormData(): DetailLearningCardWidgetForm {
        return {
            realTimeLearningCardWidgetFormData: this.getRealTimeFormData(),
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
        };
    }

    updateWidgetData(modifiedData: RealTimeLearningCardWidgetForm | DetailLearningCardWidgetForm): void {
        if (modifiedData.hasOwnProperty('realTimeLearningCardWidgetFormData')) {
            modifiedData = {
                ...modifiedData,
                ...(modifiedData as DetailLearningCardWidgetForm).realTimeLearningCardWidgetFormData,
            };
        }
        this.data = { ...this.data, ...modifiedData };
    }
}
