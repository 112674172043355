import { MatchingQuestion, PairAnswer, Question } from '@apiModels';

import { TypedResponse } from '../../base-widget-models/typed-widget-response';
import {
    BaseQuestionWidgetDataSpec,
    DetailBaseQuestionWidgetForm,
    QuestionBaseWidgetClass,
    RealTimeBaseQuestionWidgetForm,
} from '../question-base-model';
import { IMatchingAnswerModel, MatchingAnswerModel } from './matching-answer-model';

export interface RealTimeMatchingQuestionWidgetForm extends RealTimeBaseQuestionWidgetForm {
    answers: IMatchingAnswerModel[];
}

export interface DetailMatchingQuestionWidgetForm extends DetailBaseQuestionWidgetForm {
    realTimeQuestionWidgetFormData: RealTimeMatchingQuestionWidgetForm;
}

export type MixedChoiceQuestionForm = RealTimeMatchingQuestionWidgetForm & DetailMatchingQuestionWidgetForm;

export interface MatchingQuestionWidgetData extends BaseQuestionWidgetDataSpec {
    question: MatchingQuestion;
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION_CORRECTLY_ANSWERED' | 'QUESTION_ANSWERED';
    answers: MatchingAnswerModel[];
}

export class MatchingQuestionWidgetModel extends QuestionBaseWidgetClass<
    MatchingQuestionWidgetData,
    RealTimeMatchingQuestionWidgetForm,
    DetailMatchingQuestionWidgetForm
> {
    constructor(data: TypedResponse<MatchingQuestionWidgetData>) {
        super(data, MatchingAnswerModel);
    }

    updateAnswers(questionData: Question, answers: IMatchingAnswerModel[]): PairAnswer[] {
        const answerIds = answers.map((answer: IMatchingAnswerModel) => answer.id);
        this.data.answers = this.data.answers.filter((e) => answerIds.indexOf(e.data.id) > -1);
        answers.forEach((elem: IMatchingAnswerModel) => {
            const existing = this.data.answers.find((item) => item.data.id === elem.id);
            const updateElem = {
                ...elem,
                mediaResourceElement: questionData.hasAnswerMedia
                    ? elem.mediaResourceElement
                    : { fileItem: null, elementId: null },
            };

            if (existing) {
                if (
                    questionData.hasAnswerMedia &&
                    this.shouldSaveFile(elem.mediaResourceElement.fileItem, existing.data.mediaResourceId)
                ) {
                    this.filesToSave.push(elem.mediaResourceElement.fileItem);
                }
                existing.updateData(updateElem);
            }

            if (!existing) {
                const { mediaResourceElement, ...rest } = elem;
                const mediaResourceId = questionData.hasAnswerMedia ? this.extractUUID(mediaResourceElement) : null;
                if (questionData.hasAnswerMedia && elem.mediaResourceElement.fileItem) {
                    this.filesToSave.push(elem.mediaResourceElement.fileItem);
                }
                const newElem = new MatchingAnswerModel({ mediaResourceId, ...rest });

                newElem.updateData(updateElem);
                this.data.answers.push(newElem);
            }
        });
        return this.data.answers.map((e) => e.getDataForSave());
    }
}
