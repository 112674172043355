import { ChoiceAnswer, SingleChoiceQuestion } from '@apiModels';
import { Question } from 'src/app/api/models/question';

import { TypedResponse } from '../../base-widget-models/typed-widget-response';
import {
    BaseQuestionWidgetDataSpec,
    DetailBaseQuestionWidgetForm,
    QuestionBaseWidgetClass,
    RealTimeBaseQuestionWidgetForm,
} from '../question-base-model';
import { ChoiceAnserModel, IChoiceAnswerModel } from './choice-anser.model';

export interface RealTimeChoiceQuestionWidgetForm extends RealTimeBaseQuestionWidgetForm {
    answers: IChoiceAnswerModel[];
}

export interface DetailChoiceQuestionWidgetForm extends DetailBaseQuestionWidgetForm {
    realTimeQuestionWidgetFormData: RealTimeChoiceQuestionWidgetForm;
}

export type MixedChoiceQuestionForm = RealTimeChoiceQuestionWidgetForm & DetailChoiceQuestionWidgetForm;

export interface ChoiceQuestionWidgetDataSpec extends BaseQuestionWidgetDataSpec {
    question: SingleChoiceQuestion;
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION_CORRECTLY_ANSWERED' | 'QUESTION_ANSWERED';
    answers: ChoiceAnserModel[];
}

export class ChoiceQuestionWidgetClass extends QuestionBaseWidgetClass<
    ChoiceQuestionWidgetDataSpec,
    RealTimeChoiceQuestionWidgetForm,
    DetailChoiceQuestionWidgetForm
> {
    constructor(data: TypedResponse<ChoiceQuestionWidgetDataSpec>) {
        super(data, ChoiceAnserModel);
    }

    updateAnswers(questionData: Question, answers: IChoiceAnswerModel[]): ChoiceAnswer[] {
        const answerIds = answers.map((answer: IChoiceAnswerModel) => answer.id);
        this.data.answers = this.data.answers.filter((e) => answerIds.indexOf(e.data.id) > -1);
        answers.forEach((elem: IChoiceAnswerModel) => {
            const existing = this.data.answers.find((item) => item.data.id === elem.id);
            const updateElem = {
                ...elem,
                explanation:
                    questionData.hasAnswerExplanation ?? this.data.question.hasAnswerExplanation
                        ? elem.explanation
                        : null,
                mediaResourceElement: questionData.hasAnswerMedia
                    ? elem.mediaResourceElement
                    : { fileItem: null, elementId: null },
            };

            if (existing) {
                if (
                    questionData.hasAnswerMedia &&
                    this.shouldSaveFile(elem.mediaResourceElement.fileItem, existing.data.mediaResourceId)
                ) {
                    this.filesToSave.push(elem.mediaResourceElement.fileItem);
                }
                existing.updateData(updateElem);
            }

            if (!existing) {
                const { mediaResourceElement, ...rest } = elem;
                const mediaResourceId = questionData.hasAnswerMedia ? this.extractUUID(mediaResourceElement) : null;
                if (questionData.hasAnswerMedia && elem.mediaResourceElement.fileItem) {
                    this.filesToSave.push(elem.mediaResourceElement.fileItem);
                }
                const newElem = new ChoiceAnserModel({ mediaResourceId, ...rest });

                newElem.updateData(updateElem);
                this.data.answers.push(newElem);
            }
        });
        return this.data.answers.map((e) => e.getDataForSave());
    }
}
