/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { TooltipData } from '@shared/models/tooltip-data.model';
import Quill from 'quill';

const Inline = Quill.import('blots/inline');
export class CustomTooltipBlot extends Inline {
    static create(tooltipData: TooltipData): any {
        const node = super.create();
        node.setAttribute('data-toggle', `tooltip`);
        node.setAttribute('data-tooltip', `${tooltipData.text}`);
        node.className = `custom-tooltip tooltip-storage tooltip-marker tooltip-${tooltipData.placement}`;
        node.setAttribute('data-placement', `${tooltipData.placement}`);
        return node;
    }

    static value(node: { innerHTML: string }): string {
        return node.innerHTML;
    }

    static formats(domNode: any): TooltipData {
        return {
            text: domNode.getAttribute('data-tooltip'),
            placement: domNode.getAttribute('data-placement'),
        };
    }
}
CustomTooltipBlot.blotName = 'custom-tooltip';
CustomTooltipBlot.tagName = 'span';
CustomTooltipBlot.className = 'custom-tooltip';
