import { IFileUploadValue } from '@shared/models/file-upload.model';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { NoteWidgetData } from '@apiModels';

export interface RealTimeNoteWidgetForm {
    element: IFileUploadValue;
    title: string | null;
    text: string | null;
}

export interface DetailNoteWidgetForm {
    realTimeNoteWidgetFormData: RealTimeNoteWidgetForm;
}

export class NoteWidgetClass extends BaseWidgetClass<NoteWidgetData> {
    constructor(data: TypedResponse<NoteWidgetData>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeNoteWidgetForm {
        return {
            title: this.data?.title ?? null,
            text: this.data?.text ?? null,
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data.documentResourceId ?? null,
            },
        };
    }

    getDetailFormData(): DetailNoteWidgetForm {
        return {
            realTimeNoteWidgetFormData: this.getRealTimeFormData(),
        };
    }

    updateWidgetData(modifiedData: RealTimeNoteWidgetForm | DetailNoteWidgetForm): void {
        if (modifiedData.hasOwnProperty('realTimeNoteWidgetFormData')) {
            modifiedData = {
                ...modifiedData,
                ...(modifiedData as DetailNoteWidgetForm).realTimeNoteWidgetFormData,
            };
        }
        // @ts-ignore
        const { element, ...rest } = modifiedData;

        if (this.shouldSaveFile(element.fileItem, this.data.documentResourceId)) {
            this.filesToSave.push(element.fileItem);
        }
        this.fileUploadElement = element.fileItem;
        const documentResourceId = element.fileItem ? element.fileItem.uuid : element.elementId;
        this.data = { ...this.data, ...rest, documentResourceId };
    }
}
