export class ConfirmDialogData {
    titlePath?: string;
    messageExtraInfo?: string;
    messagePath?: string;
    primaryButtonTextPath = 'MATERIAL.DIALOG.CONFIRM';
    primaryButtonIcon?: string;
    secondaryButtonTextPath = 'MATERIAL.DIALOG.CANCEL';
    secondaryButtonIcon?: string;

    constructor(data: any) {
        Object.assign(this, data);
    }
}
