/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AnchorWidgetData } from '@apiModels';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';

export interface RealTimeAnchorWidgetForm {
    name: string;
    structural: boolean;
    visible: boolean;
}

export class AnchorWidgetClass extends BaseWidgetClass<AnchorWidgetData> {
    constructor(data: TypedResponse<AnchorWidgetData>) {
        super(data);
        this.extraSelector = '.generic-input-reset';
    }

    getRealTimeFormData(): RealTimeAnchorWidgetForm {
        return {
            name: this.data?.name,
            structural: this.data?.structural ?? false,
            visible: this.data?.visible ?? false,
        };
    }

    getDetailFormData(): any {
        return null;
    }
}
