/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourceResponse } from '../models/resource-response';
import { StorageInfo } from '../models/storage-info';
import { TenantEntity } from '../models/tenant-entity';
import { TenantLicenceData } from '../models/tenant-licence-data';

@Injectable({
  providedIn: 'root',
})
export class EditorTenantControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createTenant
   */
  static readonly CreateTenantPath = '/';

  /**
   * Create tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: TenantEntity
  }): Observable<StrictHttpResponse<TenantEntity>> {

    const rb = new RequestBuilder(this.rootUrl, EditorTenantControllerService.CreateTenantPath, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantEntity>;
      })
    );
  }

  /**
   * Create tenant.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: TenantEntity
  }): Observable<TenantEntity> {

    return this.createTenant$Response(params).pipe(
      map((r: StrictHttpResponse<TenantEntity>) => r.body as TenantEntity)
    );
  }

  /**
   * Path part for operation uploadTenantLogo
   */
  static readonly UploadTenantLogoPath = '/tenant/resources/logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadTenantLogo()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTenantLogo$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<StrictHttpResponse<ResourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EditorTenantControllerService.UploadTenantLogoPath, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadTenantLogo$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTenantLogo(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<ResourceResponse> {

    return this.uploadTenantLogo$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceResponse>) => r.body as ResourceResponse)
    );
  }

  /**
   * Path part for operation deleteTenantLogo
   */
  static readonly DeleteTenantLogoPath = '/tenant/resources/logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTenantLogo()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenantLogo$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EditorTenantControllerService.DeleteTenantLogoPath, 'delete');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTenantLogo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenantLogo(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteTenantLogo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTenant
   */
  static readonly GetTenantPath = '/{tenantCode}';

  /**
   * Get tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenant$Response(params: {
    tenantCode: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<TenantEntity>> {

    const rb = new RequestBuilder(this.rootUrl, EditorTenantControllerService.GetTenantPath, 'get');
    if (params) {
      rb.path('tenantCode', params.tenantCode, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantEntity>;
      })
    );
  }

  /**
   * Get tenant.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenant(params: {
    tenantCode: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<TenantEntity> {

    return this.getTenant$Response(params).pipe(
      map((r: StrictHttpResponse<TenantEntity>) => r.body as TenantEntity)
    );
  }

  /**
   * Path part for operation deleteTenant
   */
  static readonly DeleteTenantPath = '/{tenantCode}';

  /**
   * Delete tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant$Response(params: {
    tenantCode: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EditorTenantControllerService.DeleteTenantPath, 'delete');
    if (params) {
      rb.path('tenantCode', params.tenantCode, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete tenant.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant(params: {
    tenantCode: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteTenant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation downloadTenantResource
   */
  static readonly DownloadTenantResourcePath = '/tenant/resources/{resourceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadTenantResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadTenantResource$Response(params: {
    resourceId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EditorTenantControllerService.DownloadTenantResourcePath, 'get');
    if (params) {
      rb.path('resourceId', params.resourceId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadTenantResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadTenantResource(params: {
    resourceId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<string> {

    return this.downloadTenantResource$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getStorageInfo
   */
  static readonly GetStorageInfoPath = '/info/storage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStorageInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStorageInfo$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<StorageInfo>> {

    const rb = new RequestBuilder(this.rootUrl, EditorTenantControllerService.GetStorageInfoPath, 'get');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StorageInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStorageInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStorageInfo(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StorageInfo> {

    return this.getStorageInfo$Response(params).pipe(
      map((r: StrictHttpResponse<StorageInfo>) => r.body as StorageInfo)
    );
  }

  /**
   * Path part for operation getLicenceData
   */
  static readonly GetLicenceDataPath = '/info/licence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLicenceData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenceData$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<TenantLicenceData>> {

    const rb = new RequestBuilder(this.rootUrl, EditorTenantControllerService.GetLicenceDataPath, 'get');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantLicenceData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLicenceData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenceData(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<TenantLicenceData> {

    return this.getLicenceData$Response(params).pipe(
      map((r: StrictHttpResponse<TenantLicenceData>) => r.body as TenantLicenceData)
    );
  }

}
