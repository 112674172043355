<input type="file" #fileInput [accept]="acceptTypes" (change)="fileChangeListener()" />

<input type="file" #pptFileInput [accept]="pptAcceptTypes" (change)="ppTfileChangeListener()" />

<ng-container *ngIf="addSlideType === 'START_PAGE'">
    <div class="dropper" #droparea>
        <section
            data-e2e="image-upload"
            class="slide-empty-page"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
        >
            <div class="slide-empty-page__cover">
                <img
                    *ngIf="embeded; else gallery"
                    src="assets/images/dia-widget-empty.svg"
                    class="slide-empty-page__img"
                    alt=""
                />
                <ng-template #gallery>
                    <img src="assets/images/slide-main.svg" class="slide-empty-page__img" alt="" />
                </ng-template>

                <p class="slide-empty-page__upload-text">{{ 'IMAGE-WIDGET.IMAGE-DRAG-OR' | translate }}</p>
                <div class="slide-empty-page__buttons-container">
                    <button
                        (click)="fileInput.click()"
                        type="button"
                        class="slide-empty-page__upload-button editor-btn height-small primary border-2"
                    >
                        {{ 'CAROUSEL-WIDGET.IMAGE-UPLOAD' | translate }}
                    </button>

                    <button
                        *ngIf="embeded"
                        (click)="pptFileInput.click()"
                        type="button"
                        class="slide-empty-page__upload-button editor-btn height-small primary border-2"
                    >
                        {{ 'CAROUSEL-WIDGET.PPT-UPLOAD' | translate }}
                    </button>
                </div>
            </div>
            <!-- Sliderhez a pöttyök -->
            <ul class="indicators reset-list">
                <li *ngFor="let slide of [1, 2, 3]; let i = index">
                    <button
                        [class.active]="i === 0"
                        disabled
                        class="template-btn card-btn accent indicators__btn"
                    ></button>
                </li>
            </ul>
        </section>
    </div>
</ng-container>

<ng-container *ngIf="addSlideType === 'ADD'">
    <div class="dropper" #droparea>
        <button
            data-e2e="image-upload"
            class="template-btn card-btn accent add-new-btn"
            (click)="fileInput.click()"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
            tabindex="0"
        >
            <div class="add-new-btn__icon-wrapper">
                <span class="nitroicon-plussz"></span>
            </div>
        </button>
    </div>
</ng-container>
