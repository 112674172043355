<div class="preloader" *ngIf="show">
    <div class="loader">
        <div class="rect-container left"><div class="rect"></div></div>
        <div class="rect-container center"><div class="rect"></div></div>
        <div class="rect-container right"><div class="rect"></div></div>
    </div>
    <ng-container [ngSwitch]="type">
        <!--        <mat-spinner *ngSwitchCase="'DEFAULT'"></mat-spinner>-->
        <div class="preview-text" *ngSwitchCase="'PREVIEW'">{{ 'COMMON.PREVIEW_IN_PROGRESS' | translate }}</div>
        <div class="preview-text" *ngSwitchCase="'PPT_PROCCESS'">{{ 'CAROUSEL-WIDGET.LOADER-TEXT' | translate }}</div>
    </ng-container>
</div>
