import { IFileUploadValue } from '@shared/models/file-upload.model';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';

import { AudioWidgetData } from 'src/app/api/models/audio-widget-data';

export interface RealTimeAudioWidgetForm {
    element: IFileUploadValue;
    description: string | null;
}

export interface DetailAudioWidgetForm {
    realTimeAudioWidgetFormData: RealTimeAudioWidgetForm;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
    description: string | null;
}

export interface AudioWidgetDataSpec extends AudioWidgetData {
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
}

export class AudioWidgetClass extends BaseWidgetClass<AudioWidgetDataSpec> {
    constructor(data: TypedResponse<AudioWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeAudioWidgetForm {
        return {
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data.audioResourceId ?? null,
            },
            description: this.data?.description ?? null,
        };
    }

    getDetailFormData(): DetailAudioWidgetForm {
        return {
            realTimeAudioWidgetFormData: this.getRealTimeFormData(),
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
            description: this.data?.description ?? null,
        };
    }

    updateWidgetData(modifiedData: RealTimeAudioWidgetForm | DetailAudioWidgetForm): void {
        const hasRealTime = modifiedData.hasOwnProperty('realTimeAudioWidgetFormData');
        if (hasRealTime) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { element } = (modifiedData as DetailAudioWidgetForm).realTimeAudioWidgetFormData;
            modifiedData = {
                ...modifiedData,
                element,
            };
        }
        // @ts-ignore
        const { element, ...rest } = modifiedData;

        if (this.shouldSaveFile(element.fileItem, this.data.audioResourceId)) {
            this.filesToSave.push(element.fileItem);
        }
        this.fileUploadElement = element.fileItem;
        const audioResourceId = element.fileItem ? element.fileItem.uuid : element.elementId;
        this.data = { ...this.data, ...rest, audioResourceId };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.audioResourceId === id) {
                this.data = { ...this.data, audioResourceId: null };
                this.fileUploadElement = null;
            }
        });
    }
}
