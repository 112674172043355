/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Component, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { fadeAnimations } from '@shared/animations/fade-related.animations';
import { TooltipData } from '@shared/models/tooltip-data.model';
import { DomHandlerService } from '@sharedServices/dom-handler.service';
import { SelectionChange } from 'ngx-quill';
import Quill from 'quill';
import { CustomLinkAttributes } from './quill-formats/custom-link.bloat';

const Delta = Quill.import('delta');

export type FormatSourceType = 'user' | 'api' | 'silent';

@Component({
    selector: 'app-quill',
    templateUrl: './quill.component.html',
    styleUrls: ['./quill.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuillComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation, fadeAnimations],
})
export class QuillComponent implements ControlValueAccessor {
    @Input() placeholder = '';
    @Input() showToolbar = false;
    @Input() toolbarVisible = true;
    @Input() preSetStyles = [];
    @Input() theme: 'snow' | 'bubble' = 'snow';
    @Input() toolbarOptions = [
        'header',
        'bold',
        'italic',
        'sub',
        'super',
        'ql-script',
        'script',
        'list',
        'custom-link',
        'custom-tooltip',
        'clean',
        'align',
        'color',
        'font',
        'background',
        'underline',
        'strike',
        'size',
        'huge',
        'code-block',
        'blockquote',
    ];
    @Output() linkEditOpen = new EventEmitter<boolean>();
    @Output() afterBlurEvent = new EventEmitter<void>();
    @Output() afterFocusEvent = new EventEmitter<void>();
    hoverPosX: number;
    hoverPosY: number;
    customLinkData: CustomLinkAttributes;

    quillInstance: Quill;

    selectedIndexAndLength: { index: number; length: number };

    customTooltipData: TooltipData | null;
    tooltipEditorPosition: any;

    toolbarEditorOpen = false;

    activeFormats = [];
    updateFromWriteValue = false;

    private onTouchedCallback: () => void;
    private onChangeCallback: (_: any) => void;

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _value: string;

    constructor(private domSrv: DomHandlerService, private el: ElementRef) {}

    get value(): string {
        return this._value;
    }

    set value(v: string) {
        if (v !== this._value) {
            this._value = v;
            this.onChangeCallback(this._value);
            this.onTouchedCallback();
        }
    }

    writeValue(value: any): void {
        this.updateFromWriteValue = true;
        this._value = value;
        setTimeout(() => {
            this.updateFromWriteValue = false;
        }, 100);
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState?(isDisabled: boolean): void {}

    onBlurEvent(): void {
        if (this.customLinkData || this.customTooltipData) {
            return;
        }
        this.showToolbar = false;
        this.afterBlurEvent.emit();
    }

    onFocusEvent(): void {
        this.showToolbar = true;
        this.afterFocusEvent.emit();
    }

    onEnter(): void {
        const formats = Object.keys(this.quillInstance.getFormat());
        if (formats.includes('header')) {
            return;
        }
        this.activeFormats = this.activeFormats.filter((format: string) => !format.includes('header'));
    }

    manualBlur(): void {
        this.quillInstance.blur();
    }

    handleOnInit(e: Quill): void {
        this.quillInstance = e;
        if (this.preSetStyles.length) {
            this.quillInstance.disable();
            // új widgt beszúrásakor a prestyle beállítások miatt elcsúszik az animáció
            // megoldás: csak az animáció lezajlása után állítjuk be a formázásokat
            // ezért kell a collapseWidget animáció timing-jával megegyező 500 ms-os timeout
            setTimeout(() => {
                this.preSetStyles.forEach((style: string) => {
                    const isTwoPartStyle = style.includes('-');
                    const format = isTwoPartStyle ? style.split('-')[0] : style;
                    const value = isTwoPartStyle ? style.split('-')[1] : true;
                    this.setFormat(format, value, 'api');
                });
                this.quillInstance.enable();
                this.quillInstance.blur();
            }, 500);
        }
        this.addCustomMatcher();
        delete this.quillInstance.getModule('keyboard').bindings['9'];
    }

    addCustomMatcher(): void {
        this.quillInstance.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            const plaintext = node.innerText;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            if (this.updateFromWriteValue) {
                return delta;
            }
            return new Delta().insert(plaintext);
        });
    }

    onFormatClick(event: MouseEvent, format: string, value: boolean | string = true): void {
        event.stopPropagation();
        event.preventDefault();
        const hasFormat = this.getFormatInSelection(format, value);

        switch (format) {
            case 'clean': {
                this.quillInstance.removeFormat(
                    this.selectedIndexAndLength.index,
                    this.selectedIndexAndLength.length,
                    'user'
                );
                break;
            }
            case 'custom-tooltip': {
                if (hasFormat) {
                    this.editTooltip(hasFormat);
                } else {
                    this.initTooltip();
                }
                break;
            }
            case 'custom-link': {
                if (hasFormat) {
                    this.editLink(hasFormat);
                } else {
                    this.editLink();
                }
                break;
            }
            default: {
                if (hasFormat) {
                    this.unsetFormat(format);
                } else {
                    this.setFormat(format, value);
                }
            }
        }
    }

    getFormatInSelection(format: string, value: string | boolean): boolean | any {
        const currentFormat = this.quillInstance.getFormat(
            this.selectedIndexAndLength.index,
            this.selectedIndexAndLength.length
        );

        return format in currentFormat && (value === true || String(value) === String(currentFormat[format]))
            ? currentFormat[format]
            : false;
    }

    updateFormats(): void {
        const currentFormat = this.quillInstance.getFormat(
            this.selectedIndexAndLength.index,
            this.selectedIndexAndLength.length
        );
        this.activeFormats = Object.entries(currentFormat).map((f) => {
            return ['header', 'list', 'script'].includes(f[0]) ? `${f[0]}-${f[1]}` : f[0];
        });
    }

    linkEditDone(event: { action: 'DELETE' | 'CANCEL' | 'DONE'; link: CustomLinkAttributes }): void {
        switch (event.action) {
            case 'DONE':
                this.setFormat('custom-link', event.link);
                break;
            case 'DELETE':
                this.unsetFormat('custom-link');
                break;
            default:
                break;
        }
        this.linkEditOpen.emit(false);
        this.customLinkData = null;
    }

    setFormat(format: string, value: any, source: FormatSourceType = 'user'): void {
        this.quillInstance.format(format, value, source);
        this.updateFormats();
    }

    unsetFormat(format: string, source: FormatSourceType = 'user'): void {
        this.quillInstance.format(format, false, source);
        this.updateFormats();
    }

    private editLink(attributes = { value: null }): void {
        this.linkEditOpen.emit(true);
        this.customLinkData = new CustomLinkAttributes(attributes);
    }

    private editTooltip(data: TooltipData) {
        this.tooltipEditorPosition = this.quillInstance.getBounds(
            this.selectedIndexAndLength.index,
            this.selectedIndexAndLength.length
        );
        this.customTooltipData = data;
    }

    initTooltip(): void {
        this.tooltipEditorPosition = this.quillInstance.getBounds(
            this.selectedIndexAndLength.index,
            this.selectedIndexAndLength.length
        );
        this.customTooltipData = {
            text: '',
            placement: 'bottom',
        };
        this.setFormat('custom-tooltip', this.customTooltipData);
    }

    tooltipDone(tooltipData: TooltipData): void {
        if (tooltipData.text !== '') {
            this.setFormat('custom-tooltip', tooltipData);
        } else {
            this.unsetFormat('custom-tooltip');
        }
        this.customTooltipData = null;
    }

    tooltipCancel(): void {
        if (this.customTooltipData.text === '') {
            this.unsetFormat('custom-tooltip');
        }
        this.customTooltipData = null;
    }

    tooltipDelete(): void {
        this.unsetFormat('custom-tooltip');
        this.customTooltipData = null;
    }

    handleSelectionChange(e: SelectionChange): void {
        if (e.range) {
            this.selectedIndexAndLength = { index: e.range.index, length: e.range.length };
        } else if (e.oldRange) {
            this.selectedIndexAndLength = { index: e.oldRange.index, length: e.oldRange.length };
        }

        const currentFormat = this.quillInstance.getFormat(
            this.selectedIndexAndLength.index,
            this.selectedIndexAndLength.length
        );

        this.activeFormats = Object.entries(currentFormat).map((f) => {
            return ['header', 'list', 'script'].includes(f[0]) ? `${f[0]}-${f[1]}` : f[0];
        });

        this.domSrv.offset(this.el.nativeElement);

        if (this.selectedIndexAndLength.length) {
            const stringPosition = this.quillInstance.getBounds(
                this.selectedIndexAndLength.index,
                this.selectedIndexAndLength.length
            );

            const { top } = this.domSrv.offset(this.el.nativeElement);
            const displayOnTop = top + 270 > this.domSrv.documentHeight();
            this.hoverPosX = Math.floor((stringPosition.left + stringPosition.right) / 2);
            this.hoverPosY = stringPosition.top + stringPosition.height + 5 - (displayOnTop ? 270 : 0);
        }
    }
}
