<fieldset class="detail-form__group" id="{{ groupName }}" [attr.disabled]="controlIsDisabled ? true : null">
    <legend class="detail-form__group__label" *ngIf="title">{{ title | translate }}</legend>
    <div class="radio-fieldset__options-wrapper" [ngClass]="layout">
        <ng-container *ngFor="let option of options">
            <div class="radio-fieldset__radio-group" *ngIf="!option.hidden" [ngClass]="layout">
                <input
                    [id]="option.id"
                    type="radio"
                    class="radio-fieldset__radio-input"
                    [attr.name]="groupName"
                    [attr.value]="option.value"
                    [checked]="option.value === value"
                    [attr.disabled]="option.disabled ? true : null"
                    (change)="onValueChange($event, option.value)"
                />

                <label
                    *ngIf="option?.iconLabel; else textLabel"
                    [attr.for]="option.id"
                    class="radio-fieldset__radio-label"
                >
                    <span [ngClass]="option?.iconLabel" class="radio-fieldset__radio-icon-button"></span>
                </label>

                <ng-template #textLabel>
                    <label [attr.for]="option.id" class="radio-fieldset__radio-label">
                        <span class="radio-fieldset__radio-button"></span>
                        {{ option.label | translate }}
                    </label>
                </ng-template>
            </div>
        </ng-container>
    </div>
</fieldset>
