import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { DetailNoteWidgetForm } from '../../../../api-wrapper/models/widget-models/note-widget-model';

@Component({
    selector: 'app-note-detail-widget',
    templateUrl: './note-detail-widget.component.html',
    styleUrls: ['./note-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NoteDetailWidgetComponent),
            multi: true,
        },
    ],
})
export class NoteDetailWidgetComponent extends AbstractFormBaseComponent<DetailNoteWidgetForm, IWidgetSupport> {
    testEnv = false;
    constructor() {
        super();
    }

    createForm(): FormGroupTyped<DetailNoteWidgetForm> {
        return new FormGroupTyped<DetailNoteWidgetForm>({
            realTimeNoteWidgetFormData: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
