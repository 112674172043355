import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { ConceptWidgetData } from 'src/app/api/models/concept-widget-data';

export interface RealTimeConceptCardWidgetForm {
    concept: string;
    definition: string;
}

export class ConceptCardWidgetClass extends BaseWidgetClass<ConceptWidgetData> {
    constructor(data: TypedResponse<ConceptWidgetData>) {
        super(data);
        this.extraSelector = '.ql-editor';
    }

    getRealTimeFormData(): RealTimeConceptCardWidgetForm {
        return {
            concept: this.data?.concept ?? null,
            definition: this.data?.definition ?? null,
        };
    }

    getDetailFormData(): any {
        return null;
    }

    updateWidgetData(modifiedData: RealTimeConceptCardWidgetForm): void {
        this.data = { ...this.data, ...modifiedData };
    }
}
