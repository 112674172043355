<input type="file" #fileInput [accept]="acceptTypes" (change)="fileChangeListener()" />

<div class="wrapper" *ngIf="fileSrc; else emptyBtn">
    <a
        type="text"
        disabled
        #droparea
        [download]="fileName"
        [href]="fileSrc | protectedResourceUrl | async"
        class="subtitle"
        (drop)="landFile($event); droparea.className = 'dropper'"
        (dragenter)="droparea.className = 'dropper dragover'"
        (dragover)="$event.preventDefault()"
        (dragleave)="droparea.className = 'dropper'"
        tabindex="0"
        >{{ fileName }}</a
    >
    <button
        (keyup.enter)="deleteFile()"
        type="button"
        (click)="deleteFile()"
        class="editor-btn height-medium danger border-1 left-icon"
    >
        <span class="nitroicon-delete editor-btn-icon"></span>
        {{ 'SUBTITLE-WIDGET.FILE-DELETE' | translate }}
    </button>
</div>

<ng-template #emptyBtn>
    <div class="dropper" #droparea>
        <button
            class="empty-btn"
            (click)="fileInput.click()"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
            tabindex="0"
        >
            <span #contentWrapper>
                <ng-content></ng-content>
            </span>

            <div *ngIf="contentWrapper.childNodes.length === 0" class="empty-btn__cover">
                <button type="button" class="empty-btn__upload-button editor-btn orange border-2 radius-25">
                    {{ 'SUBTITLE-WIDGET.FILE-UPLOAD' | translate }}
                </button>
            </div>
        </button>
    </div>
</ng-template>
