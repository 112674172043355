<form
    data-e2e="audio-text"
    [formGroup]="form"
    class="audio-text-widget"
    [ngClass]="[dividerLocationValue, setStarterImageSize()]"
>
    <section
        class="column left-column"
        [class.right-borders]="!flippedValue"
        [class.left-borders]="flippedValue"
        [style.order]="flippedValue ? 1 : 0"
    >
        <app-quill
            *ngIf="!testEnv"
            [formControlName]="'text'"
            class="app-quill"
            [toolbarVisible]="true"
            [placeholder]="'IMAGE-TEXT-WIDGET.TEXT-SIDE-PLACEHOLDER' | translate"
        ></app-quill>

        <div
            class="real-time-actions"
            [class.right]="!flippedValue"
            [class.left]="flippedValue"
            *ngIf="location === 'real-time'"
            (mousedown)="mouseOnButton = true"
            (mouseup)="mouseOnButton = false"
            (mouseleave)="mouseOnButton = false"
            (mousemove)="changeLayout($event)"
        >
            <button
                class="switch-button editor-btn height-small icon-only primary"
                type="button"
                (mousemove)="$event.stopPropagation()"
                (click)="switchSides()"
            >
                <span class="nitroicon-switch_sides editor-btn-icon"></span>
            </button>
        </div>
    </section>

    <section
        class="column right-column"
        [class.right-borders]="flippedValue"
        [class.left-borders]="!flippedValue"
        #imageColumn
    >
        <app-multi-audio-upload class="app-audio-upload" [formControlName]="'element'" [courseId]="config?.courseId">
            <div class="audio-text-widget__upload">
                <img src="assets/images/audio-widget.svg" class="audio-text-widget__img" alt="" />
            </div>
        </app-multi-audio-upload>

        <div class="custom-collapse-card">
            <app-collapse-card
                *ngIf="location === 'real-time' &&  audioDescription"
                [title]="'AUDIO-WIDGET.TRANSCRIPT-TITLE' | translate"
                [opened]="false"
            >
                <figcaption
                    class="audio-widget__caption section-font-color"
                    [innerHTML]="audioDescription"
                ></figcaption>
            </app-collapse-card>
        </div>
    </section>
</form>
