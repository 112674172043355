import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { ImageUploadComponent } from '@shared/forms/file-upload/image-upload/image-upload.component';
import { VideoUploadComponent } from '@shared/forms/file-upload/video-upload/video-upload.component';
import { ISelectOption } from '@shared/forms/select-input/select-input.component';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { IFileUploadValue } from '@shared/models/file-upload.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { URLHelperUtility } from '@sharedUtilities/url-helpers.utility';
import { startWith } from 'rxjs/operators';
import { CourseEditorHelpers } from 'src/app/course-editor/helpers/fulfillment.helper';
import { WidgetSizeEnum } from '../../../../api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import {
    DetailSimpleVideoWidgetForm,
    ISubtitleFormData,
} from '../../../../api-wrapper/models/widget-models/video-widget-model';
import { IWidgetSupport } from '../../../models/widget-support-info.model';

@Component({
    selector: 'app-base-video-detail-widget',
    template: '',
    styleUrls: [],
})
export abstract class VideoDetailBaseWidgetComponent<T extends DetailSimpleVideoWidgetForm>
    extends AbstractFormBaseComponent<T, IWidgetSupport>
    implements OnInit
{
    @ViewChild('videoUploadControl', { static: false }) videoUploadControl: VideoUploadComponent;
    @ViewChild('previewImageControl', { static: false }) previewImageControl: ImageUploadComponent;

    widgetSizeEnum = WidgetSizeEnum;
    size: WidgetSizeEnum;
    sizeClass: string;

    playMode: IChekboxRadioFormModel[] = [
        { label: 'FORM-CONTROLS.LOOP', controlName: 'loop', disabled: true },
        { label: 'FORM-CONTROLS.MUTE', controlName: 'muted' },
        { label: 'FORM-CONTROLS.AUTOMATIC', controlName: 'autoplay', disabled: true },
        { label: 'FORM-CONTROLS.GIF', controlName: 'playAsGif' },
    ];

    displayable: IChekboxRadioFormModel[] = [
        { label: 'FORM-CONTROLS.CONTROLS', controlName: 'controlsEnabled' },
        { label: 'FORM-CONTROLS.TRANSCRIPT', controlName: 'hasTranscript' },
    ];

    fulfillmentAvailableOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-available-option-true', label: 'FORM-CONTROLS.YES', value: true },
        { id: 'fulfillment-available-option-false', label: 'FORM-CONTROLS.NO', value: false },
    ];

    fulfillmentOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-option-show', label: 'FORM-CONTROLS.SHOW', value: 'SHOW', group: 'LAX' },
        {
            id: 'fulfillment-option-started',
            label: 'FORM-CONTROLS.VIDEO_STARTED',
            value: 'VIDEO_STARTED',
            group: 'MEDIUM',
        },
        {
            id: 'fulfillment-option-finished',
            label: 'FORM-CONTROLS.VIDEO_FINISHED',
            value: 'VIDEO_FINISHED',
            group: 'STRICT',
        },
    ];

    langOptions: ISelectOption[] = [
        { value: 'hu', displayName: 'COMMON.LANGUAGES.hu' },
        { value: 'en', displayName: 'COMMON.LANGUAGES.en' },
    ];

    constructor() {
        super();
    }

    get hasTranscript(): boolean {
        return this.form.get('hasTranscript').value as boolean;
    }

    get hasVideo(): boolean {
        const video = this.form.get('element').value as IFileUploadValue;
        return video && (video.fileItem !== null || !!video.elementId);
    }

    get previewImage(): string {
        const preViewImage = this.form.get('previewImageElement').value as IFileUploadValue;
        if (!preViewImage) {
            return null;
        }
        return preViewImage?.fileItem
            ? URL.createObjectURL(preViewImage?.fileItem.resource)
            : preViewImage?.elementId
            ? URLHelperUtility.imageResourceURLForCourse(this.config.courseId, preViewImage?.elementId, 'L')
            : null;
    }

    get hasPreviewImage(): boolean {
        const preViewImage = this.form.get('previewImageElement').value as IFileUploadValue;
        return preViewImage && (preViewImage.fileItem !== null || !!preViewImage.elementId);
    }

    get hasSubtitle(): boolean {
        const subtitle = this.form.get('subtitleClass.element').value as IFileUploadValue;
        return subtitle && (subtitle.fileItem !== null || !!subtitle.elementId);
    }

    get subtitle(): ISubtitleFormData {
        return this.form.get('subtitleClass').value as ISubtitleFormData;
    }

    get loop(): boolean {
        const loop = this.form.get('loop')?.value as boolean;
        return loop ?? false;
    }

    get muted(): boolean {
        const muted = this.form.get('muted')?.value as boolean;
        return muted ?? false;
    }

    get autoplay(): boolean {
        const autoplay = this.form.get('autoplay')?.value as boolean;
        return autoplay ?? false;
    }

    get controlsEnabled(): boolean {
        const controlsEnabled = this.form.get('controlsEnabled')?.value as boolean;
        return controlsEnabled ?? false;
    }

    get originalFileName(): string {
        const subtitle = this.form.get('subtitleClass').value as ISubtitleFormData;
        return subtitle?.originalFileName ?? null;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initDisabledFields();
        this.initSizeChanges();
    }

    initSizeChanges(): void {
        this.subscriptions.add(
            this.form
                .get('size')
                .valueChanges.pipe(startWith(this.form.get('size').value as WidgetSizeEnum))
                .subscribe((size: WidgetSizeEnum) => {
                    this.size = size;
                    this.sizeClass = size?.toLowerCase();
                })
        );
    }

    uploadVideo(): void {
        this.videoUploadControl.fileInput.nativeElement.click();
    }

    uploadPreviewImage(): void {
        this.previewImageControl.fileInput.nativeElement.click();
    }

    deleteVideo(): void {
        this.videoUploadControl.deleteFile();
    }

    deletePreviewImage(): void {
        this.previewImageControl.deleteFile();
    }

    createForm(): FormGroupTyped<any> {
        return new FormGroupTyped<any>(this.addVideoDetailFormControls());
    }

    addVideoDetailFormControls(): { [key: string]: UntypedFormControl | FormGroupTyped<ISubtitleFormData> } {
        return {
            element: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
            playAsGif: FormHelper.controlFactoryWithCalculatedValue(null),
            loop: FormHelper.controlFactoryWithCalculatedValue(null, null, true),
            muted: FormHelper.controlFactoryWithCalculatedValue(null, null, true),
            autoplay: FormHelper.controlFactoryWithCalculatedValue(null, null, true),
            controlsEnabled: FormHelper.controlFactoryWithCalculatedValue(null),
            hasTranscript: FormHelper.controlFactoryWithCalculatedValue(null),
            previewImageElement: FormHelper.controlFactoryWithCalculatedValue(null),
            transcript: FormHelper.controlFactoryWithCalculatedValue(null),
            subtitleClass: this.createSubtitles(),
            size: FormHelper.controlFactoryWithCalculatedValue(null),
        };
    }

    createSubtitles(data?: ISubtitleFormData): FormGroupTyped<ISubtitleFormData> {
        return new FormGroupTyped<ISubtitleFormData>({
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: data?.element?.fileItem ?? null,
                elementId: data?.element?.elementId ?? null,
            }),
            srcLang: FormHelper.controlFactoryWithCalculatedValue(null),
            originalFileName: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    handleGif(): void {
        this.form.get('controlsEnabled').disable({ emitEvent: false });
        (this.form as UntypedFormGroup).patchValue(
            {
                controlsEnabled: false,
                muted: true,
                autoplay: true,
                loop: true,
            },
            { emitEvent: false }
        );
    }

    handleControlsEnabled(): void {
        this.form.get('playAsGif').disable({ emitEvent: false });
        (this.form as UntypedFormGroup).patchValue(
            {
                playAsGif: false,
                muted: false,
                autoplay: false,
                loop: false,
            },
            { emitEvent: false }
        );
    }

    initDisabledFields(): void {
        const value = this.form.get('fulfillmentAvailable').value as boolean;
        if (!value) {
            this.form.get('fulfillment').disable({ emitEvent: false });
        }
        this.subscriptions.add(
            this.form.get('fulfillmentAvailable').valueChanges.subscribe((fulfillmentAvailable: boolean) => {
                CourseEditorHelpers.handleFulfillment(
                    fulfillmentAvailable,
                    this.form,
                    this.config.fulfillmentSettings.fulfillmentLevel,
                    this.fulfillmentOptions
                );
            })
        );

        const playAsGif = this.form.get('playAsGif').value as boolean;
        if (playAsGif) {
            this.handleGif();
        }
        this.subscriptions.add(
            this.form.get('playAsGif').valueChanges.subscribe((playAsGif) => {
                if (playAsGif) {
                    this.handleGif();
                } else if (!playAsGif) {
                    this.form.get('controlsEnabled').enable({ emitEvent: false });
                    (this.form as UntypedFormGroup).patchValue(
                        {
                            controlsEnabled: true,
                            muted: false,
                            autoplay: false,
                            loop: false,
                        },
                        { emitEvent: false }
                    );
                }
            })
        );

        const controlsEnabled = this.form.get('controlsEnabled').value as boolean;
        if (controlsEnabled) {
            this.handleControlsEnabled();
        }

        this.subscriptions.add(
            this.form.get('controlsEnabled').valueChanges.subscribe((controlsEnabled) => {
                if (controlsEnabled) {
                    this.handleControlsEnabled();
                } else {
                    this.form.get('playAsGif').enable({ emitEvent: false });
                }
            })
        );
    }

    setWidgetSize(size: WidgetSizeEnum): void {
        (this.form as UntypedFormGroup).patchValue({ size });
    }
}
