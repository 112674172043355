/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PageExtendedUser } from '../models/page-extended-user';
import { RoleChangeRequest } from '../models/role-change-request';
import { User } from '../models/user';
import { UserInviteRequest } from '../models/user-invite-request';

@Injectable({
  providedIn: 'root',
})
export class UserControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateUserRole
   */
  static readonly UpdateUserRolePath = '/users/{userId}/roles';

  /**
   * Update user role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserRole$Response(params: {
    userId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: RoleChangeRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UpdateUserRolePath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update user role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserRole(params: {
    userId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: RoleChangeRequest
  }): Observable<void> {

    return this.updateUserRole$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation enableUser
   */
  static readonly EnableUserPath = '/users/{userId}/enable';

  /**
   * Enable user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableUser$Response(params: {
    userId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.EnableUserPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Enable user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `enableUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableUser(params: {
    userId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.enableUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation disableUser
   */
  static readonly DisableUserPath = '/users/{userId}/disable';

  /**
   * Disable user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disableUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableUser$Response(params: {
    userId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.DisableUserPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Disable user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `disableUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableUser(params: {
    userId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.disableUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation inviteUser
   */
  static readonly InviteUserPath = '/users/invite';

  /**
   * Invite user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: UserInviteRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.InviteUserPath, 'put');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Invite user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inviteUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: UserInviteRequest
  }): Observable<void> {

    return this.inviteUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation searchUsers
   */
  static readonly SearchUsersPath = '/users';

  /**
   * Get users in pageable format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsers$Response(params?: {
    fragment?: string;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<PageExtendedUser>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.SearchUsersPath, 'get');
    if (params) {
      rb.query('fragment', params.fragment, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageExtendedUser>;
      })
    );
  }

  /**
   * Get users in pageable format.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsers(params?: {
    fragment?: string;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<PageExtendedUser> {

    return this.searchUsers$Response(params).pipe(
      map((r: StrictHttpResponse<PageExtendedUser>) => r.body as PageExtendedUser)
    );
  }

  /**
   * Path part for operation searchUsers1
   */
  static readonly SearchUsers1Path = '/users/search/{fragment}';

  /**
   * Search users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchUsers1()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsers1$Response(params: {
    fragment: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.SearchUsers1Path, 'get');
    if (params) {
      rb.path('fragment', params.fragment, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * Search users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchUsers1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsers1(params: {
    fragment: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<User>> {

    return this.searchUsers1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

}
