<div class="dialog-header">
    <h2>{{ 'RECOMMENDATION-BUTTON-WIDGET.PATH-SELECTOR-TITLE' | translate }}</h2>
    <button
        class="editor-btn height-small radius-25 border-0 primary icon-only"
        mat-dialog-close
        data-e2e="recommendation-button-cloze-path-selector"
        [attr.aria-label]="'MATERIAL.DIALOG.CLOSE' | translate"
    >
        <span class="nitroicon-close editor-btn-icon"></span>
    </button>
</div>

<div class="detail-form__group" [formGroup]="searchControl">
    <label class="detail-form__group__label" for="searchTerm"
        >{{ 'RECOMMENDATION-BUTTON-WIDGET.PATH-SELECTOR-SUB-TITLE' | translate }}
    </label>
    <input
        [placeholder]="'RECOMMENDATION-BUTTON-WIDGET.PATH-SELECTOR-PLACEHOLDER' | translate"
        type="text"
        class="input"
        id="searchTerm"
        formControlName="searchTerm"
        [matAutocomplete]="auto"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onPageSelected($event)">
        <section>
            <p class="autocomplete-subheader">{{ 'RECOMMENDATION-BUTTON-WIDGET.PATH-SELECTOR-PAGES' | translate }}</p>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option"> {{ option.name }} </mat-option>
        </section>
        <section>
            <p class="autocomplete-subheader">{{ 'RECOMMENDATION-BUTTON-WIDGET.PATH-SELECTOR-ANCHORS' | translate }}</p>
            <mat-optgroup *ngFor="let option of filteredAnchorOptions | async" [label]="option.name">
                <mat-option *ngFor="let anchor of option.anchors" [value]="anchor">
                    <span class="nitroicon-anchor"></span>
                    {{ anchor.name }}
                </mat-option>
            </mat-optgroup>
        </section>
    </mat-autocomplete>

    <section class="action-buttons">
        <button class="editor-btn secondary height-medium" (click)="onCloseClick()">
            {{ 'MATERIAL.DIALOG.CANCEL' | translate }}
        </button>
        <button [disabled]="!selected" class="editor-btn accent height-medium" (click)="onSelect()">
            {{ 'RECOMMENDATION-WIDGET.LINK-SELECTOR.SELECT' | translate }}
        </button>
    </section>
</div>
