<div class="course-preview-wrapper" [ngStyle]="{ width: width, height: height }">
    <app-loader-for-components></app-loader-for-components>
    <iframe
        (error)="onError($event)"
        (load)="onLoad($event)"
        #previewIframe
        class="iframe"
        [width]="'1024px'"
        [height]="'1024px'"
        [src]="config.coursePreviewUrl | safeResourceUrl"
        title="preview"
    ></iframe>
</div>
