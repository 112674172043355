import { Question } from '@apiModels';
import { OrderAnswer } from 'src/app/api/models/order-answer';
import { SortingQuestion } from 'src/app/api/models/sorting-question';
import { TypedResponse } from '../../base-widget-models/typed-widget-response';

import {
    BaseQuestionWidgetDataSpec,
    DetailBaseQuestionWidgetForm,
    QuestionBaseWidgetClass,
    RealTimeBaseQuestionWidgetForm,
} from '../question-base-model';
import { ISortingAnswerModel, SortingAnswerModel } from './sorting-answer-model';

export interface RealTimeSortingQuestionWidgetForm extends RealTimeBaseQuestionWidgetForm {
    answers: ISortingAnswerModel[];
}

export interface DetailSortingQuestionWidgetForm extends DetailBaseQuestionWidgetForm {
    realTimeQuestionWidgetFormData: RealTimeSortingQuestionWidgetForm;
}

export type MixedChoiceQuestionForm = RealTimeSortingQuestionWidgetForm & DetailSortingQuestionWidgetForm;

export interface SortingQuestionWidgetData extends BaseQuestionWidgetDataSpec {
    question: SortingQuestion;
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION_CORRECTLY_ANSWERED' | 'QUESTION_ANSWERED';
    answers: SortingAnswerModel[];
}

export class SortingQuestionWidgetModel extends QuestionBaseWidgetClass<
    SortingQuestionWidgetData,
    RealTimeSortingQuestionWidgetForm,
    DetailSortingQuestionWidgetForm
> {
    constructor(data: TypedResponse<SortingQuestionWidgetData>) {
        super(data, SortingAnswerModel);
    }

    updateAnswers(questionData: Question, answers: ISortingAnswerModel[]): OrderAnswer[] {
        const answerIds = answers.map((answer: ISortingAnswerModel) => answer.id);
        this.data.answers = this.data.answers.filter((e) => answerIds.indexOf(e.data.id) > -1);
        answers.forEach((elem: ISortingAnswerModel, index: number) => {
            const existing = this.data.answers.find((item) => item.data.id === elem.id);
            const updateElem = {
                ...elem,
                order: index + 1,
                mediaResourceElement: questionData.hasAnswerMedia
                    ? elem.mediaResourceElement
                    : { fileItem: null, elementId: null },
            };

            if (existing) {
                if (
                    questionData.hasAnswerMedia &&
                    this.shouldSaveFile(elem.mediaResourceElement.fileItem, existing.data.mediaResourceId)
                ) {
                    this.filesToSave.push(elem.mediaResourceElement.fileItem);
                }
                existing.updateData(updateElem);
            }

            if (!existing) {
                const { mediaResourceElement, ...rest } = elem;
                const mediaResourceId = questionData.hasAnswerMedia ? this.extractUUID(mediaResourceElement) : null;
                if (questionData.hasAnswerMedia && elem.mediaResourceElement.fileItem) {
                    this.filesToSave.push(elem.mediaResourceElement.fileItem);
                }
                const newElem = new SortingAnswerModel({ mediaResourceId, ...rest });

                newElem.updateData(updateElem);
                this.data.answers.push(newElem);
            }
        });
        this.data.answers.sort((a: SortingAnswerModel, b: SortingAnswerModel) => a.data.order - b.data.order);
        return this.data.answers.map((e) => e.getDataForSave());
    }
}
