/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PageModifyRequest } from '../models/page-modify-request';
import { PageResponse } from '../models/page-response';

@Injectable({
  providedIn: 'root',
})
export class PageControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPageByCourseId
   */
  static readonly GetPageByCourseIdPath = '/courses/{courseId}/pages/{pageId}';

  /**
   * Get page by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPageByCourseId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageByCourseId$Response(params: {
    courseId: number;
    pageId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<PageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.GetPageByCourseIdPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('pageId', params.pageId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageResponse>;
      })
    );
  }

  /**
   * Get page by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPageByCourseId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageByCourseId(params: {
    courseId: number;
    pageId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<PageResponse> {

    return this.getPageByCourseId$Response(params).pipe(
      map((r: StrictHttpResponse<PageResponse>) => r.body as PageResponse)
    );
  }

  /**
   * Path part for operation modifyPage
   */
  static readonly ModifyPagePath = '/courses/{courseId}/pages/{pageId}';

  /**
   * Modify the specified page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyPage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyPage$Response(params: {
    courseId: number;
    pageId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: PageModifyRequest
  }): Observable<StrictHttpResponse<PageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.ModifyPagePath, 'put');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('pageId', params.pageId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageResponse>;
      })
    );
  }

  /**
   * Modify the specified page.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifyPage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyPage(params: {
    courseId: number;
    pageId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: PageModifyRequest
  }): Observable<PageResponse> {

    return this.modifyPage$Response(params).pipe(
      map((r: StrictHttpResponse<PageResponse>) => r.body as PageResponse)
    );
  }

  /**
   * Path part for operation getAllPagesByCourseId
   */
  static readonly GetAllPagesByCourseIdPath = '/courses/{courseId}/pages';

  /**
   * List all pages under the specified id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPagesByCourseId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPagesByCourseId$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<PageResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.GetAllPagesByCourseIdPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PageResponse>>;
      })
    );
  }

  /**
   * List all pages under the specified id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPagesByCourseId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPagesByCourseId(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<PageResponse>> {

    return this.getAllPagesByCourseId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PageResponse>>) => r.body as Array<PageResponse>)
    );
  }

}
