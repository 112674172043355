import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { scaleFromOriginalAnimation } from '@shared/animations/scale-from-original.animation';
import { FOCUSABLE_ELEMENTS } from '@sharedUtilities/focus-helper.utility';

import { InnerComponentDirective } from './inner-component.directive';

const headerHeight = 50;

@Component({
    selector: 'app-full-page-modal',
    templateUrl: './full-page-modal.component.html',
    styleUrls: ['./full-page-modal.component.scss'],
    animations: [scaleFromOriginalAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullPageModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() originalElemId: string;
    @Input() title: string;
    @Input() innerComponentData: any;
    @Input() alternativeHeader: string;
    @Input() dontNeedSaveButtons: boolean;
    @Input() dontNeedCloseButton: boolean;
    @Output() save = new EventEmitter<void>();
    @Output() saveAndCloseModal = new EventEmitter<void>();
    @Output() closeModal = new EventEmitter<void>();
    @ViewChild(InnerComponentDirective, { static: true }) templateHost: InnerComponentDirective;
    @HostBinding('attr.id') id: string;
    @HostBinding('class.full-page-modal') fullPageModalClass = true;
    @HostBinding('attr.class') classList: string;
    @HostBinding('attr.role') role = 'dialog';
    @HostBinding('attr.aria-modal') ariaModal = true;
    @HostBinding('attr.aria-labelledby') labelledBy = '';

    startFocusElement: HTMLElement;
    firstFocusableElement: HTMLElement;
    lastFocusableElement: HTMLElement;
    focusFrom: HTMLElement | null;
    innerComponent: any;
    animationOriginX: number;
    animationOriginY: number;

    constructor(private hostElement: ElementRef) {}

    ngOnInit(): void {
        document.body.style.overflow = 'hidden';
        if (!this.id) {
            this.id = `FullPageModal_${this.originalElemId ?? 'test'}`;
        }
    }

    @HostBinding('@scaleFromOriginal')
    get showAnimation(): any {
        const animationStartElement = document.getElementById(this.originalElemId);

        let originX: number;
        let originY: number;
        if (animationStartElement) {
            const { x, y, width, height } = animationStartElement.getBoundingClientRect();
            originX = x + width / 2;
            originY = y + height / 2 - headerHeight;
        } else {
            originX = window.innerWidth / 2;
            originY = window.innerHeight / 2 - headerHeight;
        }
        this.animationOriginX = this.animationOriginX ? this.animationOriginX : originX;
        this.animationOriginY = this.animationOriginY ? this.animationOriginY : originY;
        return {
            value: true,
            params: { originX: this.animationOriginX, originY: this.animationOriginY },
        };
    }

    onSave(): void {
        this.save.emit();
    }

    onSaveAndClose(): void {
        this.saveAndCloseModal.emit();
    }

    onClose(): void {
        this.closeModal.emit();
    }

    @HostListener('document:keydown.tab', ['$event'])
    onTab(event: KeyboardEvent): void {
        this.refreshFocusableElements();
        if (document.activeElement === this.lastFocusableElement) {
            event.preventDefault();
            this.firstFocusableElement.focus();
        }
    }

    @HostListener('document:keydown.shift.tab', ['$event'])
    onShiftTab(event: KeyboardEvent): void {
        this.refreshFocusableElements();
        if (document.activeElement === this.firstFocusableElement) {
            event.preventDefault();
            this.lastFocusableElement.focus();
        }
    }

    ngAfterViewInit(): void {
        this.focusFrom = document.activeElement as HTMLElement;
        this.refreshFocusableElements();

        setTimeout(() => {
            if (this.startFocusElement) {
                this.startFocusElement.focus();
            }
        });
    }

    ngOnDestroy(): void {
        document.body.style.overflow = '';
        this.focusFrom?.focus();
    }

    refreshFocusableElements(): void {
        if (this.id) {
            setTimeout(() => {
                const focusableElements = this.hostElement.nativeElement.querySelectorAll(FOCUSABLE_ELEMENTS);
                this.firstFocusableElement = focusableElements[0];
                this.lastFocusableElement = focusableElements[focusableElements.length - 1];
                this.startFocusElement = this.firstFocusableElement.parentElement.classList.contains(
                    'full-page-modal__header-buttons'
                )
                    ? (focusableElements[this.firstFocusableElement.parentElement.childElementCount] as HTMLElement)
                    : this.firstFocusableElement;
            });
        }
    }
}
