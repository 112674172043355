/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { VideoTextWidgetData } from '@apiModels';
import { IFileUploadElement } from '@shared/models/file-upload.model';
import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from './base-widget-models/widget-size.enum';
import { MultiWidgetDividerLocation, MultiWidgetImageFit } from './image-text-widget-model';
import { RealTimeSimpleVideoWidgetForm, SubtitleClass } from './video-widget-model';

export interface RealTimeVideoTextWidgetForm extends RealTimeSimpleVideoWidgetForm {
    text: string | null;
    dividerLocation: MultiWidgetDividerLocation;
    flipped: boolean;
    videoFit: MultiWidgetImageFit;
}

export interface DetailVideoTextWidgetForm extends RealTimeVideoTextWidgetForm {
    loop: boolean;
    muted: boolean;
    autoplay: boolean;
    playAsGif: boolean;
    controlsEnabled: boolean;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW' | 'VIDEO_STARTED' | 'VIDEO_FINISHED';
    size: WidgetSizeEnum;
}

export interface VideoTextWidgetDataSpec extends VideoTextWidgetData {
    fulfillment: 'NONE' | 'SHOW' | 'VIDEO_STARTED' | 'VIDEO_FINISHED';
    subtitleClass: SubtitleClass;
}

export class VideoTextWidgetClass extends BaseWidgetClass<VideoTextWidgetDataSpec> {
    filePosterUploadElement: IFileUploadElement | null = null;
    constructor(data: TypedResponse<VideoTextWidgetDataSpec>) {
        super(data);
        this.data.subtitleClass = new SubtitleClass(this.data?.subtitle);
    }

    getRealTimeFormData(): RealTimeVideoTextWidgetForm {
        return {
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data?.videoResourceId ?? null,
            },
            previewImageElement: {
                fileItem: this.filePosterUploadElement,
                elementId: this.data.previewImageResourceId ?? null,
            },
            subtitleClass: this.data.subtitleClass.getFormData(),
            transcript: this.data?.transcript ?? null,
            hasTranscript: this.data?.hasTranscript ?? false,
            text: this.data.text ?? null,
            dividerLocation: this.data?.dividerLocation ?? 'CENTER',
            flipped: this.data?.flipped ?? false,
            videoFit: this.data?.videoFit ?? 'CONTAIN',
            loop: this.data?.loop ?? false,
            muted: this.data?.muted ?? false,
            autoplay: this.data?.autoplay ?? false,
            controlsEnabled: this.data?.controlsEnabled ?? true,
        };
    }

    getDetailFormData(): DetailVideoTextWidgetForm {
        return {
            ...this.getRealTimeFormData(),
            playAsGif: this.data?.playAsGif ?? false,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
        };
    }

    updateWidgetData(modifiedData: RealTimeVideoTextWidgetForm | DetailVideoTextWidgetForm): void {
        const { element, previewImageElement, subtitleClass, ...rest } = modifiedData;

        if (this.shouldSaveFile(element.fileItem, this.data.videoResourceId)) {
            this.filesToSave.push(element.fileItem);
        }

        if (subtitleClass) {
            if (this.shouldSaveFile(subtitleClass.element.fileItem, this.data?.subtitle?.subtitleResourceId)) {
                this.filesToSave.push(subtitleClass.element.fileItem);
            }
            this.data.subtitleClass.updateData(subtitleClass);
        }

        if (previewImageElement) {
            if (this.shouldSaveFile(previewImageElement?.fileItem, this.data?.previewImageResourceId)) {
                this.filesToSave.push(previewImageElement.fileItem);
            }
        }

        this.fileUploadElement = element.fileItem;
        this.filePosterUploadElement = previewImageElement?.fileItem;

        const subtitle = this.data.subtitleClass.getDataForSave();

        const videoResourceId = this.extractUUID(element);
        const previewImageResourceId = this.extractUUID(previewImageElement);

        if (subtitle.subtitleResourceId === null) {
            subtitle.originalFileName = null;
            subtitle.srcLang = null;
        }
        this.data = { ...this.data, ...rest, subtitle, videoResourceId, previewImageResourceId };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.videoResourceId === id) {
                this.data = { ...this.data, videoResourceId: null };
                this.fileUploadElement = null;
            } else if (this.data.previewImageResourceId === id) {
                this.data = { ...this.data, previewImageResourceId: null };
                this.filePosterUploadElement = null;
            } else if (this.data.subtitle?.subtitleResourceId === id) {
                const subtitle = { subtitleResourceId: null, originalFileName: null, srcLang: null };
                this.data = { ...this.data, subtitle };
                this.data.subtitleClass = new SubtitleClass(this.data?.subtitle);
            }
        });
    }
}
