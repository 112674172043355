<section class="user-info">
    <div class="user-image">
        <img class="user-image__img" *ngIf="profileImg; else monogramTemplate" [src]="profileImg" alt="" />
        <ng-template #monogramTemplate>
            <span>{{ monogram }}</span>
        </ng-template>
    </div>
    <div class="user-text">
        <h3 class="user-text__role">{{ user?.lastName + ' ' + user?.firstName }}</h3>
        <p class="user-text__email">{{ user?.email }}</p>
        <div class="user-text__link-container">
            <a [routerLink]="'/account-settings/profile'" class="user-text__link">
                <span>{{ 'MENU.ACCOUNT' | translate }}</span>
                <span class="nitroicon-arrow_right"></span>
            </a>
        </div>
    </div>
</section>

<footer class="logout-container">
    <button
        class="editor-btn btn-w100 radius-0 border-0 height-small left-icon"
        (click)="onActionEvent('LOGOUT', user)"
    >
        <span class="nitroicon-Import editor-btn-icon"></span>
        <span>{{ 'MENU.LOGOUT' | translate }}</span>
    </button>
</footer>
