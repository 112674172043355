import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { ImageUploadComponent } from '@shared/forms/file-upload/image-upload/image-upload.component';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { MultiWidgetDividerLocation } from 'src/app/api-wrapper/models/widget-models/image-text-widget-model';
import { RealTimeAudioTextWidgetForm } from 'src/app/api-wrapper/models/widget-models/multi-widgets/audio-text-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

@Component({
    selector: 'app-audio-text-real-time-widget',
    templateUrl: './audio-text-real-time-widget.component.html',
    styleUrls: ['./audio-text-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AudioTextRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class AudioTextRealTimeWidgetComponent extends AbstractFormBaseComponent<
    RealTimeAudioTextWidgetForm,
    IWidgetSupport
> {
    @ViewChild('imageUpload') imageUpload: ImageUploadComponent;
    @Input() location: 'detail' | 'real-time' = 'real-time';
    @Input() dividerLocation: MultiWidgetDividerLocation;
    @Input() flipped: boolean;
    smallerThanText: boolean;
    testEnv = false;
    mouseOnButton = false;
    originalX = 0;
    layoutChangeInProgress = false;
    audioDescription: string | null;

    constructor() {
        super();
    }

    get dividerLocationValue(): MultiWidgetDividerLocation {
        return this.location === 'detail' ? this.dividerLocation : this.form.get('dividerLocation')?.value ?? 'CENTER';
    }

    get flippedValue(): boolean {
        return this.location === 'detail' ? this.flipped : this.form.get('flipped')?.value ?? false;
    }

    switchSides(): void {
        this.form.patchValue({ flipped: !this.flippedValue });
    }

    updateForm(value: RealTimeAudioTextWidgetForm): void {
        this.form.setValue(value, { emitEvent: false });
        const audioDescription = this.form.get('audioDescription').value as string | null;
        this.audioDescription = audioDescription;
    }

    changeLayout(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        if (!this.mouseOnButton) {
            this.originalX = 0;
            return;
        }

        if ((event.target as HTMLElement).classList.contains('nitroicon-switch_sides')) {
            return;
        }
        if (this.originalX === 0) {
            this.originalX = event.x;
            return;
        }
        const diff = this.originalX - event.x;

        if (Math.abs(diff) < 5) {
            return;
        }
        if ((diff > 0 && this.dividerLocationValue === 'LEFT') || (diff < 0 && this.dividerLocationValue === 'RIGHT')) {
            return;
        }
        let dividerLocation: MultiWidgetDividerLocation = 'CENTER';
        if (this.dividerLocationValue === 'CENTER') {
            dividerLocation = diff > 0 ? 'LEFT' : 'RIGHT';
        }
        this.form.patchValue({ dividerLocation });
    }

    setStarterImageSize(): string {
        let size: string;
        switch (this.config?.starterWidgetSize) {
            case WidgetSizeEnum.FULLSIZE:
                size = 'XL';
                break;
            default:
                size = 'L';
                break;
        }
        return size;
    }

    createForm(): FormGroupTyped<RealTimeAudioTextWidgetForm> {
        return new FormGroupTyped<RealTimeAudioTextWidgetForm>({
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            audioDescription: FormHelper.controlFactoryWithCalculatedValue(null),
            text: FormHelper.controlFactoryWithCalculatedValue(null),
            dividerLocation: FormHelper.controlFactoryWithCalculatedValue(null),
            flipped: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    configDataChanged(widgetSize?: WidgetSizeEnum): void {
        if (this.config.starterWidgetSize !== widgetSize) {
            this.config.starterWidgetSize = widgetSize;
        }
    }
}
