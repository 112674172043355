import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { collapseAnimation } from '@shared/animations/collapse.animations';

@Component({
    selector: 'app-collapse-card',
    templateUrl: './collapse-card.component.html',
    styleUrls: ['./collapse-card.component.scss'],
    animations: [collapseAnimation],
})
export class CollapseCardComponent {
    @Input() opened = false;
    @Input() title: string;
    @Input() canEditTitle = false;
    @HostBinding('class.card') card = true;
    @HostBinding('@parent') parent = true;
    @Output() titleChanged = new EventEmitter<string>();
}
