import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeHu from '@angular/common/locales/hu';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { myRxStompConfigFactory } from '@shared/websocket-config/websocket-config';
import { MatPaginationTranslate } from '@sharedUtilities/mat-pagination-translate';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { QuillModule } from 'ngx-quill';
import { SortablejsModule } from 'ngx-sortablejs';
import { firstValueFrom } from 'rxjs';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ResponseInterceptor } from './shared/interceptors/response-interceptor';
import { DevModeGuardService } from './shared/services/dev-mode-guard.service';
import { SharedModule } from './shared/shared.module';
import { PublicConfig } from '@apiModels';

registerLocaleData(localeHu);

export function initializer(keycloak: KeycloakService, http: HttpClient): () => Promise<any> {
    return () =>
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        new Promise<boolean>((resolve) => {
            const promise = firstValueFrom(http.get('/apps-api/editor/public/config')).then((data: PublicConfig) => {
                const AUTH_REALM = data.authRealm;
                console.log(AUTH_REALM);
                void keycloak
                    .init({
                        config: {
                            url: '/auth',
                            realm: AUTH_REALM,
                            clientId: 'editor-gui',
                        },
                        initOptions: {
                            onLoad: 'check-sso',
                        },
                    })
                    .then(resolve);
            });

            return promise;
        });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ApiModule.forRoot({ rootUrl: '/apps-api/editor' }),
        BrowserAnimationsModule,
        TranslateModule.forRoot(),
        KeycloakAngularModule,
        SharedModule,
        CoreModule,
        QuillModule.forRoot({ modules: { clipboard: { matchVisual: false } } }),
        SortablejsModule.forRoot({ animation: 250, handle: '.handle' }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService, HttpClient],
        },
        DevModeGuardService,
        { provide: MatPaginatorIntl, useClass: MatPaginationTranslate },
        {
            provide: InjectableRxStompConfig,
            useFactory: myRxStompConfigFactory,
            deps: [KeycloakService],
        },
        {
            provide: RxStompService,
            useFactory: rxStompServiceFactory,
            deps: [InjectableRxStompConfig],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
