import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { startWith } from 'rxjs/operators';
import { PreviewMessageService } from '@sharedServices/preview-message.service';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { LoaderForComponentsService } from '@shared/components/loader-for-components/loader-for-components.service';

export enum PreviewLayoutEnum {
    FULL_PAGE = 'FULL_PAGE',
    TABLET_PORTRAIT = 'TABLET_PORTRAI',
    TABLET_LANDSCAPE = 'TABLET_LANDSCAPE',
    MOBILE_PORTRAIT = 'MOBILE_PORTRAI',
    MOBILE_LANDSCAPE = 'MOBILE_LANDSCAPE',
}

export interface IPreviewConfig {
    coursePreviewUrl: string;
}

export interface IPreviewForm {
    layout: PreviewLayoutEnum;
}

const PreviewSizees = {
    [PreviewLayoutEnum.FULL_PAGE]: { width: '100%', height: '100%' },
    [PreviewLayoutEnum.MOBILE_LANDSCAPE]: { width: '640px', height: '360px' },
    [PreviewLayoutEnum.MOBILE_PORTRAIT]: { width: '360px', height: '640px' },
    [PreviewLayoutEnum.TABLET_LANDSCAPE]: { width: '1024px', height: '760px' },
    [PreviewLayoutEnum.TABLET_PORTRAIT]: { width: '760px', height: 'calc(100vh - 101px)' },
};

@Component({
    selector: 'app-course-preview-show',
    templateUrl: './course-preview-show.component.html',
    styleUrls: ['./course-preview-show.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoursePreviewShowComponent),
            multi: true,
        },
    ],
})
export class CoursePreviewShowComponent
    extends AbstractFormBaseComponent<IPreviewForm, IPreviewConfig>
    implements OnInit, OnChanges, OnDestroy
{
    @Input() name = 'preview';
    width = '360px';
    height = '640px';
    @ViewChild('previewIframe') previewIframe: ElementRef<HTMLIFrameElement>;

    constructor(
        private cdr: ChangeDetectorRef,
        private previewMessageService: PreviewMessageService,
        private loaderForComponentsService: LoaderForComponentsService,
        private toastMessageService: ToastMessageService
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.changeLayout();
        this.previewMessageService.addPreview(this.previewIframe, this.name);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.previewMessageService.removePreview(this.name);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.config.currentValue?.coursePreviewUrl !== changes?.config.previousValue?.coursePreviewUrl) {
            this.loaderForComponentsService.showLoader('global', 'PREVIEW');
            return;
        }
    }

    onLoad(event: Event): void {
        event.stopPropagation();

        setTimeout(() => {
            this.loaderForComponentsService.hideLoader('global', 'PREVIEW');
            this.previewMessageService.previewIsReady.next(this.name);
        }, 6000);
    }

    onError(event: Event): void {
        event.stopPropagation();
        // this.loaderForComponentsService.hideLoader('preview', 'PREVIEW');
        this.toastMessageService.error({ translatedMessage: 'COURSE-PREVIEW.ERROR' });
    }

    changeLayout(): void {
        this.subscriptions.add(
            this.form
                .get('layout')
                .valueChanges.pipe(startWith(this.form.get('layout').value))
                .subscribe((layout: PreviewLayoutEnum) => {
                    const { width, height } = PreviewSizees[layout];
                    this.width = width;
                    this.height = height;
                    this.cdr.detectChanges();
                })
        );
    }

    updateForm(value: IPreviewForm): void {
        this.form.setValue(value, { emitEvent: true });
    }

    createForm(): FormGroupTyped<IPreviewForm> {
        return new FormGroupTyped<IPreviewForm>({
            layout: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
