<form [formGroup]="form" class="note-widget" data-e2e="note-widget">
    <div class="note-widget__container">
        <div class="note-widget__title">
            <div>
                <span class="nitroicon-warning"></span>
                {{ 'NOTE-WIDGET.TITLE' | translate }}
            </div>
        </div>
        <div class="note-widget__text">
            <app-quill
                *ngIf="!testEnv"
                class="app-quill"
                [placeholder]="'NOTE-WIDGET.TITLE-PLACEHOLDER' | translate"
                [formControlName]="'title'"
                [toolbarVisible]="true"
            >
            </app-quill>
        </div>
        <app-document-upload
            *ngIf="editorLocation === 'detail' || !hasElement"
            [formControlName]="'element'"
            [courseId]="config?.courseId"
        >
        </app-document-upload>
        <button class="template-btn left-icon document-download-wrapper__button" *ngIf="hasElement">
            <span class="nitroicon-download btn-icon"></span>
            <input
                [ngStyle]="{ 'width.px': width }"
                (input)="resize()"
                class="generic-input-reset"
                type="text"
                [placeholder]="'NOTE-WIDGET.DOCUMENT-TITLE-PLACEHOLDER' | translate"
                [formControlName]="'text'"
            />
            <span #hiddenText class="document-download-wrapper__hidden-span"
                >{{ this.form.controls['text'].value }}</span
            >
        </button>
    </div>
</form>
