import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData } from '@shared/components/confirm-dialog/confirm-dialog-data.model';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationBlockDeleteModalComponent } from 'src/app/course-editor/widgets/NavigationBlockWidget/navigation-block-delete-modal/navigation-block-delete-modal.component';
import { NavigationBlockDeleteData } from 'src/app/course-editor/widgets/NavigationBlockWidget/navigation-block-delete-modal/navigation-block-delete-data.model';

import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable({ providedIn: 'root' })
export class ConfirmChoiceService {
    constructor(public dialog: MatDialog) {}
    confirm({
        titlePath = 'MATERIAL.DIALOG.CONFIRM_HEADER',
        messagePath = 'MATERIAL.DIALOG.CONFIRM_TEMP',
        primaryButtonTextPath = 'MATERIAL.DIALOG.DELETE_BTN',
        secondaryButtonTextPath = 'MATERIAL.DIALOG.CANCEL',
        primaryButtonIcon = 'nitroicon-delete',
        omitFilter = false,
        messageExtraInfo = '',
    } = {}): Observable<any> {
        return new Observable((obs) => {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: new ConfirmDialogData({
                    titlePath,
                    messageExtraInfo,
                    messagePath,
                    primaryButtonTextPath,
                    primaryButtonIcon,
                    secondaryButtonTextPath,
                }),
                maxWidth: '400px',
            });

            dialogRef.afterClosed().subscribe((result) => {
                const convertedResult = result ? result.result === 'primary' : false;
                obs.next(convertedResult);
                obs.complete();
            });
        }).pipe(filter((answer) => (omitFilter ? true : Boolean(answer))));
    }

    confirmNavDelete({
        titlePath = 'MATERIAL.DIALOG.NAVBLOCK-DELETE-MODAL.TITLE',
        messagePath = 'MATERIAL.DIALOG.NAVBLOCK-DELETE-MODAL.MESSAGE',
        primaryButtonTextPath = 'MATERIAL.DIALOG.NAVBLOCK-DELETE-MODAL.DELETE-FOREVER',
        secondaryButtonTextPath = 'MATERIAL.DIALOG.CANCEL',
        omitFilter = false,
    } = {}): Observable<any> {
        return new Observable((obs) => {
            const dialogRef = this.dialog.open(NavigationBlockDeleteModalComponent, {
                data: new NavigationBlockDeleteData({
                    titlePath,
                    messagePath,
                    primaryButtonTextPath,
                    secondaryButtonTextPath,
                }),
                maxWidth: '512px',
            });

            dialogRef.afterClosed().subscribe((result) => {
                const convertedResult = result ? result.result === 'primary' : false;
                obs.next(convertedResult);
                obs.complete();
            });
        }).pipe(filter((answer) => (omitFilter ? true : Boolean(answer))));
    }
}
