<form
    [formGroup]="form"
    class="image-widget"
    [ngClass]="this.setStarterImageSize()"
    [class.homepage]="config?.isHomepage"
    [class.detail]="location === 'detail'"
    [class.original]="this.setStarterImageSize() === ''"
>
    <app-image-upload
        #imageUpload
        class="app-image-upload"
        [imageSize]="this.setStarterImageSize()"
        [formControlName]="'element'"
        [courseId]="config?.courseId"
    >
        <div class="image-widget__upload">
            <img src="assets/images/simple-image-widget.svg" class="image-widget__img" alt="" />
            <span class="image-widget__upload-text">{{ 'IMAGE-WIDGET.IMAGE-DRAG-OR' | translate }}</span>
            <button type="button" class="image-widget__upload-button template-btn editor-btn section-btn border-2">
                {{ 'IMAGE-WIDGET.IMAGE-UPLOAD' | translate }}
            </button>
        </div>
    </app-image-upload>

    <div class="cropper-actions" *ngIf="location === 'detail'">
        <button
            *ngIf="imageMode === 'normal'; else cropMode"
            class="editor-btn accent height-small"
            [disabled]="!hasImageForCrop"
            (click)="onUtilizeCropperRequest()"
        >
            {{ 'CROPPER.UTILIZE' | translate }}
        </button>
        <ng-template #cropMode>
            <button class="editor-btn secondary height-small" (click)="onCropRequest()">
                {{ 'CROPPER.CROP' | translate }}
            </button>
            <app-select-input
                (selectedOption)="onRatioChange($event)"
                [startValue]="'4/3'"
                [options]="cropSizeOptions"
            ></app-select-input>
            <button class="editor-btn danger height-small" (click)="onCancelCropRequest()">
                {{ 'CROPPER.CANCEL' | translate }}
            </button>
        </ng-template>
    </div>

    <app-quill
        *ngIf="!testEnv"
        [formControlName]="'caption'"
        class="image-widget__caption"
        [toolbarVisible]="true"
        [placeholder]="'IMAGE-WIDGET.CAPTION-PLACEHOLDER' | translate"
    ></app-quill>
</form>
