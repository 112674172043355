import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CarouselWidgetData } from '@apiModels';
import { AnimationDirection, slideAnimations } from '@shared/animations/carousel-slideIn.animations';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { LoaderForComponentsService } from '@shared/components/loader-for-components/loader-for-components.service';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { IFileUploadValue } from '@shared/models/file-upload.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { ConfirmChoiceService } from '@sharedServices/confirm-choice.service';
import { SocketService } from '@sharedServices/socket.service';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { Subscription } from 'rxjs';
import {
    ICarouselImageFormData,
    RealTimeCarouselWidgetForm,
} from 'src/app/api-wrapper/models/widget-models/carousel-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

const ratioClasses = {
    4: 'fourPerThree',
    16: 'sixteenPerNine',
    1: 'onePerOne',
    3: 'threePerFour',
};

@Component({
    selector: 'app-carousel-real-time-widget',
    templateUrl: './carousel-real-time-widget.component.html',
    styleUrls: ['./carousel-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CarouselRealTimeWidgetComponent),
            multi: true,
        },
    ],
    animations: [slideAnimations, collapseAnimation],
})
export class CarouselRealTimeWidgetComponent
    extends AbstractFormBaseComponent<RealTimeCarouselWidgetForm, IWidgetSupport>
    implements OnInit, OnDestroy
{
    @Input() editorLocation: 'detail' | 'real-time' = 'real-time';
    @Input() xPosition: number;
    testEnv = false;
    currentSlide = 0;
    animationType: AnimationDirection;
    ratio = ratioClasses[4];
    openCarouselSlider = true;
    zoomable: boolean;
    pptProcessIsOnSelector = false;
    embeded: boolean;
    titlePlaceholder: string;
    e2eTag: string;
    subscription: Subscription;
    notProcessedUrl = false;

    constructor(
        private confirmChoiceService: ConfirmChoiceService,
        private socketService: SocketService,
        private cdr: ChangeDetectorRef,
        private loaderForComponentsService: LoaderForComponentsService,
        private toastsSrv: ToastMessageService
    ) {
        super();
    }

    get images(): UntypedFormArray {
        return this.form.get('images') as UntypedFormArray;
    }

    ngOnInit(): void {
        this.carouselAndImageGallerySpecificConfig();
        super.ngOnInit();
    }

    carouselAndImageGallerySpecificConfig(): void {
        this.embeded = this.config.widgetType === 'CAROUSEL_WIDGET';
        this.titlePlaceholder = this.embeded
            ? 'CAROUSEL-WIDGET.TITLE-PLACEHOLDER'
            : 'CAROUSEL-WIDGET.TITLE-GALLERY-PLACEHOLDER';
        this.e2eTag = this.embeded ? 'carousel-widget' : 'gallery-widget';
    }

    onRatioChange(xPosition: number): void {
        this.xPosition = xPosition;
        this.ratio = ratioClasses[xPosition ?? 4] ?? ratioClasses[4];
    }

    onZoomableChange(zoomable: boolean): void {
        this.zoomable = zoomable;
    }

    createForm(): FormGroupTyped<RealTimeCarouselWidgetForm> {
        return new FormGroupTyped<RealTimeCarouselWidgetForm>({
            images: new UntypedFormArray([]),
            pptResourceId: FormHelper.controlFactoryWithCalculatedValue(null),
            title: FormHelper.controlFactoryWithCalculatedValue(null),
            xPosition: FormHelper.controlFactoryWithCalculatedValue(null),
            zoomable: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    updateForm(value: RealTimeCarouselWidgetForm): void {
        this.shouldSave = false;
        if (value.pptResourceId && value.images.length < 1) {
            this.pptProccessStarted();
        }
        this.ratio = ratioClasses[value?.xPosition ?? 4] ?? ratioClasses[4];
        this.zoomable = value.zoomable;
        this.xPosition = value.xPosition;
        this.form.patchValue(
            { xPosition: value.xPosition, zoomable: value.zoomable, title: value?.title ?? null },
            { emitEvent: false }
        );

        this.form.setControl('images', FormHelper.initBaseFormarray(value.images, this.createImageCard));
        if (value.images.length && this.currentSlide >= value.images.length) {
            this.currentSlide = value.images.length - 1;
        }
        this.cdr.detectChanges();
        this.shouldSave = true;
    }

    pptProccessStarted(pptResourceId?: string): void {
        if (pptResourceId) {
            this.mode = 'ONLY_UPDATE';
            this.form.patchValue({ pptResourceId });
        }
        this.watchPPTProcess();
        this.pptProcessIsOnSelector = true;
        this.loaderForComponentsService.showLoader(this.config.widgetId, 'PPT_PROCCESS');
        this.cdr.markForCheck();
    }

    watchPPTProcess(): void {
        if (this.subscription) {
            return;
        }
        this.subscription = this.socketService
            .watchPptProcess(this.config.widgetId, this.config.courseId)
            .subscribe((data) => this.afterPptProccess(data));
    }

    afterPptProccess(data: CarouselWidgetData): void {
        this.mode = 'ONLY_UPDATE';
        if (data.pptxProcessHadError) {
            this.toastsSrv.error({ translatedMessage: `CAROUSEL-WIDGET.PPT-FAILED` });
            this.pptProcessCleanup();
            return;
        }
        const imageFormData: ICarouselImageFormData[] = data.images.map((image) => {
            const { caption, description, resourceId } = image;
            return {
                caption,
                description,
                resourceImageElement: {
                    elementId: resourceId,
                    fileItem: null,
                },
            } as ICarouselImageFormData;
        });
        this.onRatioChange(data.ratio.x);
        this.notProcessedUrl = true;
        imageFormData.forEach((formData) => this.images.push(this.createImageCard(formData)));
        this.pptProcessCleanup();
    }

    pptProcessCleanup(): void {
        this.form.patchValue({ pptResourceId: null });
        this.pptProcessIsOnSelector = false;
        this.loaderForComponentsService.hideLoader(this.config.widgetId, 'PPT_PROCCESS');
        this.cdr.detectChanges();
    }

    createImageCard = (data: Partial<ICarouselImageFormData>): FormGroupTyped<ICarouselImageFormData> => {
        return new FormGroupTyped<ICarouselImageFormData>({
            caption: FormHelper.controlFactoryWithCalculatedValue(data?.caption ?? null),
            description: FormHelper.controlFactoryWithCalculatedValue(data?.description ?? null),
            resourceImageElement: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: data?.resourceImageElement?.fileItem ?? null,
                elementId: data?.resourceImageElement?.elementId ?? null,
            }),
        });
    };

    setCurrentSlide(index: number): void {
        if (this.currentSlide === index) {
            return;
        }
        this.animationType = this.currentSlide < index ? AnimationDirection.RIGHT : AnimationDirection.LEFT;

        setTimeout(() => {
            this.currentSlide = index;
            this.cdr.detectChanges();
        }, 0);
    }

    onPreviousClick(): void {
        this.animationType = AnimationDirection.LEFT;
        setTimeout(() => {
            const previous = this.currentSlide - 1;
            this.currentSlide = previous < 0 ? this.images.value.length - 1 : previous;
            this.cdr.detectChanges();
        }, 0);
    }

    onNextClick(): void {
        this.animationType = AnimationDirection.RIGHT;
        setTimeout(() => {
            const next = this.currentSlide + 1;
            this.currentSlide = next === this.images?.value?.length ? 0 : next;
            this.cdr.detectChanges();
        }, 0);
    }

    moveImage(step: number): void {
        const control = this.images.at(this.currentSlide);
        this.images.removeAt(this.currentSlide, { emitEvent: false });
        this.images.insert(this.currentSlide + step, control);
        this.currentSlide = this.currentSlide + step;
        this.cdr.detectChanges();
    }

    getImageResourceId(dia: ICarouselImageFormData): string {
        const elementId = dia?.resourceImageElement?.elementId;
        const uuid = dia?.resourceImageElement?.fileItem?.uuid;
        return elementId ?? uuid;
    }

    getResource(dia: ICarouselImageFormData): File | null {
        return dia?.resourceImageElement?.fileItem?.resource ?? null;
    }

    addImageCard(resourceImageElement: IFileUploadValue): void {
        this.images.push(this.createImageCard({ resourceImageElement }));
        setTimeout(() => {
            this.currentSlide = this.images.length - 1;
            this.cdr.detectChanges();
        }, 500);
    }

    deleteCard(index: number): void {
        this.confirmChoiceService.confirm().subscribe(() => {
            this.images.removeAt(index);

            if (index === this.currentSlide) {
                this.currentSlide = Math.max(0, this.currentSlide - 1);
            }

            this.cdr.detectChanges();
        });
    }

    trackByFn(index: number): number {
        return index;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
