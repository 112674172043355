/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IFileUploadElement, IFileUploadValue } from '@shared/models/file-upload.model';
import { ImageImageWidgetData } from 'src/app/api/models/image-image-widget-data';

import { BaseWidgetClass } from '../base-widget-models/base-widget-class';
import { TypedResponse } from '../base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from '../base-widget-models/widget-size.enum';
import { MultiWidgetDividerLocation, MultiWidgetImageFit } from './multi-detail-options';

export interface RealTimeImageImageWidgetForm {
    element: IFileUploadValue;
    dividerLocation: MultiWidgetDividerLocation;
    flipped: boolean;
    imageFit: MultiWidgetImageFit;
    otherElement: IFileUploadValue;
    otherImageFit: MultiWidgetImageFit;
}

export interface DetailImageImageWidgetForm {
    realTimeImageImageWidgetFormData: RealTimeImageImageWidgetForm;
    imageDescription: string;
    otherImageDescription: string;
    dividerLocation: MultiWidgetDividerLocation;
    flipped: boolean;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW';
    size: WidgetSizeEnum;
    imageFit: MultiWidgetImageFit;
    otherImageFit: MultiWidgetImageFit;
}

export interface ImageImageWidgetDataSpec extends ImageImageWidgetData {
    fulfillment: 'NONE' | 'SHOW';
}

export class ImageImageWidgetClass extends BaseWidgetClass<ImageImageWidgetDataSpec> {
    otherFileUploadElement: IFileUploadElement | null = null;
    constructor(data: TypedResponse<ImageImageWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeImageImageWidgetForm {
        return {
            dividerLocation: this.data?.dividerLocation ?? 'CENTER',
            flipped: this.data?.flipped ?? false,
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data?.imageResourceId ?? null,
            },
            imageFit: this.data?.imageFit ?? 'CONTAIN',
            otherElement: {
                fileItem: this.otherFileUploadElement,
                elementId: this.data?.otherImageResourceId ?? null,
            },
            otherImageFit: this.data?.otherImageFit ?? 'CONTAIN',
        };
    }

    getDetailFormData(): DetailImageImageWidgetForm {
        return {
            realTimeImageImageWidgetFormData: this.getRealTimeFormData(),
            imageDescription: this.data?.imageDescription ?? '',
            otherImageDescription: this.data?.otherImageDescription ?? '',
            dividerLocation: this.data?.dividerLocation ?? 'CENTER',
            flipped: this.data?.flipped ?? false,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
            imageFit: this.data?.imageFit ?? 'CONTAIN',
            otherImageFit: this.data?.otherImageFit ?? 'CONTAIN',
        };
    }

    updateWidgetData(modifiedData: RealTimeImageImageWidgetForm | DetailImageImageWidgetForm): void {
        if (modifiedData.hasOwnProperty('realTimeImageImageWidgetFormData')) {
            const { realTimeImageImageWidgetFormData, ...rest } = modifiedData as DetailImageImageWidgetForm;
            modifiedData = {
                ...realTimeImageImageWidgetFormData,
                ...rest,
            };
        }

        const { element, otherElement, ...rest } = modifiedData as RealTimeImageImageWidgetForm &
            DetailImageImageWidgetForm;

        if (this.shouldSaveFile(element.fileItem, this.data.imageResourceId)) {
            this.filesToSave.push(element.fileItem);
        }

        if (this.shouldSaveFile(otherElement.fileItem, this.data.otherImageResourceId)) {
            this.filesToSave.push(otherElement.fileItem);
        }
        this.fileUploadElement = element.fileItem;
        this.otherFileUploadElement = otherElement.fileItem;
        const imageResourceId = this.extractUUID(element);
        const otherImageResourceId = this.extractUUID(otherElement);
        this.data = { ...this.data, ...rest, imageResourceId, otherImageResourceId };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.imageResourceId === id) {
                this.data = { ...this.data, imageResourceId: null };
                this.fileUploadElement = null;
            } else if (this.data.otherImageResourceId === id) {
                this.data = { ...this.data, otherImageResourceId: null };
                this.otherFileUploadElement = null;
            }
        });
    }
}
