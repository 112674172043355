import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import { RealTimeAudioWidgetForm } from '../../../../api-wrapper/models/widget-models/audio-widget-model';

@Component({
    selector: 'app-audio-real-time-widget',
    templateUrl: './audio-real-time-widget.component.html',
    styleUrls: ['./audio-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AudioRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class AudioRealTimeWidgetComponent extends AbstractFormBaseComponent<RealTimeAudioWidgetForm, IWidgetSupport> {
    description: string;
    constructor() {
        super();
    }

    updateForm(value: RealTimeAudioWidgetForm): void {
        this.form.setValue(value, { emitEvent: false });
        const description = this.form.get('description').value as string | null;
        this.description = description ?? null;
    }

    createForm(): FormGroupTyped<RealTimeAudioWidgetForm> {
        return new FormGroupTyped<RealTimeAudioWidgetForm>({
            element: FormHelper.controlFactoryWithCalculatedValue(null),
            description: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
