/* eslint-disable */
/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CourseControllerService } from '@apiServices';
import { Observable } from 'rxjs';
import { ApiConfiguration } from 'src/app/api/api-configuration';
import { RequestBuilder } from 'src/app/api/request-builder';

@Injectable({
    providedIn: 'root',
})
export class CourseUploadControllerService extends CourseControllerService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Upload resource to course.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `uploadResource()` instead.
     *
     * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
     */
    uploadResourceResponse(params: {
        /**
         * Identifier of course
         */
        courseId: number;

        /**
         * Optional identifier of uploadable resource
         */
        resourceId?: string;
        /**
         * Relevant widgetId to tie widget to use in postprocessing if necessary
         */
        relevantWidgetId?: number;
        /**
         * Org Header
         */
        'Org-Header'?: string;
        body?: { file?: Blob };
    }): Observable<HttpEvent<any>> {
        const rb = new RequestBuilder(this.rootUrl, CourseControllerService.UploadResourcePath, 'post');
        if (params) {
            rb.path('courseId', params.courseId, {});
            rb.query('resourceId', params.resourceId, {});
            rb.header('Org-Header', params['Org-Header'], {});
            rb.query('relevantWidgetId', params.relevantWidgetId, {});
            rb.header('ngsw-bypass', true, {});
            rb.body(params.body, 'multipart/form-data');
        }

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json',
                reportProgress: true,
            })
        );
    }
}
