export type LoaderType = 'COPY' | null;

export class Loader {
    xhrNumber: number;
    timeout: number;
    type?: LoaderType;

    constructor(xhrNumber = 0, timeout = 0, type: LoaderType) {
        this.xhrNumber = xhrNumber;
        this.timeout = timeout;
        this.type = type;
    }
}
