import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import { RealTimeConceptCardWidgetForm } from '../../../../api-wrapper/models/widget-models/concept-card-widget-model';

@Component({
    selector: 'app-concept-card-real-time-widget',
    templateUrl: './concept-card-real-time-widget.component.html',
    styleUrls: ['./concept-card-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConceptCardRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class ConceptCardRealTimeWidgetComponent extends AbstractFormBaseComponent<
    RealTimeConceptCardWidgetForm,
    IWidgetSupport
> {
    testEnv = false;
    constructor() {
        super();
    }

    createForm(): FormGroupTyped<RealTimeConceptCardWidgetForm> {
        return new FormGroupTyped<RealTimeConceptCardWidgetForm>({
            concept: FormHelper.controlFactoryWithCalculatedValue(null),
            definition: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
