import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
    transform(
        value: number,
        options: { removeTrailingZeroes: boolean; fractionDigits: number } = {
            fractionDigits: 2,
            removeTrailingZeroes: true,
        }
    ): string {
        const format = (size: number, unit: number): string => {
            let a: string = (size / unit).toFixed(options.fractionDigits);
            if (options.removeTrailingZeroes) {
                for (let i = 0; i < options.fractionDigits; i++) {
                    if (a[a.length - 1] === '0') {
                        a = a.slice(0, a.length - 1);
                    }
                }
                if (a[a.length - 1] === '.') {
                    a = a.slice(0, a.length - 1);
                }
            }
            return a;
        };
        if (value === 0) {
            return '0B';
        }
        if (value < 1024) {
            return value + 'B';
        }
        if (value < 1048576) {
            return Math.round(value / 1024) + 'KB';
        }
        if (value < 1073741824) {
            let a: string = format(value, 1048576);
            if (a === '1024') {
                return '1GB';
            }
            return a + 'MB';
        }
        if (value < 1099511627776) {
            let a: string = format(value, 1073741824);
            if (a === '1024') {
                return '1TB';
            }
            return a + 'GB';
        }
        if (value < 1125899906842624) {
            let a: string = format(value, 1099511627776);
            if (a === '1024') {
                return '1PB';
            }
            return a + 'TB';
        }
        if (value < 1152921504606846976) {
            let a: string = format(value, 1125899906842624);
            if (a === '1024') {
                return '1EB';
            }
            return a + 'PB';
        }
        {
            let a: string = format(value, 1125899906842624);
            return a + 'EB';
        }
    }
}
