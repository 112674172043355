<div class="info-box-with-actions__info-box" [ngClass]="type">
    <span [ngClass]="icon" class="info-box-with-actions__info-icon"></span>
    <p class="info-box-with-actions__info-text">{{ infoText | translate }}</p>
</div>
<ng-content></ng-content>
<div class="info-box-with-actions__actions" *ngIf="actionButtons?.length">
    <button
        *ngFor="let button of actionButtons"
        type="button"
        class="editor-btn primary height-medium"
        (click)="onButtonClick(button.action)"
    >
        {{ button.text | translate }}
    </button>
</div>
