import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class DevModeGuardService implements CanLoad {
    constructor(private router: Router) {}

    canLoad(): Observable<boolean> {
        console.log(environment.mode);
        if (environment.mode === 'dev' || environment.mode === 'test') {
            return of(true);
        } else {
            this.router.navigate(['/']);
            return of(false);
        }
    }
}
