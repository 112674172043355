import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomVideoComponent } from '@shared/components/custom-video/custom-video.component';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { BaseFileUploadComponent } from '@shared/forms/file-upload/base-file-upload/base-file-upload.component';
import { IFileUploadValue } from '@shared/models/file-upload.model';
import { URLHelperUtility } from '@sharedUtilities/url-helpers.utility';
import { ISubtitleFormData } from 'src/app/api-wrapper/models/widget-models/video-widget-model';

@Component({
    selector: 'app-video-upload',
    templateUrl: './video-upload.component.html',
    styleUrls: ['./video-upload.component.scss', '../base-file-upload/base-file-upload.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VideoUploadComponent),
            multi: true,
        },
    ],
})
export class VideoUploadComponent extends BaseFileUploadComponent implements OnInit {
    @Input() acceptTypes = 'video/mp4, video/avi';
    @Input() regExp = new RegExp(/\.(mp4|avi)$/i);
    @Input() maxFileSize = 1_000_000_000;
    @Input() posterValue: string;
    @Input() subtitle: ISubtitleFormData;
    @Input() textWidget = false;
    @Input() availableScales: string[] = [];
    @Input() loop = false;
    @Input() muted = false;
    @Input() autoplay = false;
    @Input() controlsEnabled = true;
    @Input() fullSize = false;
    @ViewChild('droparea') customVideo: CustomVideoComponent;
    @ViewChild('droparea') videoElement: ElementRef<CustomVideoComponent>;
    @Output() deletePoster = new EventEmitter<void>();

    selectedResolution = '1080p';
    bypassSw = true;
    subtitleUrl: string;
    dragover = false;

    constructor(protected toastsSrv: ToastMessageService, private cdr: ChangeDetectorRef) {
        super(toastsSrv, cdr);
    }

    ngOnInit(): void {
        this.subtitleUrl = this.setSubtitleFile();
    }

    deleteFile(): void {
        this.value = { elementId: null, fileItem: null };
        this.fileSrc = null;
        this.orderedScales = [];
        this.onChange(this.value);
    }

    reloadVideo(): void {
        if (this.lastKnownTime > 0) {
            this.customVideo?.reloadVideo();
        }
    }

    onDeletePoster(): void {
        this.deletePoster.emit();
        this.customVideo?.reloadVideo();
    }

    getResourceUrl(value: IFileUploadValue): string {
        this.orderedScales = [
            ...(this.availableScales?.includes('1080p') ? ['1080p'] : []),
            ...(this.availableScales?.includes('720p') ? ['720p'] : []),
            ...(this.availableScales?.includes('480p') ? ['480p'] : []),
            ...(this.availableScales?.includes('360p') ? ['360p'] : []),
            ...(this.availableScales?.includes('240p') ? ['240p'] : []),
        ];
        return this.setVideoResourceUrl(value);
    }

    onProcessFile(): void {
        this.orderedScales = [];
    }

    setVideoResourceUrl(value: IFileUploadValue): string {
        if (this.orderedScales.length) {
            this.selectedResolution = this.orderedScales.includes(this.selectedResolution)
                ? this.selectedResolution
                : this.orderedScales[0];
            return URLHelperUtility.videoResourceURLForCourse(this.courseId, value.elementId, this.selectedResolution);
        }
        return URLHelperUtility.videoResourceURLForCourse(this.courseId, value.elementId);
    }

    setSubtitleFile(): string {
        if (!this.subtitle) {
            return null;
        }
        return this.subtitle?.element?.fileItem
            ? URL.createObjectURL(this.subtitle?.element?.fileItem.resource)
            : this.subtitle?.element?.elementId
            ? URLHelperUtility.resourceURLForCourse(this.courseId, this.subtitle?.element?.elementId)
            : null;
    }
}
