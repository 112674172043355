import { IFileUploadValue } from '@shared/models/file-upload.model';

export class URLHelperUtility {
    static accessToken: string;

    static setToken(token: string): void {
        this.accessToken = token;
    }

    static urlWithToken(url: string, byPassSw = false): string {
        const byPassParam = byPassSw ? '&ngsw-bypass=true' : '';
        return `${url}?access_token=${this.accessToken}${byPassParam}`;
    }

    static baseresourceURLForCourse(courseId: number, resourceId: string): string {
        return `/apps-api/editor/courses/${courseId}/resources/${resourceId}`;
    }
    static resourceURLForCourse(courseId: number, resourceId: string, byPassSw = false): string {
        const byPassParam = byPassSw ? '&ngsw-bypass=true' : '';
        return `/apps-api/editor/courses/${courseId}/resources/${resourceId}?access_token=${this.accessToken}${byPassParam}`;
    }

    static videoResourceURLForCourse(courseId: number, resourceId: string, resolution?: string): string {
        const resolutionString = resolution ? `_${resolution}` : '';
        const resourceIdArray = resourceId.split('.');
        resourceIdArray[0] = `${resourceIdArray[0]}${resolutionString}`;
        return `/apps-api/editor/courses/${courseId}/resources/${resourceIdArray.join('.')}?access_token=${
            this.accessToken
        }`;
    }

    static imageResourceURLForCourse(courseId: number, resourceId: string, imageSize = 'M', byPassSw = false): string {
        if (resourceId.endsWith('.svg')) {
            return URLHelperUtility.resourceURLForCourse(courseId, resourceId, byPassSw);
        }
        const byPassParam = byPassSw ? '&ngsw-bypass=true' : '';
        const sizeString = imageSize ? `_${imageSize}` : '';
        const resourceIdArray = resourceId.split('.');
        resourceIdArray[0] = `${resourceIdArray[0]}${sizeString}`;
        return `/apps-api/editor/courses/${courseId}/resources/${resourceIdArray.join('.')}?access_token=${
            this.accessToken
        }${byPassParam}`;
    }

    static resourceDownload(element: IFileUploadValue, courseId: number): string | null {
        return element?.fileItem
            ? URL.createObjectURL(element?.fileItem.resource)
            : element?.elementId
            ? URLHelperUtility.resourceURLForCourse(courseId, element?.elementId)
            : null;
    }

    static formatBytes(bytes: number): string {
        const dataSizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (0 === bytes) {
            return '0 Bytes';
        }
        const f = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, f)).toFixed(0)) + ' ' + dataSizes[f];
    }

    static isValidURL(url: string): boolean {
        if (!url) {
            return false;
        }
        const res = url.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res !== null;
    }

    static urlSanitizer(urlToSanitize: string): string | void {
        if (!urlToSanitize) {
            return;
        }

        return urlToSanitize.trim().replace(/^(?!(?:\w+:)?\/\/)/, 'http://');
    }

    static download(filename: string, blob: any): void {
        const link = document.createElement('a');
        // const blobUrl = URL.createObjectURL(blob);

        link.href = blob;
        link.download = filename;

        link.style.display = 'none';

        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );

        document.body.removeChild(link);
    }

    static copyUrl(val: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
