import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ISelectOption } from '@shared/forms/select-input/select-input.component';

@Component({
    selector: 'app-custom-paginator',
    templateUrl: './custom-paginator.component.html',
    styleUrls: ['./custom-paginator.component.scss'],
})
export class CustomPaginatorComponent implements OnInit, OnChanges {
    @Input() pageIndex = 0;
    @Input() pageSize = 5;
    @Input() length = 0;
    @Input() pageSizeOptions = [5, 10, 15, 20];
    @Output() page = new EventEmitter<PageEvent>();
    totalPages = 1;

    pageSizeSelectOptions: ISelectOption[] = [];
    availablePages = [1, 2, 3];

    ngOnInit(): void {
        this.pageSizeSelectOptions = this.pageSizeOptions.map((size: number) => {
            return { value: size, displayName: 'COMMON.SIZE-SELECTOR' };
        });
        this.totalPages = this.getNumberOfPages();
        this.reDrawRange();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes &&
            ((changes.length && !changes.length.firstChange) || (changes.pageSize && !changes.pageSize.firstChange))
        ) {
            this.pageIndex = 0;
            this.totalPages = this.getNumberOfPages();
            this.reDrawRange();
        }
    }

    range = (start: number, stop: number, step = 1): number[] =>
        Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

    reDrawRange(): void {
        if (this.length === 0) {
            this.availablePages = [];

            return;
        }

        if (this.pageIndex === 0) {
            const end = Math.min(2, this.totalPages - 1);
            this.availablePages = this.range(0, end);

            return;
        }
        if (this.pageIndex === this.totalPages - 1) {
            const start = Math.max(0, this.totalPages - 3);
            this.availablePages = this.range(start, this.totalPages - 1);

            return;
        }
        if (this.availablePages.includes(this.pageIndex)) {
            return;
        }
        const fromUp = this.availablePages[0] > this.pageIndex;
        if (fromUp) {
            this.availablePages = this.range(this.pageIndex - 2, this.pageIndex);

            return;
        }
        this.availablePages = this.range(this.pageIndex, this.pageIndex + 2);
    }

    getNumberOfPages(): number {
        if (!this.pageSize) {
            return 0;
        }

        return Math.ceil(this.length / this.pageSize);
    }

    sizeChanged(pageSize: number): void {
        this.pageIndex = 0;
        this.pageSize = pageSize;
        this.sendPageEvent();
    }

    moveToPage(pageIndex: number): void {
        if (this.pageIndex === pageIndex) {
            return;
        }
        this.pageIndex = pageIndex;
        this.reDrawRange();
        this.sendPageEvent();
    }

    moveToTheBeginning(): void {
        this.pageIndex = 0;
        this.reDrawRange();
        this.sendPageEvent();
    }

    moveOne(direction: number): void {
        this.pageIndex = this.pageIndex + direction;
        this.reDrawRange();

        this.sendPageEvent();
    }

    moveToTheEnd(): void {
        this.pageIndex = this.totalPages - 1;
        this.reDrawRange();
        this.sendPageEvent();
    }

    sendPageEvent(): void {
        this.page.emit({ pageIndex: this.pageIndex, pageSize: this.pageSize, length: 0 });
    }
}
