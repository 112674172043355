<div [class]="'tooltip-editor tooltip-editor-' + data.placement">
    <ng-container *ngIf="data.placement !== 'bottom'">
        <ng-container *ngTemplateOutlet="alignButtons"></ng-container>
    </ng-container>
    <span class="tooltip-arrow" [ngClass]="'tooltip-arrow-' + data.placement"></span>
    <textarea
        #tooltipTextarea
        appAutosize
        [(ngModel)]="data.text"
        [placeholder]="'QUILL.TOOLTIP-PLACEHOLDER' | translate"
        [maxLength]="250"
        rows="3"
        (blur)="onSaveTooltip($event)"
    ></textarea>

    <ng-container *ngIf="data.placement === 'bottom'">
        <ng-container *ngTemplateOutlet="alignButtons"></ng-container>
    </ng-container>
</div>

<ng-template #alignButtons>
    <div class="align-buttons" data-prevent-autosave tabindex="0">
        <button
            class="align-buttons__btn"
            type="button"
            (click)="transformAlignment('top')"
            [ngClass]="{ active: data.placement === 'top' }"
        >
            <span class="nitroicon-tooltip_up"></span>
        </button>
        <button
            class="align-buttons__btn"
            type="button"
            (click)="transformAlignment('bottom')"
            [ngClass]="{ active: data.placement === 'bottom' }"
        >
            <span class="nitroicon-tooltip_down"></span>
        </button>
        <button
            class="align-buttons__btn"
            type="button"
            (click)="transformAlignment('left')"
            [ngClass]="{ active: data.placement === 'left' }"
        >
            <span class="nitroicon-tooltip_left"></span>
        </button>
        <button
            class="align-buttons__btn"
            type="button"
            (click)="transformAlignment('right')"
            [ngClass]="{ active: data.placement === 'right' }"
        >
            <span class="nitroicon-tooltip_right"></span>
        </button>
        <button class="align-buttons__btn delete" type="button" (click)="onDelete()">
            <span class="nitroicon-delete"></span>
        </button>
    </div>
</ng-template>
