<form [formGroup]="form" class="document-widget">
    <section class="content-wrapper" *ngIf="!data.examId">
        <label for="exam-select">{{'EXAM_WIDGET.SELECT_EXAM' | translate}}</label>
        <app-select-input
            id="exam-select"
            [formControlName]="'examId'"
            (selectedOption)="onExamSelected($event)"
            [options]="examOptions"
        ></app-select-input></section>
    <section class="content-wrapper" *ngIf="data.examId">

        <h1 class="exam-title">{{ examConfig?.title }}</h1>

        <div class="card-wrapper">
            <button
                class="editor-btn primary ghost height-small icon-only absoloute-action-button card-delete-button"
                (click)="removeExam()"
            >
                <span class="nitroicon-close editor-btn-icon"></span>
            </button>
            <header class="header">
                <h2 class="header__title">{{ 'EXAM_WIDGET.HOMEPAGE.EXAM_DESCRIPTION' | translate }}</h2>
            </header>

            <section class="main">
                <div class="detail-card">
                    <span class="nitroicon-quiz detail-card__icon"></span>
                    <span class="detail-card__text">{{ examConfig?.questionCount }}</span>
                    <span>{{ 'EXAM_WIDGET.HOMEPAGE.NUMBER_OF_QUESTIONS' | translate }}</span>
                </div>
                <div class="detail-card">
                    <span class="nitroicon-timer detail-card__icon"></span>
                    <span *ngIf="timeMode !== 'NONE' else timeElse">
                <ng-container *ngIf="minutes">
                    <span class="detail-card__text">{{ minutes }}</span>
                    <span class="detail-card__text">{{ 'EXAM_WIDGET.HOMEPAGE.MINUTES' | translate }}</span>
                </ng-container>

                <ng-container *ngIf="seconds">
                    <span class="detail-card__text" *ngIf="minutes">{{ 'EXAM_WIDGET.HOMEPAGE.AND' | translate }}</span>
                    <span class="detail-card__text">{{ seconds }}</span>
                    <span class="detail-card__text">{{ 'EXAM_WIDGET.HOMEPAGE.SECONDS' | translate }}</span>
                </ng-container>
                <span *ngIf="timeMode === 'QUESTION' else timeExam">
                    <span class="detail-card__text">{{ 'EXAM_WIDGET.HOMEPAGE.TIME_PER_QUESTION' | translate }}</span>
                </span>
                <ng-template #timeExam>
                    <span class="detail-card__text">{{ 'EXAM_WIDGET.HOMEPAGE.TIME_FOR_THE_EXAM' | translate }}</span>
                </ng-template>
            </span>
                    <ng-template #timeElse>
                        <span class="detail-card__text">{{'EXAM_WIDGET.HOMEPAGE.UNLIMITED_TIME' | translate}}</span>
                    </ng-template>
                </div>
                <div class="detail-card">
                    <span class="nitroicon-restart detail-card__icon"></span>
                    <span class="detail-card__text"
                    >{{ examConfig?.maxTries ? examConfig?.maxTries + 1 :
                        'EXAM_WIDGET.HOMEPAGE.UNLIMITED_ATTEMPT' | translate }}</span
                    >
                    <span>{{ 'EXAM_WIDGET.HOMEPAGE.NUMBER_OF_ATTEMPTS' | translate }}</span>
                </div>
                <div class="detail-card">
                    <span class="nitroicon-success_rate detail-card__icon"></span>
                    <span class="detail-card__text"
                    >{{ 'EXAM_WIDGET.HOMEPAGE.CONSIDERED_SUCCESS' | translate: {value: examConfig?.successfulPercent} }}</span
                    >
                </div>

                <section class="description">
                    <h3>{{ 'EXAM_WIDGET.HOMEPAGE.DESCRIPTION-TITLE' | translate }}</h3>
                    <p [innerHTML]="examConfig?.description | safeHtml"></p>
                </section>

                <section class="action-button">
                    <button class="template-btn card-btn">
                        {{ 'EXAM_WIDGET.HOMEPAGE.START' | translate }}
                    </button>
                </section>
            </section>
        </div>
    </section>

</form>
