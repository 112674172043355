import { QuestionWidgetData } from 'src/app/api/models/question-widget-data';

export interface DetailQuestionWidgetForm {
    attempts: number;
    enableAnswerExplanation: boolean;
    enableCustomExplanation: boolean;
    enableGeneralExplanation: boolean;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION-ANSWERED' | 'QUESTION_CORRECTLY_ANSWERED' | any;
    explanation?: string;
    explanationCorrect?: string;
    explanationIncorrect?: string;
    explanationPartialCorrect?: string;
    hasAnswerExplanation?: boolean;
    hasAnswerMedia?: boolean;
    hasCustomExplanation?: boolean;
    hasExplanation?: boolean;
    hasMedia?: boolean;
    hasPdf?: boolean;
    pdfOriginalFileName?: string;
    questionType: string;
    mediaDescription?: string;
    pdfDescription?: string;
}

export function InitDetailQuestionFormData(data: QuestionWidgetData): DetailQuestionWidgetForm {
    const {
        question,
        attempts,
        enableAnswerExplanation,
        enableCustomExplanation,
        enableGeneralExplanation,
        fulfillmentAvailable,
        fulfillment,
    } = data;
    return {
        attempts: attempts ?? null,
        enableAnswerExplanation: enableAnswerExplanation ?? null,
        enableCustomExplanation: enableCustomExplanation ?? null,
        enableGeneralExplanation: enableGeneralExplanation ?? null,
        fulfillmentAvailable: fulfillmentAvailable ?? false,
        fulfillment: fulfillment ?? 'NONE',
        explanation: question?.explanation ?? null,
        explanationCorrect: question?.explanationCorrect ?? null,
        explanationIncorrect: question?.explanationIncorrect ?? null,
        explanationPartialCorrect: question?.explanationPartialCorrect ?? null,
        hasAnswerExplanation: question?.hasAnswerExplanation ?? false,
        hasAnswerMedia: question?.hasAnswerMedia ?? false,
        hasCustomExplanation: question?.hasCustomExplanation ?? false,
        hasExplanation: question?.hasExplanation ?? false,
        hasMedia: question?.hasMedia ?? false,
        hasPdf: question?.hasPdf ?? false,
        pdfOriginalFileName: question?.pdfOriginalFileName ?? null,
        questionType: question?.questionType ?? null,
        mediaDescription: question?.mediaDescription ?? null,
        pdfDescription: question?.pdfDescription ?? null,
    };
}
