import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, NG_VALUE_ACCESSOR } from '@angular/forms';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';
import { Options } from 'sortablejs';
import { ISortingAnswerModel } from 'src/app/api-wrapper/models/widget-models/questions/sorting-question/sorting-answer-model';
import { RealTimeSortingQuestionWidgetForm } from 'src/app/api-wrapper/models/widget-models/questions/sorting-question/sorting-question-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import { BaseQuestionRealTimeWidgetComponent } from '../../base-classes/abstract-base-real-time-question';
import { realTimeBaseQuestionWidgetFormFactory } from '../../helpers/choice-answers.helpers';

@Component({
    selector: 'app-sorting-question-real-time-widget',
    templateUrl: './sorting-question-real-time-widget.component.html',
    styleUrls: ['./sorting-question-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SortingQuestionRealTimeWidgetComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortingQuestionRealTimeWidgetComponent extends BaseQuestionRealTimeWidgetComponent<
    RealTimeSortingQuestionWidgetForm,
    IWidgetSupport
> {
    options: Options;
    constructor(private cdr: ChangeDetectorRef) {
        super();
        this.options = {
            forceFallback: false,
            onUpdate: () => {},
            onChange: () => {},
            onAdd: () => {},
            onRemove: (event: any) => {},
            onStart: (event: any) => {},
        };
    }

    get answers(): UntypedFormArray {
        return this.form.get('answers') as UntypedFormArray;
    }

    updateForm(value: RealTimeSortingQuestionWidgetForm): void {
        this.shouldSave = false;
        super.updateForm(value);
        this.form.setControl('answers', FormHelper.initBaseFormarray(value.answers, this.createAnswer));
        this.cdr.detectChanges();
        this.shouldSave = true;
    }

    createAnswer = (data?: Partial<ISortingAnswerModel>): FormGroupTyped<ISortingAnswerModel> => {
        if (!data.id) {
            data.id = UUIDGeneratorUtility.generate();
        }

        return new FormGroupTyped<ISortingAnswerModel>({
            order: FormHelper.controlFactoryWithCalculatedValue(data?.order ?? false),
            id: FormHelper.controlFactoryWithCalculatedValue(data?.id),
            mediaResourceElement: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: data?.mediaResourceElement?.fileItem ?? null,
                elementId: data?.mediaResourceElement?.elementId ?? null,
            }),
            text: FormHelper.controlFactoryWithCalculatedValue(data?.text ?? null),
        });

        // eslint-disable-next-line @typescript-eslint/semi,@typescript-eslint/member-delimiter-style
    };

    createForm(): FormGroupTyped<RealTimeSortingQuestionWidgetForm> {
        return realTimeBaseQuestionWidgetFormFactory();
    }

    addNewAnswer(): void {
        this.answers.insert(this.answers.length, this.createAnswer({ order: this.answers.length }));
        this.cdr.detectChanges();
    }

    removeAnswer(index: number): void {
        this.answers.removeAt(index);
        this.cdr.detectChanges();
    }

    getAnswersControls = (): AbstractControl[] => {
        return this.answers.controls;
    };
}
