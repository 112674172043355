<form [formGroup]="form">
    <header class="detail-form__header" [ngClass]="sizeClass">
        <div class="detail-form__header__action-buttons">
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.ORIGINAL }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.ORIGINAL)"
            >
                <span class="nitroicon-block_below editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.MARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.MARGIN)"
            >
                <span class="nitroicon-block_narrow editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.NOMARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.NOMARGIN)"
            >
                <span class="nitroicon-block_mid editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.FULLSIZE }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.FULLSIZE)"
            >
                <span class="nitroicon-block_fullsize editor-btn-icon"></span>
            </button>
        </div>
    </header>

    <ng-template #realTimeElement>
        <app-image-real-time-widget
            *ngIf="!testEnv"
            class="detail-form__real-time-widget"
            [ngClass]="sizeClass"
            [formControlName]="'realTimeSimpleImageWidgetFormData'"
            [config]="config"
            [location]="'detail'"
        ></app-image-real-time-widget>
    </ng-template>
    <ng-container *ngIf="size === widgetSizeEnum.FULLSIZE">
        <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
    </ng-container>

    <section class="detail-form resizable" [ngClass]="sizeClass">
        <div class="detail-form__site-left">
            <ng-container *ngIf="size !== widgetSizeEnum.FULLSIZE">
                <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
            </ng-container>

            <app-collapse-card [title]="'IMAGE-WIDGET.ACCESSIBILTY-CARD' | translate" [opened]="true">
                <div class="detail-form__group">
                    <label class="detail-form__group__label" for="accessibilityRole"
                        >{{ 'IMAGE-WIDGET.CAPTION_ROLE' | translate }}
                    </label>
                    <app-select-input
                        id="accessibilityRole"
                        [formControlName]="'accessibilityRole'"
                        [options]="accessibilityRoleOptions"
                    ></app-select-input>
                </div>
                <div class="detail-form__group">
                    <label class="detail-form__group__label" for="description"
                        >{{ 'IMAGE-WIDGET.ACCESSIBILTY-TEXT' | translate }}
                    </label>
                    <textarea
                        [placeholder]="'IMAGE-WIDGET.ACCESSIBILTY-PLACEHOLDER' | translate "
                        name="description"
                        id="description"
                        formControlName="description"
                    ></textarea>
                </div>
            </app-collapse-card>
        </div>

        <div class="detail-form__site-right">
            <app-collapse-card [title]="'IMAGE-WIDGET.SETTINGS-CARD' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'FORM-CONTROLS.ZOOMABLE-TITLE' | translate"
                    [options]="zoomOptionsForImages"
                    formControlName="zoomable"
                ></app-radio-group-control>
            </app-collapse-card>
            <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'FORM-CONTROLS.FULFILLMENT-AVAILABLE-TITLE' | translate"
                    [options]="fulfillmentAvailableOptions"
                    formControlName="fulfillmentAvailable"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                    [options]="fulfillmentOptions"
                    formControlName="fulfillment"
                ></app-radio-group-control>
            </app-collapse-card>
        </div>
    </section>
</form>
