/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MoveNavCardRequest } from '../models/move-nav-card-request';
import { WidgetCreateRequest } from '../models/widget-create-request';
import { WidgetModifyRequest } from '../models/widget-modify-request';
import { WidgetResponse } from '../models/widget-response';

@Injectable({
  providedIn: 'root',
})
export class WidgetControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getWidget
   */
  static readonly GetWidgetPath = '/courses/{courseId}/widgets/{widgetId}';

  /**
   * Get widget by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidget()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidget$Response(params: {
    courseId: number;
    widgetId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<WidgetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WidgetControllerService.GetWidgetPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('widgetId', params.widgetId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WidgetResponse>;
      })
    );
  }

  /**
   * Get widget by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWidget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidget(params: {
    courseId: number;
    widgetId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<WidgetResponse> {

    return this.getWidget$Response(params).pipe(
      map((r: StrictHttpResponse<WidgetResponse>) => r.body as WidgetResponse)
    );
  }

  /**
   * Path part for operation modifyWidget
   */
  static readonly ModifyWidgetPath = '/courses/{courseId}/widgets/{widgetId}';

  /**
   * Modify the specified widget.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyWidget()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyWidget$Response(params: {
    courseId: number;
    widgetId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: WidgetModifyRequest
  }): Observable<StrictHttpResponse<WidgetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WidgetControllerService.ModifyWidgetPath, 'put');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('widgetId', params.widgetId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WidgetResponse>;
      })
    );
  }

  /**
   * Modify the specified widget.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifyWidget$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyWidget(params: {
    courseId: number;
    widgetId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: WidgetModifyRequest
  }): Observable<WidgetResponse> {

    return this.modifyWidget$Response(params).pipe(
      map((r: StrictHttpResponse<WidgetResponse>) => r.body as WidgetResponse)
    );
  }

  /**
   * Path part for operation deleteWidget
   */
  static readonly DeleteWidgetPath = '/courses/{courseId}/widgets/{widgetId}';

  /**
   * Delete widget.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWidget()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWidget$Response(params: {
    courseId: number;
    widgetId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WidgetControllerService.DeleteWidgetPath, 'delete');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('widgetId', params.widgetId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete widget.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteWidget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWidget(params: {
    courseId: number;
    widgetId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteWidget$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reorderWidgets
   */
  static readonly ReorderWidgetsPath = '/courses/{courseId}/widgets/reorder';

  /**
   * Reorder widgets of a page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reorderWidgets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reorderWidgets$Response(params: {
    courseId: number;
    pageId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: Array<number>
  }): Observable<StrictHttpResponse<Array<WidgetResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, WidgetControllerService.ReorderWidgetsPath, 'put');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.query('pageId', params.pageId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WidgetResponse>>;
      })
    );
  }

  /**
   * Reorder widgets of a page.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reorderWidgets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reorderWidgets(params: {
    courseId: number;
    pageId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: Array<number>
  }): Observable<Array<WidgetResponse>> {

    return this.reorderWidgets$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WidgetResponse>>) => r.body as Array<WidgetResponse>)
    );
  }

  /**
   * Path part for operation moveNavCard
   */
  static readonly MoveNavCardPath = '/courses/{courseId}/widgets/moveNavCard';

  /**
   * Move navigation card between navigation blocks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveNavCard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveNavCard$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: MoveNavCardRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WidgetControllerService.MoveNavCardPath, 'put');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Move navigation card between navigation blocks.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `moveNavCard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveNavCard(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: MoveNavCardRequest
  }): Observable<void> {

    return this.moveNavCard$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getWidgets
   */
  static readonly GetWidgetsPath = '/courses/{courseId}/widgets';

  /**
   * List all widgets under the specified page ordered by order property.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWidgets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgets$Response(params: {
    courseId: number;
    pageId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<WidgetResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, WidgetControllerService.GetWidgetsPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.query('pageId', params.pageId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WidgetResponse>>;
      })
    );
  }

  /**
   * List all widgets under the specified page ordered by order property.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWidgets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWidgets(params: {
    courseId: number;
    pageId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<WidgetResponse>> {

    return this.getWidgets$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WidgetResponse>>) => r.body as Array<WidgetResponse>)
    );
  }

  /**
   * Path part for operation createWidget
   */
  static readonly CreateWidgetPath = '/courses/{courseId}/widgets';

  /**
   * Create widget for page. If order is null, widget will be inserted as the last one, else to the specified order.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWidget()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWidget$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: WidgetCreateRequest
  }): Observable<StrictHttpResponse<WidgetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WidgetControllerService.CreateWidgetPath, 'post');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WidgetResponse>;
      })
    );
  }

  /**
   * Create widget for page. If order is null, widget will be inserted as the last one, else to the specified order.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createWidget$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWidget(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: WidgetCreateRequest
  }): Observable<WidgetResponse> {

    return this.createWidget$Response(params).pipe(
      map((r: StrictHttpResponse<WidgetResponse>) => r.body as WidgetResponse)
    );
  }

  /**
   * Path part for operation getAnchors
   */
  static readonly GetAnchorsPath = '/courses/{courseId}/widgets/anchors';

  /**
   * List all anchor widgets.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnchors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnchors$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<WidgetResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, WidgetControllerService.GetAnchorsPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WidgetResponse>>;
      })
    );
  }

  /**
   * List all anchor widgets.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAnchors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnchors(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<WidgetResponse>> {

    return this.getAnchors$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WidgetResponse>>) => r.body as Array<WidgetResponse>)
    );
  }

}
