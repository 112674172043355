export interface BaseRealTimeEmbededWidgetForm {
    description?: string;
    title?: string;
}

export interface BaseDetailEmbededWidgetForm extends BaseRealTimeEmbededWidgetForm {
    fulfillmentAvailable?: boolean;
    fulfillment?: 'NONE' | 'SHOW';
}

export function EmbededRealTimeFormFactory<T extends BaseRealTimeEmbededWidgetForm>(
    data: T
): BaseRealTimeEmbededWidgetForm {
    return {
        description: data?.description ?? null,
        title: data?.title ?? null,
    };
}

export function EmbededDetailFormFactory<T extends BaseDetailEmbededWidgetForm>(data: T): BaseDetailEmbededWidgetForm {
    return {
        ...EmbededRealTimeFormFactory<T>(data),
        fulfillmentAvailable: data?.fulfillmentAvailable ?? false,
        fulfillment: data?.fulfillment ?? (data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
    };
}
