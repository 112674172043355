import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { toastAnimations } from './animations';

export type TranslatedMessage = string;

export interface IToastMessage {
    component?: ToastMessageComponent;
    translatedMessage?: TranslatedMessage;
    message?: string;
    type: 'SUCCESS' | 'INFO' | 'ERROR' | 'WARNING';
    timeout?: number;
    closeable?: boolean;
    link?: string;
    fragment?: string;
}

@Component({
    selector: 'app-toast-message',
    templateUrl: './toast-message.component.html',
    styleUrls: ['./toast-message.component.scss'],
    animations: [toastAnimations],
})
export class ToastMessageComponent {
    @Input() closeable = false;
    @Input() message: string;
    @Input() translatedMessage: string;
    @Input() link: string;
    @Input() fragment: string;
    @Output() remove = new EventEmitter();
    @HostBinding('attr.class') ownClass: string;
    @HostBinding('@visibleAnimation') animation;

    @Input() set type(val: 'SUCCESS' | 'INFO' | 'ERROR' | 'WARNING') {
        this.ownClass = val.toLowerCase();
    }

    @Input() set timeout(val: number) {
        if (val) {
            setTimeout(() => {
                this.remove.emit();
            }, val);
        }
    }

    closeToastMessage(): void {
        this.remove.emit();
    }
}
