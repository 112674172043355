/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Quill from 'quill';
const Inline = Quill.import('blots/inline');

export class CustomClozeBlot extends Inline {
    static create(value) {
        const node = super.create();
        node.setAttribute('data-value', value);
        return node;
    }

    static formats(node) {
        const data = node.getAttribute('data-value');
        return data;
    }
}
CustomClozeBlot.blotName = 'gap';
CustomClozeBlot.tagName = 'cq';
