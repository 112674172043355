<form [formGroup]="form">
    <header class="detail-form__header" [ngClass]="sizeClass">
        <div class="detail-form__header__action-buttons">
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.MARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.MARGIN)"
            >
                <span class="nitroicon-block_narrow editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.NOMARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.NOMARGIN)"
            >
                <span class="nitroicon-block_mid editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.FULLSIZE }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.FULLSIZE)"
            >
                <span class="nitroicon-block_fullsize editor-btn-icon"></span>
            </button>
        </div>
    </header>

    <ng-template #realTimeElement>
        <div class="iframe-empty-state" *ngIf="!src" data-e2e="youtube-widget">
            <img src="assets/images/youtube_empty_state.svg" alt="" />
            <div class="iframe-empty-state__text">
                <span>
                    Hogy a tananyagban meg tudjuk jeleníteni a tartalmat <br />
                    érvényes URL-re van szükségünk.</span
                >
            </div>
            <button class="template-btn section-btn">URL megadása</button>
        </div>
        <div class="youtube-player" *ngIf="src" data-e2e="youtube-widget">
            <iframe
                width="700"
                height="1000"
                [src]="src | safeResourceUrl"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </div>
    </ng-template>

    <ng-container *ngIf="size === widgetSizeEnum.FULLSIZE">
        <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
    </ng-container>

    <section class="detail-form resizable" [ngClass]="sizeClass">
        <div class="detail-form__site-left">
            <ng-container *ngIf="size !== widgetSizeEnum.FULLSIZE">
                <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
            </ng-container>
            <app-collapse-card [title]="'IMAGE-WIDGET.ACCESSIBILTY-CARD' | translate" [opened]="true">
                <div class="detail-form__group">
                    <label class="detail-form__group__label" for="description"
                        >{{ 'EMBEDED-WIDGET.ACCESSIBILTY' | translate }}
                    </label>
                    <textarea name="description" id="description" formControlName="description"></textarea>
                </div>
            </app-collapse-card>
        </div>

        <div class="detail-form__site-right">
            <app-collapse-card [title]="'EMBEDED-WIDGET.SETTINGS' | translate" [opened]="true">
                <ng-container *ngTemplateOutlet="youtubeUrl"></ng-container>
                <fieldset class="detail-form__group">
                    <label class="detail-form__group__label" for="title">
                        {{ 'EMBEDED-WIDGET.TITLE' | translate }}
                    </label>
                    <input
                        name="title"
                        class="detail-form__input"
                        id="title"
                        [placeholder]="'EMBEDED-WIDGET.TITLE-PLACEHOLDER' | translate"
                        formControlName="title"
                    />
                </fieldset>
            </app-collapse-card>
            <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'EMBEDED-WIDGET.FULLFILMENT' | translate"
                    [options]="fulfillmentAvailableOptions"
                    formControlName="fulfillmentAvailable"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                    [options]="fulfillmentOptions"
                    formControlName="fulfillment"
                ></app-radio-group-control>
            </app-collapse-card>
        </div>
    </section>
</form>

<ng-template #youtubeUrl>
    <fieldset class="detail-form__group" [formGroup]="urlForm">
        <label class="detail-form__group__label" for="src"> {{ 'EMBEDED-WIDGET.LINK' | translate }} </label>
        <input
            name="src"
            class="detail-form__input"
            id="src"
            [placeholder]="'EMBEDED-WIDGET.LINK-PLACEHOLDER' | translate"
            formControlName="src"
        />
    </fieldset>
</ng-template>
