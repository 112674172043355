<button
    class="editor-btn height-small radius-25 border-0 primary icon-only"
    [matMenuTriggerFor]="menu"
    aria-label="menu"
>
    <span class="nitroicon-courses editor-btn-icon header-icon"></span>
</button>
<a class="logo" [routerLink]="['/courses-list']">
    <img src="./assets/images/nitro_learning_logo_courze_cloud_black.svg" alt="" />
</a>
<mat-menu #menu="matMenu">
    <ng-container *ngFor="let item of menuList; let i = index; trackBy: trackByFn">
        <a mat-menu-item [href]="'/exam-admin/'">
            <img src="./assets/images/cc_exams_logo.svg" alt="" />
        </a>
    </ng-container>
</mat-menu>
