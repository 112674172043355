import { IFileUploadValue } from '@shared/models/file-upload.model';
import { DocumentWidgetData } from 'src/app/api/models/document-widget-data';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';

export interface RealTimeDocumentWidgetForm {
    element: IFileUploadValue;
    align: 'LEFT' | 'CENTER' | 'RIGHT';
    title: string | null;
}

export interface DetailDocumentWidgetForm {
    realTimeDocumentWidgetFormData: RealTimeDocumentWidgetForm;
    description: string | null;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
}

export interface DocumentWidgetDataSpec extends DocumentWidgetData {
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
}

export class DocumentWidgetClass extends BaseWidgetClass<DocumentWidgetDataSpec> {
    constructor(data: TypedResponse<DocumentWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeDocumentWidgetForm {
        return {
            align: this.data?.align ?? 'LEFT',
            title: this.data?.title ?? null,
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data.documentResourceId ?? null,
            },
        };
    }

    getDetailFormData(): DetailDocumentWidgetForm {
        return {
            realTimeDocumentWidgetFormData: this.getRealTimeFormData(),
            description: this.data?.description ?? null,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
        };
    }

    updateWidgetData(modifiedData: RealTimeDocumentWidgetForm | DetailDocumentWidgetForm): void {
        if (modifiedData.hasOwnProperty('realTimeDocumentWidgetFormData')) {
            modifiedData = {
                ...modifiedData,
                ...(modifiedData as DetailDocumentWidgetForm).realTimeDocumentWidgetFormData,
            };
        }
        // @ts-ignore
        const { element, ...rest } = modifiedData;

        if (this.shouldSaveFile(element.fileItem, this.data.documentResourceId)) {
            this.filesToSave.push(element.fileItem);
        }
        this.fileUploadElement = element.fileItem;
        const documentResourceId = element.fileItem ? element.fileItem.uuid : element.elementId;
        const originalDocumentName = documentResourceId
            ? this.fileUploadElement
                ? this.fileUploadElement.name
                : this?.data?.originalDocumentName
            : null;
        this.data = { ...this.data, ...rest, documentResourceId, originalDocumentName };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.documentResourceId === id) {
                this.data = { ...this.data, documentResourceId: null };
                this.fileUploadElement = null;
            }
        });
    }
}
