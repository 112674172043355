/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CarouselImage, CarouselWidgetData } from '@apiModels';
import { IFileUploadValue } from '@shared/models/file-upload.model';

import { BaseSubFormClass } from './base-widget-models/base-sub-form-class';
import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';

const RatioMapper = {
    16: { x: 16, y: 9 },
    4: { x: 4, y: 3 },
    1: { x: 1, y: 1 },
    3: { x: 3, y: 4 },
};

export interface RealTimeCarouselWidgetForm {
    title: string;
    images: ICarouselImageFormData[];
    xPosition: number;
    zoomable: boolean;
    pptResourceId: string;
}

export interface DetailCarouselWidgetForm {
    realTimeCarouselWidgetForm: RealTimeCarouselWidgetForm;
    xPosition: number;
    zoomable: boolean;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW';
}

export interface CarouselWidgetDataSpec extends CarouselWidgetData {
    fulfillment: 'NONE' | 'SHOW';
}

export type UnionCarouselDataType = Partial<RealTimeCarouselWidgetForm & DetailCarouselWidgetForm>;

export class CarouselWidgetClass extends BaseWidgetClass<CarouselWidgetDataSpec> {
    localCards: CarouselImageClass[];
    constructor(data: TypedResponse<CarouselWidgetDataSpec>) {
        super(data);
        this.localCards = this.data?.images?.map((c) => new CarouselImageClass({ ...c })) ?? [];
    }

    getRealTimeFormData(): RealTimeCarouselWidgetForm {
        return {
            title: this.data?.title ?? '',
            images: this.localCards.map((c) => c.getFormData()) ?? [],
            xPosition: this.data?.ratio ? this.data?.ratio?.x : 4,
            zoomable: this.data?.zoomable ?? false,
            pptResourceId: this.data?.pptResourceId ?? null,
        };
    }

    getDetailFormData(): DetailCarouselWidgetForm {
        return {
            realTimeCarouselWidgetForm: this.getRealTimeFormData(),
            xPosition: this.data?.ratio ? this.data?.ratio?.x : 4,
            zoomable: this.data?.zoomable ?? false,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? 'SHOW',
        };
    }

    updateWidgetData(modifiedData: RealTimeCarouselWidgetForm | DetailCarouselWidgetForm): void {
        let newData: UnionCarouselDataType = modifiedData;
        const detailForm = modifiedData.hasOwnProperty('realTimeCarouselWidgetForm');
        if (detailForm) {
            const { xPosition, zoomable, ...rest } = (modifiedData as DetailCarouselWidgetForm)
                .realTimeCarouselWidgetForm;
            newData = {
                ...modifiedData,
                ...rest,
            };
        }
        const { images, xPosition, pptResourceId, ...attrs } = newData;

        const tempimages: CarouselImageClass[] = images.map((elem: ICarouselImageFormData, i: number) => {
            const existing =
                elem.resourceImageElement?.fileItem === null
                    ? false
                    : this.localCards.find((item) => item.data.resourceId === elem.resourceImageElement.fileItem.uuid);
            if (existing) {
                existing.updateData(elem);
                return existing;
            }
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { resourceImageElement, ...rest } = elem;

            // eslint-disable-next-line @typescript-eslint/no-shadow
            const resourceId = this.extractUUID(resourceImageElement);
            if (elem.resourceImageElement.fileItem) {
                this.filesToSave.push(elem.resourceImageElement.fileItem);
            }
            return new CarouselImageClass({
                resourceId,
                ...rest,
            });
        });
        this.localCards = [...tempimages];
        const mappedImages = this.localCards.map((c) => c.getDataForSave());
        const tempRatio = RatioMapper[xPosition] ?? this.data.ratio;
        this.data = { ...this.data, ...attrs, images: mappedImages as any, ratio: tempRatio };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            this.localCards = this.localCards.filter((cardElem) => cardElem.data.resourceId !== id);
        });
        const images = this.localCards.map((c) => c.getDataForSave());
        this.data = { ...this.data, images };
    }
}

export interface ICarouselImageFormData {
    caption: string;
    description: string;
    resourceImageElement: IFileUploadValue;
}

export class CarouselImageClass extends BaseSubFormClass<CarouselImage, ICarouselImageFormData> {
    constructor(data: CarouselImage) {
        super(data);
    }
    getFormData(): ICarouselImageFormData {
        return {
            caption: this.data?.caption ?? null,
            description: this.data?.description ?? null,
            resourceImageElement: {
                fileItem: this.fileUploadElement,
                elementId: this.data?.resourceId ?? null,
            },
        };
    }

    updateData(modifiedData: ICarouselImageFormData): void {
        const { resourceImageElement, ...rest } = modifiedData;
        this.fileUploadElement = resourceImageElement.fileItem;
        const resourceId = this.extractUUID(resourceImageElement);
        this.data = { ...this.data, ...rest, resourceId };
    }
}
