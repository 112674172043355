export class NavigationBlockDeleteData {
    titlePath?: string;
    messagePath?: string;
    primaryButtonTextPath?: string;
    secondaryButtonTextPath?: string;

    constructor(data: any) {
        Object.assign(this, data);
    }
}
