import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LoaderComponent } from './loader.component';
import { LoaderDirective } from './models/loader.directive';
import { Loader, LoaderType } from './models/loader.model';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    @ViewChild(LoaderDirective, { static: true })
    loaderHost: LoaderDirective;
    lastXhrNumber = 0;
    loaderComponent: ComponentRef<LoaderComponent>;

    public loader: { component: ComponentRef<LoaderComponent>; element: HTMLElement }[] = [];

    public commonLoader: BehaviorSubject<Loader> = new BehaviorSubject<Loader>(new Loader(0, 0, null));

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef
    ) {
        this.commonLoader.subscribe((loader: Loader) => {
            setTimeout(() => {
                if (this.lastXhrNumber === 0 && loader.xhrNumber > 0) {
                    this.showLoader(loader.type);
                }
                if (this.lastXhrNumber > 0 && loader.xhrNumber === 0) {
                    this.hideLoader();
                }
                this.lastXhrNumber = loader.xhrNumber;
            }, loader.timeout);
        });
    }

    public incrementXhrNumber(timeout?: number, type?: 'COPY' | null): void {
        const loaderModel = new Loader(this.commonLoader.value.xhrNumber + 1, timeout, type);
        this.commonLoader.next(loaderModel);
    }

    public decrementXhrNumber(): void {
        const loaderModel = new Loader(Math.max(0, this.commonLoader.value.xhrNumber - 1), 0, null);
        this.commonLoader.next(loaderModel);
    }

    public showLoader(type: LoaderType = null): void {
        const loaderComponentFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
        this.loaderComponent = loaderComponentFactory.create(this.injector);
        this.loaderComponent.instance.type = type;
        this.create(this.loaderComponent);
    }

    private create(loaderComponent: ComponentRef<LoaderComponent>): void {
        this.appRef.attachView(loaderComponent.hostView);
        const domElem = (loaderComponent.hostView as EmbeddedViewRef<LoaderComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
    }

    public hideLoader(): void {
        this.appRef.detachView(this.loaderComponent.hostView);
        this.loaderComponent.destroy();
    }
}
