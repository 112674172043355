import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import en from '../../../assets/i18n/app/en.json';
import hu from '../../../assets/i18n/app/hu.json';
import de from '../../../assets/i18n/app/de.json';

@Injectable({ providedIn: 'root' })
export class LanguageWrapperService {
    private selectedLanguage: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _selectedLanguageSub = new BehaviorSubject<string>('hu');
    public selectedLanguageSub$ = this._selectedLanguageSub.asObservable();

    constructor(
        private translate: TranslateService,
        @Inject(DOCUMENT) private document: Document,
        private titleSrv: Title
    ) {}

    get SelectedLang(): string {
        return this.selectedLanguage;
    }

    init(languages: string[]): void {
        this.selectedLanguage = languages.length ? languages[0] : 'hu';
        this._selectedLanguageSub.next(this.selectedLanguage);
        this.translate.addLangs(['hu', 'en', 'de']);
        this.document.documentElement.lang = this.selectedLanguage;

        this.translate.setDefaultLang(this.selectedLanguage);
        this.setTranslate(this.selectedLanguage);
    }

    setLang(language: string): void {
        this.selectedLanguage = language;
        this._selectedLanguageSub.next(this.selectedLanguage);
        this.document.documentElement.lang = this.selectedLanguage;
        this.setTranslate(language);
    }

    setTranslate(language: string): void {
        this.translate.use(this.selectedLanguage);
        const languageFile = ((language) => {
            switch (language) {
                case 'hu':
                    return hu;
                case 'en':
                    return en;
                case 'de':
                    return de;
                default:
                    return en;
            }
        })(language);
        // betöltjük az angolt alap rétegnek, aztán rámergeljük ami kell, hogy az angol legyen a fallback
        this.translate.setTranslation(this.selectedLanguage, en, false);
        this.translate.setTranslation(this.selectedLanguage, languageFile, true);

        const title = this.translate.instant('COMMON.TITLE') as string;
        this.titleSrv.setTitle(title);
    }
}
