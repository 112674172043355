/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
export class TooltipData {
    text: string; // -> data-tip
    placement: string; // -> data-place
}

@Component({
    selector: 'app-tooltip-editor',
    templateUrl: './tooltip-editor.component.html',
    styleUrls: ['./tooltip-editor.component.scss'],
})
export class TooltipEditorComponent implements AfterViewInit {
    @Input() data: TooltipData;
    @Input() position: { [key: string]: number };
    @Output() done = new EventEmitter<TooltipData>();
    @Output() delete = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @HostBinding('style.top.px') top = 0;
    @HostBinding('style.left.px') left = 0;
    @ViewChild('tooltipTextarea', { static: true }) tooltipTextarea: ElementRef;

    constructor(private selfRef: ElementRef) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.transformAlignment(this.data.placement);
        });
    }

    transformAlignment(placement: string): void {
        this.data.placement = placement;
        const { offsetWidth, offsetHeight } = this.selfRef.nativeElement;
        const ownWidth = offsetWidth as number;
        const ownHeight = offsetHeight as number;
        switch (this.data.placement) {
            case 'top':
                this.top = this.position.top - 8 - ownHeight;
                this.left = this.position.right - ownWidth;
                break;
            case 'bottom':
                this.top = this.position.bottom + 8;
                this.left = this.position.left;
                break;
            case 'left':
                this.top = this.position.top - 53;
                this.left = this.position.left - ownWidth - 10;
                break;
            case 'right':
                this.top = this.position.top - 53;
                this.left = this.position.right + 10;
                break;
        }
        this.tooltipTextarea.nativeElement.focus();
    }

    onSaveTooltip($event: FocusEvent): void {
        if ($event.relatedTarget instanceof Element && $event.relatedTarget.closest('[data-prevent-autosave]')) {
            return;
        }
        this.done.emit(this.data);
    }

    onDelete(): void {
        this.delete.emit();
    }
}
