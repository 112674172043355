import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from '@shared/components/info-modal/info-modal.component';

import { DomHandlerService } from './dom-handler.service';

@Injectable({ providedIn: 'root' })
export class FullPageModalGuardService {
    constructor(private dialog: MatDialog, private domSrv: DomHandlerService) {}

    canDeactivate(): boolean {
        if (this.domSrv.hasFullPageModalOpen()) {
            this.openModal();
            return false;
        }
        return true;
    }

    private openModal(): void {
        const dialogRef = this.dialog.open(InfoModalComponent, {
            data: {
                titlePath: 'MATERIAL.DIALOG.FULL-PAGE-TITLE',
                messagePath: 'MATERIAL.DIALOG.FULL-PAGE-TEXT',
                primaryButtonTextPath: 'MATERIAL.DIALOG.ACCEPT',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {});
    }
}
