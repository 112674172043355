<form [formGroup]="form" data-e2e="recommendation-widget-card">
    <app-text-and-card-recommender
        [form]="form"
        [config]="config"
        (linkEditorRequested)="openPageLinkHandler()"
    ></app-text-and-card-recommender>
</form>

<ng-container *ngIf="editorLocation === 'real-time'">
    <app-exported-template-actions [selector]="'#extra-action-container_' + config?.focusElemId" *ngIf="!testEnv">
        <button class="editor-btn section-btn ghost height-small icon-only" (click)="openPageLinkHandler()">
            <span class="nitroicon-link editor-btn-icon"></span>
        </button>
    </app-exported-template-actions>
</ng-container>
