<section class="file-upload-container" *ngIf="fileUploadList">
    <div class="file-upload-container__header" [class.card-not-opened]="!opened">
        <span class="nitroicon-info"></span>
        <label>{{ fileUploadList.length }} db fájl feltöltése folyamatban.</label>
        <button
            (click)="opened = !opened"
            class="editor-btn ghost height-extra-small icon-only radius-25 collapse-icon"
            type="button"
        >
            <span class="nitroicon-arrow_right editor-btn-icon"></span>
        </button>
        <button
            (click)="cancelAllTheFileUploads()"
            class="editor-btn ghost height-extra-small icon-only radius-25"
            type="button"
        >
            <span class="nitroicon-close editor-btn-icon"></span>
        </button>
    </div>
    <app-collapse class="collapse-card" *ngIf="opened">
        <ul class="file-upload__list">
            <li class="file-upload__list-elem" *ngFor="let fileUploadElement of fileUploadList">
                <div
                    class="progress"
                    *ngIf="fileUploadElement.type !== 'ERROR'"
                    role="progressbar"
                    [attr.aria-valuenow]="fileUploadElement.progress ?? 0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                >
                    {{ fileUploadElement.progress }}%
                </div>
                <p>{{ fileUploadElement.fileName }}</p>
                <a
                    class="resource-error-link"
                    *ngIf="fileUploadElement.type === 'ERROR' && fileUploadElement.link"
                    [routerLink]="fileUploadElement.link"
                    [fragment]="fileUploadElement.fragment"
                >
                    {{ fileUploadElement.fileName }}
                </a>
                <button
                    (click)="cancelUpload(fileUploadElement.id, fileUploadElement)"
                    class="editor-btn ghost height-extra-small icon-only radius-25 collapse-icon"
                    type="button"
                >
                    <span class="nitroicon-close editor-btn-icon"></span>
                </button>
            </li>
        </ul>
    </app-collapse>
</section>
