import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoaderForComponentsService } from '@shared/components/loader-for-components/loader-for-components.service';
import {
    ComponentLoaderType,
    LoaderForComponentsModel,
} from '@shared/components/loader-for-components/loader-for-components.model';

import { Subscription } from 'rxjs';
import { loaderAnimations } from '../loader/animations';

@Component({
    selector: 'app-loader-for-components',
    templateUrl: './loader-for-components.component.html',
    styleUrls: ['./loader-for-components.component.scss'],
    animations: [loaderAnimations],
})
export class LoaderForComponentsComponent implements OnInit, OnDestroy {
    @Input() id: string | number = 'global';
    show: boolean;
    subscription = new Subscription();
    type: ComponentLoaderType = 'DEFAULT';

    constructor(private loaderService: LoaderForComponentsService, private cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.loaderService.loaderStatus$.subscribe((response: LoaderForComponentsModel) => {
                this.show = String(this.id) === response.id && response.status;
                this.type = response.type;
                this.cdr.markForCheck();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
