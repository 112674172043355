import { Component } from '@angular/core';

@Component({
    selector: 'app-application-selector',
    templateUrl: './application-selector.component.html',
    styleUrls: ['./application-selector.component.scss'],
})
export class ApplicationSelectorComponent {
    menuList = ['1'];

    trackByFn(index: number): number {
        return index;
    }
}
