import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'formattedDate',
})
export class FormattedDatePipe implements PipeTransform {
    constructor(private translateSrv: TranslateService) {}

    transform(
        value: Date | string | undefined,
        selectedLang = 'hu',
        needHours = true,
        referenceTime?: string
    ): string | null | undefined {
        if (!value) {
            return 'Nincs adat';
        }
        if (typeof value === 'string') {
            value = new Date(value);
        }
        const refDate = referenceTime ? new Date(referenceTime) : new Date();
        const diff = (refDate.getTime() - value.getTime()) / 1000;

        if (isNaN(diff) || diff < -10) {
            return '';
        } else if (diff < 86400) {
            return this.convertToFormattedDate(diff);
        } else {
            const format = selectedLang === 'hu' ? 'hu-HU' : 'en-EN';
            const datePipe = new DatePipe(format);
            const dateString = needHours ? 'yyyy.MM.dd - HH:mm' : 'yyyy.MM.dd';
            return datePipe.transform(value, dateString);
        }
    }

    convertToFormattedDate(diff: number): string {
        switch (true) {
            case diff < 60:
                return this.translateSrv.instant('DATE_MESSAGE.NOW');
            case diff < 3600:
                return this.translateSrv.instant('DATE_MESSAGE.MINUTES', { minute: Math.floor(diff / 60) });
            default:
                return this.translateSrv.instant('DATE_MESSAGE.HOURS', { hour: Math.floor(diff / 3600) });
        }
    }
}
