import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { startWith } from 'rxjs';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { DetailEmbeddedDocumentWidgetForm } from 'src/app/api-wrapper/models/widget-models/embeded-widgets/embedded-document-model';
import { CourseEditorHelpers } from 'src/app/course-editor/helpers/fulfillment.helper';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

@Component({
    selector: 'app-embedded-document-detail-widget',
    templateUrl: './embedded-document-detail-widget.component.html',
    styleUrls: ['./embedded-document-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmbeddedDocumentDetailWidgetComponent),
            multi: true,
        },
    ],
})
export class EmbeddedDocumentDetailWidgetComponent
    extends AbstractFormBaseComponent<DetailEmbeddedDocumentWidgetForm, IWidgetSupport>
    implements OnInit
{
    fulfillmentAvailableOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-available-option-true', label: 'FORM-CONTROLS.YES', value: true },
        { id: 'fulfillment-available-option-false', label: 'FORM-CONTROLS.NO', value: false },
    ];

    fulfillmentOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-option-show', label: 'FORM-CONTROLS.SHOW', value: 'SHOW', group: 'LAX' },
        { id: 'fulfillment-option-none', label: '', value: 'NONE', hidden: true, group: 'NONE' },
    ];
    testEnv = false;
    height = '394';
    regExp = new RegExp(/\.(pdf)$/i);
    widgetSizeEnum = WidgetSizeEnum;
    size: WidgetSizeEnum;
    sizeClass: string;
    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initDisabledFields();
        this.initHeightChange();
        this.initSizeChange();
    }

    createForm(): FormGroupTyped<DetailEmbeddedDocumentWidgetForm> {
        return new FormGroupTyped<DetailEmbeddedDocumentWidgetForm>({
            description: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            height: FormHelper.controlFactoryWithCalculatedValue(null),
            title: FormHelper.controlFactoryWithCalculatedValue(null),
            size: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    initSizeChange(): void {
        this.subscriptions.add(
            this.form.controls.size.valueChanges
                .pipe(startWith(this.form.get('size').value as WidgetSizeEnum))
                .subscribe((size: WidgetSizeEnum) => {
                    if (this.config?.starterWidgetSize) {
                        this.config.starterWidgetSize = size;
                    }
                    this.size = size;
                    this.sizeClass = size === 'ORIGINAL' ? 'margin oriinal' : size?.toLowerCase();
                })
        );
    }

    initHeightChange(): void {
        this.subscriptions.add(
            this.form
                .get('height')
                .valueChanges.pipe(startWith(this.form.get('height').value as string))
                .subscribe((height: string) => (this.height = height || '394'))
        );
    }

    initDisabledFields(): void {
        const value = this.form.get('fulfillmentAvailable').value as boolean;
        if (!value) {
            this.form.get('fulfillment').disable({ emitEvent: false });
        }
        this.subscriptions.add(
            this.form.get('fulfillmentAvailable').valueChanges.subscribe((fulfillmentAvailable: boolean) => {
                CourseEditorHelpers.handleFulfillment(
                    fulfillmentAvailable,
                    this.form,
                    this.config.fulfillmentSettings.fulfillmentLevel,
                    this.fulfillmentOptions
                );
            })
        );
    }

    setWidgetSize(size: WidgetSizeEnum): void {
        this.form.patchValue({ size });
    }
}
