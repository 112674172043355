import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WindowEventService {
    scrollEvent(): Observable<number> {
        return fromEvent(window, 'scroll').pipe(
            debounceTime(40),
            distinctUntilChanged(),
            map(() => window.scrollY)
        );
    }

    clickEvent(): Observable<Event> {
        return fromEvent(window, 'click');
    }

    mousedownEvent(): Observable<Event> {
        return fromEvent(window, 'mousedown');
    }

    beforeUnloadEvent(): Observable<Event> {
        return fromEvent(window, 'beforeunload');
    }
}
