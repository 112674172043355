import { ClozeGapText } from '@apiModels';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';

import { BaseSubFormClass } from '../../base-widget-models/base-sub-form-class';

export class ClozeGapTextModel extends BaseSubFormClass<ClozeGapText, ClozeGapText> {
    constructor(data: ClozeGapText) {
        super(data);

        if (!this.data.gapId) {
            this.data.gapId = UUIDGeneratorUtility.generate();
        }
    }

    getFormData(): ClozeGapText {
        return {
            gapId: this.data?.gapId ?? null,
            type: this.data.type,
            text: this.data?.text ?? null,
        };
    }

    updateData(modifiedData: ClozeGapText): void {
        this.data = { ...this.data, ...modifiedData };
    }
}
