<form [formGroup]="form" class="detail-form">
    <div class="detail-form__site-left">
        <app-document-real-time-widget
            *ngIf="!testEnv"
            class="detail-form__real-time-widget"
            [formControlName]="'realTimeDocumentWidgetFormData'"
            [config]="config"
            [editorLocation]="'detail'"
            [data]="data.realTimeDocumentWidgetFormData"
        >
            <fieldset class="detail-form__group">
                <label class="detail-form__group__label" for="document-widget-description">
                    {{ 'DOCUMENT-WIDGET.DOCUMENT-ACCESSIBILTY-LABEL' | translate }}
                </label>
                <textarea
                    name="document-widget-description"
                    id="document-widget-description"
                    [placeholder]="'DOCUMENT-WIDGET.DOCUMENT-ACCESSIBILTY-PLACEHOLDER' | translate"
                    formControlName="description"
                ></textarea>
            </fieldset>
        </app-document-real-time-widget>
    </div>

    <div class="detail-form__site-right">
        <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
            <app-radio-group-control
                class="detail-form__group"
                [title]="'DOCUMENT-WIDGET.FULFILLMENT-AVAILABLE-TITLE' | translate"
                [options]="fulfillmentAvailableOptions"
                formControlName="fulfillmentAvailable"
            ></app-radio-group-control>

            <app-radio-group-control
                class="detail-form__group"
                [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                [options]="fulfillmentOptions"
                formControlName="fulfillment"
            ></app-radio-group-control>
        </app-collapse-card>
    </div>
</form>
