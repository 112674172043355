<div class="iframe-empty-state" *ngIf="!src" data-e2e="iframe-widget">
    <img src="assets/images/iframe-widget-empty-state.svg" alt="" />
    <div class="iframe-empty-state__text">
        <span>
            Hogy a tananyagban meg tudjuk jeleníteni a tartalmat <br />
            érvényes URL-re van szükségünk.</span
        >
    </div>
    <button class="template-btn section-btn">URL megadása</button>
</div>

<div class="iframe-container" *ngIf="src" data-e2e="iframe-widget">
    <iframe
        sandbox="allow-forms allow-scripts allow-same-origin allow-pointer-lock allow-popups"
        frameborder="0"
        allowfullscreen
        [src]="src | safeResourceUrl"
        [title]="title"
        [height]="height"
        class="iframe-container__iframe"
    ></iframe>
</div>
