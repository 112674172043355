import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { RealTimeIframeWidgetForm } from 'src/app/api-wrapper/models/widget-models/embeded-widgets/iframe-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

@Component({
    selector: 'app-iframe-real-time-widget',
    templateUrl: './iframe-real-time-widget.component.html',
    styleUrls: ['./iframe-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IframeRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class IframeRealTimeWidgetComponent extends AbstractFormBaseComponent<RealTimeIframeWidgetForm, IWidgetSupport> {
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    src: string;
    height: string;
    title: string;
    constructor() {
        super();
    }

    updateForm(data: RealTimeIframeWidgetForm): void {
        super.updateForm(data);
        this.src = data.src ?? null;
        this.height = data.height ?? '394';
        this.title = data.title ?? '';
    }

    createForm(): FormGroupTyped<RealTimeIframeWidgetForm> {
        return new FormGroupTyped<RealTimeIframeWidgetForm>({
            src: FormHelper.controlFactoryWithCalculatedValue(null),
            description: FormHelper.controlFactoryWithCalculatedValue(null),
            height: FormHelper.controlFactoryWithCalculatedValue(null),
            title: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
