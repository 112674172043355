/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CourseVersionResponse } from '../models/course-version-response';
import { ThemeRequest } from '../models/theme-request';
import { ThemeResponse } from '../models/theme-response';

@Injectable({
  providedIn: 'root',
})
export class ThemeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation modifiyTheme
   */
  static readonly ModifiyThemePath = '/themes/{id}';

  /**
   * Change theme name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifiyTheme()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifiyTheme$Response(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: ThemeRequest
  }): Observable<StrictHttpResponse<ThemeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThemeControllerService.ModifiyThemePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThemeResponse>;
      })
    );
  }

  /**
   * Change theme name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifiyTheme$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifiyTheme(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: ThemeRequest
  }): Observable<ThemeResponse> {

    return this.modifiyTheme$Response(params).pipe(
      map((r: StrictHttpResponse<ThemeResponse>) => r.body as ThemeResponse)
    );
  }

  /**
   * Path part for operation deleteTheme
   */
  static readonly DeleteThemePath = '/themes/{id}';

  /**
   * Delete theme.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTheme()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTheme$Response(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ThemeControllerService.DeleteThemePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete theme.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTheme$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTheme(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteTheme$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getMyThemes
   */
  static readonly GetMyThemesPath = '/themes';

  /**
   * List all themes of logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyThemes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyThemes$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<ThemeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ThemeControllerService.GetMyThemesPath, 'get');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ThemeResponse>>;
      })
    );
  }

  /**
   * List all themes of logged in user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyThemes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyThemes(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<ThemeResponse>> {

    return this.getMyThemes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ThemeResponse>>) => r.body as Array<ThemeResponse>)
    );
  }

  /**
   * Path part for operation createTheme
   */
  static readonly CreateThemePath = '/themes';

  /**
   * Create a theme for current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTheme()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTheme$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: ThemeRequest
  }): Observable<StrictHttpResponse<ThemeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ThemeControllerService.CreateThemePath, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThemeResponse>;
      })
    );
  }

  /**
   * Create a theme for current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTheme$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTheme(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: ThemeRequest
  }): Observable<ThemeResponse> {

    return this.createTheme$Response(params).pipe(
      map((r: StrictHttpResponse<ThemeResponse>) => r.body as ThemeResponse)
    );
  }

  /**
   * Path part for operation importFromZip
   */
  static readonly ImportFromZipPath = '/themes/import/for-course/{courseId}';

  /**
   * Import a theme from zip.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importFromZip()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importFromZip$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ThemeControllerService.ImportFromZipPath, 'post');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Import a theme from zip.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importFromZip$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importFromZip(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<void> {

    return this.importFromZip$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation importThemeResources
   */
  static readonly ImportThemeResourcesPath = '/themes/import-resource/{courseId}/{globalThemeId}';

  /**
   * Import resources from global theme.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importThemeResources()` instead.
   *
   * This method doesn't expect any request body.
   */
  importThemeResources$Response(params: {
    courseId: number;
    globalThemeId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ThemeControllerService.ImportThemeResourcesPath, 'post');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('globalThemeId', params.globalThemeId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Import resources from global theme.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importThemeResources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importThemeResources(params: {
    courseId: number;
    globalThemeId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.importThemeResources$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getThemeVersions
   */
  static readonly GetThemeVersionsPath = '/themes/theme-versions';

  /**
   * Get all available themes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getThemeVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThemeVersions$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<CourseVersionResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ThemeControllerService.GetThemeVersionsPath, 'get');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CourseVersionResponse>>;
      })
    );
  }

  /**
   * Get all available themes.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getThemeVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThemeVersions(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<CourseVersionResponse>> {

    return this.getThemeVersions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CourseVersionResponse>>) => r.body as Array<CourseVersionResponse>)
    );
  }

  /**
   * Path part for operation getGlobalThemes
   */
  static readonly GetGlobalThemesPath = '/themes/global';

  /**
   * List global themes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalThemes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalThemes$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<ThemeResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ThemeControllerService.GetGlobalThemesPath, 'get');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ThemeResponse>>;
      })
    );
  }

  /**
   * List global themes.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGlobalThemes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalThemes(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<ThemeResponse>> {

    return this.getGlobalThemes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ThemeResponse>>) => r.body as Array<ThemeResponse>)
    );
  }

  /**
   * Path part for operation export
   */
  static readonly ExportPath = '/themes/export/{courseId}/{themeName}';

  /**
   * List global themes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `export()` instead.
   *
   * This method doesn't expect any request body.
   */
  export$Response(params: {
    courseId: number;
    themeName: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ThemeControllerService.ExportPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('themeName', params.themeName, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * List global themes.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `export$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  export(params: {
    courseId: number;
    themeName: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.export$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
