import { Component, Input } from '@angular/core';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { RealTimeBaseQuestionWidgetForm } from 'src/app/api-wrapper/models/widget-models/questions/question-base-model';

@Component({
    selector: 'app-abstract-real-time-question-base',
    template: '',
    styles: [],
})
export abstract class BaseQuestionRealTimeWidgetComponent<FormData, ConfigData> extends AbstractFormBaseComponent<
    any,
    ConfigData
> {
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    @Input() hasAnswerExplanation: boolean;
    @Input() hasMediaInfo: boolean;
    @Input() hasPdfInfo: boolean;
    @Input() attemptsInfo: number;
    @Input() hasAnswerMediaInfo: boolean;
    testEnv = false;

    constructor() {
        super();
    }

    get hasMedia(): boolean {
        return this.editorLocation === 'real-time'
            ? this.form.get('hasMedia') && (this.form.get('hasMedia').value as boolean)
            : this.hasMediaInfo;
    }

    get hasPdf(): boolean {
        return this.editorLocation === 'real-time'
            ? this.form.get('hasPdf') && (this.form.get('hasPdf').value as boolean)
            : this.hasPdfInfo;
    }

    get attempts(): number {
        return this.editorLocation === 'real-time'
            ? this.form.get('attempts') && (this.form.get('attempts').value as number)
            : this.attemptsInfo;
    }

    get hasAnswerMedia(): boolean {
        return this.editorLocation === 'real-time'
            ? this.form.get('hasAnswerMedia') && (this.form.get('hasAnswerMedia').value as boolean)
            : this.hasAnswerMediaInfo;
    }

    abstract createForm(): FormGroupTyped<FormData>;

    updateForm(value: RealTimeBaseQuestionWidgetForm): void {
        if (this.editorLocation === 'real-time') {
            this.form.patchValue({ hasAnswerMedia: value.hasAnswerMedia });
            this.form.patchValue({ hasMedia: value.hasMedia });
            this.form.patchValue({ hasPdf: value.hasPdf });
            this.form.patchValue({ attempts: value.attempts });
        }
        this.form.patchValue({
            introduction: value.introduction,
            text: value.text,
            pdfResourceElement: value.pdfResourceElement,
            mediaResourceElement: value.mediaResourceElement,
        });
    }
}
