<div
    class="mobile-controls"
    [class.textWidget]="textWidget"
    [class.fullsize]="fullSize"
    [class.hide]="isPlaying"
    *ngIf="controlsEnabled"
>
    <button
        class="ctrl-button left mobile bigger"
        type="button"
        [attr.aria-label]="'VIDEO-WIDGET.REPLAY' | translate"
        (click)="replay()"
    >
        <span class="editor-btn-icon nitroicon-replay_10 large"></span>
    </button>
    <button
        class="ctrl-button left mobile large"
        type="button"
        [attr.aria-label]="'VIDEO-WIDGET.' + (isPlaying ? 'PAUSE' : 'PLAY') | translate"
        (click)="togglePlay()"
    >
        <span [class.hidden]="isPlaying" class="editor-btn-icon nitroicon-video_play bigger"></span>
        <span [class.hidden]="!isPlaying" class="editor-btn-icon nitroicon-video_pause bigger"></span>
    </button>

    <button
        class="ctrl-button left mobile bigger"
        type="button"
        [attr.aria-label]="'VIDEO-WIDGET.FORWARD' | translate"
        (click)="forward()"
    >
        <span class="editor-btn-icon nitroicon-forward_10 large"></span>
    </button>
</div>

<video
    *ngIf="fileSrc"
    class="video-widget__video"
    [class.fullsize]="false"
    [class.fullscreen]="isFullScreen"
    #video
    preload="metadata"
    [src]="fileSrc | protectedResourceUrl | async"
    role="application"
    [poster]="posterUrl"
    [controls]="false"
    [autoplay]="autoplay"
    [loop]="loop"
    [muted]="muted"
    (error)="handleError()"
    (ended)="finished()"
    (timeupdate)="setLastKnownTime($event)"
    [currentTime]="currentTime"
    [poster]="posterValue | protectedResourceUrl | async"
    (click)="togglePlay()"
    playsinline
>
    <track
        default
        *ngIf="subtitle?.element?.elementId"
        kind="subtitles"
        class="video-widget__subtitle"
        [src]="subtitleUrl | protectedResourceUrl | async"
        [srclang]="subtitle.srcLang"
        [label]="'VIDEO-WIDGET.' + subtitle.srcLang | translate"
    />
</video>
<div *ngIf="controlsEnabled" class="video-controls" [class.fullsize]="false" [class.hide]="isPlaying">
    <div class="bottom-controls">
        <div class="left-controls">
            <button
                class="ctrl-button left no-mobile"
                [class.textWidget]="textWidget"
                [class.fullsize]="false"
                type="button"
                [attr.aria-label]="'VIDEO-WIDGET.' + (isPlaying ? 'PAUSE' : 'PLAY') | translate"
                (click)="togglePlay()"
            >
                <span [class.hidden]="isPlaying" class="editor-btn-icon nitroicon-video_play"></span>
                <span [class.hidden]="!isPlaying" class="editor-btn-icon nitroicon-video_pause"></span>
            </button>
            <button
                class="ctrl-button left no-mobile"
                [class.textWidget]="textWidget"
                [class.fullsize]="false"
                type="button"
                [attr.aria-label]="'VIDEO-WIDGET.REPLAY' | translate"
                (click)="replay()"
            >
                <span class="editor-btn-icon nitroicon-replay_10"></span>
            </button>
            <button
                class="ctrl-button left longer-width"
                type="button"
                [matMenuTriggerFor]="videoSpeed"
                [attr.aria-label]="'VIDEO-WIDGET.PLAYBACK-SPEED' | translate"
            >
                <span class="video-speed">{{ currentVideoSpeed }}x</span>
            </button>
            <button
                class="ctrl-button left no-mobile"
                [class.textWidget]="textWidget"
                [class.fullsize]="false"
                type="button"
                [attr.aria-label]="'VIDEO-WIDGET.FORWARD' | translate"
                (click)="forward()"
            >
                <span class="editor-btn-icon nitroicon-forward_10"></span>
            </button>

            <div class="time">
                <time>{{ lastKnownTime | mediaTime }}</time>
                <span>/</span>
                <time>{{ videoMax | mediaTime }}</time>
            </div>
        </div>

        <div class="right-controls">
            <div class="volume-controls">
                <div class="volume-slider-wrapper">
                    <label class="visually-hidden" [for]="'volume' + elementId">
                        {{ 'VIDEO-WIDGET.VOLUME-CONTROL' | translate }}
                    </label>
                    <input
                        #volumeSlider
                        class="volume"
                        [id]="'volume' + elementId"
                        [value]="currentVolume"
                        [attr.aria-valuenow]="currentVolume"
                        [attr.aria-orientation]="'vertical'"
                        type="range"
                        max="1"
                        min="0"
                        step="0.01"
                        (input)="updateVolume()"
                        (change)="updateVolume()"
                    />
                </div>

                <button
                    (click)="toggleMute()"
                    type="button"
                    [attr.aria-label]="'VIDEO-WIDGET.' + (currentVolume > 0 ? 'MUTE' : 'UNMUTE') | translate"
                    class="ctrl-button bellow right"
                >
                    <span [class.hidden]="currentVolume > 0" class="editor-btn-icon nitroicon-volume_mute"></span>
                    <span [class.hidden]="currentVolume <= 0" class="editor-btn-icon nitroicon-volume_up"></span>
                </button>
            </div>
            <button
                *ngIf="subtitle?.element?.elementId"
                (click)="toggleSubtitle()"
                type="button"
                [attr.aria-label]="'VIDEO-WIDGET.SUBTITLE' | translate"
                class="ctrl-button right"
            >
                <span [class.hidden]="subtitleIsOn" class="editor-btn-icon nitroicon-caption_off"></span>
                <span [class.hidden]="!subtitleIsOn" class="editor-btn-icon nitroicon-caption_on"></span>
            </button>

            <button
                *ngIf="orderedScales.length"
                mat-icon-button
                class="ctrl-button right"
                [matMenuTriggerFor]="quality"
                [attr.aria-label]="'VIDEO-WIDGET.QUALITY' | translate"
            >
                <span class="editor-btn-icon nitroicon-video_settings"></span>
            </button>
            <button
                (click)="toggleFullscreen()"
                class="ctrl-button right"
                *ngIf="!autoplay ?? supportFullScreen"
                [attr.aria-label]="'VIDEO-WIDGET.' + (isFullScreen ? 'EXIT-FULLSCREEN' : 'FULLSCREEN') | translate"
            >
                <span [class.hidden]="isFullScreen" class="editor-btn-icon nitroicon-fullscreen"></span>
                <span [class.hidden]="!isFullScreen" class="editor-btn-icon nitroicon-fullscreen_exit"></span>
            </button>
        </div>
    </div>
    <div class="video-progress">
        <label class="visually-hidden" [for]="'duration' + elementId">
            {{ 'VIDEO-WIDGET.TIMELINE' | translate }}
        </label>
        <input
            #durationSlider
            class="duration-slider"
            [value]="lastKnownTime"
            [id]="'duration' + elementId"
            min="0"
            [max]="videoMax"
            type="range"
            step="1"
            [attr.aria-valuenow]="lastKnownTime"
            (input)="sliderChanged()"
            (change)="sliderChanged()"
        />
        <div [ngStyle]="{ left: sliderValue }" class="seek-tooltip">{{ seekTime | mediaTime }}</div>
    </div>
</div>

<mat-menu #quality="matMenu" yPosition="above" xPosition="before">
    <div class="video-mat-panel-wrapper">
        <header class="mat-panel-header">{{ 'VIDEO-WIDGET.QUALITY' | translate }}</header>
        <hr class="divider" />
        <ng-container *ngFor="let scale of orderedScales">
            <button (click)="setScale(scale)" mat-menu-item>
                <span *ngIf="scale === selectedResolution" class="nitroicon-check"></span>
                {{ scale }}
            </button>
        </ng-container>
    </div>
</mat-menu>

<mat-menu #videoSpeed="matMenu" yPosition="above" xPosition="after">
    <div class="video-mat-panel-wrapper">
        <header class="mat-panel-header">{{ 'VIDEO-WIDGET.PLAYBACK-SPEED' | translate }}</header>
        <hr class="divider" />
        <ng-container *ngFor="let speed of videoSpeedList">
            <button (click)="setVideoSpeed(speed)" mat-menu-item>
                <span *ngIf="speed === currentVideoSpeed" class="nitroicon-check"></span>
                {{ speed }}x
                <span *ngIf="speed === 1"> ({{ 'VIDEO-WIDGET.NORMAL' | translate }})</span>
            </button>
        </ng-container>
    </div>
</mat-menu>
