import { Component, ElementRef, Renderer2, ViewChild, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-custom-textarea',
    templateUrl: './custom-textarea.component.html',
    styleUrls: ['./custom-textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomTextareaComponent),
            multi: true,
        },
    ],
})
export class CustomTextareaComponent implements ControlValueAccessor {
    @Input() title: string;
    @Input() placeholder = '';
    @Input() extraClasses = '';
    @Input() standAlone = false;
    @Output() currentText = new EventEmitter<string>();
    @Output() blurEvent = new EventEmitter<void>();
    @ViewChild('textarea', { static: true }) textarea: ElementRef;
    private onChange = (_: any) => {};
    private onTouched = (_: any) => {};

    constructor(private renderer: Renderer2) {}

    writeValue(value: any): void {
        this.setValue(value);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    change($event: any): void {
        this.onChange($event.target.textContent);
        this.onTouched($event.target.textContent);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        const div = this.textarea.nativeElement;
        const action = isDisabled ? 'addClass' : 'removeClass';
        this.renderer[action](div, 'disabled');
    }

    onEnter($event: any): void {
        if (!this.standAlone) {
            return;
        }
        const currentText = ($event.target.textContent ?? '').trim();
        this.currentText.emit(currentText);
        this.setValue('');
    }

    onBlur($event: any): void {
        this.blurEvent.emit();
    }

    setValue(value: any): void {
        const div = this.textarea.nativeElement;
        this.renderer.setProperty(div, 'textContent', value);
    }
}
