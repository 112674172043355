/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CourseVersionResponse } from '../models/course-version-response';
import { CourseVersionResponseWithPatchable } from '../models/course-version-response-with-patchable';
import { PageEnrollmentEditorResponse } from '../models/page-enrollment-editor-response';

@Injectable({
  providedIn: 'root',
})
export class CourseVersionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation modifyVersion
   */
  static readonly ModifyVersionPath = '/courses/{courseId}/versions/{courseVersion}/modify';

  /**
   * modify course version.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  modifyVersion$Response(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * new name for version
     */
    newName?: string;

    /**
     * new comment for version
     */
    newComment?: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<CourseVersionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CourseVersionControllerService.ModifyVersionPath, 'put');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('courseVersion', params.courseVersion, {});
      rb.query('newName', params.newName, {});
      rb.query('newComment', params.newComment, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseVersionResponse>;
      })
    );
  }

  /**
   * modify course version.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifyVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  modifyVersion(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * new name for version
     */
    newName?: string;

    /**
     * new comment for version
     */
    newComment?: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<CourseVersionResponse> {

    return this.modifyVersion$Response(params).pipe(
      map((r: StrictHttpResponse<CourseVersionResponse>) => r.body as CourseVersionResponse)
    );
  }

  /**
   * Path part for operation getVersions
   */
  static readonly GetVersionsPath = '/courses/{courseId}/versions';

  /**
   * Get all available versions by courseId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersions$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;
    courseVersionType?: 'CLOUD' | 'SCORM' | 'PUBLIC' | 'LTI' | 'THEME' | 'EXPORT';
    externalSystem?: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<CourseVersionResponseWithPatchable>>> {

    const rb = new RequestBuilder(this.rootUrl, CourseVersionControllerService.GetVersionsPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.query('courseVersionType', params.courseVersionType, {});
      rb.query('externalSystem', params.externalSystem, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CourseVersionResponseWithPatchable>>;
      })
    );
  }

  /**
   * Get all available versions by courseId.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersions(params: {

    /**
     * Identifier of course
     */
    courseId: number;
    courseVersionType?: 'CLOUD' | 'SCORM' | 'PUBLIC' | 'LTI' | 'THEME' | 'EXPORT';
    externalSystem?: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<CourseVersionResponseWithPatchable>> {

    return this.getVersions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CourseVersionResponseWithPatchable>>) => r.body as Array<CourseVersionResponseWithPatchable>)
    );
  }

  /**
   * Path part for operation createCourseVersion
   */
  static readonly CreateCourseVersionPath = '/courses/{courseId}/versions';

  /**
   * Async generate a new course version from actual course data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCourseVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCourseVersion$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;
    courseVersionType: 'CLOUD' | 'SCORM' | 'PUBLIC' | 'LTI' | 'THEME' | 'EXPORT';
    name?: string;
    externalSystem?: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<CourseVersionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CourseVersionControllerService.CreateCourseVersionPath, 'post');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.query('courseVersionType', params.courseVersionType, {});
      rb.query('name', params.name, {});
      rb.query('externalSystem', params.externalSystem, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseVersionResponse>;
      })
    );
  }

  /**
   * Async generate a new course version from actual course data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCourseVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCourseVersion(params: {

    /**
     * Identifier of course
     */
    courseId: number;
    courseVersionType: 'CLOUD' | 'SCORM' | 'PUBLIC' | 'LTI' | 'THEME' | 'EXPORT';
    name?: string;
    externalSystem?: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<CourseVersionResponse> {

    return this.createCourseVersion$Response(params).pipe(
      map((r: StrictHttpResponse<CourseVersionResponse>) => r.body as CourseVersionResponse)
    );
  }

  /**
   * Path part for operation deleteVersion
   */
  static readonly DeleteVersionPath = '/courses/{courseId}/versions/{courseVersion}';

  /**
   * Mark course version as deleted.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVersion$Response(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CourseVersionControllerService.DeleteVersionPath, 'delete');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('courseVersion', params.courseVersion, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Mark course version as deleted.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVersion(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteVersion$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchVersion
   */
  static readonly PatchVersionPath = '/courses/{courseId}/versions/{courseVersion}';

  /**
   * patch course version with the current state of the course.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchVersion$Response(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<CourseVersionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CourseVersionControllerService.PatchVersionPath, 'patch');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('courseVersion', params.courseVersion, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseVersionResponse>;
      })
    );
  }

  /**
   * patch course version with the current state of the course.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  patchVersion(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<CourseVersionResponse> {

    return this.patchVersion$Response(params).pipe(
      map((r: StrictHttpResponse<CourseVersionResponse>) => r.body as CourseVersionResponse)
    );
  }

  /**
   * Path part for operation getEnrollments
   */
  static readonly GetEnrollmentsPath = '/courses/{courseId}/versions/{version}/enrollments';

  /**
   * Get all enrollments by courseId and version.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnrollments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnrollments$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Number of version
     */
    version: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<PageEnrollmentEditorResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CourseVersionControllerService.GetEnrollmentsPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('version', params.version, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageEnrollmentEditorResponse>;
      })
    );
  }

  /**
   * Get all enrollments by courseId and version.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnrollments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnrollments(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Number of version
     */
    version: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<PageEnrollmentEditorResponse> {

    return this.getEnrollments$Response(params).pipe(
      map((r: StrictHttpResponse<PageEnrollmentEditorResponse>) => r.body as PageEnrollmentEditorResponse)
    );
  }

  /**
   * Path part for operation exportEnrollments
   */
  static readonly ExportEnrollmentsPath = '/courses/{courseId}/versions/{version}/enrollments/export';

  /**
   * Get all enrollments by courseId and version.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportEnrollments()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportEnrollments$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Number of version
     */
    version: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CourseVersionControllerService.ExportEnrollmentsPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('version', params.version, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get all enrollments by courseId and version.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportEnrollments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportEnrollments(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Number of version
     */
    version: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.exportEnrollments$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getScormZip
   */
  static readonly GetScormZipPath = '/courses/{courseId}/versions/{courseVersion}/scorm';

  /**
   * Get the course specific resources e.g .js files and any other which comes from the template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScormZip()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScormZip$Response(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CourseVersionControllerService.GetScormZipPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('courseVersion', params.courseVersion, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get the course specific resources e.g .js files and any other which comes from the template.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScormZip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScormZip(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.getScormZip$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getExportedZip
   */
  static readonly GetExportedZipPath = '/courses/{courseId}/versions/{courseVersion}/export';

  /**
   * Get the course specific resources e.g .js files and any other which comes from the template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExportedZip()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExportedZip$Response(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CourseVersionControllerService.GetExportedZipPath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('courseVersion', params.courseVersion, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get the course specific resources e.g .js files and any other which comes from the template.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExportedZip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExportedZip(params: {

    /**
     * id of the course
     */
    courseId: number;

    /**
     * number of the version
     */
    courseVersion: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.getExportedZip$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
