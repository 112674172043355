/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TenantEntity } from '../models/tenant-entity';

@Injectable({
  providedIn: 'root',
})
export class TenantControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createTenant1
   */
  static readonly CreateTenant1Path = '/tenant';

  /**
   * Create tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenant1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant1$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: TenantEntity
  }): Observable<StrictHttpResponse<TenantEntity>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.CreateTenant1Path, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantEntity>;
      })
    );
  }

  /**
   * Create tenant.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTenant1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant1(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: TenantEntity
  }): Observable<TenantEntity> {

    return this.createTenant1$Response(params).pipe(
      map((r: StrictHttpResponse<TenantEntity>) => r.body as TenantEntity)
    );
  }

  /**
   * Path part for operation getTenant1
   */
  static readonly GetTenant1Path = '/tenant/{tenantCode}';

  /**
   * Get tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenant1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenant1$Response(params: {
    tenantCode: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<TenantEntity>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.GetTenant1Path, 'get');
    if (params) {
      rb.path('tenantCode', params.tenantCode, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantEntity>;
      })
    );
  }

  /**
   * Get tenant.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTenant1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenant1(params: {
    tenantCode: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<TenantEntity> {

    return this.getTenant1$Response(params).pipe(
      map((r: StrictHttpResponse<TenantEntity>) => r.body as TenantEntity)
    );
  }

  /**
   * Path part for operation deleteTenant1
   */
  static readonly DeleteTenant1Path = '/tenant/{tenantCode}';

  /**
   * Delete tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTenant1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant1$Response(params: {
    tenantCode: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.DeleteTenant1Path, 'delete');
    if (params) {
      rb.path('tenantCode', params.tenantCode, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete tenant.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTenant1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant1(params: {
    tenantCode: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteTenant1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
