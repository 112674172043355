import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { ImageAspectRatio, ImageUploadComponent } from '@shared/forms/file-upload/image-upload/image-upload.component';
import { ISelectOption } from '@shared/forms/select-input/select-input.component';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { RealTimeImageWidgetForm } from 'src/app/api-wrapper/models/widget-models/image-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import { IFileUploadValue } from '@shared/models/file-upload.model';

@Component({
    selector: 'app-image-real-time-widget',
    templateUrl: './image-real-time-widget.component.html',
    styleUrls: ['./image-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class ImageRealTimeWidgetComponent extends AbstractFormBaseComponent<RealTimeImageWidgetForm, IWidgetSupport> {
    @ViewChild('imageUpload') imageUpload: ImageUploadComponent;
    @Input() location: 'detail' | 'real-time' = 'real-time';
    testEnv = false;
    imageMode: 'normal' | 'crop' = 'normal';
    cropSizeOptions: ISelectOption[] = [
        { value: 'NONE', displayName: 'CROPPER.NONE' },
        { value: '4/3', displayName: 'CROPPER.4/3' },
        { value: '16/9', displayName: 'CROPPER.16/9' },
    ];
    constructor() {
        super();
    }

    get hasImageForCrop(): boolean {
        const element = this.form.get('element').value as IFileUploadValue;
        return element.fileItem !== null || element.elementId !== null;
    }

    setStarterImageSize(): string {
        let size: string;
        switch (this.config?.starterWidgetSize) {
            case WidgetSizeEnum.ORIGINAL:
                size = '';
                break;
            case WidgetSizeEnum.FULLSIZE:
                size = 'XL';
                break;
            default:
                size = 'L';
                break;
        }
        return size;
    }

    onUtilizeCropperRequest(): void {
        this.imageMode = 'crop';
        this.imageUpload.imageMode = 'crop';
    }

    onCropRequest(): void {
        this.imageMode = 'normal';
        this.imageUpload.acceptCroppedImage();
    }

    onCancelCropRequest(): void {
        this.imageMode = 'normal';
        this.imageUpload.cancelCroppedImage();
    }

    onRatioChange(ratio: ImageAspectRatio | string): void {
        this.imageUpload.setRatio(ratio);
    }

    createForm(): FormGroupTyped<RealTimeImageWidgetForm> {
        return new FormGroupTyped<RealTimeImageWidgetForm>({
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            caption: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    configDataChanged(widgetSize?: WidgetSizeEnum): void {
        if (this.config.starterWidgetSize !== widgetSize) {
            this.config.starterWidgetSize = widgetSize;
            switch (this.config?.starterWidgetSize) {
                case WidgetSizeEnum.ORIGINAL:
                    this.imageUpload.setSrc('');
                    break;
                case WidgetSizeEnum.FULLSIZE:
                    this.imageUpload.setSrc('XL');
                    break;
                default:
                    this.imageUpload.setSrc('L');
                    break;
            }
        }
    }
}
