import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { toastAnimations } from '../toast-message/animations';

export type FileUploadErrorType = 'SUCCESS' | 'INFO' | 'ERROR';

export interface IFileUploadMessage {
    component?: FileUploadMessageComponent;
    type: FileUploadErrorType;
    fileName: string;
    progress?: number;
    timeout?: number;
    closeable?: boolean;
    link?: string;
    fragment?: string;
}

@Component({
    selector: 'app-file-upload-message',
    templateUrl: './file-upload-message.component.html',
    styleUrls: ['./file-upload-message.component.scss'],
    animations: [toastAnimations],
})
export class FileUploadMessageComponent {
    @Input() closeable = false;
    @Input() fileName: string;
    @Input() progress: number;
    @Input() link: string;
    @Input() fragment: string;
    @Output() remove = new EventEmitter();
    @HostBinding('attr.class') ownClass: string;
    @HostBinding('@visibleAnimation') animation;
    headerMessage = 'FILE.PROGRESS';

    _type: FileUploadErrorType;
    @Input() set type(val: FileUploadErrorType) {
        this._type = val;
        this.ownClass = val.toLowerCase();
        this.headerMessage = val === 'INFO' ? 'FILE.PROGRESS' : val === 'SUCCESS' ? 'FILE.SUCCESS' : 'FILE.ERROR';
    }

    get type(): FileUploadErrorType {
        return this._type;
    }

    @Input() set timeout(val: number) {
        if (val) {
            setTimeout(() => {
                this.remove.emit();
            }, val);
        }
    }

    closeToastMessage(): void {
        this.remove.emit();
    }
}
