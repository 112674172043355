import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
} from '@angular/core';
import { FileUploadMessageComponent, IFileUploadMessage } from '../file-upload-message/file-upload-message.component';
import { IToastMessage, ToastMessageComponent } from './toast-message.component';

@Injectable({ providedIn: 'root' })
export class ToastMessageService {
    // fileUploadContainer;
    public toasts: {
        component: ComponentRef<ToastMessageComponent | FileUploadMessageComponent>;
        element: HTMLElement;
    }[] = [];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef
    ) {}

    public error(message: Partial<IToastMessage>): ToastMessageComponent {
        return this.addMessage({ type: 'ERROR', ...message } as IToastMessage);
    }

    public warning(message: Partial<IToastMessage>): ToastMessageComponent {
        return this.addMessage({ type: 'WARNING', ...message } as IToastMessage);
    }

    public info(message: Partial<IToastMessage>): ToastMessageComponent {
        return this.addMessage({ type: 'INFO', ...message } as IToastMessage);
    }

    public success(message: Partial<IToastMessage>): ToastMessageComponent {
        return this.addMessage({ type: 'SUCCESS', ...message } as IToastMessage);
    }

    public addFileUploadMessage(message: Partial<IFileUploadMessage>): FileUploadMessageComponent {
        return this.addFileUploadMsg({ type: 'INFO', ...message } as IFileUploadMessage);
    }

    private addFileUploadMsg(message: IFileUploadMessage): FileUploadMessageComponent {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FileUploadMessageComponent);
        const fileUploadComponent = componentFactory.create(this.injector);
        fileUploadComponent.instance.type = message.type;
        fileUploadComponent.instance.progress = message.progress ?? null;
        fileUploadComponent.instance.fileName = message.fileName ?? '';
        fileUploadComponent.instance.link = message.link ?? null;
        fileUploadComponent.instance.fragment = message.fragment ?? null;
        fileUploadComponent.instance.timeout = message.timeout ?? 0;
        fileUploadComponent.instance.closeable = message?.closeable ?? false;
        fileUploadComponent.instance.remove.subscribe(() => {
            this.removeFromDom(fileUploadComponent);
        });

        setTimeout(() => this.addToDom(fileUploadComponent));
        return fileUploadComponent.instance;
    }

    public updateFileUploadMessage({
        component,
        progress,
        type,
        link,
        fragment,
        timeout,
        closeable,
    }: Partial<IFileUploadMessage>): void {
        if (type) {
            component.type = type;
        }

        if (closeable) {
            component.closeable = closeable;
        }

        if (timeout) {
            component.timeout = timeout;
        }

        if (progress) {
            component.progress = progress;
        }
        if (link) {
            component.link = link;
        }

        if (fragment) {
            component.fragment = fragment;
        }
    }

    public updateMessage(
        component: ToastMessageComponent,
        message: string,
        type?: 'SUCCESS' | 'INFO' | 'ERROR' | 'WARNING',
        timeout?: number,
        closeable?: boolean
    ): void {
        component.message = message;
        if (type) {
            component.type = type;
        }
        if (timeout) {
            component.timeout = timeout;
        }
        if (closeable !== undefined) {
            component.closeable = closeable;
        }
    }

    private addMessage(message: IToastMessage): ToastMessageComponent {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ToastMessageComponent);
        const toastComponent = componentFactory.create(this.injector);
        toastComponent.instance.type = message.type;
        toastComponent.instance.link = message.link ?? null;
        toastComponent.instance.fragment = message.fragment ?? null;
        toastComponent.instance.translatedMessage = message.translatedMessage ?? null;
        toastComponent.instance.message = message.message ?? null;
        toastComponent.instance.timeout = message.timeout ?? 6000;
        toastComponent.instance.closeable = message?.closeable ?? true;
        toastComponent.instance.remove.subscribe(() => {
            this.removeFromDom(toastComponent);
        });

        setTimeout(() => this.addToDom(toastComponent));
        return toastComponent.instance;
    }

    private addToDom(toastComponent: ComponentRef<ToastMessageComponent | FileUploadMessageComponent>): void {
        let bottom = 10;
        this.toasts.forEach((toast) => {
            bottom += toast.element.clientHeight + 10;
        });

        this.appRef.attachView(toastComponent.hostView);
        const domElem = (toastComponent.hostView as EmbeddedViewRef<ToastMessageComponent>).rootNodes[0] as HTMLElement;
        this.toasts.push({ component: toastComponent, element: domElem });
        this.setBottom(domElem, bottom);
        document.body.appendChild(domElem);
    }

    private removeFromDom(toastComponent: ComponentRef<ToastMessageComponent | FileUploadMessageComponent>): void {
        this.appRef.detachView(toastComponent.hostView);
        toastComponent.destroy();
        this.toasts = this.toasts.filter((toast) => toast.component !== toastComponent);
        let bottom = 10;
        this.toasts.forEach((toast) => {
            this.setBottom(toast.element, bottom);
            bottom += toast.element.clientHeight + 10;
        });
    }

    private setBottom(element: HTMLElement, bottom: number): void {
        element.style.bottom = `${bottom}px`;
    }
}
