import { Component, ElementRef, forwardRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        },
    ],
})
export class NumberInputComponent implements ControlValueAccessor {
    @Input() min = 0;
    @Input() max = 3;
    @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
    @ViewChild('plusBtnElement', { static: true }) plusBtnElement: ElementRef;
    @ViewChild('minusBtnElement', { static: true }) minusBtnElement: ElementRef;

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _value = 0;
    private onChangeCallback: (_: any) => void;
    private onTouchedCallback: () => void;

    get value(): string | number | null {
        return this._value;
    }

    set value(value: string | number | null) {
        const valueToNum = Number(value) ?? 0;
        if (valueToNum !== this._value && !isNaN(valueToNum)) {
            let correctedValue = valueToNum;
            if (valueToNum > this.max) {
                correctedValue = this.max;
            } else if (value < this.min) {
                correctedValue = this.min;
            }
            this._value = correctedValue;
            this.onChangeCallback(this.value);
        }
    }

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    plusClicked(): void {
        if (this._value < this.max) {
            this._value++;
            this.emitChanges();
        }
    }

    minusClicked(): void {
        if (this._value > this.min) {
            this._value--;
            this.emitChanges();
        }
    }

    emitChanges(): void {
        this.onChangeCallback(this.value);
    }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', isDisabled ? 'true' : null);
        this.renderer.setProperty(this.inputElement.nativeElement, 'disabled', isDisabled);
        this.renderer.setProperty(this.plusBtnElement.nativeElement, 'disabled', isDisabled);
        this.renderer.setProperty(this.minusBtnElement.nativeElement, 'disabled', isDisabled);
    }
}
