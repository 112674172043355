/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ImageTextWidgetData } from '@apiModels';
import { IFileUploadValue } from '@shared/models/file-upload.model';

import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from './base-widget-models/widget-size.enum';

export type MultiWidgetDividerLocation = 'LEFT' | 'CENTER' | 'RIGHT';

export type MultiWidgetImageFit = 'CONTAIN' | 'PADDED' | 'COVER';

export interface RealTimeImageTextWidgetForm {
    element: IFileUploadValue;
    text: string | null;
    dividerLocation: MultiWidgetDividerLocation;
    flipped: boolean;
    imageFit: MultiWidgetImageFit;
}

export interface DetailImageTextWidgetForm {
    realTimeImageTextWidgetFormData: RealTimeImageTextWidgetForm;
    imageDescription: string;
    dividerLocation: MultiWidgetDividerLocation;
    flipped: boolean;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW';
    size: WidgetSizeEnum;
    imageFit: MultiWidgetImageFit;
}

export interface ImageTextWidgetDataSpec extends ImageTextWidgetData {
    fulfillment: 'NONE' | 'SHOW';
}

export class ImageTextWidgetClass extends BaseWidgetClass<ImageTextWidgetDataSpec> {
    constructor(data: TypedResponse<ImageTextWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeImageTextWidgetForm {
        return {
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data?.imageResourceId ?? null,
            },
            text: this.data.text ?? null,
            dividerLocation: this.data?.dividerLocation ?? 'CENTER',
            flipped: this.data?.flipped ?? false,
            imageFit: this.data?.imageFit ?? 'CONTAIN',
        };
    }

    getDetailFormData(): DetailImageTextWidgetForm {
        return {
            realTimeImageTextWidgetFormData: this.getRealTimeFormData(),
            imageDescription: this.data?.imageDescription ?? '',
            dividerLocation: this.data?.dividerLocation ?? 'CENTER',
            flipped: this.data?.flipped ?? false,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
            imageFit: this.data?.imageFit ?? 'CONTAIN',
        };
    }

    updateWidgetData(modifiedData: RealTimeImageTextWidgetForm | DetailImageTextWidgetForm): void {
        if (modifiedData.hasOwnProperty('realTimeImageTextWidgetFormData')) {
            const { realTimeImageTextWidgetFormData, ...rest } = modifiedData as DetailImageTextWidgetForm;
            modifiedData = {
                ...realTimeImageTextWidgetFormData,
                ...rest,
            };
        }

        const { element, ...rest } = modifiedData as RealTimeImageTextWidgetForm & DetailImageTextWidgetForm;

        if (this.shouldSaveFile(element.fileItem, this.data.imageResourceId)) {
            this.filesToSave.push(element.fileItem);
        }
        this.fileUploadElement = element.fileItem;
        const imageResourceId = this.extractUUID(element);
        this.data = { ...this.data, ...rest, imageResourceId };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.imageResourceId === id) {
                this.data = { ...this.data, imageResourceId: null };
                this.fileUploadElement = null;
            }
        });
    }
}
