import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { BaseFileUploadComponent } from '@shared/forms/file-upload/base-file-upload/base-file-upload.component';

@Component({
    selector: 'app-subtitle-upload',
    templateUrl: './subtitle-upload.component.html',
    styleUrls: ['./subtitle-upload.component.scss', '../base-file-upload/base-file-upload.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SubtitleUploadComponent),
            multi: true,
        },
    ],
})
export class SubtitleUploadComponent extends BaseFileUploadComponent {
    @Input() acceptTypes = '.vtt, .src';
    @Input() regExp = new RegExp(/\.(vtt|src)$/i);
    @Input() maxFileSize = 5000000;

    constructor(protected toastsSrv: ToastMessageService, private cdr: ChangeDetectorRef) {
        super(toastsSrv, cdr);
    }
}
