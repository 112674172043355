/* eslint-disable prettier/prettier */
/* eslint-disable */
import { CdkPortal, DomPortalOutlet, PortalOutlet } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    Injector,
    Input,
    OnDestroy,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-exported-template-actions',
    template: `
        <ng-template cdk-portal>
            <ng-content></ng-content>
        </ng-template>
    `,
    styles: [],
})
export class ExportedTemplateComponent implements AfterViewInit, OnDestroy {
    @Input() selector: string;
    private portalHost: PortalOutlet;
    @ViewChild(CdkPortal) portal: CdkPortal;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef
    ) {}

    ngAfterViewInit(): void {
        // Create a portalHost from a DOM element
        this.portalHost = new DomPortalOutlet(
            document.querySelector(this.selector) as Element,
            this.componentFactoryResolver,
            this.appRef,
            this.injector
        );

        // Attach portal to host
        this.portalHost.attach(this.portal);
    }

    ngOnDestroy(): void {
        this.portalHost.detach();
    }
}
