import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { DetailYoutubeWidgetForm } from 'src/app/api-wrapper/models/widget-models/embeded-widgets/youtube-widget.model';
import { CourseEditorHelpers } from 'src/app/course-editor/helpers/fulfillment.helper';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

const youtubeQueryParam = 'https://www.youtube.com/watch?v=';
const youtubeShareLink = 'https://youtu.be/';
const youtubeEmbededLink = 'https://www.youtube.com/embed/';

@Component({
    selector: 'app-youtube-detail-widget',
    templateUrl: './youtube-detail-widget.component.html',
    styleUrls: ['./youtube-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => YoutubeDetailWidgetComponent),
            multi: true,
        },
    ],
})
export class YoutubeDetailWidgetComponent
    extends AbstractFormBaseComponent<DetailYoutubeWidgetForm, IWidgetSupport>
    implements OnInit
{
    widgetSizeEnum = WidgetSizeEnum;
    size: WidgetSizeEnum;
    sizeClass: string;
    fulfillmentAvailableOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-available-option-true', label: 'FORM-CONTROLS.YES', value: true },
        { id: 'fulfillment-available-option-false', label: 'FORM-CONTROLS.NO', value: false },
    ];

    fulfillmentOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-option-show', label: 'FORM-CONTROLS.SHOW', value: 'SHOW', group: 'LAX' },
        { id: 'fulfillment-option-none', label: '', value: 'NONE', hidden: true, group: 'NONE' },
    ];
    testEnv = false;
    videoId: string;
    src: string;
    urlForm: UntypedFormGroup;
    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initDisabledFields();
        this.initYoutubeUrlChange();
        this.initSizeChanges();
    }

    createForm(): FormGroupTyped<DetailYoutubeWidgetForm> {
        return new FormGroupTyped<DetailYoutubeWidgetForm>({
            description: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
            videoId: FormHelper.controlFactoryWithCalculatedValue(null),
            size: FormHelper.controlFactoryWithCalculatedValue(null),
            title: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    initSizeChanges(): void {
        this.subscriptions.add(
            this.form
                .get('size')
                .valueChanges.pipe(startWith(this.form.get('size').value as WidgetSizeEnum))
                .subscribe((size: WidgetSizeEnum) => {
                    this.size = size;
                    this.sizeClass = size?.toLowerCase();
                })
        );
    }

    initYoutubeUrlChange(): void {
        this.videoId = this.data.videoId;
        this.src = this.data.videoId ? `https://www.youtube.com/embed/${this.data.videoId}` : null;
        const src = this.videoId ? `https://youtu.be/${this.videoId}` : null;
        this.urlForm = new UntypedFormGroup({
            src: FormHelper.controlFactoryWithCalculatedValue(src),
        });
        this.subscriptions.add(
            this.urlForm
                .get('src')
                .valueChanges.pipe(debounceTime(300), distinctUntilChanged())
                .subscribe((src: string) => {
                    if (src.includes(youtubeQueryParam)) {
                        this.videoId = src.split(youtubeQueryParam)[1];
                        this.src = `https://www.youtube.com/embed/${this.videoId}`;
                    } else if (src.includes(youtubeShareLink)) {
                        this.videoId = src.split(youtubeShareLink)[1];
                        this.src = `https://www.youtube.com/embed/${this.videoId}`;
                    } else if (src.includes(youtubeEmbededLink)) {
                        this.videoId = src.split(youtubeEmbededLink)[1];
                        this.src = src;
                    } else {
                        this.videoId = null;
                    }
                    this.cdr.markForCheck();
                    this.form.patchValue({ videoId: this.videoId });
                })
        );
    }

    initDisabledFields(): void {
        const value = this.form.get('fulfillmentAvailable').value as boolean;
        if (!value) {
            this.form.get('fulfillment').disable({ emitEvent: false });
        }
        this.subscriptions.add(
            this.form.get('fulfillmentAvailable').valueChanges.subscribe((fulfillmentAvailable: boolean) => {
                CourseEditorHelpers.handleFulfillment(
                    fulfillmentAvailable,
                    this.form,
                    this.config.fulfillmentSettings.fulfillmentLevel,
                    this.fulfillmentOptions
                );
            })
        );
    }

    setWidgetSize(size: WidgetSizeEnum): void {
        (this.form as UntypedFormGroup).patchValue({ size });
    }
}
