<form [formGroup]="form" class="detail-form">
    <div class="detail-form__site-left">
        <app-carousel-real-time-widget
            *ngIf="!testEnv"
            #realTimeCarousel
            class="detail-form__real-time-widget"
            [formControlName]="'realTimeCarouselWidgetForm'"
            [config]="config"
            [xPosition]="xPosition"
            [editorLocation]="'detail'"
            [data]="data.realTimeCarouselWidgetForm"
        >
        </app-carousel-real-time-widget>
    </div>

    <div class="detail-form__site-right">
        <app-collapse-card [title]="'CAROUSEL-WIDGET.SETTINGS-CARD' | translate" [opened]="true">
            <app-radio-group-control
                class="detail-form__group"
                [title]="'CAROUSEL-WIDGET.SLIDE-SIZE' | translate"
                [options]="slideSizeOptions"
                formControlName="xPosition"
            ></app-radio-group-control>

            <app-radio-group-control
                class="detail-form__group"
                [title]="'FORM-CONTROLS.ZOOMABLE-TITLE' | translate"
                [options]="zoomOptionsForImages"
                formControlName="zoomable"
            ></app-radio-group-control>

            <!-- <app-radio-group-control
                class="detail-form__group"
                [title]="'CAROUSEL-WIDGET.AUTO-PLAY' | translate"
                [options]="fulfillmentAvailableOptions"
                formControlName="fulfillmentAvailable"
            ></app-radio-group-control> -->
        </app-collapse-card>

        <app-collapse-card [title]="'CAROUSEL-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
            <app-radio-group-control
                class="detail-form__group"
                [title]="'CAROUSEL-WIDGET.FULFILLMENT-AVAILABLE-TITLE' | translate"
                [options]="fulfillmentAvailableOptions"
                formControlName="fulfillmentAvailable"
            ></app-radio-group-control>

            <app-radio-group-control
                class="detail-form__group"
                [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                [options]="fulfillmentOptions"
                formControlName="fulfillment"
            ></app-radio-group-control>
        </app-collapse-card>
    </div>
</form>
