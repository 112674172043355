<div class="card__header" [class.card-not-opened]="!opened">
    <label *ngIf="!canEditTitle; else titleToEdit"> {{ title }} </label>
    <ng-template #titleToEdit>
        <app-standalone-input
            class="app-standalone-input"
            [startValue]="title"
            [onlyEmitOnEnter]="true"
            (textChanged)="titleChanged.emit($event)"
        ></app-standalone-input>
    </ng-template>

    <button
        (click)="opened = !opened"
        class="editor-btn ghost height-small icon-only radius-25 collapse-icon"
        type="button"
    >
        <span class="nitroicon-arrow_right editor-btn-icon"></span>
    </button>
</div>
<app-collapse class="collapse-card" *ngIf="opened">
    <div class="card__body">
        <div class="card__content">
            <ng-content></ng-content>
        </div>
    </div>
</app-collapse>
