import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { IFileUploadElement, IFileUploadValue } from '@shared/models/file-upload.model';
import { IDownloadProgress } from '@sharedServices/protected-resource-download.service';
import { URLHelperUtility } from '@sharedUtilities/url-helpers.utility';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';

@Component({
    template: '',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseFileUploadComponent),
            multi: true,
        },
    ],
})
export abstract class BaseFileUploadComponent implements ControlValueAccessor {
    @Input() courseId: number;
    @Input() maxFileSize: number;
    @ViewChild('fileInput') fileInput: ElementRef;
    @Input() acceptTypes: string;
    @Input() regExp: RegExp;
    @Input() originalFileName: string;
    @Input() showDeleteButtonOnHover = true;
    orderedScales: string[] = [];
    bypassSw = false;
    lastKnownTime = 0;
    currentTime = 0;
    jumpTo?: number = null;
    isPlaying = false;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    protected _value: IFileUploadValue;

    public fileSrc: string | null;
    downloadProgress: IDownloadProgress = null;
    protected onTouchedCallback = () => {};
    protected onChange = (value: any) => {};

    constructor(protected toastsSrv: ToastMessageService, private crf: ChangeDetectorRef) {}

    get value(): IFileUploadValue {
        return this._value;
    }

    set value(v: IFileUploadValue) {
        if (v !== this._value) {
            this._value = v;
        }
    }

    onBlur(): void {
        this.onTouchedCallback();
    }

    get fileName(): string {
        return this._value?.fileItem?.resource.name || this.originalFileName;
    }

    handleError(): void {
        this.fileSrc = URLHelperUtility.resourceURLForCourse(this.courseId, this.value.elementId, this.bypassSw);
        this.jumpTo = this.lastKnownTime;
    }

    setIsplaying(state: boolean): void {
        this.isPlaying = state;
    }

    setLastKnownTime(data: Event): void {
        if (this.jumpTo !== null) {
            this.currentTime = this.jumpTo;
            this.jumpTo = null;
            if (this.isPlaying) {
                this.startToPlay();
            }
        }
        const time: number = (data.target as HTMLAudioElement).currentTime;
        if (time > 0) {
            this.lastKnownTime = time;
        }
    }

    startToPlay(): void {}

    writeValue(value: IFileUploadValue): void {
        this._value = value;
        if (value) {
            this.fileSrc = value.fileItem
                ? URL.createObjectURL(value.fileItem.resource)
                : value.elementId
                ? this.getResourceUrl(value)
                : null;
        }
    }

    getResourceUrl(value: IFileUploadValue): string {
        return URLHelperUtility.resourceURLForCourse(this.courseId, value.elementId, this.bypassSw);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    deleteFile(): void {
        this.value = { elementId: null, fileItem: null };
        this.fileSrc = null;
        this.onChange(this.value);
    }

    landFile(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (!event?.dataTransfer?.files?.length) {
            return;
        }

        this.processFile(event.dataTransfer.files[0]);
    }

    fileChangeListener(): void {
        const hasFile = this.fileInput && this.fileInput.nativeElement && this.fileInput.nativeElement.files.length > 0;

        if (!hasFile) {
            return;
        }

        this.processFile(this.fileInput.nativeElement.files[0]);
    }

    onProcessFile(): void {}

    protected processFile(file: File): void {
        if (!this.regExp.test(file.name)) {
            this.toastsSrv.error({ translatedMessage: 'ERROR-MESSAGE.ERROR-FORMAT' });
            return;
        }

        if (this.maxFileSize && file.size > this.maxFileSize) {
            this.toastsSrv.error({ translatedMessage: 'ERROR-MESSAGE.ERROR-SIZE' });
            return;
        }

        this.fileSrc = URL.createObjectURL(file);
        this.fileInput.nativeElement.value = null;
        this.onProcessFile();

        if (!this._value?.fileItem) {
            if (!this._value) {
                this._value = {} as IFileUploadValue;
            }
            this._value.fileItem = {} as IFileUploadElement;
        }
        const [extension, ...rest] = file.name.split('.').reverse();
        this._value.fileItem.mime_type = file.type ? file.type : extension;
        this._value.fileItem.name = file.name;
        this.originalFileName = file.name;
        this._value.fileItem.resource = file;
        UUIDGeneratorUtility.generateMd5(file).subscribe((hash) => {
            this._value.fileItem.uuid = hash + '.' + extension;
            this.onChange(this.value);
        });
    }
}
