import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { DetailNavigationBlockWidgetForm } from 'src/app/api-wrapper/models/widget-models/navigation-block-widget.model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { NavigationBlockRealTimeWidgetComponent } from '../navigation-block-real-time-widget/navigation-block-real-time-widget.component';

@Component({
    selector: 'app-navigation-block-detail-widget',
    templateUrl: './navigation-block-detail-widget.component.html',
    styleUrls: ['./navigation-block-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NavigationBlockDetailWidgetComponent),
            multi: true,
        },
    ],
})
export class NavigationBlockDetailWidgetComponent
    extends AbstractFormBaseComponent<DetailNavigationBlockWidgetForm, IWidgetSupport>
    implements OnInit
{
    @ViewChild('realTimeNavigationWidget') realTimeNavigationWidget: NavigationBlockRealTimeWidgetComponent;
    widgetSizeEnum = WidgetSizeEnum;
    size: WidgetSizeEnum;
    sizeClass: string;
    testEnv = false;

    constructor() {
        super();
    }

    get columns(): number {
        const columns = this.form.get('columns') && (this.form.get('columns').value as number);
        return this.size !== this.widgetSizeEnum.FULLSIZE && columns > 3 ? 3 : columns;
    }

    ngOnInit(): void {
        super.ngOnInit();
        const starterSize = this.form.get('size').value as WidgetSizeEnum;
        this.setWidgetSizeValues(starterSize);
    }

    createForm(): FormGroupTyped<DetailNavigationBlockWidgetForm> {
        return new FormGroupTyped<DetailNavigationBlockWidgetForm>({
            realTimeNavigationBlockForm: FormHelper.controlFactoryWithCalculatedValue(null),
            columns: FormHelper.controlFactoryWithCalculatedValue(2),
            size: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    setWidgetSize(size: WidgetSizeEnum): void {
        this.form.patchValue({ size });
        this.setWidgetSizeValues(size);
    }
    setWidgetSizeValues(size: WidgetSizeEnum): void {
        if (this.config?.starterWidgetSize) {
            this.config.starterWidgetSize = size;
        }

        this.sizeClass = size?.toLowerCase();
        this.size = size;
    }

    addNavigationCard(): void {
        this.realTimeNavigationWidget.addNavigationCard();
    }
}
