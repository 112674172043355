<input type="file" #fileInput [accept]="acceptTypes" (change)="fileChangeListener()" />

<ng-container *ngIf="fileSrc; else emptyBtn">
    <audio
        #droparea
        [src]="fileSrc | protectedResourceUrl | async"
        class="audio"
        (click)="fileInput.click()"
        (drop)="landFile($event); droparea.className = 'dropper'"
        (dragenter)="droparea.className = 'dropper dragover'"
        (dragover)="$event.preventDefault()"
        (dragleave)="droparea.className = 'dropper'"
        tabindex="0"
        controls
        (error)="handleError()"
        (timeupdate)="setLastKnownTime($event)"
        [currentTime]="currentTime"
        (play)="setIsplaying(true)"
        (pause)="setIsplaying(false)"
        (ended)="setIsplaying(false)"
    ></audio>
    <button class="delete-btn" (click)="deleteFile()" (keyup.enter)="deleteFile()">
        <span class="nitroicon-close" aria-hidden="true"></span>
    </button>
</ng-container>

<ng-template #emptyBtn>
    <div class="dropper" #droparea>
        <button
            data-e2e="audio-upload"
            class="empty-btn"
            (click)="fileInput.click()"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
            tabindex="0"
        >
            <span #contentWrapper>
                <ng-content></ng-content>
            </span>

            <div *ngIf="contentWrapper.childNodes.length === 0" class="empty-btn__cover"></div>
        </button>
    </div>
</ng-template>
