<div
    class="form__textarea"
    contenteditable="true"
    tabindex="0"
    [attr.data-placeholder]="placeholder"
    role="textarea"
    (keyup.enter)="onEnter($event)"
    #textarea
    (input)="change($event)"
    (blur)="onBlur($event)"
></div>
