import { ChangeDetectorRef, Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { UntypedFormArray, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { ConfirmChoiceService } from '@sharedServices/confirm-choice.service';
import { TranslateWrapperService } from '@sharedServices/translate-wrapper.service';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { Options } from 'sortablejs';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import {
    INavigationCardFormData,
    RealTimeNavigationBlockWidgetForm,
} from 'src/app/api-wrapper/models/widget-models/navigation-block-widget.model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

@Component({
    selector: 'app-navigation-block-real-time-widget',
    templateUrl: './navigation-block-real-time-widget.component.html',
    styleUrls: ['./navigation-block-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NavigationBlockRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class NavigationBlockRealTimeWidgetComponent
    extends AbstractFormBaseComponent<RealTimeNavigationBlockWidgetForm, IWidgetSupport>
    implements OnInit
{
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    @Input() columns: number;
    @HostBinding('class.fullsize') fullsizeClass = false;
    @HostBinding('class.homepage') homepageClass = false;
    options: Options;
    horizontal: boolean;
    testEnv = false;
    numberOfCardsChanged = false;
    titleHeight = '52px';

    constructor(
        private translate: TranslateWrapperService,
        private confirmChoiceService: ConfirmChoiceService,
        private cdr: ChangeDetectorRef
    ) {
        super();
        this.options = {
            group: 'navigation-block',
            forceFallback: false,
            onUpdate: () => {},
            onChange: () => {},
            onAdd: () => {
                this.mode = 'MOVE_NAVIGATION_CARD';
            },
            onRemove: (event: any) => {
                this.mode = 'ONLY_UPDATE';
            },
            onStart: (event: any) => {},
        };
        // TODO - BE-ről kell jönnie az adatnak
        // this.horizontal = true; // kép MELLETTE szöveg, MELETTE gomb
        this.horizontal = false; // kép ALATTA szöveg, ALATTA gomb
    }

    get title(): string {
        return this.form.get('title').value as string;
    }

    get cards(): UntypedFormArray {
        return this.form.get('cards') as UntypedFormArray;
    }

    get coulumnsNumber(): number {
        if (this.fullsizeClass === false && this.editorLocation === 'real-time' && this.form.get('columns').value > 3) {
            return this.form.get('columns') && 3;
        } else {
            return this.editorLocation === 'real-time'
                ? this.form.get('columns') && (this.form.get('columns').value as number)
                : this.columns;
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.homepageClass = this.config?.isHomepage;
        this.fullsizeClass = this.config?.starterWidgetSize === WidgetSizeEnum.FULLSIZE;
    }

    createForm(): FormGroupTyped<RealTimeNavigationBlockWidgetForm> {
        return new FormGroupTyped<RealTimeNavigationBlockWidgetForm>({
            cards: new UntypedFormArray([]),
            columns: FormHelper.controlFactoryWithCalculatedValue(2),
            id: FormHelper.controlFactoryWithCalculatedValue(null),
            title: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    updateForm(value: RealTimeNavigationBlockWidgetForm): void {
        this.shouldSave = false;
        this.form.patchValue(
            { columns: value.columns, id: value.id, title: value?.title ?? null },
            { emitEvent: false }
        );
        this.form.setControl('cards', FormHelper.initBaseFormarray(value.cards, this.createNavCard));
        this.config.setDisabledActionButtons(false);
        this.numberOfCardsChanged = false;
        this.shouldSave = true;
    }

    createNavCard = (data: INavigationCardFormData): FormGroupTyped<INavigationCardFormData> => {
        return new FormGroupTyped<INavigationCardFormData>({
            navigationBlockID: FormHelper.controlFactoryWithCalculatedValue(
                data?.navigationBlockID ?? this.form.get('id').value
            ),
            pageId: FormHelper.controlFactoryWithCalculatedValue(data?.pageId ?? null),
            isLesson: FormHelper.controlFactoryWithCalculatedValue(false),
            coverImageElement: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: data?.coverImageElement?.fileItem ?? null,
                elementId: data?.coverImageElement?.elementId ?? null,
            }),
            name: FormHelper.controlFactoryWithCalculatedValue(
                data?.name ?? this.translate.getInstantTranslate('NAV-WIDGET.CHAPTER-TEXT')
            ),
        });
        // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    };

    addNavigationCard(): void {
        this.cards.push(this.createNavCard(null));
        this.config.setDisabledActionButtons(true);
        this.numberOfCardsChanged = this.editorLocation !== 'detail';
    }

    deleteCard(index: number): void {
        this.confirmChoiceService.confirm().subscribe(() => {
            this.cards.removeAt(index);
            this.cdr.detectChanges();
        });
    }
}
