import { ClozeGapAnswers } from '@apiModels';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';

import { BaseSubFormClass } from '../../base-widget-models/base-sub-form-class';

export class ClozeGapAnswerModel extends BaseSubFormClass<ClozeGapAnswers, ClozeGapAnswers> {
    constructor(data: ClozeGapAnswers) {
        super(data);

        if (!this.data.gapId) {
            this.data.gapId = UUIDGeneratorUtility.generate();
        }
    }

    getFormData(): ClozeGapAnswers {
        return {
            gapId: this.data?.gapId ?? null,
            type: this.data.type,
            answers: this.data?.answers ?? [],
        };
    }

    updateData(modifiedData: ClozeGapAnswers): void {
        this.data = { ...this.data, ...modifiedData };
    }
}
