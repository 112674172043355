/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { TypedResponse } from 'src/app/api-wrapper/models/widget-models/base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';

import { BaseWidgetClass } from '../../api-wrapper/models/widget-models/base-widget-models/base-widget-class';
import { WidgetConfig, WidgetType } from '../configs/widget.config';

export class PageWidgetElem {
    widget: BaseWidgetClass<any>;
    widgetType: WidgetType;
    widgetId: number;
    widgetSelectorOpen = false;
    disableActionButtons = false;
    openDetailAfterCreation = false;

    constructor(data: TypedResponse<any>) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const isQuestion = data.data.hasOwnProperty('question');

        this.widgetType = (isQuestion ? data?.data?.question?.questionType : data?.data?.type) as WidgetType;
        const model = WidgetConfig.get(this.widgetType)?.model;

        if (!model) {
            throw new Error('Widget Type is not implemented!!');
        }
        this.widget = new model(data);
    }

    get widgetSize(): WidgetSizeEnum {
        return this.widget?.data?.size as WidgetSizeEnum;
    }

    get disbledActionButton(): boolean {
        return this.disableActionButtons;
    }

    setOpenDetailAfterCreation(newState: boolean): void {
        this.openDetailAfterCreation = newState;
    }

    setwidgetSelectorState(newState: boolean): void {
        this.widgetSelectorOpen = newState;
    }

    isImageOrVideo(): boolean {
        return this.widgetType === 'SIMPLE_IMAGE_WIDGET' || this.widgetType === 'SIMPLE_VIDEO_WIDGET';
    }

    isImage(): boolean {
        return this.widgetType === 'SIMPLE_IMAGE_WIDGET';
    }

    noMarginForImageOrVideo(): boolean {
        return this.isImageOrVideo() && this.widgetSize === WidgetSizeEnum.NOMARGIN;
    }

    fullSizeForImageOrVideo(): boolean {
        return this.isImageOrVideo() && this.widgetSize === WidgetSizeEnum.FULLSIZE;
    }

    imageOrVideoOnHomepage(isHomePage: boolean): boolean {
        return this.isImageOrVideo() && isHomePage;
    }

    setWidgetSize(size: WidgetSizeEnum): boolean {
        if (this.widgetSize === size) {
            return false;
        }
        this.widget.data.size = size;
        return true;
    }

    removeResourceIds(resourceIds: string[]): void {
        this.widget.removeResourceIds(resourceIds);
    }

    setErrorVisibility(errorToCheck: string, addError = true): void {
        if (addError) {
            this.widget.data.ignoredErrors = [...(this.widget?.data?.ignoredErrors ?? []), errorToCheck];
            return;
        }
        this.widget.data.ignoredErrors =
            this.widget?.data?.ignoredErrors?.filter((error) => errorToCheck !== error) ?? [];
    }

    originalSize(): boolean {
        return this.widgetSize === WidgetSizeEnum.ORIGINAL;
    }

    hasMargin(): boolean {
        return this.widgetSize === WidgetSizeEnum.MARGIN;
    }

    noMargin(): boolean {
        return this.widgetSize === WidgetSizeEnum.NOMARGIN;
    }

    fullSize(): boolean {
        return this.widgetSize === WidgetSizeEnum.FULLSIZE;
    }

    canResize(): boolean {
        return (
            this.isImageOrVideo() ||
            this.widgetType === 'NAVIGATION_BLOCK_WIDGET' ||
            this.widgetType === 'IMAGE_TEXT_WIDGET' ||
            this.widgetType === 'VIDEO_TEXT_WIDGET' ||
            this.widgetType === 'AUDIO_TEXT_WIDGET' ||
            this.widgetType === 'DOCUMENT_TEXT_WIDGET' ||
            this.widgetType === 'YOUTUBE_WIDGET' ||
            this.widgetType === 'EMBEDDED_DOCUMENT_WIDGET' ||
            this.widgetType === 'IMAGE_IMAGE_WIDGET' ||
            this.widgetType === 'EXAM_WIDGET'
        );
    }

    setDisbledActionButtons = (disable: boolean): void => {
        this.disableActionButtons = disable;
        // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    };

    hasCaption(): boolean {
        const widgetData = this.widget.data;
        return (
            this.widgetType === 'SIMPLE_IMAGE_WIDGET' ||
            (this.widgetType === 'SIMPLE_VIDEO_WIDGET' && widgetData.hasTranscript && widgetData.transcript)
        );
    }
}
