<form [formGroup]="form" class="real-time-video-widget" [class.XL]="fullSize">
    <app-video-upload
        #videoUploadControl
        class="app-video-upload"
        [showDeleteButtonOnHover]="false"
        [posterValue]="previewImageElement"
        [formControlName]="'element'"
        [courseId]="config?.courseId"
        [subtitle]="subtitle"
        [loop]="loop"
        [muted]="muted"
        [autoplay]="autoplay"
        [controlsEnabled]="controlsEnabled"
        [availableScales]="config?.availableScales ?? []"
        (deletePoster)="deletePreviewImage()"
    >
        <div class="video-widget__upload">
            <img src="assets/images/simple-video-widget.svg" class="video-widget__img" alt="" />
            <span class="video-widget__upload-text">{{ 'VIDEO-WIDGET.VIDEO-DRAG-OR' | translate }}</span>
            <button type="button" class="video-widget__upload-button template-btn editor-btn section-btn border-2">
                {{ 'VIDEO-WIDGET.VIDEO-UPLOAD' | translate }}
            </button>
        </div>
    </app-video-upload>
    <div class="custom-collapse-card">
        <app-collapse-card
            *ngIf="transcript && hasTranscript"
            [title]="'VIDEO-WIDGET.CAPTION-PLACEHOLDER' | translate"
            [opened]="false"
        >
            <figcaption class="video-widget__caption section-font-color" [innerHTML]="transcript"></figcaption>
        </app-collapse-card>
    </div>
</form>
