import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import { RealTimeLearningCardWidgetForm } from '../../../../api-wrapper/models/widget-models/learning-card-widget-model';

@Component({
    selector: 'app-learning-card-real-time-widget',
    templateUrl: './learning-card-real-time-widget.component.html',
    styleUrls: ['./learning-card-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LearningCardRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class LearningCardRealTimeWidgetComponent extends AbstractFormBaseComponent<
    RealTimeLearningCardWidgetForm,
    IWidgetSupport
> {
    testEnv = false;
    flip = 'active';
    openedLinkEditor = false;
    constructor() {
        super();
    }

    createForm(): FormGroupTyped<RealTimeLearningCardWidgetForm> {
        return new FormGroupTyped<RealTimeLearningCardWidgetForm>({
            statement: FormHelper.controlFactoryWithCalculatedValue(null),
            explanation: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
