import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnchorWidgetData, PageResponse, WidgetResponse } from '@apiModels';
import { debounceTime, distinctUntilChanged, map, Observable, of, pluck, Subject, switchMap, takeUntil } from 'rxjs';

export interface IPath {
    pageId: number;
    widgetId: number;
    name: string;
    page: PageResponse;
}
export interface IAnchorSelector {
    name: string;
    anchors: IPath[];
}

@Component({
    selector: 'app-path-selector',
    templateUrl: './path-selector.component.html',
    styleUrls: ['./path-selector.component.scss'],
})
export class PathSelectorComponent implements OnInit, OnDestroy {
    pages: IPath[] = [];
    anchors: IAnchorSelector[] = [];
    searchControl = new UntypedFormGroup({
        searchTerm: new UntypedFormControl(''),
    });
    filteredOptions: Observable<IPath[]>;
    filteredAnchorOptions: Observable<IAnchorSelector[]>;
    selected: IPath;
    takeUntilSub = new Subject<void>();
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { pages: PageResponse[]; anchors: WidgetResponse[] },
        public dialogRef: MatDialogRef<PathSelectorComponent>
    ) {}

    ngOnInit(): void {
        this.pages = this.data.pages.map((page) => ({
            name: page.name,
            pageId: page.id,
            widgetId: null,
            page: page,
        }));
        this.searchForPages();
        this.initAnchorsList();
        this.searchAnchors();
    }

    searchForPages(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.filteredOptions = this.searchControl.valueChanges.pipe(
            pluck('searchTerm'),
            map((fragment: unknown) => (typeof fragment === 'string' ? fragment.toLowerCase() : '')),
            distinctUntilChanged(),
            debounceTime(300),
            switchMap((name: string) => {
                if (!name) {
                    return of([]);
                }
                this.selected = null;
                return of(this.pages.filter((page) => page.name?.toLowerCase().includes(name)));
            }),
            takeUntil(this.takeUntilSub)
        );
    }

    initAnchorsList(): void {
        const pageIdObj: { [key: number]: { name: string; anchors: WidgetResponse[]; page: PageResponse } } = {};
        this.data.anchors.forEach((anchor: WidgetResponse) => {
            if (pageIdObj[anchor.pageId]) {
                (pageIdObj[anchor.pageId]?.anchors ?? [])?.push(anchor);
                return;
            }
            const page = this.data.pages.find((page) => page.id === anchor.pageId);
            pageIdObj[anchor.pageId] = {
                name: page?.name ?? '',
                anchors: [anchor],
                page: page,
            };
        });
        this.anchors = Object.keys(pageIdObj).map((key: string) => {
            const obj = pageIdObj[Number(key)];
            return {
                name: obj.name,
                anchors: obj.anchors.map((anchor) => {
                    const data = anchor.data as AnchorWidgetData;
                    return {
                        pageId: anchor.pageId,
                        widgetId: anchor.id,
                        name: data.name ?? '',
                        page: pageIdObj[anchor.pageId].page,
                    };
                }),
            };
        });
    }

    searchAnchors(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.filteredAnchorOptions = this.searchControl.valueChanges.pipe(
            pluck('searchTerm'),
            map((fragment: unknown) => (typeof fragment === 'string' ? fragment.toLowerCase() : '')),
            distinctUntilChanged(),
            debounceTime(300),
            switchMap((name: string) => {
                if (!name) {
                    return of([]);
                }
                this.selected = null;
                const currAnchors = this.anchors
                    .map((group) => ({
                        name: group.name,
                        anchors: group.anchors.filter((anchor) => anchor.name.toLowerCase().includes(name)),
                    }))
                    .filter((group) => group.anchors.length > 0);
                return of(currAnchors);
            }),
            takeUntil(this.takeUntilSub)
        );
    }

    onPageSelected(event: MatAutocompleteSelectedEvent): void {
        this.selected = event.option.value as IPath;
        this.searchControl.patchValue({ searchTerm: this.selected.name }, { emitEvent: false });
    }

    onSelect(): void {
        this.dialogRef.close(this.selected);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.takeUntilSub.next();
        this.takeUntilSub.complete();
    }
}
