<div class="nav-delete-modal-header" data-e2e="navigation-delete-confirm-modal">
    <h2>{{ data.titlePath | translate }}</h2>
    <button
            class="editor-btn height-small radius-25 border-0 primary icon-only"
            mat-dialog-close
            [mat-dialog-close]="resultNone"
            [attr.aria-label]="'MATERIAL.DIALOG.CLOSE' | translate"
        >
            <span class="nitroicon-close editor-btn-icon"></span>
    </button>
</div>
<p class="nav-delete-modal-message">{{ data.messagePath | translate }}</p>
<mat-dialog-actions class="nav-delete-modal-actions">
    <button class="dialog-actions__button editor-btn border-2 primary" [mat-dialog-close]="resultPrimary" data-e2e="modal-confirm-button">
        {{ data.primaryButtonTextPath | translate }}
    </button>
    <button class="dialog-actions__button editor-btn border-2 secondary-blue" [mat-dialog-close]="resultSecondary" data-e2e="modal-cancel-button">
        {{ data.secondaryButtonTextPath | translate }}
    </button>
</mat-dialog-actions>
