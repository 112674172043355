import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@apiModels';
import { MenuActionInterface } from '../material-wrappers/material-menu/material-menu.component';

export interface UserAttributes {
    locale?: string[];
    profile_picture?: string[];
}

export interface ExtendedUser extends User {
    attributes: UserAttributes;
    firstName: string;
    lastName: string;
}

@Component({
    selector: 'app-user-info-card',
    templateUrl: './user-info-card.component.html',
    styleUrls: ['./user-info-card.component.scss'],
})
export class UserInfoCardComponent implements OnInit {
    @Input() user: ExtendedUser;
    @Output() actionEvent = new EventEmitter<MenuActionInterface<string, any>>();
    profileImg: string;
    monogram: string;

    ngOnInit(): void {
        const profileImg = this.user?.attributes?.profile_picture;
        this.profileImg = profileImg?.length ? profileImg[0] : '';

        const firstLetter = this.user?.lastName?.length ? this.user?.lastName[0] : '';
        const lastLetter = this.user?.firstName?.length ? this.user?.firstName[0] : '';
        this.monogram = `${firstLetter}${lastLetter}`;
    }

    onActionEvent(action: string, item: any): void {
        this.actionEvent.emit({ action, item });
    }
}
