import { ElementRef, Injectable } from '@angular/core';
import { ThemeData } from '@apiModels';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PreviewMessageService {
    public previewIsReady = new Subject<string>();

    private currentPreviewRefs: Map<string, ElementRef<HTMLIFrameElement>> = new Map<
        string,
        ElementRef<HTMLIFrameElement>
    >();

    public addPreview(ref: ElementRef<HTMLIFrameElement>, code: string): void {
        this.currentPreviewRefs.set(code, ref);
    }

    public sendThemeData(theme: ThemeData, type: 'THEME' | 'DATA' | 'THEMEANDDATA', code = 'preview'): void {
        const ref = this.currentPreviewRefs.get(code);
        if (ref) {
            const content = ref?.nativeElement?.contentWindow;
            content?.postMessage(
                {
                    type,
                    data: theme,
                },
                '*'
            );
        }
    }

    removePreview(code: string) {
        this.currentPreviewRefs.delete(code);
    }
}
