import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';

import { BaseFileUploadComponent } from '../base-file-upload/base-file-upload.component';

@Component({
    selector: 'app-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss', '../base-file-upload/base-file-upload.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DocumentUploadComponent),
            multi: true,
        },
    ],
})
export class DocumentUploadComponent extends BaseFileUploadComponent {
    @Input() acceptTypes =
        'application/pdf, ' +
        'application/zip, .rar,' +
        'application/msword, .doc, ' +
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document, .docx, ' +
        'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
        'application/vnd.ms-powerpoint, .ppt,' +
        'application/vnd.openxmlformats-officedocument.presentationml.presentation, .pptx, ' +
        'application/vnd.oasis.opendocument.text, .odt, ' +
        'application/vnd.oasis.opendocument.spreadsheet, .ods, ' +
        'application/vnd.oasis.opendocument.presentation, .odp';

    @Input() regExp = new RegExp(/\.(pdf|zip|rar|doc|docx|xls|xlsx|ppt|pptx|odt|ods|odp)$/i);
    @Input() maxFileSize = 1_000_000_000;
    @Input() originalFileName: string;
    @Input() height = '400';
    @Input() title = '';

    constructor(protected toastsSrv: ToastMessageService, private cdr: ChangeDetectorRef) {
        super(toastsSrv, cdr);
    }

    get pdfType(): boolean {
        const idPdf = this.value?.elementId?.endsWith('pdf');
        const filePdf = this.value?.fileItem?.mime_type === 'application/pdf';
        return idPdf || filePdf;
    }

    iframeLoaded(e: any): void {
        const iframe = e.srcElement;
        if (iframe.contentDocument) {
            const ifTitle = iframe.contentDocument.title;
            if (ifTitle.indexOf('404') >= 0) {
                this.fileSrc = null;
            }
        }
    }
}
