import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { IFileUploadValue } from '@shared/models/file-upload.model';
import { FileUploadHandlerService } from '@sharedServices/file-upload-handler.service';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-slide-upload',
    templateUrl: './slide-upload.component.html',
    styleUrls: ['./slide-upload.component.scss', '../base-file-upload/base-file-upload.scss'],
})
export class SlideUploadComponent {
    @Input() addSlideType: 'ADD' | 'START_PAGE' = 'ADD';
    @Input() courseId: number;
    @Input() widgetId: number;
    @Input() pageId: number;
    @Input() embeded = false;
    @Output() fileItemAdded = new EventEmitter<IFileUploadValue>();
    @Output() pptProccessStarted = new EventEmitter<string>();
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('pptFileInput') pptFileInput: ElementRef;
    subscription: Subscription;
    acceptTypes = 'image/png, image/jpeg, image/jpg, image/gif, image/tiff, image/svg+xml';
    regExp = new RegExp(/\.(gif|jpg|jpeg|tiff|png|svg|webp|jfif)$/i);
    maxFileSize = 1_000_000_000;
    pptRegexp = new RegExp(/\.(pptx)$/i);
    pptAcceptTypes = '.pptx,';

    constructor(
        protected toastsSrv: ToastMessageService,

        private fileUploadSrv: FileUploadHandlerService
    ) {}

    landFile(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (!event?.dataTransfer?.files?.length) {
            return;
        }
        const file = event.dataTransfer.files[0];
        if (this.pptRegexp.test(file.name)) {
            this.processPPTFiles(file);
            return;
        }

        this.processFile(file);
    }

    ppTfileChangeListener(): void {
        const hasFile =
            this.pptFileInput && this.pptFileInput.nativeElement && this.pptFileInput.nativeElement.files.length > 0;

        if (!hasFile) {
            return;
        }
        this.processPPTFiles(this.pptFileInput.nativeElement.files[0]);
    }

    fileChangeListener(): void {
        const hasFile = this.fileInput && this.fileInput.nativeElement && this.fileInput.nativeElement.files.length > 0;

        if (!hasFile) {
            return;
        }

        this.processFile(this.fileInput.nativeElement.files[0]);
    }

    protected processPPTFiles(file: File): void {
        if (this.testFileParams(file, this.pptRegexp)) {
            return;
        }
        this.pptFileInput.nativeElement.value = null;
        const value = this.createFileItem(file);

        this.fileUploadSrv.sendFilesToUpload([
            { courseId: this.courseId, pageId: this.pageId, relevantWidgetId: this.widgetId, file: value.fileItem },
        ]);

        if (this.addSlideType === 'START_PAGE') {
            this.pptProccessStarted.emit(value.fileItem.uuid);
        }
    }

    protected processFile(file: File): void {
        if (this.testFileParams(file, this.regExp)) {
            return;
        }

        this.fileInput.nativeElement.value = null;
        const value = this.createFileItem(file);
        this.fileItemAdded.emit(value);
    }

    createFileItem(file: File): IFileUploadValue {
        const value = { fileItem: {} } as IFileUploadValue;

        const [extension, ...rest] = file.name.split('.').reverse();
        value.fileItem.mime_type = file.type ? file.type : extension;
        value.fileItem.name = file.name;
        value.fileItem.resource = file;
        value.fileItem.uuid = UUIDGeneratorUtility.generate() + '.' + extension;
        return value;
    }

    testFileParams(file: File, regExp: RegExp): boolean {
        if (!regExp.test(file.name)) {
            this.toastsSrv.error({ translatedMessage: 'ERROR-MESSAGE.ERROR-FORMAT' });
            return true;
        }

        if (this.maxFileSize && file.size > this.maxFileSize) {
            this.toastsSrv.error({ translatedMessage: 'ERROR-MESSAGE.ERROR-SIZE' });
            return true;
        }
        return false;
    }
}
