import { Injectable } from '@angular/core';
import { CarouselWidgetData, CourseVersionResponse } from '@apiModels';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    constructor(private stompService: RxStompService) {}

    watchFactory<T>(path: string): Observable<T> {
        return this.stompService.watch(path).pipe(map((message) => JSON.parse(message.body) as T));
    }

    watchGenerator(version: CourseVersionResponse): Observable<CourseVersionResponse> {
        return this.watchFactory<CourseVersionResponse>(`/topic/generator.${version.courseId}.${version.version}`);
    }

    watchPptProcess(widgetId: number, courseId: number): Observable<CarouselWidgetData> {
        return this.watchFactory<CarouselWidgetData>(`/topic/pptprocessing.${courseId}.${widgetId}`);
    }
}
