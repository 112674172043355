import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { WindowEventService } from '@sharedServices/window-event.service';
import { URLHelperUtility } from '@sharedUtilities/url-helpers.utility';
import { Subscription } from 'rxjs';

import { CustomLinkAttributes } from '../quill-formats/custom-link.bloat';

@Component({
    selector: 'app-quill-link-editor',
    templateUrl: './quill-link-editor.component.html',
    styleUrls: ['./quill-link-editor.component.scss'],
})
export class QuillLinkEditorComponent implements OnInit, OnDestroy {
    @Input() link: CustomLinkAttributes;
    @Output() editDone = new EventEmitter<{ action: 'DELETE' | 'CANCEL' | 'DONE'; link: CustomLinkAttributes }>();
    form: UntypedFormGroup;
    url: string;
    hasStartValue = false;
    subscription = new Subscription();
    mousedownTarget;

    targetOptions: IChekboxRadioFormModel[] = [
        { id: 'option-open-blank', label: 'QUILL.LINK-EDIT-LOCATION-BLANK', value: '_blank' },
        { id: 'option-open-local', label: 'QUILL.LINK-EDIT-LOCATION-SELF', value: '_self' },
    ];

    constructor(private windowEventService: WindowEventService) {}

    ngOnInit(): void {
        this.url = this.link?.value ?? '';
        this.form = new UntypedFormGroup({
            link: new UntypedFormControl(this.link?.value ?? '', Validators.required),
            target: new UntypedFormControl(this.link?.target ?? '_blank'),
        });
        if (this.url) {
            this.hasStartValue = true;
        }

        this.subscription.add(
            this.windowEventService.mousedownEvent().subscribe((event: Event) => {
                this.mousedownTarget = event.target;
            })
        );

        this.subscription.add(
            this.windowEventService.clickEvent().subscribe((event: Event) => {
                if (
                    !this.mousedownTarget.closest('app-quill-link-editor') &&
                    !(event.target as HTMLElement).closest('app-quill-link-editor')
                ) {
                    this.cancel();
                }
            })
        );
    }

    onLinkChange(text: string): void {
        this.url = text;
    }

    addLink(): void {
        const value = this.form.value.link.trim();
        if (!value) {
            this.form.markAllAsTouched();
            return;
        }
        const target = this.form.value.target;
        const link = URLHelperUtility.urlSanitizer(value) ?? '';

        this.editDone.emit({ action: 'DONE', link: { value: link as string, target } });
    }

    deleteLink(): void {
        this.editDone.emit({ action: 'DELETE', link: null });
    }

    cancel(): void {
        this.editDone.emit({ action: 'CANCEL', link: null });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
