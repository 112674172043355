import { animate, style, transition, trigger } from '@angular/animations';

const baseTime = 400;
const timings = {
    fast: baseTime * 0.5 + 'ms ease-out',
    normal: baseTime + 'ms ease-out',
    slow: baseTime * 2 + 'ms ease-out',
};

export enum AnimationDirection {
    'LEFT' = 'LEFT',
    'RIGHT' = 'RIGHT',
}
export const slideAnimations = [
    trigger('parent', [transition(':enter', [])]),
    trigger('slideAnimation', [
        transition('void => LEFT', [
            style({ transform: 'translateX(-100%)', overflow: 'hidden' }),
            animate(timings.slow, style({ transform: 'translateX(0)' })),
        ]),
        transition('LEFT => void', [
            style({ transform: 'translateX(0)', overflow: 'hidden' }),
            animate(timings.slow, style({ transform: 'translateX(+100%)' })),
        ]),

        transition('void => RIGHT', [
            style({ transform: 'translateX(100%)', overflow: 'hidden' }),
            animate(timings.slow, style({ transform: 'translateX(0)' })),
        ]),
        transition('RIGHT => void', [
            style({ transform: 'translateX(0)', overflow: 'hidden' }),
            animate(timings.slow, style({ transform: 'translateX(-100%)' })),
        ]),
    ]),
];
