/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DirectoryRequest } from '../models/directory-request';
import { DirectoryResponse } from '../models/directory-response';

@Injectable({
  providedIn: 'root',
})
export class DirectoryControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDirectory
   */
  static readonly GetDirectoryPath = '/directories/{id}';

  /**
   * Get directory by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDirectory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectory$Response(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<DirectoryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.GetDirectoryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryResponse>;
      })
    );
  }

  /**
   * Get directory by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDirectory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectory(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<DirectoryResponse> {

    return this.getDirectory$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryResponse>) => r.body as DirectoryResponse)
    );
  }

  /**
   * Path part for operation changeDirectoryName
   */
  static readonly ChangeDirectoryNamePath = '/directories/{id}';

  /**
   * Change directory name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeDirectoryName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeDirectoryName$Response(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: DirectoryRequest
  }): Observable<StrictHttpResponse<DirectoryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.ChangeDirectoryNamePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryResponse>;
      })
    );
  }

  /**
   * Change directory name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeDirectoryName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeDirectoryName(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: DirectoryRequest
  }): Observable<DirectoryResponse> {

    return this.changeDirectoryName$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryResponse>) => r.body as DirectoryResponse)
    );
  }

  /**
   * Path part for operation deleteDirectory
   */
  static readonly DeleteDirectoryPath = '/directories/{id}';

  /**
   * Delete directory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDirectory()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDirectory$Response(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.DeleteDirectoryPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete directory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDirectory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDirectory(params: {
    id: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.deleteDirectory$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reorderDirectories
   */
  static readonly ReorderDirectoriesPath = '/directories/reorder';

  /**
   * Reorder directories of a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reorderDirectories()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reorderDirectories$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: Array<number>
  }): Observable<StrictHttpResponse<Array<DirectoryResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.ReorderDirectoriesPath, 'put');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DirectoryResponse>>;
      })
    );
  }

  /**
   * Reorder directories of a user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reorderDirectories$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reorderDirectories(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: Array<number>
  }): Observable<Array<DirectoryResponse>> {

    return this.reorderDirectories$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DirectoryResponse>>) => r.body as Array<DirectoryResponse>)
    );
  }

  /**
   * Path part for operation getDirectories
   */
  static readonly GetDirectoriesPath = '/directories';

  /**
   * List all directories of logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDirectories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectories$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<DirectoryResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.GetDirectoriesPath, 'get');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DirectoryResponse>>;
      })
    );
  }

  /**
   * List all directories of logged in user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDirectories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectories(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<DirectoryResponse>> {

    return this.getDirectories$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DirectoryResponse>>) => r.body as Array<DirectoryResponse>)
    );
  }

  /**
   * Path part for operation createDirectory
   */
  static readonly CreateDirectoryPath = '/directories';

  /**
   * Create directory for currently logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDirectory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDirectory$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: DirectoryRequest
  }): Observable<StrictHttpResponse<DirectoryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.CreateDirectoryPath, 'post');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DirectoryResponse>;
      })
    );
  }

  /**
   * Create directory for currently logged in user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDirectory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDirectory(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: DirectoryRequest
  }): Observable<DirectoryResponse> {

    return this.createDirectory$Response(params).pipe(
      map((r: StrictHttpResponse<DirectoryResponse>) => r.body as DirectoryResponse)
    );
  }

  /**
   * Path part for operation addCourseToDirectory
   */
  static readonly AddCourseToDirectoryPath = '/directories/{directoryId}/course/{courseId}';

  /**
   * Add course to a directory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCourseToDirectory()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCourseToDirectory$Response(params: {
    directoryId: number;
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.AddCourseToDirectoryPath, 'post');
    if (params) {
      rb.path('directoryId', params.directoryId, {});
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Add course to a directory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCourseToDirectory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCourseToDirectory(params: {
    directoryId: number;
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.addCourseToDirectory$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeCourseFromDirectory
   */
  static readonly RemoveCourseFromDirectoryPath = '/directories/{directoryId}/course/{courseId}';

  /**
   * Delete course from a directory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCourseFromDirectory()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCourseFromDirectory$Response(params: {
    directoryId: number;
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.RemoveCourseFromDirectoryPath, 'delete');
    if (params) {
      rb.path('directoryId', params.directoryId, {});
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete course from a directory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCourseFromDirectory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCourseFromDirectory(params: {
    directoryId: number;
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.removeCourseFromDirectory$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getGlobalDirectories
   */
  static readonly GetGlobalDirectoriesPath = '/directories/global';

  /**
   * List global directories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalDirectories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalDirectories$Response(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<DirectoryResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.GetGlobalDirectoriesPath, 'get');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DirectoryResponse>>;
      })
    );
  }

  /**
   * List global directories.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGlobalDirectories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalDirectories(params?: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<DirectoryResponse>> {

    return this.getGlobalDirectories$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DirectoryResponse>>) => r.body as Array<DirectoryResponse>)
    );
  }

  /**
   * Path part for operation getDirectoriesContainCourse
   */
  static readonly GetDirectoriesContainCoursePath = '/directories/course/{courseId}';

  /**
   * Get global and user directories contain given course for logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDirectoriesContainCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectoriesContainCourse$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<Array<DirectoryResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, DirectoryControllerService.GetDirectoriesContainCoursePath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DirectoryResponse>>;
      })
    );
  }

  /**
   * Get global and user directories contain given course for logged in user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDirectoriesContainCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectoriesContainCourse(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<Array<DirectoryResponse>> {

    return this.getDirectoriesContainCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DirectoryResponse>>) => r.body as Array<DirectoryResponse>)
    );
  }

}
