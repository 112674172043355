<div class="loader-container">
    <div class="loader">
        <div class="rect-container left"><div class="rect"></div></div>
        <div class="rect-container center"><div class="rect"></div></div>
        <div class="rect-container right"><div class="rect"></div></div>
    </div>
    <ng-container [ngSwitch]="type">
        <div class="preview-text" *ngSwitchCase="'COPY'">{{ 'COURSES.DUPLICATE-COURSE-IN-PROGGRESS' | translate }}</div>
    </ng-container>
</div>
