<form [formGroup]="form">
    <header class="detail-form__header" [ngClass]="sizeClass">
        <div class="detail-form__header__action-buttons">
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.MARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.MARGIN)"
            >
                <span class="nitroicon-block_narrow editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.NOMARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.NOMARGIN)"
            >
                <span class="nitroicon-block_mid editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.FULLSIZE }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.FULLSIZE)"
            >
                <span class="nitroicon-block_fullsize editor-btn-icon"></span>
            </button>
        </div>
    </header>

    <ng-template #realTimeElement>
        <app-image-image-real-time-widget
            #realTimeImageImage
            *ngIf="!testEnv"
            class="detail-form__real-time-widget"
            [ngClass]="sizeClass"
            [formControlName]="'realTimeImageImageWidgetFormData'"
            [config]="config"
            [location]="'detail'"
            [dividerLocation]="dividerLocationValue"
            [imageFit]="imageFitValue"
            [otherImageFit]="otherImageFitValue"
            [flipped]="flippedValue"
        ></app-image-image-real-time-widget>
    </ng-template>
    <ng-container *ngIf="size === widgetSizeEnum.FULLSIZE">
        <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
    </ng-container>

    <section class="detail-form resizable" [ngClass]="sizeClass">
        <div class="detail-form__site-left">
            <ng-container *ngIf="size !== widgetSizeEnum.FULLSIZE">
                <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
            </ng-container>

            <app-collapse-card [title]="'IMAGE-TEXT-WIDGET.ACCESSIBILTY-CARD-1' | translate" [opened]="true">
                <div class="detail-form__group">
                    <label class="detail-form__group__label" for="imageDescription"
                        >{{ 'IMAGE-WIDGET.ACCESSIBILTY-TEXT' | translate }}
                    </label>
                    <textarea
                        name="imageDescription"
                        id="imageDescription"
                        formControlName="imageDescription"
                    ></textarea>
                </div>
            </app-collapse-card>

            <app-collapse-card [title]="'IMAGE-TEXT-WIDGET.ACCESSIBILTY-CARD-2' | translate" [opened]="true">
                <div class="detail-form__group">
                    <label class="detail-form__group__label" for="otherImageDescription"
                        >{{ 'IMAGE-WIDGET.ACCESSIBILTY-TEXT' | translate }}
                    </label>
                    <textarea
                        name="otherImageDescription"
                        id="otherImageDescription"
                        formControlName="otherImageDescription"
                    ></textarea>
                </div>
            </app-collapse-card>
        </div>

        <div class="detail-form__site-right">
            <app-collapse-card [title]="'IMAGE-TEXT-WIDGET.LAYOUT-TITLE' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.DIVIDER-LOCATION-TITLE' | translate"
                    [options]="dividerOptions"
                    [layout]="'horizontal'"
                    formControlName="dividerLocation"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.POSITION-TITLE' | translate"
                    [options]="flippedAvailableOptions"
                    [layout]="'horizontal'"
                    formControlName="flipped"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.IMAGE-FIT-1' | translate"
                    [options]="imageFitOptions"
                    [layout]="'horizontal'"
                    formControlName="imageFit"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.IMAGE-FIT-2' | translate"
                    [options]="otherImageFitOptions"
                    [layout]="'horizontal'"
                    formControlName="otherImageFit"
                ></app-radio-group-control>
            </app-collapse-card>
            <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'IMAGE-TEXT-WIDGET.FULFILLMENT-AVAILABLE-TITLE' | translate"
                    [options]="fulfillmentAvailableOptions"
                    formControlName="fulfillmentAvailable"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                    [options]="fulfillmentOptions"
                    formControlName="fulfillment"
                ></app-radio-group-control>
            </app-collapse-card>
        </div>
    </section>
</form>
