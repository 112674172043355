import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { collapseAnimation } from '@shared/animations/collapse.animations';

import { DetailSimpleVideoWidgetForm } from '../../../../api-wrapper/models/widget-models/video-widget-model';
import { VideoDetailBaseWidgetComponent } from '../video-base-classes/video-detail-base';

@Component({
    selector: 'app-video-detail-widget',
    templateUrl: './video-detail-widget.component.html',
    styleUrls: ['../video-widget-common/video-widget.component.scss', './video-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VideoDetailWidgetComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation],
})
export class VideoDetailWidgetComponent extends VideoDetailBaseWidgetComponent<DetailSimpleVideoWidgetForm> {
    constructor() {
        super();
    }
}
