/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AudioTextWidgetData } from '@apiModels';
import { IFileUploadValue } from '@shared/models/file-upload.model';

import { BaseWidgetClass } from '../base-widget-models/base-widget-class';
import { TypedResponse } from '../base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from '../base-widget-models/widget-size.enum';
import { MultiWidgetDividerLocation } from '../image-text-widget-model';

export interface RealTimeAudioTextWidgetForm {
    element: IFileUploadValue;
    audioDescription: string;
    text: string | null;
    dividerLocation: MultiWidgetDividerLocation;
    flipped: boolean;
}

export interface DetailAudioTextWidgetForm {
    realTimeAudioTextWidgetFormData: RealTimeAudioTextWidgetForm;
    audioDescription: string;
    dividerLocation: MultiWidgetDividerLocation;
    flipped: boolean;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
    size: WidgetSizeEnum;
}

export interface AudioTextWidgetDataSpec extends AudioTextWidgetData {
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
}

export class AudioTextWidgetClass extends BaseWidgetClass<AudioTextWidgetDataSpec> {
    constructor(data: TypedResponse<AudioTextWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeAudioTextWidgetForm {
        return {
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data?.audioResourceId ?? null,
            },
            audioDescription: this.data?.audioDescription ?? '',
            text: this.data.text ?? null,
            dividerLocation: this.data?.dividerLocation ?? 'CENTER',
            flipped: this.data?.flipped ?? false,
        };
    }

    getDetailFormData(): DetailAudioTextWidgetForm {
        return {
            realTimeAudioTextWidgetFormData: this.getRealTimeFormData(),
            audioDescription: this.data?.audioDescription ?? '',
            dividerLocation: this.data?.dividerLocation ?? 'CENTER',
            flipped: this.data?.flipped ?? false,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
        };
    }

    updateWidgetData(modifiedData: RealTimeAudioTextWidgetForm | DetailAudioTextWidgetForm): void {
        if (modifiedData.hasOwnProperty('realTimeAudioTextWidgetFormData')) {
            const { realTimeAudioTextWidgetFormData: realTimeAudioTextWidgetFormData, ...rest } =
                modifiedData as DetailAudioTextWidgetForm;
            modifiedData = {
                ...realTimeAudioTextWidgetFormData,
                ...rest,
            };
        }

        const { element, ...rest } = modifiedData as RealTimeAudioTextWidgetForm & DetailAudioTextWidgetForm;

        if (this.shouldSaveFile(element.fileItem, this.data.audioResourceId)) {
            this.filesToSave.push(element.fileItem);
        }
        this.fileUploadElement = element.fileItem;
        const audioResourceId = this.extractUUID(element);
        this.data = { ...this.data, ...rest, audioResourceId };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.audioResourceId === id) {
                this.data = { ...this.data, audioResourceId: null };
                this.fileUploadElement = null;
            }
        });
    }
}
