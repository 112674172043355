/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PasswordChangeRequest } from '../models/password-change-request';
import { ProfileChangeRequest } from '../models/profile-change-request';

@Injectable({
  providedIn: 'root',
})
export class ProfileControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateBaseProfileData
   */
  static readonly UpdateBaseProfileDataPath = '/profile';

  /**
   * Update profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBaseProfileData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBaseProfileData$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: ProfileChangeRequest
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileControllerService.UpdateBaseProfileDataPath, 'put');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Update profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateBaseProfileData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBaseProfileData(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: ProfileChangeRequest
  }): Observable<any> {

    return this.updateBaseProfileData$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation updatePassword
   */
  static readonly UpdatePasswordPath = '/profile/password';

  /**
   * Change password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassword$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: PasswordChangeRequest
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileControllerService.UpdatePasswordPath, 'put');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Change password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassword(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: PasswordChangeRequest
  }): Observable<any> {

    return this.updatePassword$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
