/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { QuestionWidgetData, SingleChoiceQuestion } from '@apiModels';
import { IFileUploadElement, IFileUploadValue } from '@shared/models/file-upload.model';
import { Question } from 'src/app/api/models/question';

import { BaseWidgetClass } from '../base-widget-models/base-widget-class';
import { TypedResponse } from '../base-widget-models/typed-widget-response';
import { WidgetModifyParams } from '../base-widget-models/widget-modify-params';
import { DetailQuestionWidgetForm, InitDetailQuestionFormData } from './init-question-detail-form';

export interface RealTimeBaseQuestionWidgetForm {
    answers: any[];
    text: string;
    introduction: string;
    pdfResourceElement?: IFileUploadValue;
    mediaResourceElement?: IFileUploadValue;
    hasAnswerMedia?: boolean;
    hasMedia?: boolean;
    hasPdf?: boolean;
    attempts?: number;
}

export interface DetailBaseQuestionWidgetForm extends DetailQuestionWidgetForm {
    realTimeQuestionWidgetFormData: RealTimeBaseQuestionWidgetForm;
}

export type MixedQuestionForm = RealTimeBaseQuestionWidgetForm & DetailBaseQuestionWidgetForm;
export interface QuestionModel extends Question {
    answers?: any[];
}
export interface BaseQuestionWidgetDataSpec extends QuestionWidgetData {
    question: QuestionModel;
    fulfillment: 'NONE' | 'SHOW' | 'QUESTION_CORRECTLY_ANSWERED' | 'QUESTION_ANSWERED';
    answers: any[];
}

export abstract class QuestionBaseWidgetClass<
    T extends BaseQuestionWidgetDataSpec,
    RealTimeForm extends RealTimeBaseQuestionWidgetForm,
    DetailForm extends DetailBaseQuestionWidgetForm
> extends BaseWidgetClass<T> {
    mediaUploadElement: IFileUploadElement | null = null;
    constructor(data: TypedResponse<T>, answerModel: any) {
        super(data);
        if (answerModel) {
            this.data.answers = this.data?.question?.answers.map((a) => new answerModel(a));
        }
    }

    getRealTimeFormData(): RealTimeForm {
        return {
            answers: this.data.answers.map((b) => b.getFormData()),
            text: this.data?.question?.text ?? null,
            introduction: this.data?.question?.introduction ?? null,
            hasAnswerMedia: this.data?.question?.hasAnswerMedia ?? false,
            hasMedia: this.data?.question?.hasMedia ?? false,
            hasPdf: this.data?.question?.hasPdf ?? false,
            attempts: this.data?.attempts ?? 0,
            pdfResourceElement: {
                fileItem: this.fileUploadElement,
                elementId: this.data.question?.pdfResourceId ?? null,
            },
            mediaResourceElement: {
                fileItem: this.mediaUploadElement,
                elementId: this.data.question?.mediaResourceId ?? null,
            },
        } as RealTimeForm;
    }

    getDetailFormData(): DetailForm {
        return {
            realTimeQuestionWidgetFormData: this.getRealTimeFormData(),
            ...(InitDetailQuestionFormData(this.data) as DetailQuestionWidgetForm),
        } as unknown as DetailForm;
    }

    transformModifiedData(modifiedData: RealTimeForm | DetailForm): Partial<MixedQuestionForm> {
        const detailForm = modifiedData.hasOwnProperty('realTimeQuestionWidgetFormData');
        let mixedForm: Partial<MixedQuestionForm>;
        if (detailForm) {
            const { hasAnswerMedia, hasMedia, hasPdf, attempts, ...rest } = (modifiedData as DetailForm)
                .realTimeQuestionWidgetFormData;
            mixedForm = {
                ...modifiedData,
                ...rest,
            };
        } else {
            mixedForm = { ...modifiedData };
        }
        return mixedForm;
    }

    updatePdf(
        questionData: Question,
        pdfResourceElement: IFileUploadValue
    ): { pdfResourceId: string; pdfOriginalFileName: string } {
        const shouldSaveFile = this.shouldSaveFile(pdfResourceElement?.fileItem, this.data?.question.pdfResourceId);
        if (questionData.hasPdf && shouldSaveFile) {
            this.filesToSave.push(pdfResourceElement.fileItem);
        }
        this.fileUploadElement = questionData.hasPdf ? pdfResourceElement.fileItem : null;
        const pdfResourceId = questionData.hasPdf ? this.extractUUID(pdfResourceElement) : null;
        const originalName = this.fileUploadElement
            ? this.fileUploadElement.name
            : this?.data?.question?.pdfOriginalFileName;
        const pdfOriginalFileName = pdfResourceId ? originalName : null;
        return { pdfResourceId, pdfOriginalFileName };
    }

    updateMedia(questionData: Question, mediaResourceElement: IFileUploadValue): string | null {
        const shouldSaveFile = this.shouldSaveFile(
            mediaResourceElement?.fileItem,
            this.data?.question?.mediaResourceId
        );
        if (questionData.hasMedia && shouldSaveFile) {
            this.filesToSave.push(mediaResourceElement.fileItem);
        }

        this.mediaUploadElement = questionData.hasMedia ? mediaResourceElement.fileItem : null;
        return questionData.hasMedia ? this.extractUUID(mediaResourceElement) : null;
    }

    abstract updateAnswers(questionData: Question, answers: any[]): any[];

    updateWidgetData(newData: RealTimeForm | DetailForm): void {
        const modifiedData = this.transformModifiedData(newData);

        const { answers, ...detailData } = modifiedData;

        const {
            attempts,
            enableAnswerExplanation,
            enableCustomExplanation,
            enableGeneralExplanation,
            fulfillment,
            fulfillmentAvailable,
            mediaResourceElement,
            pdfResourceElement,
            ...questionData
        } = detailData as any;

        // * Cast the elem so we will have type safety and autocomplete
        const tempQuestionData: SingleChoiceQuestion = { ...questionData };

        // * Update answers
        const updatedAnswers = this.updateAnswers(tempQuestionData, answers);

        // * Update pdf
        const { pdfResourceId, pdfOriginalFileName } = this.updatePdf(tempQuestionData, pdfResourceElement);

        // * Update media
        const mediaResourceId = this.updateMedia(tempQuestionData, mediaResourceElement);

        const hasExplanation = tempQuestionData.hasExplanation ?? this.data.question.hasExplanation;
        const hasCustomExplanation = tempQuestionData.hasCustomExplanation ?? this.data.question.hasCustomExplanation;
        // * Update QuestionData
        const question: SingleChoiceQuestion = {
            ...this.data.question,
            ...tempQuestionData,
            pdfResourceId,
            mediaResourceId,
            pdfOriginalFileName,
            explanation: hasExplanation ? tempQuestionData.explanation ?? this.data.question.explanation : null,
            explanationCorrect: hasCustomExplanation
                ? tempQuestionData.explanationCorrect ?? this.data.question.explanationCorrect
                : null,
            explanationIncorrect: hasCustomExplanation
                ? tempQuestionData.explanationIncorrect ?? this.data.question.explanationIncorrect
                : null,
            explanationPartialCorrect: hasCustomExplanation
                ? tempQuestionData.explanationPartialCorrect ?? this.data.question.explanationPartialCorrect
                : null,
            answers: updatedAnswers,
        };

        // *  Widget level data
        const widgetLevelData = {
            attempts: attempts ?? this.data.attempts,
            enableAnswerExplanation: tempQuestionData.hasAnswerExplanation, // enableAnswerExplanation ?? this.data.enableAnswerExplanation,
            enableCustomExplanation: question.hasCustomExplanation, // enableCustomExplanation ?? this.data.enableCustomExplanation,
            enableGeneralExplanation: question.hasExplanation, // enableGeneralExplanation ?? this.data.enableGeneralExplanation,
            fulfillmentAvailable: fulfillmentAvailable ?? this.data.fulfillmentAvailable,
            fulfillment: fulfillment ?? this.data.fulfillment,
        };

        this.data = { ...this.data, ...widgetLevelData, question };
    }

    getWidgetModifyRequest(): WidgetModifyParams<T> {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { answers, ...rest } = this.data;
        const question = { ...rest.question };

        return {
            widgetId: this.id,
            body: { data: { ...rest, question } as T },
        };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.question.pdfResourceId === id) {
                this.data = {
                    ...this.data,
                    question: { ...this.data.question, pdfResourceId: null, pdfOriginalFileName: null },
                };
                this.fileUploadElement = null;
            } else if (this.data.question.mediaResourceId === id) {
                this.data = { ...this.data, question: { ...this.data.question, mediaResourceId: null } };
                this.mediaUploadElement = null;
            } else if (this.data.answers) {
                const answer = this.data.answers.find((answerElem) => answerElem.data.mediaResourceId === id);
                if (answer) {
                    const updateElem = {
                        ...answer.data,
                        mediaResourceElement: { fileItem: null, elementId: null },
                    };
                    answer.updateData(updateElem);
                }
            }
        });

        if (this.data.answers) {
            this.data.question.answers = this.data.answers.map((e) => e.getDataForSave());
        }
    }
}
