import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';

import { BaseFileUploadComponent } from '../base-file-upload/base-file-upload.component';

@Component({
    selector: 'app-font-upload',
    templateUrl: './font-upload.component.html',
    styleUrls: ['./font-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FontUploadComponent),
            multi: true,
        },
    ],
})
export class FontUploadComponent extends BaseFileUploadComponent {
    @Input() acceptTypes = '';
    @Input() regExp = new RegExp(/\./i);
    @Input() maxFileSize = 50_000_000_0;
    bypassSw = true;
    @ViewChild('droparea') audioElement: ElementRef<any>;

    constructor(protected toastsSrv: ToastMessageService, private cdr: ChangeDetectorRef) {
        super(toastsSrv, cdr);
    }
}
