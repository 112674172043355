import { Component, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { VideoUploadComponent } from '@shared/forms/file-upload/video-upload/video-upload.component';
import { IFileUploadValue } from '@shared/models/file-upload.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { URLHelperUtility } from '@sharedUtilities/url-helpers.utility';
import { WidgetSizeEnum } from 'src/app/api-wrapper/models/widget-models/base-widget-models/widget-size.enum';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import {
    ISubtitleFormData,
    RealTimeSimpleVideoWidgetForm,
} from '../../../../api-wrapper/models/widget-models/video-widget-model';

@Component({
    selector: 'app-video-real-time-widget',
    templateUrl: './video-real-time-widget.component.html',
    styleUrls: ['../video-widget-common/video-widget.component.scss', './video-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VideoRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class VideoRealTimeWidgetComponent extends AbstractFormBaseComponent<
    RealTimeSimpleVideoWidgetForm,
    IWidgetSupport
> {
    @ViewChild('videoUploadControl', { static: false }) videoUploadControl: VideoUploadComponent;
    previewImageElement: string;
    hasTranscript: boolean;
    transcript: string;
    subtitle: ISubtitleFormData;
    fullSize: boolean;
    constructor() {
        super();
    }

    get hasVideo(): boolean {
        const video = this.form.get('element').value as IFileUploadValue;
        return video && (video.fileItem !== null || !!video.elementId);
    }

    get loop(): boolean {
        const loop = this.form.get('loop')?.value as boolean;
        return loop ?? false;
    }

    get muted(): boolean {
        const muted = this.form.get('muted')?.value as boolean;
        return muted ?? false;
    }

    get autoplay(): boolean {
        const autoplay = this.form.get('autoplay')?.value as boolean;
        return autoplay ?? false;
    }

    get controlsEnabled(): boolean {
        const controlsEnabled = this.form.get('controlsEnabled')?.value as boolean;
        return controlsEnabled ?? true;
    }

    deletePreviewImage(): void {
        this.form.patchValue({ previewImageElement: { elementId: null, fileItem: null } });
        this.previewImageElement = null;
    }

    updateForm(value: RealTimeSimpleVideoWidgetForm): void {
        this.form.setValue(value, { emitEvent: false });
        this.initUrlsAndVars();
        this.videoUploadControl?.reloadVideo();
    }

    initUrlsAndVars(): void {
        const element = this.form.get('previewImageElement').value as IFileUploadValue;
        this.fullSize = this.config.starterWidgetSize === WidgetSizeEnum.FULLSIZE;
        this.previewImageElement = element?.fileItem
            ? URL.createObjectURL(element?.fileItem.resource)
            : element?.elementId
            ? URLHelperUtility.imageResourceURLForCourse(this.config.courseId, element?.elementId, 'L')
            : null;

        this.hasTranscript = this.form.get('hasTranscript').value as boolean;
        this.transcript = this.form.get('transcript').value as string;
        this.subtitle = this.form.get('subtitleClass').value as ISubtitleFormData;
    }

    createForm(): FormGroupTyped<RealTimeSimpleVideoWidgetForm> {
        return new FormGroupTyped<RealTimeSimpleVideoWidgetForm>({
            element: FormHelper.controlFactoryWithCalculatedValue(null),
            previewImageElement: FormHelper.controlFactoryWithCalculatedValue(null),
            transcript: FormHelper.controlFactoryWithCalculatedValue(null),
            hasTranscript: FormHelper.controlFactoryWithCalculatedValue(null),
            subtitleClass: this.createSubtitles(),
            loop: FormHelper.controlFactoryWithCalculatedValue(null),
            muted: FormHelper.controlFactoryWithCalculatedValue(null),
            autoplay: FormHelper.controlFactoryWithCalculatedValue(null),
            controlsEnabled: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    createSubtitles(data?: ISubtitleFormData): FormGroupTyped<ISubtitleFormData> {
        return new FormGroupTyped<ISubtitleFormData>({
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: data?.element?.fileItem ?? null,
                elementId: data?.element?.elementId ?? null,
            }),
            srcLang: FormHelper.controlFactoryWithCalculatedValue(null),
            originalFileName: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    configDataChanged(widgetSize?: WidgetSizeEnum): void {
        this.fullSize = widgetSize === WidgetSizeEnum.FULLSIZE;
    }
}
