import { Injectable } from '@angular/core';
import { WidgetResponse } from '@apiModels';
import { map, Subject, switchMap } from 'rxjs';
import { TypedResponse } from 'src/app/api-wrapper/models/widget-models/base-widget-models/typed-widget-response';
import { WidgetControllerService } from 'src/app/api/services/widget-controller.service';
import { PageWidgetElem } from '../models/page-widget-elem.model';
import { WidgetStoreBaseService } from './course-editor-state.service';

@Injectable({
    providedIn: 'root',
})
export class UpdateWidgetService {
    updateUiSub = new Subject<number>();
    updateUiObs$ = this.updateUiSub.asObservable();
    constructor(private widgetSrv: WidgetControllerService, private widgetStateSrv: WidgetStoreBaseService) {}

    removeResourceIds(courseId: number, widgetId: number, resourcesToDelete: string[]): void {
        const pageWidgetElem = this.widgetStateSrv.getElem(widgetId);

        if (pageWidgetElem) {
            pageWidgetElem.removeResourceIds(resourcesToDelete);
            this.widgetSrv
                .modifyWidget({
                    ...pageWidgetElem.widget.getWidgetModifyRequest(),
                    courseId,
                })
                .subscribe(() => this.updateUiSub.next(widgetId));
            return;
        }
        this.widgetSrv
            .getWidget({ courseId, widgetId })
            .pipe(
                map((widget: WidgetResponse) => {
                    const elem = new PageWidgetElem(widget as TypedResponse<any>);
                    elem.removeResourceIds(resourcesToDelete);
                    return elem;
                }),
                switchMap((elem: PageWidgetElem) =>
                    this.widgetSrv.modifyWidget({
                        courseId,
                        ...elem.widget.getWidgetModifyRequest(),
                    })
                )
            )
            .subscribe();
    }
}
