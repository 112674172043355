<form [formGroup]="form" data-e2e="text-widget" #collapsibleElement>
    <app-quill
        *ngIf="!testEnv && !collapsible"
        [id]="config?.focusElemId"
        [formControlName]="'text'"
        [placeholder]="'WIDGET.TEXT_PLACEHOLDER' | translate"
    ></app-quill>
    <ng-container *ngIf="!testEnv && collapsible">
        <div
            class="interactive"
            [class.closed]="closedClass"
            [@openClose]="{ value: opened ? 'open' : 'closed', params: { animTiming: animTiming } }"
        >
            <app-quill
                #quillComponent
                class="interactive-app-quill"
                [id]="config?.focusElemId"
                [formControlName]="'text'"
                (afterBlurEvent)="setOpened(false)"
                (afterFocusEvent)="setOpened(true)"
                [placeholder]="'WIDGET.TEXT_PLACEHOLDER' | translate"
            ></app-quill>
        </div>
        <div *ngIf="!opened" class="fade-text" (click)="toogleOpened()"></div>
        <button
            [id]="config?.focusElemId + '_opener-btn'"
            class="opener-btn template-btn section-btn height-small left-icon"
            (click)="toogleOpened()"
        >
            <span class="nitroicon-arrow_right editor-btn-icon opener" [class.opened]="opened"></span>
            {{ (opened ? 'WIDGET.OPENED_TEXT-WIDGET' : 'WIDGET.CLOSED_TEXT-WIDGET') | translate }}
        </button>
    </ng-container>
</form>

<ng-container *ngIf="!testEnv">
    <app-exported-template-actions [selector]="'#extra-action-container_' + config?.focusElemId" *ngIf="!testEnv">
        <button
            [class.accent]="collapsible"
            class="editor-btn section-btn ghost height-small icon-only"
            (click)="setInteractive(true)"
        >
            <span class="nitroicon-Text_hiding editor-btn-icon"></span>
        </button>
        <button
            [class.accent]="!collapsible"
            class="editor-btn section-btn ghost height-small icon-only"
            (click)="setInteractive(false)"
        >
            <span class="nitroicon-Text_show editor-btn-icon"></span>
        </button>
    </app-exported-template-actions>
</ng-container>
