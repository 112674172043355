import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-flip-card',
    templateUrl: './flip-card.component.html',
    styleUrls: ['./flip-card.component.scss'],
})
export class FlipCardComponent {
    @Input() flip = '';

    toggleFlip(): void {
        this.flip = this.flip === 'clicked' ? '' : 'clicked';
    }
}
