/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ChangeDetectorRef, Component, forwardRef, HostBinding, HostListener } from '@angular/core';
import { UntypedFormArray, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChoiceAnswer, ClozeGap, ClozeGapAnswers, ClozeGapText } from '@apiModels';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';
import Quill, { DeltaOperation } from 'quill';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RealTimeClozeQuestionWidgetForm } from 'src/app/api-wrapper/models/widget-models/questions/cloze-question/cloze-question-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { BaseQuestionRealTimeWidgetComponent } from '../../base-classes/abstract-base-real-time-question';
import { realTimeBaseQuestionWidgetFormFactory } from '../../helpers/choice-answers.helpers';

const Delta = Quill.import('delta');

@Component({
    selector: 'app-close-question-real-time-widget',
    templateUrl: './close-question-real-time-widget.component.html',
    styleUrls: ['./close-question-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CloseQuestionRealTimeWidgetComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation],
})
export class CloseQuestionRealTimeWidgetComponent extends BaseQuestionRealTimeWidgetComponent<
    RealTimeClozeQuestionWidgetForm,
    IWidgetSupport
> {
    quill: Quill;
    showToolbar = false;
    focused: boolean;
    hasSelected = false;
    @HostBinding('attr.tabindex') tabindex = -1;
    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    get gaps(): UntypedFormArray {
        return this.form.get('gaps') as UntypedFormArray;
    }

    getGapAnswers(index: number): UntypedFormArray {
        return this.gaps.at(index).get('answers') as UntypedFormArray;
    }

    updateForm(value: RealTimeClozeQuestionWidgetForm): void {
        this.shouldSave = false;
        super.updateForm(value);
        this.form.setControl('gaps', FormHelper.initBaseFormarray(value.gaps, this.createAnswer));
        if (this.quill) {
            this.handleOnInit();
        }
        this.cdr.detectChanges();
        this.shouldSave = true;
    }

    handleOnInit(quill?: Quill): void {
        if (quill) {
            this.quill = quill;
        }
        this.quill.disable();

        if (this.gaps) {
            const deltas = (this.gaps.value as (ClozeGapText | ClozeGapAnswers)[]).map(
                (elem: ClozeGapText | ClozeGapAnswers) => {
                    if (elem.type === 'GAP_TEXT') {
                        return { insert: (elem as ClozeGapText)?.text };
                    }
                    const typedElem = elem as ClozeGapAnswers;
                    const answers = typedElem?.answers ?? [];
                    const correct = answers.find((ans) => ans.correct === true);
                    return { insert: correct.text, attributes: { gap: JSON.stringify(elem.gapId) } };
                }
            );
            this.quill.setContents(new Delta(deltas), 'silent');
        }
        this.quill.setSelection(0, 0, 'silent');
        setTimeout(() => {
            this.quill.enable();
            this.quill.blur();
        }, 500);

        this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            const plaintext = node.innerText;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            return new Delta().insert(plaintext);
        });
    }

    handleTextChange(event: any): void {
        if (event.source !== 'user') {
            return;
        }
        const contents = this.quill.getContents();

        if (contents) {
            contents.ops.forEach((op: DeltaOperation, index: number) => {
                const attributes = op.attributes;
                const text = op.insert.toString();
                if (attributes) {
                    // ha van attribútum, az azt jelenti, hogy nem sima szövegrész van, hanem választható elemek
                    const gapId = JSON.parse(attributes.gap);
                    const gapControlIndex = this.gaps.controls.findIndex((control) => control.value.gapId === gapId);
                    if (gapControlIndex > -1) {
                        // ha megtaláltuk a listában, akkor az előtte lévőeket (az aktuális indexig) törölni kell,
                        // mert azokra már biztos nem lesz szükség
                        this.gaps.controls.splice(index, gapControlIndex - index);
                        if (text !== this.gaps.controls[index].value.text) {
                            // ha már benne van a formunkban ezzel a gap_id-val,
                            // akkor csak a correct answert kell maximum frissíteni, ha az változott

                            const correctAnswer = this.getGapAnswers(index).controls.find(
                                (control) => control.get('correct').value
                            );
                            correctAnswer.get('text').patchValue(text, { emitEvent: false });
                        }
                    } else {
                        // ha nem volt még a formArray listában, akkor hozzá kell adni
                        this.gaps.insert(
                            index,
                            this.createAnswer({ answers: [{ correct: true, text }], type: 'GAP_ANSWERS', gapId })
                        );
                    }
                } else {
                    // ha nincs attribútum, akkor sima szövegrész van. Amit akkor adunk hozzá, ha még nem létezett ilyen index-el elem,
                    // vagy ha változott a szövege
                    const controlAtIndex = this.gaps.controls[index];
                    if (!controlAtIndex || controlAtIndex.value.text !== text) {
                        this.gaps.insert(
                            index,
                            this.createAnswer({ type: 'GAP_TEXT', text, gapId: controlAtIndex?.value?.gapId ?? null })
                        );
                    }
                }
            });
            this.gaps.controls.splice(contents.ops.length);
        } else {
            this.gaps.clear();
        }
        this.gaps.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }

    createAnswer = (data?: Partial<ClozeGapText | ClozeGapAnswers>): FormGroupTyped<ClozeGapText | ClozeGapAnswers> => {
        if (!data.gapId) {
            data.gapId = UUIDGeneratorUtility.generate();
        }

        if (data.type === 'GAP_TEXT') {
            return new FormGroupTyped<ClozeGapText>({
                gapId: FormHelper.controlFactoryWithCalculatedValue(data?.gapId),
                type: FormHelper.controlFactoryWithCalculatedValue(data?.type),
                text: FormHelper.controlFactoryWithCalculatedValue((data as ClozeGapText)?.text),
            });
        }
        return new FormGroupTyped<ClozeGapAnswers>({
            gapId: FormHelper.controlFactoryWithCalculatedValue(data?.gapId),
            type: FormHelper.controlFactoryWithCalculatedValue(data?.type),
            answers: FormHelper.initBaseFormarray((data as ClozeGapAnswers)?.answers ?? [], this.createChoiceAnswer, {
                gapId: data.gapId,
            }),
        });
    };

    createChoiceAnswer = (
        data: Partial<ChoiceAnswer>,
        params: { gapId: string }
    ): FormGroupTyped<Partial<ChoiceAnswer>> => {
        if (!data.id) {
            data.id = UUIDGeneratorUtility.generate();
        }

        const choiceAnswer = new FormGroupTyped<Partial<ChoiceAnswer>>({
            correct: FormHelper.controlFactoryWithCalculatedValue(data?.correct ?? false),
            id: FormHelper.controlFactoryWithCalculatedValue(data?.id),
            text: FormHelper.controlFactoryWithCalculatedValue(data?.text ?? null),
            explanation: FormHelper.controlFactoryWithCalculatedValue(null),
            mediaResourceId: FormHelper.controlFactoryWithCalculatedValue(null),
        });
        if (data.correct) {
            choiceAnswer.valueChanges
                .pipe(debounceTime(100), distinctUntilChanged())
                .subscribe((correctAnswer) => this.updateQuillByGap(params.gapId, correctAnswer.text));
        }
        return choiceAnswer;
    };

    updateQuillByGap(gapId: string, text: string): void {
        const contents = this.quill.getContents();
        if (contents) {
            const ops = [];
            // bejárjuk a quill összes levél elemét és frissítjük ami változott
            contents.ops.forEach((op) => {
                if (op.attributes) {
                    if (JSON.parse(op.attributes.gap) === gapId) {
                        ops.push({ delete: op.insert.toString().length });
                        ops.push({ insert: text, attributes: op.attributes });
                    } else {
                        ops.push({ retain: op.insert.toString().length, attributes: op.attributes });
                    }
                } else {
                    ops.push({ retain: op.insert.toString().length });
                }
            });
            this.quill.updateContents(new Delta(ops), 'silent');
        }
    }

    createForm(): FormGroupTyped<RealTimeClozeQuestionWidgetForm> {
        return realTimeBaseQuestionWidgetFormFactory({
            gaps: new UntypedFormArray([]),
        }) as FormGroupTyped<RealTimeClozeQuestionWidgetForm>;
    }

    addGap(): void {
        const quill = this.quill;
        const selection = quill.getSelection();
        const leaf = quill.getLeaf(selection.index + 1);
        if (leaf[0].parent.domNode.nodeName === 'CQA') {
            return;
        } else if (selection.length > 0) {
            let rightAnswerText = quill.getText(selection.index, selection.length);

            // !Kettős kattintásos kijelölésnél beleveszi a két szó közötti szóközt is és ilyenkor azt le kell venni
            if (rightAnswerText[selection.length - 1] === ' ') {
                rightAnswerText = quill.getText(selection.index, selection.length - 1);
            }

            /* Insert standard cloze question marker with the data attribute */
            const clozeString = JSON.stringify(UUIDGeneratorUtility.generate());
            const deltas = [];

            if (selection.index !== 0) {
                deltas.push({ retain: selection.index });
            }
            deltas.push({ retain: rightAnswerText.length, attributes: { gap: clozeString } });
            deltas.push({ retain: this.quill.getLength() - selection.index - rightAnswerText.length });
            quill.updateContents(new Delta(deltas), 'user');
            quill.setSelection(selection.index + rightAnswerText.length + 1, 0);
            this.showToolbar = true;
        }
    }

    removeGapFormat(): void {
        const selection = this.quill.getSelection();
        this.quill.removeFormat(selection.index, selection.length, 'user');
        this.quill.setSelection(selection.index + selection.length, 0);
    }

    addChoiceAnswer(index: number, correct = false): void {
        this.getGapAnswers(index).push(this.createChoiceAnswer({ correct, text: '' }, { gapId: null }));
    }

    deleteChoiceAnswer(index: number, elementIndex: number): void {
        this.getGapAnswers(index).removeAt(elementIndex);
    }

    @HostListener('focusin')
    onFocusIn(): void {
        if (
            this.editorLocation === 'detail' ||
            this.showToolbar ||
            !(this.gaps.value as ClozeGap[]).some((gap: ClozeGap) => gap.type === 'GAP_ANSWERS')
        ) {
            return;
        }
        setTimeout(() => (this.showToolbar = true));
    }

    @HostListener('focusout', ['$event'])
    onFocusOut(event: { relatedTarget: HTMLElement }): void {
        const realtimeEditor = event.relatedTarget
            ? event.relatedTarget.closest('app-close-question-real-time-widget')
            : null;
        if (!realtimeEditor) {
            setTimeout(() => (this.showToolbar = false));
        }
    }
}
