import Quill from 'quill';
const Inline = Quill.import('blots/inline');

export interface ICustomLink {
    value: string;
    target?: string;
}

export class CustomLinkBlot extends Inline {
    static create(value: ICustomLink): any {
        const node = super.create();
        node.setAttribute('href', value.value);
        node.setAttribute('target', value.target);
        return node;
    }

    static formats(node: any): ICustomLink {
        return {
            value: node.getAttribute('href'),
            target: node.getAttribute('target'),
        };
    }
}
// eslint-disable-next-line @typescript-eslint/dot-notation
CustomLinkBlot['blotName'] = 'custom-link';
// eslint-disable-next-line @typescript-eslint/dot-notation
CustomLinkBlot['tagName'] = 'a';

export class CustomLinkAttributes {
    value: string;
    target?: string;

    constructor(input: ICustomLink) {
        this.value = input?.value ?? '';
        this.target = input?.target ?? '_blank';
    }
}
