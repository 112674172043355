<section class="image-container">
    <button mat-dialog-close type="button" class="template-btn">
        <span class="nitroicon-close" [attr.aria-label]="'MODAL.CLOSE' | translate"> </span>
    </button>
    <figure>
        <img
            (load)="onLoad()"
            appImgPreloader
            [resourceId]="data?.resourceId"
            [courseId]="data?.courseId"
            imageSize="L"
            [alt]="imageAlt ? imageAlt : ''"
            class="image-viewer"
        />
    </figure>
</section>
