import { animate, AnimationMetadata, style, transition, trigger } from '@angular/animations';

const animTiming = 700;

export const scaleFromOriginalAnimation: AnimationMetadata[] = [
    trigger('scaleFromOriginal', [
        transition(
            ':enter',
            [
                style({
                    transform: 'scale(0)',
                    transformOrigin: '{{originX}}px {{originY}}px',
                    opacity: 0.5,
                    overflow: 'hidden',
                }),
                animate(`${animTiming}ms ease`, style({ transform: 'scale(1)', opacity: 1 })),
            ],
            { params: { originX: 0, originY: 0 } }
        ),
        transition(
            ':leave',
            [
                style({
                    transform: 'scale(1)',
                    transformOrigin: '{{originX}}px {{originY}}px',
                    opacity: 1,
                    overflow: 'hidden',
                }),
                animate(`${animTiming}ms ease`, style({ transform: 'scale(0)', opacity: 0.5 })),
            ],
            { params: { originX: 0, originY: 0 } }
        ),
    ]),
];
