<form [formGroup]="form" class="audio-widget">
    <app-audio-upload [formControlName]="'element'" [courseId]="config?.courseId">
        <div class="audio-widget__upload">
            <img src="assets/images/audio-widget.svg" class="audio-widget__img" alt="" />
            <span class="audio-widget__upload-text">{{ 'AUDIO-WIDGET.AUDIO-DRAG-OR' | translate }}</span>
            <button type="button" class="audio-widget__upload-button editor-btn section-btn border-2">
                {{ 'AUDIO-WIDGET.AUDIO-UPLOAD' | translate }}
            </button>
        </div>
    </app-audio-upload>
</form>
<div class="custom-collapse-card">
    <app-collapse-card *ngIf="description" [title]="'AUDIO-WIDGET.TRANSCRIPT-TITLE' | translate" [opened]="false">
        <figcaption class="audio-widget__caption section-font-color" [innerHTML]="description"></figcaption>
    </app-collapse-card>
</div>
