import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { RealTimeEmbeddedDocumentWidgetForm } from 'src/app/api-wrapper/models/widget-models/embeded-widgets/embedded-document-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

@Component({
    selector: 'app-embedded-document-real-time-widget',
    templateUrl: './embedded-document-real-time-widget.component.html',
    styleUrls: ['./embedded-document-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmbeddedDocumentRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class EmbeddedDocumentRealTimeWidgetComponent extends AbstractFormBaseComponent<
    RealTimeEmbeddedDocumentWidgetForm,
    IWidgetSupport
> {
    height: string;
    title: string;
    regExp = new RegExp(/\.(pdf)$/i);
    constructor() {
        super();
    }

    updateForm(data: RealTimeEmbeddedDocumentWidgetForm): void {
        super.updateForm(data);
        this.height = data.height ?? '394';
        this.title = data.title ?? '';
    }

    createForm(): FormGroupTyped<RealTimeEmbeddedDocumentWidgetForm> {
        return new FormGroupTyped<RealTimeEmbeddedDocumentWidgetForm>({
            element: FormHelper.controlFactoryWithCalculatedValue({
                fileItem: null,
                elementId: null,
            }),
            description: FormHelper.controlFactoryWithCalculatedValue(null),
            height: FormHelper.controlFactoryWithCalculatedValue(null),
            title: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
