import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { PageDeletedComponent } from './page-deleted/page-deleted.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ShowFileUploadsComponent } from './show-file-uploads/show-file-uploads.component';

const exportedModules = [CommonModule, TranslateModule, RouterModule];

const exportedComponents = [FooterComponent, PageNotFoundComponent, PageDeletedComponent];

@NgModule({
    imports: [...exportedModules, SharedModule],
    declarations: [...exportedComponents, ShowFileUploadsComponent],
    exports: [...exportedModules, ...exportedComponents],
})
export class CoreModule {}
