<footer #editorFooter>
    <div class="footer-left">
        <span>&#169; {{ actualYear }} Courze & Cloud</span>
        <ul class="link-list">
            <li>
                <a
                    class="footer-link"
                    target="_blank"
                    rel="noopener"
                    [href]="'INFORMATION_PAGES.IMPRESSUM_LINK' | translate"
                    >{{ 'INFORMATION_PAGES.IMPRESSUM' | translate }}</a
                >
            </li>
            <li>
                <a
                    class="footer-link"
                    target="_blank"
                    rel="noopener"
                    [href]="'INFORMATION_PAGES.TERMS_AND_CONDITIONS_LINK' | translate"
                    >{{ 'INFORMATION_PAGES.TERMS_AND_CONDITIONS' | translate }}</a
                >
            </li>
            <li>
                <a
                    class="footer-link"
                    target="_blank"
                    rel="noopener"
                    [href]="'INFORMATION_PAGES.PRIVAY_POLICY_LINK' | translate"
                    >{{ 'INFORMATION_PAGES.PRIVAY_POLICY' | translate }}</a
                >
            </li>
        </ul>
    </div>
    <div class="footer-right">
        <ul class="link-list">
            <li>
                <a
                    class="footer-link"
                    href="mailto:support@nitrolearning.hu?subject={{
                        'INFORMATION_PAGES.FEEDBACK_SUBJECT' | translate
                    }}"
                    >{{ 'INFORMATION_PAGES.FEEDBACK' | translate }}</a
                >
            </li>
            <li>
                <a
                    class="footer-link"
                    target="_blank"
                    rel="noopener"
                    [href]="'INFORMATION_PAGES.HANDBOOK_LINK' | translate"
                    >{{ 'INFORMATION_PAGES.HANDBOOK' | translate }}</a
                >
            </li>
            <li>
                <a
                    class="footer-link"
                    target="_blank"
                    rel="noopener"
                    [href]="'INFORMATION_PAGES.RELEASE_NOTES_LINK' | translate"
                    >{{ 'INFORMATION_PAGES.RELEASE_NOTES' | translate }}</a
                >
            </li>
        </ul>
        <app-show-file-uploads></app-show-file-uploads>
    </div>
</footer>
