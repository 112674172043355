import { CourseFulfillmentSettingsComponent } from './components/course-fulfillment-settings/course-fulfillement-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoursePreviewShowComponent } from '@shared/components/course-preview-show/course-preview-show.component';
import { ImgViewerComponent } from '@shared/components/img-viewer/img-viewer.component';
import { LoaderForComponentsComponent } from '@shared/components/loader-for-components/loader-for-components.component';
import { QuillComponent } from '@shared/components/quill/quill.component';
import { CollapseCardComponent } from '@shared/components/ui-components/collapse-card/collapse-card.component';
import { CollapseComponent } from '@shared/components/ui-components/collapse/collapse.component';
import { SubtitleUploadComponent } from '@shared/forms/file-upload/subtitle-upload/subtitle-upload.component';
import { RadioGroupControlComponent } from '@shared/forms/radio-group-control/radio-group-control.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { QuillModule } from 'ngx-quill';
import { ApplicationSelectorComponent } from './components/application-selector/application-selector.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CustomPaginatorComponent } from './components/custom-paginator/custom-paginator.component';
import { CustomVideoComponent } from './components/custom-video/custom-video.component';
import { ExportedTemplateComponent } from './components/exported-template.component';
import { FileUploadMessageComponent } from './components/file-upload-message/file-upload-message.component';
import { ConfirmCloseFullPageModalComponent } from './components/full-page-modal/confirm-close-full-page-modal/confirm-close-full-page-modal.component';
import { FullPageModalComponent } from './components/full-page-modal/full-page-modal.component';
import { InnerComponentDirective } from './components/full-page-modal/inner-component.directive';
import { InfoBoxWithActionsComponent } from './components/info-box-with-actions/info-box-with-actions.component';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderDirective } from './components/loader/models/loader.directive';
import { DialogComponent } from './components/material-wrappers/dialog/dialog.component';
import { MaterialMenuComponent } from './components/material-wrappers/material-menu/material-menu.component';
import { QuillLinkEditorComponent } from './components/quill/quill-link-editor/quill-link-editor.component';
import { TooltipEditorComponent } from './components/quill/tooltip-editor/tooltip-editor.component';
import { StandaloneInputComponent } from './components/standalone-input/standalone-input.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { UserInfoCardComponent } from './components/user-info-card/user-info-card.component';
import { ImagePreloaderDirective } from './directives/image-preloader.directive';
import { CheckboxControlComponent } from './forms/checkbox-control/checkbox-control.component';
import { CustomTextareaComponent } from './forms/custom-textarea/custom-textarea.component';
import { AnyFileUploadComponent } from './forms/file-upload/any-file-upload/any-file-upload.component';
import { AudioUploadComponent } from './forms/file-upload/audio-upload/audio-upload.component';
import { DocumentUploadComponent } from './forms/file-upload/document-upload/document-upload.component';
import { ImageUploadComponent } from './forms/file-upload/image-upload/image-upload.component';
import { MultiAudioUploadComponent } from './forms/file-upload/multi-audio-upload/multi-audio-upload.component';
import { SlideUploadComponent } from './forms/file-upload/slide-file-upload/slide-upload.component';
import { VideoUploadComponent } from './forms/file-upload/video-upload/video-upload.component';
import { NumberInputComponent } from './forms/number-input/number-input.component';
import { RadioControlComponent } from './forms/radio-control/radio-control.component';
import { SelectInputComponent } from './forms/select-input/select-input.component';
import { MaterialImportModule } from './material-import.module';
import { FormattedDatePipe } from './pipes/formatted-date-format.pipe';
import { MediaTimePipe } from './pipes/media-time-display.pipe';
import { NoByPassProtectedResourceUrlPipe } from './pipes/no-bypass-resource.pipe';
import { ProtectedPdfResourceUrlPipe } from './pipes/protected-pdf-resource.pipe';
import { ProtectedResourceUrlPipe } from './pipes/protected-resource.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeResourceUrlPipe } from './pipes/safe-resource-url.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { FontUploadComponent } from '@shared/forms/file-upload/font-upload/font-upload.component';
import { FileSizePipe } from '@shared/pipes/filesize.pipe';

const exportedModules = [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportModule,
    DragDropModule,
    RouterModule,
    ImageCropperModule,
    QuillModule,
];

const exportedComponents = [
    CheckboxControlComponent,
    DialogComponent,
    ConfirmDialogComponent,
    RadioControlComponent,
    RadioGroupControlComponent,
    StandaloneInputComponent,
    MaterialMenuComponent,
    SafeHtmlPipe,
    ToastMessageComponent,
    ImageUploadComponent,
    SafeUrlPipe,
    CollapseComponent,
    CollapseCardComponent,
    FullPageModalComponent,
    QuillComponent,
    FormattedDatePipe,
    ExportedTemplateComponent,
    VideoUploadComponent,
    AudioUploadComponent,
    DocumentUploadComponent,
    SafeResourceUrlPipe,
    SubtitleUploadComponent,
    InfoModalComponent,
    NumberInputComponent,
    ProtectedResourceUrlPipe,
    ProtectedPdfResourceUrlPipe,
    SelectInputComponent,
    FileUploadMessageComponent,
    CoursePreviewShowComponent,
    LoaderComponent,
    LoaderDirective,
    LoaderForComponentsComponent,
    AnyFileUploadComponent,
    UserInfoCardComponent,
    InfoBoxWithActionsComponent,
    NoByPassProtectedResourceUrlPipe,
    ImagePreloaderDirective,
    CustomTextareaComponent,
    CustomPaginatorComponent,
    SlideUploadComponent,
    ImgViewerComponent,
    MultiAudioUploadComponent,
    ApplicationSelectorComponent,
    CustomVideoComponent,
    MediaTimePipe,
    CourseFulfillmentSettingsComponent,
    FontUploadComponent,
    FileSizePipe,
];

@NgModule({
    imports: [...exportedModules, PortalModule],
    declarations: [
        ...exportedComponents,
        InnerComponentDirective,
        QuillLinkEditorComponent,
        TooltipEditorComponent,
        ConfirmCloseFullPageModalComponent,
        CourseFulfillmentSettingsComponent,
    ],
    exports: [...exportedModules, ...exportedComponents],
})
export class SharedModule {}
