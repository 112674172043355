<input type="file" #fileInput [accept]="acceptTypes" (change)="fileChangeListener()" />
<div class="download-row" *ngIf="fileSrc; else emptyBtn">
    <a
        [download]="fileName" [href]="fileSrc | safeUrl"
        target="_blank"
        class="editor-btn primary download-button left-icon download-button height-small btn-w100"
    >
        {{ fileName }}
    </a>

    <a class="editor-btn primary height-small icon-only" (click)="fileInput.click()"
       (drop)="landFile($event)"
       (dragover)="$event.preventDefault()">
        <span class="nitroicon-upload editor-btn-icon"></span>
    </a>
    <button class="editor-btn primary height-small icon-only" (click)="deleteFile()" (keyup.enter)="deleteFile()">
        <span class="nitroicon-delete editor-btn-icon"></span>
    </button>
</div>

<ng-template #emptyBtn>
    <div class="dropper" #droparea>
        <span
            (click)="fileInput.click()"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
            tabindex="0"
        >

            <div class="empty-btn__cover">
                <button class="editor-btn primary height-small left-icon" type="button">
                    <span class="nitroicon-upload editor-btn-icon"></span>
                    {{ 'COURSE_FONTS.CUSTOM_FONT.UPLOAD' | translate }}
                </button>
            </div>
        </span>
    </div>
</ng-template>
