<form [formGroup]="form">
    <header class="detail-form__header" [ngClass]="sizeClass">
        <article class="detail-form__header__action-buttons">
            <button class="editor-btn height-small left-icon primary new-card-btn" (click)="addNavigationCard()">
                <span class="nitroicon-add editor-btn-icon"></span> {{ 'NAVIGATION-BLOCK-WIDGET.NEW-CARD' | translate }}
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.MARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.MARGIN)"
            >
                <span class="nitroicon-block_narrow editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.NOMARGIN }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.NOMARGIN)"
            >
                <span class="nitroicon-block_mid editor-btn-icon"></span>
            </button>
            <button
                class="editor-btn height-small icon-only primary"
                [ngClass]="{ accent: size === widgetSizeEnum.FULLSIZE }"
                type="button"
                (click)="setWidgetSize(widgetSizeEnum.FULLSIZE)"
            >
                <span class="nitroicon-block_fullsize editor-btn-icon"></span>
            </button>
        </article>
    </header>

    <ng-template #realTimeElement>
        <app-navigation-block-real-time-widget
            *ngIf="!testEnv"
            class="detail-form__real-time-widget"
            [ngClass]="sizeClass"
            #realTimeNavigationWidget
            [formControlName]="'realTimeNavigationBlockForm'"
            [config]="config"
            [data]="form.get('realTimeNavigationBlockForm').value"
            [editorLocation]="'detail'"
            [columns]="columns"
        >
        </app-navigation-block-real-time-widget>
    </ng-template>
    <ng-container *ngIf="size === widgetSizeEnum.FULLSIZE">
        <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
    </ng-container>

    <section class="detail-form resizable" [ngClass]="sizeClass">
        <div class="detail-form__site-left" *ngIf="size !== widgetSizeEnum.FULLSIZE">
            <ng-container *ngTemplateOutlet="realTimeElement"></ng-container>
        </div>

        <div class="detail-form__site-right">
            <app-collapse-card [title]="'NAVIGATION-BLOCK-WIDGET.SETTINGS' | translate" [opened]="true">
                <fieldset class="detail-form__group">
                    <legend class="detail-form__group__label">
                        {{ 'NAVIGATION-BLOCK-WIDGET.LAYOUT' | translate }}
                    </legend>
                    <div class="detail-form__number-block">
                        <button
                            class="editor-btn primary border-0 icon-only radius-25 tooltip-delay"
                            [attr.data-tooltip]="'NAVIGATION-BLOCK-WIDGET.TOOLTIP' | translate"
                        >
                            <span class="nitroicon-info editor-btn-icon"></span>
                        </button>
                        <span>{{ 'NAVIGATION-BLOCK-WIDGET.NUMBER-OF-CARDS' | translate }}</span>
                        <app-number-input formControlName="columns" [min]="1" [max]="5"></app-number-input>
                    </div>
                </fieldset>
            </app-collapse-card>
        </div>
    </section>
</form>
