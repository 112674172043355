/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CourseErrors } from '../models/course-errors';
import { CourseRequest } from '../models/course-request';
import { CourseResponse } from '../models/course-response';
import { FulfillmentSettings } from '../models/fulfillment-settings';
import { ResourceResponse } from '../models/resource-response';

@Injectable({
  providedIn: 'root',
})
export class CourseControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation modifyCourse
   */
  static readonly ModifyCoursePath = '/courses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyCourse$Response(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: CourseRequest
  }): Observable<StrictHttpResponse<CourseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CourseControllerService.ModifyCoursePath, 'put');
    if (params) {
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `modifyCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  modifyCourse(params: {

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: CourseRequest
  }): Observable<CourseResponse> {

    return this.modifyCourse$Response(params).pipe(
      map((r: StrictHttpResponse<CourseResponse>) => r.body as CourseResponse)
    );
  }

  /**
   * Path part for operation updateFulfilmentSettings
   */
  static readonly UpdateFulfilmentSettingsPath = '/courses/{courseId}/fulfillment_settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFulfilmentSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFulfilmentSettings$Response(params: {
    courseId: number;
    overwriteExisting: boolean;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: FulfillmentSettings
  }): Observable<StrictHttpResponse<FulfillmentSettings>> {

    const rb = new RequestBuilder(this.rootUrl, CourseControllerService.UpdateFulfilmentSettingsPath, 'put');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.query('overwriteExisting', params.overwriteExisting, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FulfillmentSettings>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFulfilmentSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFulfilmentSettings(params: {
    courseId: number;
    overwriteExisting: boolean;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body: FulfillmentSettings
  }): Observable<FulfillmentSettings> {

    return this.updateFulfilmentSettings$Response(params).pipe(
      map((r: StrictHttpResponse<FulfillmentSettings>) => r.body as FulfillmentSettings)
    );
  }

  /**
   * Path part for operation uploadResource
   */
  static readonly UploadResourcePath = '/courses/{courseId}/resources';

  /**
   * Upload resource to course.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadResource()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadResource$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Optional identifier of uploadable resource
     */
    resourceId?: string;

    /**
     * Relevant widgetId to tie widget to use in postprocessing if necessary
     */
    relevantWidgetId?: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<StrictHttpResponse<ResourceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CourseControllerService.UploadResourcePath, 'post');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.query('resourceId', params.resourceId, {});
      rb.query('relevantWidgetId', params.relevantWidgetId, {});
      rb.header('Org-Header', params['Org-Header'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceResponse>;
      })
    );
  }

  /**
   * Upload resource to course.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadResource$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadResource(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Optional identifier of uploadable resource
     */
    resourceId?: string;

    /**
     * Relevant widgetId to tie widget to use in postprocessing if necessary
     */
    relevantWidgetId?: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
    body?: { 'file': Blob }
  }): Observable<ResourceResponse> {

    return this.uploadResource$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceResponse>) => r.body as ResourceResponse)
    );
  }

  /**
   * Path part for operation generatePreviewCourseVersion
   */
  static readonly GeneratePreviewCourseVersionPath = '/courses/{courseId}/generate_preview';

  /**
   * Generate course version from actual course data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generatePreviewCourseVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  generatePreviewCourseVersion$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CourseControllerService.GeneratePreviewCourseVersionPath, 'post');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Generate course version from actual course data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generatePreviewCourseVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generatePreviewCourseVersion(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<string> {

    return this.generatePreviewCourseVersion$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getCourse
   */
  static readonly GetCoursePath = '/courses/{courseId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourse$Response(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<CourseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CourseControllerService.GetCoursePath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourse(params: {
    courseId: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<CourseResponse> {

    return this.getCourse$Response(params).pipe(
      map((r: StrictHttpResponse<CourseResponse>) => r.body as CourseResponse)
    );
  }

  /**
   * Path part for operation validate
   */
  static readonly ValidatePath = '/courses/{courseId}/validate';

  /**
   * Get validation errors for course.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validate()` instead.
   *
   * This method doesn't expect any request body.
   */
  validate$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Id of widget to skip
     */
    skipWidget?: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<CourseErrors>> {

    const rb = new RequestBuilder(this.rootUrl, CourseControllerService.ValidatePath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.query('skipWidget', params.skipWidget, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseErrors>;
      })
    );
  }

  /**
   * Get validation errors for course.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validate(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Id of widget to skip
     */
    skipWidget?: number;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<CourseErrors> {

    return this.validate$Response(params).pipe(
      map((r: StrictHttpResponse<CourseErrors>) => r.body as CourseErrors)
    );
  }

  /**
   * Path part for operation downloadResource
   */
  static readonly DownloadResourcePath = '/courses/{courseId}/resources/{resourceId}';

  /**
   * Download resource from course.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadResource$Response(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Identifier of resource
     */
    resourceId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CourseControllerService.DownloadResourcePath, 'get');
    if (params) {
      rb.path('courseId', params.courseId, {});
      rb.path('resourceId', params.resourceId, {});
      rb.header('Org-Header', params['Org-Header'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Download resource from course.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadResource(params: {

    /**
     * Identifier of course
     */
    courseId: number;

    /**
     * Identifier of resource
     */
    resourceId: string;

    /**
     * Tenant identifier
     */
    'Org-Header'?: string;
  }): Observable<void> {

    return this.downloadResource$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
