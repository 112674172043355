import { Component, ContentChild, Inject, Input, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
    @Input() titlePath?: string;
    @Input() messagePath?: string;
    @Input() messageExtraInfo?: string;
    @Input() primaryButtonTextPath?: string;
    @Input() secondaryButtonTextPath?: string;
    @Input() primaryButtonIcon?: string; // nitroicon font class name
    @Input() secondaryButtonIcon?: string; // nitroicon font class name
    @Input() buttonIconAlign: 'start' | 'end' = 'start';
    @Input() allowCloseInHeader = false;
    @Input() alignActions: 'start' | 'center' | 'end' = 'end';
    @ContentChild(TemplateRef, { static: true }) contentTemplateRef?: TemplateRef<any>;

    get showDialogActions(): boolean {
        // TODO
        // return this.primaryButtonTextPath || this.secondaryButtonTextPath;
        return !!this.primaryButtonTextPath || !!this.secondaryButtonTextPath;
    }

    resultPrimary = {
        result: 'primary',
        data: this.data,
    };

    resultSecondary = {
        result: 'secodary',
        data: this.data,
    };

    resultNone = {
        result: 'none',
        data: this.data,
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
