import { PairAnswer } from '@apiModels';
import { IFileUploadValue } from '@shared/models/file-upload.model';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';

import { BaseSubFormClass } from '../../base-widget-models/base-sub-form-class';

export interface IMatchingAnswerModel {
    id?: string;
    order?: number;
    mediaResourceElement?: IFileUploadValue;
    text?: string;
    pairId?: string;
    textPair?: string;
}

export class MatchingAnswerModel extends BaseSubFormClass<PairAnswer, IMatchingAnswerModel> {
    constructor(data: PairAnswer) {
        super(data);

        if (!this.data.id) {
            this.data.id = UUIDGeneratorUtility.generate();
            this.data.pairId = UUIDGeneratorUtility.generate();
        }
    }

    getFormData(): IMatchingAnswerModel {
        return {
            id: this.data?.id ?? null,
            mediaResourceElement: {
                fileItem: this.fileUploadElement,
                elementId: this.data.mediaResourceId ?? null,
            },
            text: this.data?.text ?? null,
            pairId: this.data?.pairId ?? null,
            textPair: this.data?.textPair ?? null,
        };
    }

    updateData(modifiedData: IMatchingAnswerModel): void {
        const { mediaResourceElement, ...rest } = modifiedData;
        const mediaResourceId = this.extractUUID(mediaResourceElement);
        this.fileUploadElement = mediaResourceElement.fileItem;
        this.data = { ...this.data, ...rest, mediaResourceId };
    }
}
