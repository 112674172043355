<quill-editor
    class="quill-editor"
    [placeholder]="placeholder"
    [theme]="theme"
    [(ngModel)]="value"
    [formats]="toolbarOptions"
    [class.toolbar-editor-open]="toolbarEditorOpen"
    [class.toolbar-visible]="showToolbar && toolbarVisible"
    (onSelectionChanged)="handleSelectionChange($event)"
    (onEditorCreated)="handleOnInit($event)"
    (onFocus)="onFocusEvent()"
    (onBlur)="onBlurEvent()"
    (drop)="$event.preventDefault()"
    (keydown.enter)="onEnter()"
>
    <div quill-editor-toolbar [class.hidden]="!toolbarVisible">
        <span *ngIf="toolbarOptions.length" class="ql-formats" data-prevent-autosave tabindex="0">
            <button
                *ngIf="toolbarOptions.includes('header')"
                value="2"
                type="button"
                [class.ql-active]="activeFormats.includes('header-2')"
                (click)="onFormatClick($event, 'header', '2')"
            >
                <span class="nitroicon-H1"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('header-3')"
                *ngIf="toolbarOptions.includes('header')"
                value="3"
                type="button"
                (click)="onFormatClick($event, 'header', '3')"
            >
                <span class="nitroicon-H2"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('bold')"
                *ngIf="toolbarOptions.includes('bold')"
                type="button"
                (click)="onFormatClick($event, 'bold')"
            >
                <span class="nitroicon-bold"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('italic')"
                *ngIf="toolbarOptions.includes('italic')"
                type="button"
                (click)="onFormatClick($event, 'italic')"
            >
                <span class="nitroicon-italic"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('underline')"
                *ngIf="toolbarOptions.includes('underline')"
                type="button"
                (click)="onFormatClick($event, 'underline')"
            >
                <span class="nitroicon-underline"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('strike')"
                *ngIf="toolbarOptions.includes('strike')"
                type="button"
                (click)="onFormatClick($event, 'strike')"
            >
                <span class="nitroicon-strike"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('script-sub')"
                *ngIf="toolbarOptions.includes('script')"
                value="sub"
                type="button"
                (click)="onFormatClick($event, 'script', 'sub')"
            >
                <span class="nitroicon-sub"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('script-super')"
                *ngIf="toolbarOptions.includes('script')"
                value="super"
                type="button"
                (click)="onFormatClick($event, 'script', 'super')"
            >
                <span class="nitroicon-super"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('list-bullet')"
                *ngIf="toolbarOptions.includes('list')"
                value="bullet"
                type="button"
                (click)="onFormatClick($event, 'list', 'bullet')"
            >
                <span class="nitroicon-list-bullet"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('list-ordered')"
                *ngIf="toolbarOptions.includes('list')"
                value="ordered"
                type="button"
                (click)="onFormatClick($event, 'list', 'ordered')"
            >
                <span class="nitroicon-list-ordered"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('clean')"
                *ngIf="toolbarOptions.includes('clean')"
                type="button"
                (click)="onFormatClick($event, 'clean')"
            >
                <span class="nitroicon-format_clear"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('code-block')"
                *ngIf="toolbarOptions.includes('code-block')"
                type="button"
                (click)="onFormatClick($event, 'code-block')"
            >
                <span class="nitroicon-code"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('blockquote')"
                *ngIf="toolbarOptions.includes('blockquote')"
                type="button"
                (click)="onFormatClick($event, 'blockquote')"
            >
                <span class="nitroicon-quote"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('custom-link')"
                *ngIf="toolbarOptions.includes('custom-link')"
                type="button"
                (click)="onFormatClick($event, 'custom-link')"
            >
                <span class="nitroicon-link"></span>
            </button>
            <button
                [class.ql-active]="activeFormats.includes('custom-tooltip')"
                *ngIf="toolbarOptions.includes('custom-tooltip')"
                type="button"
                (click)="onFormatClick($event, 'custom-tooltip')"
            >
                <span class="nitroicon-tooltip_up"></span>
            </button>
            <button [class.ql-active]="activeFormats.includes('align')" type="button">
                <select class="ql-align" [title]="'Aligment'" tabindex="0">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                </select>
            </button>
            <button [class.ql-active]="activeFormats.includes('size')" type="button" class="auto-size">
                <select class="ql-size" [title]="'Size'" tabindex="0" (click)="onFormatClick($event, 'size')">
                    <option value="small"></option>
                    <option></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                </select>
            </button>
        </span>
    </div>
</quill-editor>

<app-tooltip-editor
    *ngIf="customTooltipData"
    [@fadeInOut]
    [data]="customTooltipData"
    [position]="tooltipEditorPosition"
    (cancel)="tooltipCancel()"
    (done)="tooltipDone($event)"
    (delete)="tooltipDelete()"
></app-tooltip-editor>

<div
    class="quill-hover-container"
    [@cardCollapse]="{ value: true, params: { animTiming: '300ms ease' } }"
    *ngIf="customLinkData"
    [style.left.px]="hoverPosX"
    [style.top.px]="hoverPosY"
>
    <app-quill-link-editor [link]="customLinkData" (editDone)="linkEditDone($event)"></app-quill-link-editor>
</div>
