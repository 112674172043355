import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { WidgetResponse } from '@apiModels';
import { BehaviorSubject } from 'rxjs';
import { TypedResponse } from 'src/app/api-wrapper/models/widget-models/base-widget-models/typed-widget-response';
import { PageWidgetElem } from 'src/app/course-editor/models/page-widget-elem.model';

@Injectable({
    providedIn: 'root',
})
export class WidgetStoreBaseService {
    firstSelectorOpen = false;
    widgetElemList: PageWidgetElem[] = [];
    pageId: number;

    private widgetElemListSubject = new BehaviorSubject<PageWidgetElem[]>(this.widgetElemList);
    public widgetElemListObs$ = this.widgetElemListSubject.asObservable();

    initState(widgetList: WidgetResponse[]): void {
        this.widgetElemList = widgetList.map((widget) => new PageWidgetElem(widget as TypedResponse<any>));
        this.widgetElemListSubject.next(this.widgetElemList);
    }

    getElem(id: number): PageWidgetElem {
        return this.widgetElemList.find((elem) => elem.widget.id === id);
    }

    getIndex(id: number): number {
        return this.widgetElemList.findIndex((elem) => elem.widget.id === id);
    }

    getIdList(): number[] {
        return this.widgetElemList.map((elem) => elem.widget.id);
    }

    getNavBlocks(): PageWidgetElem[] {
        return this.widgetElemList.filter((elem) => elem.widgetType === 'NAVIGATION_BLOCK_WIDGET');
    }

    getNavWidgetPageIndex(widetId: number | undefined): number {
        const navWidgets = this.widgetElemList.filter((widget) => widget.widgetType === 'NAVIGATION_BLOCK_WIDGET');
        return navWidgets.findIndex((widget) => widget.widget.id === widetId);
    }

    moveItemInArray(event: CdkDragDrop<PageWidgetElem[]>): void {
        moveItemInArray(this.widgetElemList, event.previousIndex, event.currentIndex);
        this.widgetElemListSubject.next(this.widgetElemList);
    }

    moveItemByArrows(previousIndex: number, currentIndex: number): void {
        moveItemInArray(this.widgetElemList, previousIndex, currentIndex);
        this.widgetElemListSubject.next(this.widgetElemList);
    }

    addNewItemInArray(widget: WidgetResponse, index: number): void {
        const newElem = new PageWidgetElem(widget as TypedResponse<any>);
        if (
            newElem.widgetType === 'IFRAME_WIDGET' ||
            newElem.widgetType === 'YOUTUBE_WIDGET' ||
            newElem.widgetType === 'RECOMMENDATION_BUTTON_WIDGET' ||
            newElem.widgetType === 'ANCHOR_WIDGET'
        ) {
            newElem.setOpenDetailAfterCreation(true);
        }
        this.widgetElemList.splice(index, 0, newElem);
        this.widgetElemListSubject.next(this.widgetElemList);
    }

    removeItemInArray(index: number): void {
        this.widgetElemList = this.widgetElemList.filter((_, i) => i !== index);
        this.widgetElemListSubject.next(this.widgetElemList);
    }

    setFirstSelector(newState: boolean): void {
        this.firstSelectorOpen = newState;
    }

    closeSelectors(): void {
        this.setFirstSelector(false);
        this.widgetElemList.forEach((elem) => elem.setwidgetSelectorState(false));
    }

    openSelectors(): void {
        this.setFirstSelector(true);
        this.widgetElemList.forEach((elem) => elem.setwidgetSelectorState(true));
    }

    clearState(): void {
        this.widgetElemList = [];
        this.widgetElemListSubject.next(this.widgetElemList);
    }
}
