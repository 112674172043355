/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class MatPaginationTranslate extends MatPaginatorIntl implements OnDestroy {
    untilSub = new Subject<void>();

    constructor(private translateService: TranslateService, private translateParser: TranslateParser) {
        super();
        this.getTranslations();
    }

    getTranslations(): void {
        this.translateService
            .get([
                'PAGINATOR.ITEMS_PER_PAGE',
                'PAGINATOR.NEXT_PAGE',
                'PAGINATOR.PREVIOUS_PAGE',
                'PAGINATOR.LAST_PAGE',
                'PAGINATOR.FIRST_PAGE',
            ])
            .pipe(takeUntil(this.untilSub))
            .subscribe((translation) => {
                this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
                this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE'];
                this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE'];
                this.lastPageLabel = translation['PAGINATOR.LAST_PAGE'];
                this.firstPageLabel = translation['PAGINATOR.FIRST_PAGE'];
                this.changes.next();
            });
    }

    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length == 0 || pageSize == 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} / ${length}`;
    };

    ngOnDestroy(): void {
        this.untilSub.next();
        this.untilSub.complete();
    }
}
