import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { FileUploadHandlerService, IUploadMessage } from '@sharedServices/file-upload-handler.service';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-show-file-uploads',
    templateUrl: './show-file-uploads.component.html',
    styleUrls: ['./show-file-uploads.component.scss'],
    animations: [collapseAnimation],
})
export class ShowFileUploadsComponent implements OnInit, OnDestroy {
    opened = true;
    sub!: Subscription;
    fileUploadList: IUploadMessage[];
    @HostBinding('class.empty') empty = true;
    constructor(private fileUploadMessageService: FileUploadHandlerService) {}

    ngOnInit(): void {
        this.sub = this.fileUploadMessageService.subCurrentMessages$.subscribe((currentList) => {
            this.fileUploadList = currentList;
            this.empty = currentList.length < 1;
        });
    }

    cancelAllTheFileUploads(): void {
        this.fileUploadMessageService.cancelAllTheFileUploads();
    }

    cancelUpload(id: string, fileUploadElement: IUploadMessage): void {
        this.fileUploadMessageService.cancelFileUpload(id, fileUploadElement);
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
