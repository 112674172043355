import { Component, EventEmitter, Input, Output } from '@angular/core';

export type MenuItemType = 'ACTION' | 'LINK' | 'INFO' | 'USER';
export interface MenuItemInterface<T, A> {
    type?: MenuItemType;
    name: string;
    icon?: string;
    action?: A;
    item?: T;
    navigateTo?: string[];
    fragment?: string;
}

export interface MenuActionInterface<T, A> {
    action: A;
    item?: T;
}

@Component({
    selector: 'app-material-menu',
    templateUrl: './material-menu.component.html',
    styleUrls: ['./material-menu.component.scss'],
})
export class MaterialMenuComponent {
    @Input() icon: 'nitroicon-more_vert' | 'nitroicon-more_hor' | string = 'nitroicon-more_hor';
    @Input() menuList: MenuItemInterface<any, any>[] = [];
    @Output() actionEvent = new EventEmitter<MenuActionInterface<any, any>>();

    onActionEvent(action: any, item: any): void {
        this.actionEvent.emit({ action, item });
    }

    errorColor(iconName: string): boolean {
        return iconName.includes('delete');
    }

    trackByFn(index: number, id: any): number {
        return index;
    }
}
