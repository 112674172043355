/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { EmbeddedDocumentWidgetData } from '@apiModels';
import { IFileUploadValue } from '@shared/models/file-upload.model';

import { BaseWidgetClass } from '../base-widget-models/base-widget-class';
import { TypedResponse } from '../base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from '../base-widget-models/widget-size.enum';
import {
    BaseDetailEmbededWidgetForm,
    BaseRealTimeEmbededWidgetForm,
    EmbededDetailFormFactory,
    EmbededRealTimeFormFactory,
} from './base-embeded.model';

export interface RealTimeEmbeddedDocumentWidgetForm extends BaseRealTimeEmbededWidgetForm {
    element: IFileUploadValue;
    height?: string;
}

export interface DetailEmbeddedDocumentWidgetForm extends BaseDetailEmbededWidgetForm {
    element: IFileUploadValue;
    height?: string;
    size: WidgetSizeEnum;
}

export interface EmbeddedDocumentWidgetDataSpec extends EmbeddedDocumentWidgetData {
    fulfillment: 'NONE' | 'SHOW';
}

export class EmbeddedDocumentWidgetClass extends BaseWidgetClass<EmbeddedDocumentWidgetDataSpec> {
    constructor(data: TypedResponse<EmbeddedDocumentWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeEmbeddedDocumentWidgetForm {
        return {
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data.resourceId ?? null,
            },
            height: this.data?.height ?? '394',
            ...EmbededRealTimeFormFactory<BaseRealTimeEmbededWidgetForm>(this.data),
        };
    }

    getDetailFormData(): DetailEmbeddedDocumentWidgetForm {
        return {
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data.resourceId ?? null,
            },
            height: this.data?.height ?? '394',
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
            ...EmbededDetailFormFactory<BaseDetailEmbededWidgetForm>(this.data),
        };
    }

    updateWidgetData(modifiedData: RealTimeEmbeddedDocumentWidgetForm | DetailEmbeddedDocumentWidgetForm): void {
        const { element, ...rest } = modifiedData;

        if (this.shouldSaveFile(element.fileItem, this.data.resourceId)) {
            this.filesToSave.push(element.fileItem);
        }
        this.fileUploadElement = element.fileItem;
        const resourceId = element.fileItem ? element.fileItem.uuid : element.elementId;
        this.data = { ...this.data, ...rest, resourceId };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.resourceId === id) {
                this.data = { ...this.data, resourceId: null };
                this.fileUploadElement = null;
            }
        });
    }
}
