import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslateWrapperService {
    constructor(private translate: TranslateService) {}

    getInstantTranslate(key: string): string {
        return this.translate.instant(key);
    }
}
