import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { RealTimeDocumentWidgetForm } from 'src/app/api-wrapper/models/widget-models/document-widget-model';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

@Component({
    selector: 'app-document-real-time-widget',
    templateUrl: './document-real-time-widget.component.html',
    styleUrls: ['./document-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DocumentRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class DocumentRealTimeWidgetComponent
    extends AbstractFormBaseComponent<RealTimeDocumentWidgetForm, IWidgetSupport>
    implements OnInit, AfterViewInit
{
    @ViewChild('hiddenText') textEl: ElementRef;
    @ViewChild('downloadIcon') iconEl: ElementRef;
    @ViewChild('placeholderText') placeholderText: ElementRef;
    minWidth = 180;
    width = this.minWidth;
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    get hasElement(): boolean {
        if (!this.form) {
            return false;
        }
        const { fileItem, elementId } = this.form.get('element').value;
        return fileItem !== null || elementId !== null;
    }

    get align(): string {
        return this.form && this.form.get('align').value;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.resize(300);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.placeholderText) {
                this.minWidth = this.placeholderText.nativeElement.offsetWidth;
                this.width = this.minWidth;
                this.changeDetectorRef.markForCheck();
            }
        }, 0);
    }

    updateForm(value: RealTimeDocumentWidgetForm): void {
        super.updateForm(value);
        this.resize(300);
    }

    createForm(): FormGroupTyped<RealTimeDocumentWidgetForm> {
        return new FormGroupTyped<RealTimeDocumentWidgetForm>({
            element: FormHelper.controlFactoryWithCalculatedValue({ elementId: null, fileItem: null }),
            align: FormHelper.controlFactoryWithCalculatedValue(null),
            title: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    updateAlign(align: 'LEFT' | 'CENTER' | 'RIGHT'): void {
        this.form.patchValue({ align });
    }

    resize(time = 0): void {
        setTimeout(() => {
            if (this.textEl) {
                if (this.form.controls['title'].value) {
                    this.width = this.textEl.nativeElement.offsetWidth + this.iconEl.nativeElement.offsetWidth - 10;
                } else {
                    this.width = this.minWidth;
                }
                this.changeDetectorRef.detectChanges();
            }
        }, time);
    }
}
