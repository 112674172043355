<div class="page-not-found">
    <div class="page-not-found-image-container">
        <img src="assets/images/404_deleted.svg" alt="" />
    </div>
    <div class="page-not-found-text-container">
        <h2 class="page-not-found-text-container__subtitle">{{ 'PAGE_DELETED.SUBTITLE' | translate }}</h2>
        <span class="page-not-found-text-container__details"> {{ 'PAGE_DELETED.DETAILS' | translate }}</span>
    </div>
    <div class="page-not-found__btn">
        <a routerLink="/" class="editor-btn border-2 orange"> {{ 'PAGE_DELETED.BACK_TO_LANDING_PAGE' | translate }} </a>
    </div>
</div>
