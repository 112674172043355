import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-standalone-input',
    templateUrl: './standalone-input.component.html',
    styleUrls: ['./standalone-input.component.scss'],
})
export class StandaloneInputComponent implements OnInit, OnDestroy {
    @Input() startValue = '';
    @Input() placeholder = '';
    @Input() simpleInput = true;
    @Input() onlyEmitOnEnter = false;
    @Input() resetOnEnter = false;
    @Input() numberOfRows = 3;
    @Input() delay = 600;
    @Input() disabled = false;
    @Output() textChanged = new EventEmitter<string>();
    @Output() blurEvent = new EventEmitter<void>();
    formControl: UntypedFormControl = new UntypedFormControl('');
    subscription = new Subscription();
    currentValue: string;
    constructor() {}

    ngOnInit(): void {
        this.currentValue = this.startValue;
        this.formControl.setValue(this.startValue);
        if (!this.onlyEmitOnEnter) {
            this.subscription.add(
                this.formControl.valueChanges
                    .pipe(debounceTime(this.delay), distinctUntilChanged())
                    .subscribe((data) => this.textChanged.emit(data.trim()))
            );
        }
    }

    onEnter(): void {
        const value = String(this.formControl.value ?? '').trim();
        if (this.onlyEmitOnEnter && value !== this.currentValue) {
            this.currentValue = value;
            this.textChanged.emit(value);
        }

        if (this.onlyEmitOnEnter && value === this.currentValue) {
            this.blurEvent.emit();
        }
        if (this.resetOnEnter) {
            this.formControl.setValue('');
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
