<div class="iframe-empty-state" *ngIf="!src" data-e2e="youtube-widget">
    <img src="assets/images/youtube_empty_state.svg" alt="" />
    <div class="iframe-empty-state__text">
        <span>
            Hogy a tananyagban meg tudjuk jeleníteni a tartalmat <br />
            érvényes URL-re van szükségünk.</span
        >
    </div>
    <button class="template-btn section-btn">URL megadása</button>
</div>
<div class="youtube-player" *ngIf="src" data-e2e="youtube-widget">
    <iframe
        width="700"
        height="1000"
        [src]="src | safeResourceUrl"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
    ></iframe>
</div>
