import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

const shortTiming = 200;

export const fadeAnimations = [
    trigger('fadeInOut', [
        transition(':enter', [style({ opacity: '0' }), animate(shortTiming, style({ opacity: '1' }))]),
        transition(':leave', [
            style({ opacity: '1', display: 'block' }),
            animate(0, style({ opacity: '0', display: 'block' })),
        ]),
    ]),

    trigger('listAnimation', [
        transition('* <=> *', [
            query(
                ':enter',
                [style({ opacity: 0 }), stagger('60ms', animate('300ms ease-out', style({ opacity: 1 })))],
                {
                    optional: true,
                }
            ),
            // query(':leave', animate('30ms', style({ opacity: 0 })), { optional: true }),
        ]),
    ]),
];
