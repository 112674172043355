import { BaseSubFormClass } from '../../base-widget-models/base-sub-form-class';
import { ChoiceAnswer } from '@apiModels';
import { UUIDGeneratorUtility } from '@sharedUtilities/uuid-genarator.utility';
import { IFileUploadValue } from '@shared/models/file-upload.model';

export interface ChocieAnswerWrapper {
    correct: boolean;
    explanation?: string;
    id?: string;
    mediaResourceId?: string;
    text?: string;
}

export interface IChoiceAnswerModel {
    correct: boolean;
    explanation?: string;
    id?: string;
    mediaResourceElement?: IFileUploadValue;
    text?: string;
}

export class ChoiceAnserModel extends BaseSubFormClass<ChoiceAnswer, ChocieAnswerWrapper> {
    constructor(data: ChoiceAnswer) {
        super(data);

        if (!this.data.id) {
            this.data.id = UUIDGeneratorUtility.generate();
        }
    }

    getFormData(): IChoiceAnswerModel {
        return {
            correct: this.data?.correct ?? null,
            explanation: this.data?.explanation ?? null,
            id: this.data?.id ?? null,
            mediaResourceElement: {
                fileItem: this.fileUploadElement,
                elementId: this.data.mediaResourceId ?? null,
            },
            text: this.data?.text ?? null,
        };
    }

    updateData(modifiedData: IChoiceAnswerModel): void {
        const { mediaResourceElement, ...rest } = modifiedData;
        const mediaResourceId = this.extractUUID(mediaResourceElement);
        this.fileUploadElement = mediaResourceElement.fileItem;
        this.data = { ...this.data, ...rest, mediaResourceId };
    }
}
