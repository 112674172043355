import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PageDeletedComponent } from './core/page-deleted/page-deleted.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/courses-list',
    },
    {
        path: 'courses-list',
        loadChildren: () => import('./courses/courses.module').then((m) => m.CoursesModule),
    },
    {
        path: 'course',
        loadChildren: () => import('./course-editor/course-editor.module').then((m) => m.CourseEditorModule),
    },
    {
        path: 'account-settings',
        loadChildren: () => import('./account-settings/account-settings.module').then((m) => m.AccountSettingsModule),
    },
    { path: 'page_deleted', component: PageDeletedComponent },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
