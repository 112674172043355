<input type="file" #fileInput [accept]="acceptTypes" (change)="fileChangeListener()" />

<ng-container *ngIf="fileSrc; else emptyBtn">
    <iframe
        *ngIf="pdfType"
        #droparea
        type="application/pdf"
        [src]="fileSrc | protectedResourceUrl | async"
        (load)="iframeLoaded($event)"
        class="document"
        (click)="fileInput.click()"
        (drop)="landFile($event); droparea.className = 'dropper'"
        (dragenter)="droparea.className = 'dropper dragover'"
        (dragover)="$event.preventDefault()"
        (dragleave)="droparea.className = 'dropper'"
        tabindex="0"
        [title]="title"
        [height]="height"
    ></iframe>
    <button *ngIf="pdfType" class="delete-btn" (click)="deleteFile()" (keyup.enter)="deleteFile()">
        <span class="nitroicon-close" aria-hidden="true"></span>
    </button>
    <button *ngIf="!pdfType" class="editor-btn height-small left-icon primary new-card-btn" (click)="fileInput.click()">
        <span class="nitroicon-upload editor-btn-icon"></span>
        {{ fileName + ' ' }}{{ 'DOCUMENT-WIDGET.FILE-CHANGE' | translate }}
    </button>
</ng-container>

<ng-template #emptyBtn>
    <div class="dropper" #droparea>
        <button
            data-e2e="file-upload"
            class="empty-btn"
            (click)="fileInput.click()"
            (drop)="landFile($event); droparea.className = 'dropper'"
            (dragenter)="droparea.className = 'dropper dragover'"
            (dragover)="$event.preventDefault()"
            (dragleave)="droparea.className = 'dropper'"
            tabindex="0"
        >
            <span #contentWrapper>
                <ng-content></ng-content>
            </span>

            <div *ngIf="contentWrapper.childNodes.length === 0" class="empty-btn__cover">
                <img src="assets/images/documentum-widget.svg" class="empty-btn__img" alt="" />
                <span class="empty-btn__upload-text">{{ 'DOCUMENT-WIDGET.DOCUMENT-DRAG-OR' | translate }}</span>
                <button type="button" class="empty-btn__upload-button editor-btn border-2">
                    {{ 'DOCUMENT-WIDGET.DOCUMENT-UPLOAD' | translate }}
                </button>
            </div>
        </button>
    </div>
</ng-template>
