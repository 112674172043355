import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { IFullPageModalActions } from '@shared/components/full-page-modal/full-page-modal-data.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';

export type FormSaveModeType = 'NORMAL' | 'ONLY_UPDATE' | 'MOVE_NAVIGATION_CARD';

export interface IFormChanged<FormData> {
    data: FormData;
    mode: FormSaveModeType;
}
export interface IAbstractFormBase<FormData, ConfigData> {
    formChanged: EventEmitter<IFormChanged<FormData>>;
    data: FormData;
    config: ConfigData;
    fullsizeClass: boolean;
    updateForm(value: FormData): void;
    resetEmittedForSave(): void;
    configDataChanged(data?: any): void;
}

@Component({
    selector: 'app-abstract-form-base',
    template: '',
    styles: [],
})
export abstract class AbstractFormBaseComponent<FormData, ConfigData>
    implements IAbstractFormBase<FormData, ConfigData>, IFullPageModalActions, ControlValueAccessor, OnInit, OnDestroy
{
    @Input() data: FormData;
    @Input() config: ConfigData;
    @Input() formChangedebounceTime = 600;
    @Output() formChanged = new EventEmitter<IFormChanged<FormData>>();
    form: FormGroupTyped<FormData>;
    mode: FormSaveModeType = 'NORMAL';
    subscriptions = new Subscription();
    shouldSave = true;
    emittedForSave = false;
    fullsizeClass: boolean;

    onChange: any = () => {};
    onTouched: any = () => {};

    get value(): FormData {
        return this.form.value;
    }

    set value(value: FormData) {
        this.form.setValue(value);
        this.onChange(value);
        this.onTouched();
    }

    protected constructor() {
        this.form = this.createForm();
        this.subscriptions.add(
            this.form.valueChanges
                .pipe(
                    filter((_) => this.shouldSave),
                    tap((value: any) => {
                        this.emittedForSave = false;
                        this.onChange(value);
                        this.onTouched();
                    }),
                    debounceTime(this.formChangedebounceTime)
                )
                .subscribe((value: any) => {
                    this.emittedForSave = true;
                    this.formChanged.emit({ data: value, mode: this.mode });
                    this.mode = 'NORMAL';
                })
        );
    }

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle
    ngOnInit(): void {
        if (this.data) {
            this.updateForm(this.data);
        }
    }

    abstract createForm(): FormGroupTyped<FormData>;

    updateForm(value: FormData): void {
        this.form.setValue(value, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    configDataChanged(data?: any): void {}

    writeValue(value: any): void {
        if (value) {
            this.value = value;
        }

        if (value === null) {
            this.form.reset();
        }
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // communicate the inner form validation to the parent form
    validate(_: UntypedFormControl): void {
        // return this.form.valid ? null : { profile: { valid: false } };
    }

    canClose(): boolean {
        return !this.form.dirty;
    }

    resetEmittedForSave(): void {
        this.emittedForSave = false;
    }

    afterSave(): void {
        this.form.markAsPristine();
    }

    setTouchedState(): void {
        this.form.markAllAsTouched();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
