import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProtectedResourceDownloadService } from '@sharedServices/protected-resource-download.service';
import { Observable, of } from 'rxjs';

@Pipe({
    name: 'noBypassProtectedResourceUrl',
})
export class NoByPassProtectedResourceUrlPipe implements PipeTransform {
    constructor(private protectedDownloadSrv: ProtectedResourceDownloadService, protected sanitizer: DomSanitizer) {}

    transform(src: string, simpleUrlDownload = true): Observable<string> {
        if (!src) {
            return of(null);
        }
        if (src.startsWith('blob')) {
            return of(src);
        }

        return this.protectedDownloadSrv.downloadResource(src, simpleUrlDownload);
    }
}
