import { BaseWidgetClass } from './base-widget-models/base-widget-class';
import { TypedResponse } from './base-widget-models/typed-widget-response';
import { InfoWidgetData } from 'src/app/api/models/info-widget-data';

export interface RealTimeInfoCardWidgetForm {
    text: string;
    color: string;
    pictogram: any;
}

export class InfoCardWidgetClass extends BaseWidgetClass<InfoWidgetData> {
    constructor(data: TypedResponse<InfoWidgetData>) {
        super(data);
        this.extraSelector = '.ql-editor';
    }

    getRealTimeFormData(): RealTimeInfoCardWidgetForm {
        return {
            text: this.data?.text ?? null,
            color: this.data?.color ?? '#009BEF',
            pictogram: this.data?.pictogram ?? 'INFO',
        };
    }

    getDetailFormData(): any {
        return null;
    }

    updateWidgetData(modifiedData: RealTimeInfoCardWidgetForm): void {
        this.data = { ...this.data, ...modifiedData };
    }
}
