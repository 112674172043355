import { Directive, HostBinding, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadHandlerService } from '@sharedServices/file-upload-handler.service';
import { URLHelperUtility } from '@sharedUtilities/url-helpers.utility';

@Directive({
    selector: '[appImgPreloader]',
})
export class ImagePreloaderDirective implements OnInit, OnChanges {
    @Input() resourceId: string;
    @Input() imageSize = 'M';
    @Input() courseId: number;
    @Input() resource: File;
    @Input() notProcessedUrl = false;

    constructor(private fileUploadHandlerSrv: FileUploadHandlerService, protected sanitizer: DomSanitizer) {}

    @HostBinding('src') src: any;

    @HostListener('error', ['$event'])
    errorListener(event: ErrorEvent): void {
        event.preventDefault();
        this.src = URLHelperUtility.resourceURLForCourse(this.courseId, this.resourceId);
    }

    ngOnInit(): void {
        if (this.resource) {
            this.src = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.resource));
            return;
        }
        const file = this.checkIfFileExistsInCache();
        this.src = file
            ? this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
            : this.notProcessedUrl
            ? URLHelperUtility.resourceURLForCourse(this.courseId, this.resourceId)
            : URLHelperUtility.imageResourceURLForCourse(this.courseId, this.resourceId, this.imageSize);
    }

    checkIfFileExistsInCache(): File | null {
        return this.fileUploadHandlerSrv.getExistingFile(this.resourceId);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resourceId) {
            this.ngOnInit();
        }
    }
}
