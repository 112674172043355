import { trigger, animate, style, transition } from '@angular/animations';

const animationTiming = '.4s';

export const toastAnimations = [
    trigger('visibleAnimation', [
        transition(':enter', [
            style({ transform: 'translateX(110%)' }),
            animate(animationTiming, style({ transform: 'translateX(0)' })),
        ]),
        transition(':leave', [
            style({ transform: 'translateX(0)' }),
            animate(animationTiming, style({ transform: 'translateX(110%)' })),
        ]),
    ]),
];
