<div class="page-not-found">
    <div class="page-not-found-image-container">
        <img src="assets/images/page-not-found.svg" alt="" />
        <div class="page-not-found-image-container__title">{{ 'PAGE_NOT_FOUND.TITLE' | translate }}</div>
    </div>
    <div class="page-not-found-text-container">
        <h2 class="page-not-found-text-container__subtitle">{{ 'PAGE_NOT_FOUND.SUBTITLE' | translate }}</h2>
        <span class="page-not-found-text-container__details"> {{ 'PAGE_NOT_FOUND.DETAILS' | translate }}</span>
    </div>
    <div class="page-not-found__btn">
        <a (click)="backToPreviousPage()" class="editor-btn radius-25 border-2 orange">
            {{ 'PAGE_NOT_FOUND.BACK_TO_PREVIOUS_PAGE' | translate }}
        </a>
        <a routerLink="/" class="editor-btn border-2 orange">
            {{ 'PAGE_NOT_FOUND.BACK_TO_LANDING_PAGE' | translate }}
        </a>
    </div>
</div>
