/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DocumentTextWidgetData } from '@apiModels';
import { IFileUploadValue } from '@shared/models/file-upload.model';

import { BaseWidgetClass } from '../base-widget-models/base-widget-class';
import { TypedResponse } from '../base-widget-models/typed-widget-response';
import { WidgetSizeEnum } from '../base-widget-models/widget-size.enum';
import { MultiWidgetDividerLocation } from '../image-text-widget-model';

export interface RealTimeDocumentTextWidgetForm {
    element: IFileUploadValue;
    text: string | null;
    dividerLocation: MultiWidgetDividerLocation;
    flipped: boolean;
    documentTitle: string | null;
}

export interface DetailDocumentTextWidgetForm {
    realTimeDocumentTextWidgetFormData: RealTimeDocumentTextWidgetForm;
    dividerLocation: MultiWidgetDividerLocation;
    flipped: boolean;
    documentDescription: string;
    fulfillmentAvailable: boolean;
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
    size: WidgetSizeEnum;
}

export interface DocumentTextWidgetDataSpec extends DocumentTextWidgetData {
    fulfillment: 'NONE' | 'SHOW' | 'CLICK';
}

export class DocumentTextWidgetClass extends BaseWidgetClass<DocumentTextWidgetDataSpec> {
    constructor(data: TypedResponse<DocumentTextWidgetDataSpec>) {
        super(data);
    }

    getRealTimeFormData(): RealTimeDocumentTextWidgetForm {
        return {
            element: {
                fileItem: this.fileUploadElement,
                elementId: this.data?.documentResourceId ?? null,
            },
            text: this.data.text ?? null,
            dividerLocation: this.data?.dividerLocation ?? 'CENTER',
            flipped: this.data?.flipped ?? false,
            documentTitle: this.data?.documentTitle ?? null,
        };
    }

    getDetailFormData(): DetailDocumentTextWidgetForm {
        return {
            realTimeDocumentTextWidgetFormData: this.getRealTimeFormData(),
            dividerLocation: this.data?.dividerLocation ?? 'CENTER',
            flipped: this.data?.flipped ?? false,
            documentDescription: this.data?.documentDescription ?? null,
            fulfillmentAvailable: this.data?.fulfillmentAvailable ?? false,
            fulfillment: this.data?.fulfillment ?? (this.data?.fulfillmentAvailable ? 'SHOW' : 'NONE'),
            size: (this.data.size as WidgetSizeEnum) ?? WidgetSizeEnum.MARGIN,
        };
    }

    updateWidgetData(modifiedData: RealTimeDocumentTextWidgetForm | DetailDocumentTextWidgetForm): void {
        if (modifiedData.hasOwnProperty('realTimeDocumentTextWidgetFormData')) {
            const { realTimeDocumentTextWidgetFormData, ...rest } = modifiedData as DetailDocumentTextWidgetForm;
            modifiedData = {
                ...realTimeDocumentTextWidgetFormData,
                ...rest,
            };
        }

        const { element, ...rest } = modifiedData as RealTimeDocumentTextWidgetForm & DetailDocumentTextWidgetForm;

        if (this.shouldSaveFile(element.fileItem, this.data.documentResourceId)) {
            this.filesToSave.push(element.fileItem);
        }
        this.fileUploadElement = element.fileItem;
        const documentResourceId = this.extractUUID(element);
        const originalDocumentName = documentResourceId
            ? this.fileUploadElement
                ? this.fileUploadElement.name
                : this?.data?.originalDocumentName
            : null;
        this.data = { ...this.data, ...rest, documentResourceId, originalDocumentName };
    }

    removeResourceIds(resourceIds: string[]): void {
        resourceIds.forEach((id: string) => {
            if (this.data.documentResourceId === id) {
                this.data = { ...this.data, documentResourceId: null, originalDocumentName: null };
                this.fileUploadElement = null;
            }
        });
    }
}
