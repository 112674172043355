<app-dialog
    [titlePath]="data.titlePath"
    [messagePath]="data.messagePath"
    [primaryButtonTextPath]="data.primaryButtonTextPath"
    [primaryButtonIcon]="data.primaryButtonIcon"
    [secondaryButtonTextPath]="data.secondaryButtonTextPath"
    [secondaryButtonIcon]="data.secondaryButtonIcon"
    [messageExtraInfo]="data.messageExtraInfo"
    [buttonIconAlign]="'start'"
    [alignActions]="'center'"
    [allowCloseInHeader]="false"
>
</app-dialog>
