import { Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-checkbox-control',
    templateUrl: './checkbox-control.component.html',
    styleUrls: ['./checkbox-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxControlComponent),
            multi: true,
        },
    ],
})
export class CheckboxControlComponent implements ControlValueAccessor {
    @Input() template: TemplateRef<unknown>;
    @Input() templateData: unknown;
    @Input() labelText = '';
    @Input() ariaLabel = '';
    @Input() id: any;
    controlIsDisabled = false;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _value = false;

    private onTouchedCallback: () => void;
    private onChangeCallback = (value: any) => {};

    get value(): boolean {
        return this._value;
    }

    set value(v: boolean) {
        if (v !== this._value) {
            this._value = v;
            this.onChangeCallback(v);
        }
    }

    writeValue(value: any): void {
        this._value = value;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.controlIsDisabled = isDisabled;
    }
}
