import { NavigationBlockDeleteData } from './navigation-block-delete-data.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-navigation-block-delete-modal',
    templateUrl: './navigation-block-delete-modal.component.html',
    styleUrls: ['./navigation-block-delete-modal.component.scss'],
})
export class NavigationBlockDeleteModalComponent {
    resultPrimary = {
        result: 'primary',
        data: this.data,
    };

    resultSecondary = {
        result: 'secodary',
        data: this.data,
    };

    resultNone = {
        result: 'none',
        data: this.data,
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: NavigationBlockDeleteData) {}
}
