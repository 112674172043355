import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface InfoBoxActions {
    text: string;
    action: string;
}

@Component({
    selector: 'app-info-box-with-actions',
    templateUrl: './info-box-with-actions.component.html',
    styleUrls: ['./info-box-with-actions.component.scss'],
})
export class InfoBoxWithActionsComponent {
    @Input() icon = 'nitroicon-info';
    @Input() type: 'INFO' | 'WARNING' | 'ERROR' = 'INFO';
    @Input() infoText = '';
    @Input() actionButtons: InfoBoxActions[] = [];
    @Output() actionEvent = new EventEmitter();

    onButtonClick(action: string): void {
        this.actionEvent.emit(action);
    }
}
