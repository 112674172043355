<form [formGroup]="form" class="concept-widget" data-e2e="concept-widget">
    <div class="concept-widget__concept">
        <app-quill
            *ngIf="!testEnv"
            class="concept-widget__concept__input app-quill"
            [id]="config?.focusElemId"
            [preSetStyles]="['bold']"
            [formControlName]="'concept'"
            [placeholder]="'CONCEPT-CARD-WIDGET.NAME-PLACEHOLDER' | translate"
        >
        </app-quill>
    </div>
    <div class="concept-widget__definition">
        <app-quill
            *ngIf="!testEnv"
            class="app-quill"
            [formControlName]="'definition'"
            [placeholder]="'CONCEPT-CARD-WIDGET.DEFINITION-PLACEHOLDER' | translate"
        >
        </app-quill>
    </div>
</form>
