<form [formGroup]="form" class="info-widget" data-e2e="info-widget">
    <div class="info-widget__container">
        <div class="info-widget__toolbar top">
            <div class="info-widget__pictograms">
                <label
                    *ngFor="let pictogram of pictograms"
                    [for]="pictogram + config?.widgetId"
                    class="pictograms-label"
                    [class.selected]="pictogram === this.form.get('pictogram').value"
                >
                    <span class="icon-itself" [ngClass]="['nitroicon-' + pictogram | lowercase]"></span>
                    <input
                        formControlName="pictogram"
                        [id]="pictogram + config?.widgetId"
                        type="radio"
                        value="{{ pictogram }}"
                    />
                </label>
            </div>
        </div>
        <div class="info-widget__toolbar left">
            <div class="info-widget__colors">
                <label
                    class="color-label"
                    *ngFor="let color of colors"
                    [for]="color + config?.widgetId"
                    [style.background-color]="color"
                    (change)="onChange()"
                >
                    <input formControlName="color" [id]="color + config?.widgetId" type="radio" value="{{ color }}" />
                </label>
            </div>
        </div>
        <input type="hidden" formControlName="pictogram" />
        <div class="info-widget__text">
            <div class="side-stripe" [style.background-color]="this.form.get('color').value"></div>
            <div class="icon-side">
                <span
                    [ngClass]="['nitroicon-' + this.form.get('pictogram').value | lowercase]"
                    [style.color]="this.form.get('color').value"
                ></span>
            </div>

            <div class="text-side">
                <app-quill
                    *ngIf="!testEnv"
                    class="app-quill"
                    [formControlName]="'text'"
                    [preSetStyles]="['italic', 'bold']"
                    [id]="config?.focusElemId"
                    [placeholder]="'INFO-CARD-WIDGET.NAME-PLACEHOLDER' | translate"
                >
                </app-quill>
            </div>
        </div>
    </div>
</form>
