<form class="editor-state" [formGroup]="form" data-e2e="anchor-widget" [hidden]="state === 'TEMPLATE'">
    <h2>{{ 'ANCHOR-WIDGET.EXPLANATION-TITLE' | translate }}</h2>
    <p>{{ 'ANCHOR-WIDGET.EXPLANATIONTEXT-1' | translate }}</p>
    <p>{{ 'ANCHOR-WIDGET.EXPLANATIONTEXT-2' | translate }}</p>
    <div class="editor-state__form-field">
        <label class="input-label" [for]="config?.focusElemId">{{ 'ANCHOR-WIDGET.CHAPTER_TITLE' | translate }}</label>
        <input
            class="generic-input-reset"
            type="text"
            (blur)="afterBlur()"
            [placeholder]="'ANCHOR-WIDGET.UNNAMED' | translate"
            [formControlName]="'name'"
            [id]="config?.focusElemId"
        />
    </div>
</form>
<button [hidden]="state === 'EDITOR'" (click)="stateToEditor()" class="template-state-button">
    {{ name ?? 'ANCHOR-WIDGET.UNNAMED' | translate }}
</button>
<ng-container *ngIf="!testEnv">
    <app-exported-template-actions [selector]="'#extra-action-container_' + config?.focusElemId" *ngIf="!testEnv">
        <button
            [class.accent]="visible"
            class="editor-btn section-btn ghost height-small icon-only"
            (click)="setVisibility(true)"
        >
            <span class="nitroicon-visibility-on editor-btn-icon"></span>
        </button>
        <button
            [class.accent]="!visible"
            class="editor-btn section-btn ghost height-small icon-only"
            (click)="setVisibility(false)"
        >
            <span class="nitroicon-visibility_off editor-btn-icon"></span>
        </button>
    </app-exported-template-actions>
</ng-container>
