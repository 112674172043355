/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Range } from 'ngx-quill';
import Quill from 'quill';
const Keyboard = Quill.import('modules/keyboard');
const Delta = Quill.import('delta');

export class CustomKeyboard extends Keyboard {
    static DEFAULTS = {
        ...Keyboard.DEFAULTS,
        bindings: {
            ...Keyboard.DEFAULTS.bindings,
            ['list autofill']: {
                ...Keyboard.DEFAULTS.bindings['list autofill'],
                prefix: /^\s*?(\d+\.|-|\*|\[ ?\]|\[x\])$/,
                handler(
                    range: Range,
                    context: { prefix: string; format: { [key: string]: number | boolean } }
                ): boolean {
                    if (Object.keys(context.format).includes('header')) {
                        return true;
                    }
                    const [line, offset] = this.quill.getLine(range.index);
                    // if (this.quill?.scroll?.query('list') == null) return true;
                    const { length } = context.prefix;
                    if (offset > length) return true;
                    let value;
                    switch (context.prefix.trim()) {
                        case '[]':
                        case '[ ]':
                            value = 'unchecked';
                            break;
                        case '[x]':
                            value = 'checked';
                            break;
                        case '-':
                        case '*':
                            value = 'bullet';
                            break;
                        default:
                            value = 'ordered';
                    }
                    this.quill.insertText(range.index, ' ', 'user');
                    this.quill.history.cutoff();
                    const delta = new Delta()
                        .retain(range.index - offset)
                        .delete(length + 1)
                        .retain(line.length() - 2 - offset)
                        .retain(1, { list: value });
                    this.quill.updateContents(delta, 'user');
                    this.quill.history.cutoff();
                    this.quill.setSelection(range.index - length, 'silent');
                    return false;
                },
            },
        },
    };

    constructor(quill, context) {
        super(quill, context);
    }
}
