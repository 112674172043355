<button
    class="editor-btn height-small radius-25 border-0 primary icon-only"
    [matMenuTriggerFor]="menu"
    aria-label="menu"
>
    <span [class]="icon + ' editor-btn-icon header-icon'"></span>
</button>
<mat-menu #menu="matMenu" [class]="'reset'">
    <ng-container *ngFor="let item of menuList; let i = index; trackBy: trackByFn">
        <button mat-menu-item *ngIf="item.action" (click)="onActionEvent(item.action, item?.item)">
            <span
                *ngIf="item.icon"
                [ngClass]="{ error: errorColor(item.icon) }"
                [class]="item.icon + ' menu-icon'"
            ></span>
            <span>{{ item.name | translate }}</span>
        </button>

        <app-user-info-card
            *ngIf="item?.type === 'USER'"
            [user]="item.item"
            (actionEvent)="onActionEvent('LOGOUT', item.item)"
        >
        </app-user-info-card>

        <span mat-menu-item *ngIf="item?.type === 'INFO'">
            <span [class]="item.icon + ' menu-icon'"></span>
            <span>{{ item.name }}</span>
        </span>

        <a
            mat-menu-item
            *ngIf="item?.navigateTo?.length"
            [routerLink]="item?.navigateTo"
            routerLinkActive="activeMenu"
            fragment="{{ item?.fragment }}"
        >
            <span [class]="item.icon + ' menu-icon'"></span>
            <span>{{ item.name | translate }}</span>
        </a>
    </ng-container>
</mat-menu>
