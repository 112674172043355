<form
    data-e2e="document-text"
    [formGroup]="form"
    class="document-text-widget"
    [ngClass]="[dividerLocationValue, setStarterImageSize()]"
>
    <section
        class="column left-column"
        [class.right-borders]="!flippedValue"
        [class.left-borders]="flippedValue"
        [style.order]="flippedValue ? 1 : 0"
    >
        <app-quill
            *ngIf="!testEnv"
            [formControlName]="'text'"
            class="app-quill"
            [toolbarVisible]="true"
            [placeholder]="'IMAGE-TEXT-WIDGET.TEXT-SIDE-PLACEHOLDER' | translate"
        ></app-quill>

        <div
            class="real-time-actions"
            [class.right]="!flippedValue"
            [class.left]="flippedValue"
            *ngIf="location === 'real-time'"
            (mousedown)="mouseOnButton = true"
            (mouseup)="mouseOnButton = false"
            (mouseleave)="mouseOnButton = false"
            (mousemove)="changeLayout($event)"
        >
            <button
                class="switch-button editor-btn height-small icon-only primary"
                type="button"
                (mousemove)="$event.stopPropagation()"
                (click)="switchSides()"
            >
                <span class="nitroicon-switch_sides editor-btn-icon"></span>
            </button>
        </div>
    </section>

    <section class="column right-column" [class.right-borders]="flippedValue" [class.left-borders]="!flippedValue">
        <app-document-upload
            *ngIf="location === 'detail' || !hasElement"
            class="app-document-upload"
            [formControlName]="'element'"
            [courseId]="config?.courseId"
            [originalFileName]="config?.originalFileName"
        >
            <img src="assets/images/documentum-widget.svg" class="document-text-widget__img" alt="" />
        </app-document-upload>

        <div class="document-download-wrapper" *ngIf="location === 'detail' || hasElement">
            <button class="template-btn left-icon document-download-wrapper-button">
                <span class="nitroicon-download btn-icon"></span>
                <input
                    [ngStyle]="{ 'width.px': width }"
                    (input)="resize()"
                    class="generic-input-reset"
                    type="text"
                    [placeholder]="'DOCUMENT-WIDGET.DOCUMENT-TITLE-PLACEHOLDER' | translate"
                    [formControlName]="'documentTitle'"
                />
                <span #hiddenText class="hidden-span">{{ this.form.controls['documentTitle']?.value }}</span>
            </button>
        </div>
    </section>
</form>
