import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { BaseFileUploadComponent } from '@shared/forms/file-upload/base-file-upload/base-file-upload.component';

@Component({
    selector: 'app-multi-audio-upload',
    templateUrl: './multi-audio-upload.component.html',
    styleUrls: ['./multi-audio-upload.component.scss', '../base-file-upload/base-file-upload.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiAudioUploadComponent),
            multi: true,
        },
    ],
})
export class MultiAudioUploadComponent extends BaseFileUploadComponent implements OnDestroy {
    @Input() acceptTypes = 'audio/mp3';
    @Input() regExp = new RegExp(/\.(mp3)$/i);
    @Input() maxFileSize = 1_000_000_000;
    bypassSw = true;
    @ViewChild('audio') audio: ElementRef<HTMLAudioElement>;
    @ViewChild('durationSlider') durationSlider: ElementRef<HTMLInputElement>;
    sliderValue = '0';
    currentTime = 0;
    audioMax: number;
    audioDurationTime: string;
    audioCurrentTime: string;
    playerStatus: 'PLAY' | 'PAUSED' = 'PAUSED';
    ref = null;

    constructor(protected toastsSrv: ToastMessageService, private cdr: ChangeDetectorRef) {
        super(toastsSrv, cdr);
    }

    startToPlay(): void {
        this.audio.nativeElement.play();
    }

    deleteFile(): void {
        super.deleteFile();
        cancelAnimationFrame(this.ref);
        this.playerStatus = 'PAUSED';
        this.sliderValue = '0';
        this.currentTime = 0;
        this.audioMax = null;
        this.audioDurationTime = null;
    }

    setAudioDuraton(): void {
        this.audioMax = Math.round(this.audio.nativeElement.duration);
        this.audioDurationTime = this.calculateTime(this.audio.nativeElement.duration);
        this.cdr.markForCheck();
    }

    togglePlayPause(): void {
        if (this.playerStatus === 'PAUSED') {
            this.playerStatus = 'PLAY';
            this.audio?.nativeElement?.play();
            requestAnimationFrame(this.whilePlaying);
            return;
        }
        this.playerStatus = 'PAUSED';
        this.audio?.nativeElement?.pause();
        cancelAnimationFrame(this.ref);
    }

    sliderChanged(): void {
        const currentValue = Number(this.durationSlider.nativeElement.value);
        this.audioCurrentTime = this.calculateTime(currentValue);
        this.currentTime = currentValue;
        if (this.playerStatus === 'PLAY') {
            requestAnimationFrame(this.whilePlaying);
        }
        this.updateSlider();
    }

    sliderInput(): void {
        const currentValue = Number(this.durationSlider.nativeElement.value);
        this.currentTime = currentValue;
        this.audioCurrentTime = this.calculateTime(currentValue);
        if (this.playerStatus === 'PLAY') {
            cancelAnimationFrame(this.ref);
        }
        this.updateSlider();
    }

    updateSlider(): void {
        const currentValue = Number(this.durationSlider.nativeElement.value);
        const sliderWidth = 200;
        const thumbWidth = 70;
        const xPX = (currentValue * (sliderWidth - thumbWidth)) / this.audioMax;
        this.sliderValue = `${xPX}px`;
        this.cdr.markForCheck();
    }

    audioEnded(): void {
        cancelAnimationFrame(this.ref);
        this.playerStatus = 'PAUSED';
    }

    calculateTime = (audioSeconds: number): string => {
        const minutes = Math.floor(audioSeconds / 60);
        const seconds = Math.floor(audioSeconds % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    };

    whilePlaying = (): void => {
        const currentTime = this.audio.nativeElement.currentTime;
        this.durationSlider.nativeElement.value = String(currentTime);
        this.updateSlider();
        this.audioCurrentTime = this.calculateTime(currentTime);
        this.ref = requestAnimationFrame(this.whilePlaying);
    };

    ngOnDestroy(): void {
        cancelAnimationFrame(this.ref);
    }
}
