import { Levels, WidgetGroups, ExamWidgetOptions } from './course-fulfillment-settings.model';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FulfillmentSettings } from '@apiModels';

@Component({
    selector: 'app-course-fulfillment-settings',
    templateUrl: './course-fulfillment-settings.component.html',
    styleUrls: ['./course-fulfillment-settings.component.scss'],
})
export class CourseFulfillmentSettingsComponent implements OnInit {
    widgetGroups = WidgetGroups;
    levels = Levels;
    examWidgetOptions = ExamWidgetOptions;

    levelForm: FormGroupTyped<FormData>;
    fulfillmentForm: FormGroup;

    @Input() fulfillmentSettings: FulfillmentSettings;
    @Output() fulfillmentSettingsChange = new EventEmitter<FulfillmentSettings>();

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.initForms();
        if (this.fulfillmentSettings) {
            this.updateLevelForm();
            this.updateFulfillmentForm(this.fulfillmentSettings);
        }
    }

    updateLevelForm(): void {
        this.levelForm.controls['chosen_level'].setValue(this.fulfillmentSettings.fulfillmentLevel ?? 'LAX');
    }

    updateFulfillmentForm(fulfillSettings: FulfillmentSettings): void {
        this.fulfillmentForm = this.fb.group({});
        switch (fulfillSettings.fulfillmentLevel) {
            case 'NONE': {
                this.widgetGroups.map((group) => {
                    group.elems.map((elem) => {
                        this.addControlToFulfillmentForm(group.groupName, elem, 'NONE');
                    });
                });
                break;
            }
            case 'LAX': {
                this.widgetGroups.map((group) => {
                    group.elems.map((elem) => {
                        this.addControlToFulfillmentForm(group.groupName, elem, 'SHOW');
                    });
                });
                break;
            }
            case 'MEDIUM': {
                this.widgetGroups.map((group) => {
                    group.elems.map((elem) => {
                        switch (group.groupName) {
                            case 'QUESTION_WIDGET':
                                this.addControlToFulfillmentForm(group.groupName, elem, 'QUESTION_ANSWERED');
                                break;
                            case 'SIMPLE_VIDEO_WIDGET':
                                this.addControlToFulfillmentForm(group.groupName, elem, 'VIDEO_STARTED');
                                break;
                            case 'AUDIO_WIDGET':
                                this.addControlToFulfillmentForm(group.groupName, elem, 'AUDIO_STARTED');
                                break;
                            case 'MULTI':
                                this.addControlToFulfillmentForm(
                                    group.groupName,
                                    elem,
                                    elem === 'VIDEO_TEXT_WIDGET'
                                        ? 'VIDEO_STARTED'
                                        : elem === 'AUDIO_TEXT_WIDGET'
                                        ? 'AUDIO_STARTED'
                                        : 'SHOW'
                                );
                                break;
                            case 'EMBEDED':
                                this.addControlToFulfillmentForm(
                                    group.groupName,
                                    elem,
                                    elem === 'EXAM_WIDGET' ? 'QUESTION_ANSWERED' : 'SHOW'
                                );
                                break;
                            default:
                                this.addControlToFulfillmentForm(group.groupName, elem, 'SHOW');
                                break;
                        }
                    });
                });
                break;
            }
            case 'STRICT': {
                this.widgetGroups.map((group) => {
                    group.elems.map((elem) => {
                        switch (group.groupName) {
                            case 'QUESTION_WIDGET':
                                this.addControlToFulfillmentForm(group.groupName, elem, 'QUESTION_CORRECTLY_ANSWERED');
                                break;
                            case 'SIMPLE_VIDEO_WIDGET':
                                this.addControlToFulfillmentForm(group.groupName, elem, 'VIDEO_FINISHED');
                                break;
                            case 'AUDIO_WIDGET':
                                this.addControlToFulfillmentForm(group.groupName, elem, 'AUDIO_FINISHED');
                                break;
                            case 'CARDS':
                                this.addControlToFulfillmentForm(group.groupName, elem, 'CLICK');
                                break;
                            case 'DOCUMENT_WIDGET':
                                this.addControlToFulfillmentForm(group.groupName, elem, 'CLICK');
                                break;
                            case 'MULTI':
                                this.addControlToFulfillmentForm(
                                    group.groupName,
                                    elem,
                                    elem === 'VIDEO_TEXT_WIDGET'
                                        ? 'VIDEO_FINISHED'
                                        : elem === 'AUDIO_TEXT_WIDGET'
                                        ? 'AUDIO_FINISHED'
                                        : elem === 'DOCUMENT_TEXT_WIDGET'
                                        ? 'CLICK'
                                        : 'SHOW'
                                );
                                break;
                            case 'EMBEDED':
                                this.addControlToFulfillmentForm(
                                    group.groupName,
                                    elem,
                                    elem === 'EXAM_WIDGET' ? 'QUESTION_CORRECTLY_ANSWERED' : 'SHOW'
                                );
                                break;
                            default:
                                this.addControlToFulfillmentForm(group.groupName, elem, 'SHOW');
                                break;
                        }
                    });
                });
                break;
            }
            case 'CUSTOM': {
                Object.entries(fulfillSettings.customFulfillmentConfig).forEach(([key, value]) => {
                    this.fulfillmentForm.addControl(key, new FormControl(value));
                });
                break;
            }
            default:
                this.widgetGroups.map((group) => {
                    group.elems.map((elem) => {
                        this.addControlToFulfillmentForm(group.groupName, elem, 'NONE');
                    });
                });
                break;
        }
    }

    initForms(): void {
        this.levelForm = new FormGroupTyped<FormData>({
            chosen_level: FormHelper.controlFactoryWithCalculatedValue('LAX'),
        });

        this.fulfillmentForm = this.fb.group({});
        this.widgetGroups.map((group) => {
            group.elems.map((elem) => {
                this.addControlToFulfillmentForm(group.groupName, elem, '');
            });
        });
    }

    onLevelChange(level: 'NONE' | 'LAX' | 'MEDIUM' | 'STRICT' | 'CUSTOM'): void {
        this.fulfillmentSettings.fulfillmentLevel = level;
        if (level !== 'CUSTOM') {
            this.fulfillmentSettings.customFulfillmentConfig = {};
            this.updateFulfillmentForm(this.fulfillmentSettings);
        } else {
            this.fulfillmentSettings.customFulfillmentConfig = this.fulfillmentForm.getRawValue() as { Array };
        }
        this.fulfillmentSettingsChange.emit(this.fulfillmentSettings);
    }

    fulfillmentFormInputChange(
        widgetType: string,
        event:
            | 'SHOW'
            | 'QUESTION_ANSWERED'
            | 'QUESTION_CORRECTLY_ANSWERED'
            | 'NONE'
            | 'VIDEO_STARTED'
            | 'VIDEO_FINISHED'
            | 'AUDIO_STARTED'
            | 'AUDIO_FINISHED'
            | 'CLICK'
    ): void {
        this.fulfillmentSettings.customFulfillmentConfig[widgetType] = event;
        this.fulfillmentSettingsChange.emit(this.fulfillmentSettings);
    }

    addControlToFulfillmentForm(groupName: string, elemName: string, value: string): void {
        this.fulfillmentForm.addControl(elemName !== '' ? `${elemName}` : `${groupName}`, new FormControl(value));
    }
}
