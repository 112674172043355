import { UntypedFormControl } from '@angular/forms';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { DetailQuestionWidgetForm } from 'src/app/api-wrapper/models/widget-models/questions/init-question-detail-form';

export type DetailQuestionWidgetFormType = Record<keyof DetailQuestionWidgetForm, UntypedFormControl>;
export function createDetailQuestionForm(): DetailQuestionWidgetFormType {
    return {
        attempts: FormHelper.controlFactoryWithCalculatedValue(null),
        enableAnswerExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        enableCustomExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        enableGeneralExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
        fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
        explanation: FormHelper.controlFactoryWithCalculatedValue(null),
        explanationCorrect: FormHelper.controlFactoryWithCalculatedValue(null),
        explanationIncorrect: FormHelper.controlFactoryWithCalculatedValue(null),
        explanationPartialCorrect: FormHelper.controlFactoryWithCalculatedValue(null),
        hasAnswerExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        hasAnswerMedia: FormHelper.controlFactoryWithCalculatedValue(null),
        hasCustomExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        hasExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
        hasMedia: FormHelper.controlFactoryWithCalculatedValue(null),
        hasPdf: FormHelper.controlFactoryWithCalculatedValue(null),
        pdfOriginalFileName: FormHelper.controlFactoryWithCalculatedValue(null),
        questionType: FormHelper.controlFactoryWithCalculatedValue(null),
        mediaDescription: FormHelper.controlFactoryWithCalculatedValue(null),
        pdfDescription: FormHelper.controlFactoryWithCalculatedValue(null),
    };
}
