import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';
import { RealTimeExamWidgetForm } from '../../../../api-wrapper/models/widget-models/exam-widget-model';
import { SimpleExamConfigResponse } from '@apiModels';
import { ExamControllerService } from '@apiServices';
import { ISelectOption } from '@shared/forms/select-input/select-input.component';

@Component({
    selector: 'app-exam-real-time-widget',
    templateUrl: './exam-real-time-widget.component.html',
    styleUrls: ['./exam-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ExamRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class ExamRealTimeWidgetComponent
    extends AbstractFormBaseComponent<RealTimeExamWidgetForm, IWidgetSupport>
    implements OnInit
{
    constructor(private examControllerService: ExamControllerService) {
        super();
    }
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    @ViewChild('hiddenText') textEl: ElementRef;
    minWidth = 180;
    width = this.minWidth;
    testEnv = false;
    get examConfig(): SimpleExamConfigResponse {
        return this.data.examData;
    }
    minutes!: number;
    seconds!: number;
    timeMode!: string;
    availableExams: SimpleExamConfigResponse[] = [];
    examOptions: ISelectOption[] = [];
    ngOnInit(): void {
        super.ngOnInit();
        this.examControllerService.listExams().subscribe((r) => {
            this.availableExams = r;
            this.examOptions = r.map((e) => ({ value: e.id, displayName: e.title }));
        });
        this.updateDurationDisplay();
    }

    updateDurationDisplay() {
        this.timeMode = this.examConfig?.timeMode ?? 'QUESTION';
        const seconds = this.examConfig?.maxTime ?? 1;
        this.minutes = Math.floor(seconds / 60);
        const tempSeconds = Number((seconds % 60).toFixed(0));
        this.seconds = tempSeconds === 60 ? 0 : tempSeconds;
    }

    removeExam(): void {
        this.data.examId = null;
        this.data.examData = null;
        this.updateForm({ examId: null, examData: null });
    }

    updateForm(value: RealTimeExamWidgetForm): void {
        this.form.setValue(value, { emitEvent: true });
    }

    createForm(): FormGroupTyped<RealTimeExamWidgetForm> {
        return new FormGroupTyped<RealTimeExamWidgetForm>({
            examId: FormHelper.controlFactoryWithCalculatedValue(null),
            examData: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    onExamSelected($event: number) {
        this.data.examId = $event;
        this.data.examData = this.availableExams.find((e) => e.id === $event);
        // this.updateForm({ examId: $event.id, examData: $event });
        this.updateDurationDisplay();
    }
}
