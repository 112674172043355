import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from '@shared/components/confirm-dialog/confirm-dialog-data.model';

@Component({
    selector: 'app-info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}
}
