import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ISelectOption {
    value: any;
    displayName: string;
}
@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectInputComponent),
            multi: true,
        },
    ],
})
export class SelectInputComponent implements ControlValueAccessor, OnInit {
    @Input() options: ISelectOption[] = [];
    @Input() startValue: any;
    @Input() disabled: boolean;
    @Output() selectedOption = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _value: any;
    protected onChange: (_: any) => void;
    protected onTouched: () => void;

    get value(): any {
        return this._value;
    }

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            if (this.onChange) {
                this.onChange(v);
            }
        }
    }

    ngOnInit(): void {
        if (this.startValue) {
            this._value = this.startValue;
        }
    }

    writeValue(obj: any): void {
        this._value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onValueChange(value: any): void {
        this.selectedOption.emit(value);
        this.value = value;
    }
}
