import { ClozeGapAnswers, ClozeGapText } from '@apiModels';

import { ClozeGapAnswerModel } from './cloze-answer.model';
import { ClozeGapTextModel } from './cloze-text.model';

export class ClozeGapFactory {
    static factory(data: ClozeGapText | ClozeGapAnswers): ClozeGapAnswerModel | ClozeGapTextModel {
        if (data.type === 'GAP_TEXT') {
            return new ClozeGapTextModel(data as ClozeGapText);
        }
        return new ClozeGapAnswerModel(data as ClozeGapAnswers);
    }
}
