import { Component, forwardRef, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { IWidgetSupport } from 'src/app/course-editor/models/widget-support-info.model';

import { RealTimeInfoCardWidgetForm } from '../../../../api-wrapper/models/widget-models/info-card-widget-model';

@Component({
    selector: 'app-info-card-real-time-widget',
    templateUrl: './info-card-real-time-widget.component.html',
    styleUrls: ['./info-card-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InfoCardRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class InfoCardRealTimeWidgetComponent extends AbstractFormBaseComponent<
    RealTimeInfoCardWidgetForm,
    IWidgetSupport
> {
    colors = ['#009BEF', '#ba2d0b', '#4e5e67', '#f7931e', '#f08024', '#3c7d58', '#243539', '#2371e7'];
    pictograms = [
        'WARN',
        'QUESTION',
        'STUDY',
        'BOARD',
        'BLOCK',
        'GPS',
        'INFO',
        'BOOK',
        'LINK',
        'SPEAKER',
        'HAMMER',
        'KEY',
    ];
    testEnv = false;
    constructor() {
        super();
    }

    @HostBinding('attr.tabindex') tabindex = 0;

    createForm(): FormGroupTyped<RealTimeInfoCardWidgetForm> {
        return new FormGroupTyped<RealTimeInfoCardWidgetForm>({
            color: FormHelper.controlFactoryWithCalculatedValue(null),
            pictogram: FormHelper.controlFactoryWithCalculatedValue(null),
            text: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }
}
